// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import classnames from '@/helpers'
import jsStyles from './styles'
import styles from './styles.scss'

type ButtonProps = {|
  title?: string,
  variant?: 'primary' | 'secondary' | 'secondaryBlue',
  disabled?: boolean,
  onClick?: ?string | ((event: DOMEvent) => void),
  ariaLabel: string,
  imgUrls?: ?{ default: string, selected: string },
  id?: string,
  className?: string,
  type?: 'button' | 'reset' | 'submit',
  testId?: string,
|}

const ButtonComponent = (props: ButtonProps) => {
  const { variant } = props

  const btnProps = { ...props }
  const { ariaLabel, onClick, title, imgUrls, className, type, testId, ...rest } = btnProps
  const renderIcon = () => (imgUrls ? <span className="icon" /> : null)
  if (typeof onClick === 'string') {
    return (
      <Link
        to={onClick}
        aria-label={ariaLabel}
        className={classnames(styles.button, className)}
        css={jsStyles.button(props)}
        {...rest}
      >
        {renderIcon()}
        {title}
      </Link>
    )
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      variant={variant}
      onClick={onClick}
      aria-label={ariaLabel}
      className={classnames(styles.button, className)}
      css={jsStyles.button(props)}
      data-testid={testId}
      {...rest}
    >
      {renderIcon()}
      {title}
    </button>
  )
}

const defaultProps = {
  imgUrls: null,
  type: 'button',
  variant: 'primary',
  title: '',
  className: '',
  onClick: null,
  disabled: false,
  id: '',
  testId: '',
}

const PrimaryButton = (props: ButtonProps) => <ButtonComponent {...props} />
const SecondaryButton = (props: ButtonProps) => <ButtonComponent {...props} variant="secondary" />
const SecondaryBlueButton = (props: ButtonProps) => (
  <ButtonComponent {...props} variant="secondaryBlue" />
)

ButtonComponent.defaultProps = defaultProps
PrimaryButton.defaultProps = defaultProps
SecondaryButton.defaultProps = defaultProps
SecondaryBlueButton.defaultProps = defaultProps

export { PrimaryButton, SecondaryButton, SecondaryBlueButton }
