// @flow

import * as React from 'react'
import { Strings, Images, PasswordErrors as PWErrors } from '@/constants'
import styles from './styles.scss'
import jsStyles from './styles'

type PasswordErrorsProps = {
  errors: Array<string>,
}
export class PasswordErrors extends React.Component<PasswordErrorsProps> {
  renderError = (): ?Array<React.Node> => {
    const { errors } = this.props

    return PWErrors.map((err: string) => {
      const valid = errors.indexOf(err) === -1
      return (
        <div key={err} css={jsStyles.message(valid)}>
          <img
            src={valid ? Images.checkmark : Images.error2}
            alt={valid ? Strings.auth.altSuccess : Strings.auth.altError}
            className={styles.icon}
          />
          {err}
        </div>
      )
    })
  }

  render() {
    return (
      <div className={styles.container}>
        <span>Password must meet the following requirements:</span>
        {this.renderError()}
      </div>
    )
  }
}

export default PasswordErrors
