// @flow
import { merge } from 'lodash'

import { camelObjectToSnakeObject } from '@/helpers'
import BaseModel from './base-model'
import { defaultArray, defaultString } from '../defaults'

export type AdressesType = {|
  id?: ?string,
  address?: ?string,
|}

export type EntityDataType = {|
  id?: ?string,
  addresses?: ?Array<AdressesType>,
  city?: ?string,
  description?: ?string,
  designee: ?Array<EntityDataType>,
  entity_type?: ?string,
  email?: ?string,
  first_name?: ?string,
  last_name?: ?string,
  legal_name?: ?string,
  middle_name?: ?string,
  postal_code?: ?string,
  state?: ?string,
  cc_type?: ?Array<string>,
|}

const defaultDataValues = {
  id: null,
  addresses: null,
  city: null,
  description: null,
  designee: null,
  entity_type: null,
  email: null,
  first_name: null,
  last_name: null,
  legal_name: null,
  middle_name: null,
  postal_code: null,
  state: null,
  cc_type: null,
}

export default class Entity extends BaseModel {
  id: string

  addresses: Array<AdressesType>

  city: string

  description: string

  designee: Array<Entity>

  entityType: string

  email: string

  firstName: string

  lastName: string

  legalName: string

  middleName: string

  postalCode: string

  state: string

  cc_type: Array<string>

  constructor(rawData: Entity | EntityDataType = defaultDataValues) {
    super()
    const data: EntityDataType =
      rawData instanceof Entity
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    this.id = defaultString(data.id)
    this.addresses = defaultArray(data.addresses)
    this.city = defaultString(data.city)
    this.description = defaultString(data.description)
    this.designee = defaultArray(data.designee).map(i => new Entity(i))
    this.entityType = defaultString(data.entity_type)
    this.email = defaultString(data.email)
    this.firstName = defaultString(data.first_name)
    this.lastName = defaultString(data.last_name)
    this.legalName = defaultString(data.legal_name)
    this.middleName = defaultString(data.middle_name)
    this.postalCode = defaultString(data.postal_code)
    this.state = defaultString(data.state)
    this.cc_type = defaultArray(data.cc_type)
  }

  get formattedName(): string {
    if (this.entityType === 'TRUST') {
      return this.legalName
    }
    return [this.firstName, this.middleName, this.lastName].filter((name: string) => name).join(' ')
  }

  get secondaryAddress(): string {
    let stateCode = this.state || this.postalCode
    if (this.postalCode && this.state) {
      stateCode = `${this.state} ${this.postalCode}`
    }
    return [this.city, stateCode].filter((name: string) => name).join(', ')
  }
}
