// @flow
import React from 'react'

import { formatPercentageValue } from '@/helpers'

type PercentageCellProps = {|
  row: RowType,
|}

const PercentageCell = (props: PercentageCellProps) => {
  const { row } = props

  // If the first column's row.value is `Subtotal` we assume we are in a subtotal row
  const isSubtotal = row.original[Object.keys(row.original)[0]] === 'Subtotal'
  const isLast = row.original.last

  let className = 'tableCell percentage'
  className += isLast ? ' last' : ''
  className += isSubtotal ? ' subtotal' : ''
  return <div className={className}>{formatPercentageValue(row.value)}</div>
}

export default PercentageCell
