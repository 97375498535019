// @flow
import { isFinite, isPlainObject } from 'lodash'
import moment from 'moment'
import { snakeToCamel } from '@/helpers'

export const makeDate = (date: string | Date, extra: number = 0): Date => {
  let newDate = new Date()
  if (typeof date === 'string') {
    const momentDate = moment.utc(date, 'YYYY-MM-DD')
    if (extra !== 0) {
      momentDate.add(extra, 'd')
    }
    newDate = momentDate.toDate()
    newDate = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000)
  } else if (date instanceof Date) {
    newDate = date
  }
  return newDate
}

export const defaultString = (value: ?string): string => (value ? value.toString() : '')

export const defaultNumber = (value: ?string | number): number => {
  const num = parseInt(value, 10)
  if (isFinite(num)) {
    return num
  }
  return 0
}

export const defaultFloat = (value: ?string | number) => {
  const num = parseFloat(value)
  if (isFinite(num)) {
    return num
  }
  return 0
}

export const defaultBoolean = (value: ?boolean): boolean => !!value

export const defaultArray = (value: ?*): * => {
  let list = []
  if (Array.isArray(value)) {
    list = value
  } else if (value) {
    list = [value]
  }
  return list
}

export function defaultEnum<EnumType, EnumObject>(
  value: ?EnumType,
  enumObj: EnumObject,
): EnumType | '' {
  if (!(Object.values(enumObj).indexOf(value) > -1 && value !== null && value !== undefined)) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn('Enum not found', { enumObj, value })
    }
  }
  return value || ''
}

export const defaultObject = (value: ?*): Object | * => (isPlainObject(value) && value ? value : {})

export function defaultCamelCaseObject(value: ?Object) {
  const obj = {}
  if (value) {
    Object.keys(value).forEach(key => {
      //  $FlowFixMe
      const currentValue = value[key]
      obj[snakeToCamel(key)] = currentValue
      if (Array.isArray(currentValue)) {
        obj[snakeToCamel(key)] = currentValue.map(x => defaultObject(x))
      } else if (typeof currentValue === 'object') {
        obj[snakeToCamel(key)] = defaultObject(currentValue)
      } else if (currentValue === 'true' || currentValue === 'false') {
        obj[snakeToCamel(key)] = JSON.parse(currentValue)
      }
    })
  }
  return obj
}
