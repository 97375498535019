// @flow
import { normalize } from 'normalizr'
import { dispatchReducer, runReducers } from '@/helpers/dispatch-and-reduce'
import { defaultReducer, noOp } from './shared-reducers'
import { client } from './schemas'
// User Actions
export const ACTIONS = {
  SET_USER: 'SET_USER',
  SET_USER_DISABLED: 'SET_USER_DISABLED',
  SET_LAST_TWO: 'SET_LAST_TWO',
  CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
  LOGOUT_CURRENT_USER: 'LOGOUT_CURRENT_USER',
  PASSWORD_RESET: 'USER/PASSWORD_RESET',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  ACCEPT_USER_INVITE_N: 'ACCEPT_USER_INVITE_N',
  ACCEPT_USER_INVITE: 'ACCEPT_USER_INVITE',
  RESEND_USER_INVITE: 'RESEND_USER_INVITE',
  SET_USER_PASSWORD: 'SET_USER_PASSWORD',
  START_REQUEST: 'USER/START_REQUEST',
  END_REQUEST: 'USER/END_REQUEST',
  SET_EMAIL_SUCCESS: 'SET_EMAIL_SUCCESS',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_USERNAME_SUCCESS: 'SET_USERNAME_SUCCESS',
  SET_MOBILE_SUCCESS: 'SET_MOBILE_SUCCESS',
  HYDRATE_USER: 'HYDRATE_USER',
  RESEND_VERIFICATION_CODE: 'RESEND_VERIFICATION_CODE',
  VERIFY_DEVICE_SUCCESS: 'VERIFY_DEVICE_SUCCESS',
  SET_ACTIVE_USER: 'SET_ACTIVE_USER',
  INACTIVITY_WARNING_RESPONSE: 'INACTIVITY_WARNING_RESPONSE',
  LOAD_DATA: 'LOAD_DATA',
  LOAD_SHORT_DATA: 'LOAD_SHORT_DATA',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  // User Session Actions
  ADD_USER_SESSION: 'ADD_USER_SESSION',
  SET_CURRENT_USER_SESSION: 'SET_CURRENT_USER_SESSION',
  REMOVE_USER_SESSION: 'REMOVE_USER_SESSION',
  UPDATE_USER_SESSION: 'UPDATE_USER_SESSION',
}

export const INITIAL_STATE: UserStoreState = {
  user: null,
  last_two: '',
  user_disabled: false,
  activeClient: null,
  clients: {},
  currentSessionToken: null,
  sessionsList: [],
}

const setUser = (state: UserStoreState, payload: { user: User }) => {
  const { user } = payload

  return {
    ...state,
    user,
    activeClient: user?.id,
  }
}

const setUserDisabled = (state: UserStoreState) => ({
  ...state,
  user_disabled: true,
})

const setLastTwo = (state: UserStoreState, payload: { last_two: string }) => ({
  ...state,
  last_two: payload.last_two,
})

const clearUserSession = (state: UserStoreState) => ({
  ...state,
  user: null,
})

const acceptInviteSuccess = (state: UserStoreState, payload: User) => ({
  ...state,
  user: payload,
})

const setActiveUser = (state: UserStoreState, payload: string) => ({
  ...state,
  activeClient: payload,
})

const getClients = (state: UserStoreState, payload: any) => {
  const normalizedData = normalize(payload, [client])
  const clients = normalizedData.entities.clients // eslint-disable-line prefer-destructuring
  const clientIds = Object.keys(clients || {})

  return {
    ...state,
    clients,
    activeClient: clientIds[0] ? clientIds[0] : state.activeClient,
  }
}

export const reduxSet = {
  clearUserSession: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.CLEAR_USER_SESSION,
    clearUserSession,
  ),
  hydrateUser: dispatchReducer<UserStoreState, null>(ACTIONS.HYDRATE_USER, noOp),
  forgotPassword: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.FORGOT_PASSWORD,
    noOp,
  ),
  resetPassword: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.PASSWORD_RESET,
    noOp,
  ),
  loginSuccess: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.LOGIN_SUCCESS,
    defaultReducer,
  ),
  inviteAccepted: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.ACCEPT_USER_INVITE_N,
    defaultReducer,
  ),
  loginError: dispatchReducer<UserStoreState, { [string]: string }>(ACTIONS.LOGIN_ERROR, noOp),
  setUser: dispatchReducer<UserStoreState, { user: User }>(ACTIONS.SET_USER, setUser), // eslint-disable-line no-undef
  setUserDisabled: dispatchReducer<UserStoreState, {}>(ACTIONS.SET_USER_DISABLED, setUserDisabled), // eslint-disable-line no-undef
  setLastTwo: dispatchReducer<UserStoreState, { last_two: string }>(
    ACTIONS.SET_LAST_TWO,
    setLastTwo,
  ), // eslint-disable-line no-undef
  portalSetEmailSuccess: dispatchReducer<UserStoreState, { user: User }>( // eslint-disable-line no-undef
    ACTIONS.SET_EMAIL_SUCCESS,
    setUser,
  ),
  portalSetPasswordSuccess: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.SET_NEW_PASSWORD_SUCCESS,
    defaultReducer,
  ),
  portalSetUsernameSuccess: dispatchReducer<UserStoreState, { user: User }>( // eslint-disable-line no-undef
    ACTIONS.SET_USERNAME_SUCCESS,
    setUser,
  ),
  portalSetMobileSuccess: dispatchReducer<UserStoreState, { user: User }>( // eslint-disable-line no-undef
    ACTIONS.SET_MOBILE_SUCCESS,
    setUser,
  ),
  acceptInviteSuccess: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.ACCEPT_USER_INVITE,
    acceptInviteSuccess,
  ),
  resendInviteSuccess: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.RESEND_USER_INVITE,
    noOp,
  ),
  resendVerificationSuccess: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.RESEND_VERIFICATION_CODE,
    noOp,
  ),
  setActiveUser: dispatchReducer<UserStoreState, string>(ACTIONS.SET_ACTIVE_USER, setActiveUser),
  inactivityWarningResponse: dispatchReducer<UserStoreState, string>(
    ACTIONS.INACTIVITY_WARNING_RESPONSE,
    noOp,
  ),
  loadData: dispatchReducer<UserStoreState, string>(ACTIONS.LOAD_DATA, noOp),
  loadShortData: dispatchReducer<UserStoreState, string>(ACTIONS.LOAD_SHORT_DATA, noOp),
  getClientsSuccess: dispatchReducer<UserStoreState, any>(ACTIONS.GET_CLIENTS_SUCCESS, getClients),
  // User Session Reducers
  addUserSession: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.ADD_USER_SESSION,
    defaultReducer,
  ),
  setCurrentUserSession: dispatchReducer<UserStoreState, { [string]: string }>(
    ACTIONS.SET_CURRENT_USER_SESSION,
    defaultReducer,
  ),
}

export const reducer = (
  state: UserStoreState = { ...INITIAL_STATE },
  action: StandardAction,
): UserStoreState => runReducers(state, reduxSet, action)
