// @flow
import * as React from 'react'
import type Moment from 'moment'
import ReactCalendar from 'react-calendar'
import Access from '@/constants/accessibility'
import type { DateType } from '@/constants'
import { DateTypes } from '@/constants'
import { clickOutside } from '@/helpers/hoc'
import jsStyles from './styles'
import styles from './styles.scss'

const { START_DATE, END_DATE } = DateTypes

type STORYBOOK_STATE = {|
  storyOpen?: boolean,
|}

type PassedPropsType = {|
  ...STORYBOOK_STATE,
  handleDateChange: (date: string, which: DateType) => void,
  startDate: Moment,
  endDate: Moment,
  minStartDate: Moment,
  maxEndDate: Moment,
|}

type CalendarWrapperPropsType = {
  ...STORYBOOK_STATE,
  ...PassedPropsType,
}

export type CalendarWrapperStateType = {|
  calendarOpen: ?DateType,
|}

export class CalendarWrapper extends React.Component<
  CalendarWrapperPropsType,
  CalendarWrapperStateType,
> {
  _calendarRef = React.createRef()

  state = {
    calendarOpen: null,
  }

  static defaultProps = {
    storyOpen: false,
  }

  handleDayClicked = (date: string, which: DateType) => {
    const { handleDateChange } = this.props
    handleDateChange(date, which)
    this.closeCalendars()
  }

  closeCalendars = () => {
    this.setState({ calendarOpen: null })
  }

  toggleCalendar = (which: DateType) => {
    const { calendarOpen } = this.state
    if (which === START_DATE && calendarOpen !== START_DATE) {
      this.setState({ calendarOpen: START_DATE })
    } else if (which === END_DATE && calendarOpen !== END_DATE) {
      this.setState({ calendarOpen: END_DATE })
    } else {
      this.setState({ calendarOpen: null })
    }
  }

  renderCalendar = () => {
    const { calendarOpen } = this.state
    const { startDate, endDate, storyOpen, minStartDate, maxEndDate } = this.props
    const navigationLabel = ({ view, label }) => {
      if (view === 'decade') {
        return 'Select Year'
      }
      return label
    }

    if (calendarOpen === START_DATE || storyOpen) {
      return (
        <ReactCalendar
          requestClose={this.closeCalendars}
          calendarType="US"
          minDate={minStartDate.toDate()}
          maxDate={endDate.toDate()}
          value={startDate.toDate()}
          onClickDay={date => this.handleDayClicked(date, START_DATE)}
          activeStartDate={startDate.toDate()}
          navigationLabel={navigationLabel}
          ref={this._calendarRef}
          visible
        />
      )
    }
    if (calendarOpen === END_DATE) {
      return (
        <ReactCalendar
          requestClose={this.closeCalendars}
          calendarType="US"
          minDate={startDate.toDate()}
          maxDate={maxEndDate.toDate()}
          value={endDate.toDate()}
          onClickDay={date => this.handleDayClicked(date, END_DATE)}
          activeStartDate={endDate.toDate()}
          navigationLabel={navigationLabel}
          ref={this._calendarRef}
          visible
        />
      )
    }
    return null
  }

  render() {
    const { startDate, endDate } = this.props
    const { calendarOpen } = this.state
    const startOpen = calendarOpen === START_DATE
    const endOpen = calendarOpen === END_DATE
    const bothClosed = !startOpen && !endOpen
    const StartOrEndCalendar = this.renderCalendar

    const WrappedCalendarAndButtons = clickOutside(props => (
      <>
        <div css={jsStyles.calendar}>
          <StartOrEndCalendar {...props} />
        </div>
        <div className={styles.dateSelector} css={jsStyles.button(!!calendarOpen)}>
          <button
            type="button"
            onClick={() => this.toggleCalendar(START_DATE)}
            aria-label={Access.Components.Chart.ToggleStartCalendar}
            className={styles.dateButton}
          >
            <span css={jsStyles.buttonText(startOpen || bothClosed)}>
              {startDate.format('MMMM D, YYYY')}
            </span>
          </button>
          <span>to</span>
          <button
            type="button"
            onClick={() => this.toggleCalendar(END_DATE)}
            aria-label={Access.Components.Chart.ToggleEndCalendar}
            className={styles.dateButton}
          >
            <span css={jsStyles.buttonText(endOpen || bothClosed)}>
              {endDate.format('MMMM D, YYYY')}
            </span>
          </button>
        </div>
      </>
    ))

    return (
      <div ref={this._calendarRef}>
        <WrappedCalendarAndButtons />
      </div>
    )
  }
}

export default CalendarWrapper
