// @flow
import React from 'react'

type HeaderCellSortableProps = {|
  active: boolean,
  children: ?any,
  sortData: () => void,
  sortOrder: SortOrderType,
  style: Object,
  accessor: string,
|}

const HeaderCellSortable = (props: HeaderCellSortableProps) => {
  const { active, children, sortData, sortOrder, style, accessor } = props

  const className = active ? `tableHeader sortable active ${sortOrder}` : 'tableHeader sortable'

  return (
    <div
      role="button"
      tabIndex={0}
      className={className}
      onClick={sortData}
      onKeyPress={() => {}}
      style={style}
      data-accessor={`cell-${accessor}`}
    >
      {children}
    </div>
  )
}

export default HeaderCellSortable
