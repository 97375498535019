// @flow
import { Strings } from '@/constants'
import { ErrorKeys, PendingKeys, MetaKeys } from '@/redux/api'

const getApiStore = (store: StoreState): APIStoreState => store.api

const getError = (store: StoreState, key: $Keys<typeof ErrorKeys>): ?string => {
  const error = getApiStore(store)[key]

  if (error) {
    let errorMsg = error.message

    switch (key) {
      case ErrorKeys.loginUserError:
        if (error.statusCode === 504) {
          error.message = { message: Strings.auth.loginError.serverError, code: 0 }
          errorMsg = error.message
        }
        break
      case ErrorKeys.portalSetEmailError:
        if (error.statusCode === 412) {
          errorMsg = { message: Strings.settings.email.alreadyUsed, code: 0 }
        }
        break
      case ErrorKeys.portalSetUsernameError:
        if (error.statusCode === 412) {
          errorMsg = { message: Strings.settings.username.alreadyUsed, code: 0 }
        }
        break
      case ErrorKeys.portalSetMobileError:
        if (error.statusCode === 412) {
          errorMsg = { message: Strings.settings.mobile.notValid, code: 0 }
        }
        break

      default:
    }
    if (errorMsg && errorMsg.message) {
      return errorMsg.message
    }
  }
  return null
}

const getErrorObj = (store: StoreState, key: $Keys<typeof ErrorKeys>): ?ErrorType =>
  getApiStore(store)[key]

const getPending = (store: StoreState, key: $Keys<typeof PendingKeys>): boolean =>
  getApiStore(store)[key]

const getPrintPending = (store: StoreState): boolean =>
  getPending(store, PendingKeys.checkPDFResultPending) || getApiStore(store).generatePDFPending

const getPrintDetailedPending = (store: StoreState): boolean =>
  getPending(store, PendingKeys.generateDetailedPDFPending) ||
  getApiStore(store).generateDetailedPDFPending

const getPrintConsolidatedPending = (store: StoreState): boolean =>
  getPending(store, PendingKeys.generateConsolidatedPDFPending) ||
  getApiStore(store).generateConsolidatedPDFPending

const getMeta = (store: StoreState, key: $Keys<typeof MetaKeys>): Object => getApiStore(store)[key]

const getLifeInsurancePending = (store: StoreState) => {
  const insurancePoliciesPending = getPending(store, PendingKeys.getInsurancePoliciesPending)
  const annuityPoliciesPending = getPending(store, PendingKeys.getAnnuityPoliciesPending)
  const privatePlacementsPending = getPending(store, PendingKeys.getPrivatePlacementsPending)

  return insurancePoliciesPending || annuityPoliciesPending || privatePlacementsPending
}
const getLifeInsuranceShortPending = (store: StoreState) => {
  const insurancePoliciesPending = getPending(store, PendingKeys.getInsurancePoliciesShortPending)
  const VULPoliciesPending = getPending(store, PendingKeys.getVULPoliciesShortPending)
  const privatePlacementsPending = getPending(store, PendingKeys.getPrivatePlacementsShortPending)

  return insurancePoliciesPending || VULPoliciesPending || privatePlacementsPending
}

export default {
  getError,
  getErrorObj,
  getPending,
  getPrintPending,
  getPrintDetailedPending,
  getPrintConsolidatedPending,
  getLifeInsurancePending,
  getLifeInsuranceShortPending,
  getMeta,
}
