// @flow

import React from 'react'
import styles from './styles.scss'
import { Images } from '@/constants'

type UiModalProps = {
  onClose: () => void,
  children: React.Node,
  title: string,
}

class UiModal extends React.Component<UiModalProps> {
  render() {
    const { title, children, onClose } = this.props
    return (
      <div className={styles.modalWrapper}>
        <div className={styles.modalWindow}>
          <button className={styles.closeButton} type="button" onClick={onClose}>
            <img src={Images.dropdownClose} alt="Close" />
          </button>
          <h3 className={styles.modalTitle}>{title}</h3>
          {children}
        </div>
      </div>
    )
  }
}

export default UiModal
