// @flow
const StorageItems = {
  currentSessionToken: 'currentSessionToken',
  sessionsList: 'sessionsList',
  activeClient: 'activeClient',
}

const StorageService = () => {
  const getItemFromLocalStorage = key => localStorage.getItem(key)

  const setItemToLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
  }

  const removeItemFromLocalStorage = key => {
    localStorage.removeItem(key)
  }

  const setAuth = (token: string) => {
    setItemToLocalStorage(StorageItems.currentSessionToken, token)
  }

  const getAuth = (): ?{ token?: string } => {
    const token = getItemFromLocalStorage(StorageItems.currentSessionToken)
    return token ? { token } : null
  }

  const deleteAuth = () => {
    removeItemFromLocalStorage(StorageItems.currentSessionToken)
  }

  const setSessionsList = (data: Array<UserSession>) => {
    setItemToLocalStorage(StorageItems.sessionsList, JSON.stringify(data))
  }

  const getSessionsList = (): Array<UserSession> => {
    const sessionsList = getItemFromLocalStorage(StorageItems.sessionsList)
    return sessionsList ? JSON.parse(sessionsList) : []
  }

  const deleteSessionsList = () => {
    removeItemFromLocalStorage(StorageItems.sessionsList)
  }

  const removeTokenFromSessionsList = (token: string) => {
    const sessionsList = getSessionsList()
    const newSessionsList = sessionsList.filter(session => session.token !== token)
    setSessionsList(newSessionsList)
  }

  const setActiveUser = (data: string) => {
    // it sets active CLIENT
    setItemToLocalStorage(StorageItems.activeClient, data)
  }

  const getActiveUser = () => getItemFromLocalStorage(StorageItems.activeClient) // it gets active CLIENT

  const deleteActiveUser = () => {
    removeItemFromLocalStorage(StorageItems.activeClient) // it delete active CLIENT
  }

  const clearStorage = () => {
    deleteActiveUser()
    deleteAuth()
  }

  return {
    setAuth,
    getAuth,
    deleteAuth,
    setActiveUser,
    getActiveUser,
    deleteActiveUser,
    clearStorage,
    setSessionsList,
    getSessionsList,
    deleteSessionsList,
    removeTokenFromSessionsList,
  }
}
export default StorageService()
