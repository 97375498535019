// @flow
import BaseModel from '../base/base-model'
import { defaultString } from '../defaults'

export type IULMonthlyStatementDataType = {|
  id: ?string,
  policy_id: ?string,
  date: ?string,
  uploaded_file: ?string,
|}

export default class IULMonthlyStatement extends BaseModel {
  id: string

  policyId: string

  date: string

  uploadedFile: string

  constructor(
    data: IULMonthlyStatementDataType = {
      id: null,
      policy_id: null,
      date: null,
      uploaded_file: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.policyId = defaultString(data.policy_id)
    this.date = defaultString(data.date)
    this.uploadedFile = defaultString(data.uploaded_file)
  }
}
