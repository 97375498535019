// @flow
import * as React from 'react'
import { Strings } from '@/constants'
import styles from './styles.scss'

type InceptionTextProps = {|
  withAsterisk?: boolean,
|}

export const InceptionText = (props: InceptionTextProps) => {
  const { withAsterisk } = props

  return (
    <div className={styles.inception}>
      {withAsterisk && '* '}
      {Strings.inceptionWarning}
    </div>
  )
}

export default InceptionText
