// @flow
import * as React from 'react'
import styles from './styles.scss'
import classnames from '@/helpers'

type UiTableRowProps = {|
  children: React.Node,
  isActive?: boolean,
|}

class UiTableRow extends React.Component<UiTableRowProps> {
  render() {
    const { children, isActive } = this.props
    return <tr className={classnames(styles.row, isActive && styles.rowActive)}>{children}</tr>
  }
}

export default UiTableRow
