// @flow
import React, { Suspense, lazy } from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/core'
import configureStore from '@/redux'
import { Routes } from '@/constants'
import history from '@/helpers/history'

// $FlowFixMe
import config from '@/agencies-config'
import App from '@/screens'
import { AppWrapper, PrivateRoute, ScrollToTop } from '@/components'
import styles from '@/styles/main.scss'

__webpack_nonce__ = config.nonce

const Homepage = lazy(() => import('@/screens/home'))
const ClientHomeScreen = lazy(() => import('@/screens/portal/home'))
const ClientListScreen = lazy(() => import('@/screens/portal/client-list'))
const AnnuityPortfolioScreen = lazy(() => import('@/screens/portal/annuity-portfolio'))
const LifeInsurancePortfolioScreen = lazy(() => import('@/screens/portal/life-insurance-portfolio'))
const DisabilityPorfolioScreen = lazy(() => import('@/screens/portal/disability-portfolio'))
const PolicyDetailAnnuityScreen = lazy(() => import('@/screens/portal/policy-detail/annuity'))
const PolicyDetailLifeScreen = lazy(() => import('@/screens/portal/policy-detail/life'))
const DisabilityDetailScreen = lazy(() => import('@/screens/portal/policy-detail/disability'))
const FundPerformanceScreen = lazy(() => import('@/screens/portal/fund-performance'))
const PolicyDocumentsScreen = lazy(() => import('@/screens/portal/policy-documents'))
const IULScreen = lazy(() => import('@/screens/portal/iul-statements'))
const PPAScreen = lazy(() => import('@/screens/portal/statement-list/ppa-screen'))
const PPLIScreen = lazy(() => import('@/screens/portal/statement-list/ppli-screen'))
const PPADetailScreen = lazy(() => import('@/screens/portal/statement-list/ppa-screen-detail'))
const PPLIDetailScreen = lazy(() => import('@/screens/portal/statement-list/ppli-screen-detail'))
const VULScreen = lazy(() => import('@/screens/portal/statement-list/vul-screen'))
const VULDetailScreen = lazy(() => import('@/screens/portal/statement-list/vul-screen-detail'))
const BrokenLinkScreen = lazy(() => import('@/screens/broken-or-expired/broken-link'))
const ExpiredRegistrationScreen = lazy(() =>
  import('@/screens/broken-or-expired/expired-registration'),
)
const ExpiredResetScreen = lazy(() => import('@/screens/broken-or-expired/expired-reset'))
const TermsScreen = lazy(() => import('@/screens/portal/terms-screen'))
const PrivacyNoticeScreen = lazy(() => import('@/screens/portal/privacy-notice'))
const LockAccountScreen = lazy(() => import('@/screens/lock-account'))
const LoginScreen = lazy(() => import('@/screens/auth/login'))
const VerifyDeviceScreen = lazy(() => import('@/screens/auth/verify-device'))
const ForgotPasswordScreen = lazy(() => import('@/screens/auth/forgot-password'))
const ResetPasswordScreen = lazy(() => import('@/screens/auth/reset-password'))
const AcceptInviteScreen = lazy(() => import('@/screens/auth/accept-invite'))

// Normalize must load first or it will overwrite our styles
require('normalize.css/normalize.css')
require('@/../src/styles/fonts.scss')
require('@/../src/styles/defaults.scss')

const cache = createCache({
  nonce: config.nonce,
})

const AppRoutes = () => (
  <CacheProvider value={cache}>
    <Provider store={configureStore()}>
      <ConnectedRouter history={history}>
        <Suspense
          fallback={
            <AppWrapper>
              <div className={styles.minHeight} />
            </AppWrapper>
          }
        >
          <ScrollToTop>
            <App>
              <Switch>
                <Route exact path={Routes.public.Home} component={Homepage} />
                <Route
                  exact
                  path={Routes.public.ExpiredRegistration}
                  component={ExpiredRegistrationScreen}
                />
                <Route exact path={Routes.public.ExpiredReset} component={ExpiredResetScreen} />
                <Route exact path={Routes.public.LockAccount} component={LockAccountScreen} />

                <PrivateRoute exact path={Routes.portal.Terms} component={TermsScreen} />
                <Route exact path={Routes.portal.PrivacyNotice} component={PrivacyNoticeScreen} />

                <PrivateRoute exact path={Routes.portal.ClientHome} component={ClientHomeScreen} />
                <PrivateRoute
                  exact
                  path={Routes.portal.InsurancePortfolio}
                  component={LifeInsurancePortfolioScreen}
                />
                <PrivateRoute
                  exact
                  path={Routes.portal.AnnuityPortfolio}
                  component={AnnuityPortfolioScreen}
                />
                <PrivateRoute
                  exact
                  path={Routes.portal.DisabilityPortfolio}
                  component={DisabilityPorfolioScreen}
                />
                <PrivateRoute
                  exact
                  path={Routes.portal.PolicyDetail}
                  component={PolicyDetailLifeScreen}
                />
                <PrivateRoute
                  exact
                  path={Routes.portal.AccountDetail}
                  component={PolicyDetailAnnuityScreen}
                />
                <PrivateRoute
                  exact
                  path={Routes.portal.PolicyDocuments}
                  component={PolicyDocumentsScreen}
                />
                <PrivateRoute exact path={Routes.portal.IUL} component={IULScreen} />
                <PrivateRoute
                  exact
                  path={Routes.portal.DisabilityDetail}
                  component={DisabilityDetailScreen}
                />
                <PrivateRoute
                  exact
                  path={Routes.portal.FundPerformance}
                  component={FundPerformanceScreen}
                />

                <PrivateRoute exact path={Routes.portal.PPA} component={PPAScreen} />
                <PrivateRoute exact path={Routes.portal.PPLI} component={PPLIScreen} />
                <PrivateRoute exact path={Routes.portal.VUL} component={VULScreen} />
                <PrivateRoute exact path={Routes.portal.PPADetail} component={PPADetailScreen} />
                <PrivateRoute exact path={Routes.portal.PPLIDetail} component={PPLIDetailScreen} />
                <PrivateRoute exact path={Routes.portal.VULDetail} component={VULDetailScreen} />
                <PrivateRoute exact path={Routes.portal.ClientList} component={ClientListScreen} />

                <Route exact path={Routes.auth.Login} component={LoginScreen} />
                <Route exact path={Routes.auth.VerifyDevice} component={VerifyDeviceScreen} />
                <Route exact path={Routes.auth.ForgotPassword} component={ForgotPasswordScreen} />
                <Route exact path={Routes.auth.ResetPassword} component={ResetPasswordScreen} />
                <Route exact path={Routes.auth.AcceptInvite} component={AcceptInviteScreen} />

                <Route component={BrokenLinkScreen} />
              </Switch>
            </App>
          </ScrollToTop>
        </Suspense>
      </ConnectedRouter>
    </Provider>
  </CacheProvider>
)

ReactDOM.render(<AppRoutes />, document.getElementById('app'))
