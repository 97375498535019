// @flow
import BaseModel from './base/base-model'
import mappers from './mappers'
import Client from './client'
import Entity from './base/entity'
import Annuity from './policies/annuity'
import AnnuityShort from './policies/annuity-short'
import Disability from './policies/disability'
import Footnote from './footnotes/footnote'
import FootnoteGroup from './footnotes/footnote-group'
import IDF from './idf/idf'
import IDFImage from './idf/idf-image'
import IDFProduct from './idf/idf-product'
import IDFStatement from './idf/idf-statement'
import IDFTransaction from './idf/idf-transaction'
import InsuranceCompany from './insurance-company'
import InsurancePolicy from './policies/insurance-policy'
import PolicyDocument from './policies-documents/policy-document'
import PremiumPayment from './premium-payment'
import PPAStatement from './policies-statements/ppa-statement'
import PPLIStatement from './policies-statements/ppli-statement'
import VULStatement from './policies-statements/vul-statement'
import VULPolicy from './policies/vul-policy'
import PrivatePlacement from './policies/private-placement'
import Product from './product'
import User from './user'
import Advisor from './advisor'
import XIRRRate from './xirr-rate'
import XIRRRateIDF from './xirr-rate-idf'
import IULMonthlyStatement from './policies-statements/iul-monthly-statement'
import IULAnnualStatement from './policies-statements/iul-annual-statement'
import IndexAccountRates from './index-account-rates'
import GeneralDocuments from './general-documents'
import SessionStatus from './session-status'
import InsurancePolicyShort from '@/models/policies/insurance-policy-short'
import InsurancePolicyCalendar from '@/models/policies/insurance-policy-calendar'
import PrivatePlacementCalendar from '@/models/policies/private-placement-calendar'
import VULPolicyCalendar from '@/models/policies/vul-policy-calendar'
import PrivatePlacementShort from '@/models/policies/private-placement-short'
import VULPolicyShort from '@/models/policies/vul-policy-short'
import ConsolidatedStatement from '@/models/policies-statements/consolidated-statement'

const models = {
  Advisor,
  Client,
  Annuity,
  AnnuityShort,
  Disability,
  Entity,
  FootnoteGroup,
  IDF,
  IDFImage,
  IDFProduct,
  IDFStatement,
  IDFTransaction,
  InsuranceCompany,
  InsurancePolicy,
  InsurancePolicyShort,
  InsurancePolicyCalendar,
  PrivatePlacementCalendar,
  VULPolicyCalendar,
  PolicyDocument,
  PPAStatement,
  PPLIStatement,
  VULPolicy,
  VULPolicyShort,
  VULStatement,
  PremiumPayment,
  PrivatePlacement,
  PrivatePlacementShort,
  Product,
  User,
  XIRRRate,
  XIRRRateIDF,
  IULMonthlyStatement,
  IULAnnualStatement,
  IndexAccountRates,
  GeneralDocuments,
  SessionStatus,
  ConsolidatedStatement,
  ...mappers,
}

export const ModelTypes: { [string]: string } = Object.keys(models).reduce((acc, curr: string) => {
  acc[curr] = curr
  return acc
}, {})

export function modelMapper(responseType: string, data: Object) {
  const Model = models[responseType]
  if (Object.getPrototypeOf(Model).prototype === BaseModel.prototype) {
    let modeled = null
    if (Array.isArray(data)) {
      modeled = data.map(d => new Model(d))
    } else {
      modeled = new Model(data)
    }
    return { [responseType.toLocaleLowerCase()]: modeled }
  }
  if (typeof Model === 'function') {
    return Model(data)
  }
  return data
}

export {
  Annuity,
  AnnuityShort,
  Disability,
  Entity,
  Footnote,
  FootnoteGroup,
  IDF,
  IDFImage,
  IDFProduct,
  IDFStatement,
  IDFTransaction,
  InsuranceCompany,
  InsurancePolicy,
  InsurancePolicyShort,
  InsurancePolicyCalendar,
  PrivatePlacementCalendar,
  VULPolicyCalendar,
  PolicyDocument,
  PPAStatement,
  PPLIStatement,
  VULPolicy,
  VULPolicyShort,
  VULStatement,
  PremiumPayment,
  PrivatePlacement,
  PrivatePlacementShort,
  Product,
  User,
  Client,
  Advisor,
  XIRRRate,
  XIRRRateIDF,
  IULMonthlyStatement,
  IULAnnualStatement,
  IndexAccountRates,
  GeneralDocuments,
  SessionStatus,
  ConsolidatedStatement,
}

export type ModelKeys =
  | $Keys<Annuity>
  | $Keys<AnnuityShort>
  | $Keys<InsurancePolicy>
  | $Keys<InsurancePolicyShort>
  | $Keys<VULPolicy>
  | $Keys<VULPolicyShort>
  | $Keys<Entity>
  | $Keys<Disability>
export type PPStatementModelKeys = $Keys<PPAStatement> | $Keys<PPLIStatement> | $Keys<VULStatement>

export default models
