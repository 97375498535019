// @flow
import React from 'react'

type FooterCellProps = {|
  children: ?any,
|}

const FooterCell = (props: FooterCellProps) => {
  const { children } = props
  return <div className="tableCellMain footer footerText">{children}</div>
}

export default FooterCell
