// @flow

import BaseModel from './base/base-model'
import { defaultString } from './defaults'

export type GeneralDocumentsDataType = {|
  id: ?string,
  filename: ?string,
  section: ?string,
  url: ?string,
|}

export default class GeneralDocuments extends BaseModel {
  id: string

  filename: string

  section: string

  url: string

  constructor(
    data: GeneralDocumentsDataType = {
      id: null,
      filename: null,
      section: null,
      url: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.filename = defaultString(data.filename)
    this.section = defaultString(data.section)
    this.url = defaultString(data.url)
  }
}
