// @flow
import * as React from 'react'

type HOCProps = {|
  // These are required for the component to work
  // We are wrapping every Input in this component
  // But not every input has these props because it doens't need to use this component
  visible?: boolean,
  requestClose?: () => void,
  forwardedRef?: { current: null | React.ElementRef<any> },
|}

const defaultProps = {
  visible: false,
  requestClose: () => {},
  forwardedRef: { current: null },
}

// type Config = React.Config<HOCProps, typeof defaultProps>

export function ClickOutsideHOC<Config: {}, Instance>(
  WrappedComponent: React.AbstractComponent<React.Config<HOCProps, typeof defaultProps>, Instance>,
) {
  class ClickOutside extends React.Component<*, *> {
    props: HOCProps

    ref: ?React.Component<*>

    node: any

    static defaultProps = defaultProps

    componentDidMount() {
      const { forwardedRef } = this.props
      if (forwardedRef && forwardedRef.current) {
        this.node = forwardedRef.current
      }
      // $FlowFixMe
      document.addEventListener('mousedown', this.close)
    }

    componentWillUnmount() {
      // $FlowFixMe
      document.removeEventListener('mousedown', this.close)
    }

    close = (e: DOMEvent) => {
      const { requestClose, visible } = this.props
      if (
        this.node &&
        !this.node.contains(e.target) &&
        visible &&
        typeof requestClose === 'function'
      ) {
        requestClose()
      }
    }

    render() {
      const { forwardedRef, ...rest } = this.props
      return <WrappedComponent {...rest} ref={forwardedRef} />
    }
  }

  function forwardRef(props, ref) {
    return <ClickOutside {...props} forwardedRef={ref} />
  }

  return React.forwardRef<Config, Instance>(forwardRef)
}

export default ClickOutsideHOC
