// @flow
import moment from 'moment'
import { IDFImage } from '@/models'
import { Enums } from '@/constants'

import BaseModel from '../base/base-model'
import { defaultArray, defaultFloat, defaultString, makeDate } from '../defaults'

export type PPAStatementDataType = {|
  id?: ?string,
  policy_id?: ?string,
  statement_start?: ?string,
  statement_end?: ?string,
  account_value?: ?number,
  policy_gross_account_value?: ?number,
  surrender_charge?: ?number,
  policy_loan_balance?: ?number,
  cost_basis?: ?number,
  idf_statements: ?Array<number>,
  insurance_proceeds?: ?number,
  policy_account_deposits?: ?number,
  policy_account_withdrawals?: ?number,
  policy_loan?: ?number,
  policy_structuring_fee?: ?number,
  policy_premium_tax?: ?number,
  policy_m_and_e_fee?: ?number,
  policy_mortality_charge?: ?number,
  beginning_balance_net?: ?number,
  beginning_balance_gross?: ?number,
  idf_net_return_month?: ?number,
  idf_net_return_ytd?: ?number,
  idf_net_return_since_inception?: ?number,
  variance_note?: ?$Values<typeof Enums.VarianceNote>,
|}

export default class PPAStatement extends BaseModel {
  id: string

  policyId: string

  statementStart: string

  statementEnd: string

  accountValue: number

  idfStatements: Array<string>

  availableIdfImages: Array<IDFImage>

  insuranceProceeds: number

  policyGrossAccountValue: number

  surrenderCharge: number

  policyLoanBalance: number

  costBasis: number

  policyAccountDeposits: number

  policyAccountWithdrawals: number

  policyLoan: number

  policyStructuringFee: number

  policyPremiumTax: number

  policyIDFReturn: number

  policyIDFReturnYTD: number

  policyIDFReturnSinceInception: number

  policyMAndEFee: number

  policyMortalityCharge: number

  beginningBalanceNet: number

  beginningBalanceGross: number

  idfNetReturnMonth: number

  idfNetReturnYTD: number

  idfNetReturnSinceInception: number

  varianceNote: $Values<typeof Enums.VarianceNote>

  constructor(
    data: PPAStatementDataType = {
      id: null,
      policy_id: null,
      statement_start: null,
      statement_end: null,
      account_value: null,
      idf_statements: null,
      insurance_proceeds: null,
      policy_gross_account_value: null,
      surrender_charge: null,
      policy_loan_balance: null,
      cost_basis: null,
      policy_account_deposits: null,
      policy_account_withdrawals: null,
      policy_loan: null,
      policy_structuring_fee: null,
      policy_premium_tax: null,
      policy_m_and_e_fee: null,
      policy_mortality_charge: null,
      beginning_balance_net: null,
      beginning_balance_gross: null,
      idf_net_return_month: null,
      idf_net_return_ytd: null,
      idf_net_return_since_inception: null,
      variance_note: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.idfStatements = defaultArray(data.idf_statements)
    this.policyId = defaultString(data.policy_id)
    this.statementStart = defaultString(data.statement_start)
    this.statementEnd = defaultString(data.statement_end)
    this.accountValue = defaultFloat(data.account_value)
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.surrenderCharge = defaultFloat(data.surrender_charge)
    this.policyLoanBalance = defaultFloat(data.policy_loan_balance)
    this.costBasis = defaultFloat(data.cost_basis)
    this.policyAccountDeposits = defaultFloat(data.policy_account_deposits)
    this.policyAccountWithdrawals = defaultFloat(data.policy_account_withdrawals)
    this.policyLoan = defaultFloat(data.policy_loan)
    this.policyStructuringFee = defaultFloat(data.policy_structuring_fee)
    this.policyPremiumTax = defaultFloat(data.policy_premium_tax)
    this.policyMAndEFee = defaultFloat(data.policy_m_and_e_fee)
    this.policyMortalityCharge = defaultFloat(data.policy_mortality_charge)
    this.beginningBalanceNet = defaultFloat(data.beginning_balance_net)
    this.beginningBalanceGross = defaultFloat(data.beginning_balance_gross)
    this.accountValue = defaultFloat(data.account_value)
    this.idfNetReturnMonth = defaultFloat(data.idf_net_return_month)
    this.idfNetReturnYTD = defaultFloat(data.idf_net_return_ytd)
    this.idfNetReturnSinceInception = defaultFloat(data.idf_net_return_since_inception)
    this.varianceNote = defaultString(data.variance_note)
  }

  get statementYear(): number {
    return moment(this.statementStart).year()
  }

  get statementStartMonth(): number {
    return moment(this.statementStart).month()
  }

  get statementStartMonthYear(): string {
    return moment(this.statementStart).format('MMMM, YYYY')
  }

  get statementEndMonthYear(): string {
    return moment(this.statementEnd).format('MMMM, YYYY')
  }

  get statementStartFormat(): string {
    return moment(this.statementStart).format('MMMM DD, YYYY')
  }

  get statementEndFormat(): string {
    return moment(this.statementEnd).format('MMMM DD, YYYY')
  }

  get statementStartDate(): Date {
    return makeDate(this.statementStart)
  }

  get statementEndDate(): Date {
    return makeDate(this.statementEnd)
  }

  startedOnOrBefore(month: string): boolean {
    return moment(this.statementStart).isSameOrBefore(month)
  }
}
