// @flow
import { Enums } from '@/constants'

import BaseModel from '../base/base-model'
import { defaultEnum, defaultString } from '../defaults'

export type PolicyDocumentDataType = {|
  id: ?string,
  policy_id: ?string,
  ext: ?string,
  name: ?string,
  created_at: ?string,
  created_by: ?string,
  upload_file_type: ?$Values<typeof Enums.UploadFileType>,
  url: ?string,
|}

export default class PolicyDocument extends BaseModel {
  id: string

  policyId: string

  ext: string

  name: string

  createdAt: string

  uploadFileType: $Values<typeof Enums.UploadFileType>

  url: string

  constructor(
    data: PolicyDocumentDataType = {
      id: null,
      created_by: null,
      policy_id: null,
      ext: null,
      name: null,
      created_at: null,
      upload_file_type: null,
      url: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.policyId = defaultString(data.policy_id)
    this.ext = defaultString(data.ext)
    this.name = defaultString(data.name)
    this.createdAt = defaultString(data.created_at)
    this.uploadFileType = defaultEnum(data.upload_file_type, Enums.IULUploadedFileType)
    this.url = defaultString(data.url)
  }
}
