/* eslint-disable no-restricted-syntax */
// @flow

import * as React from 'react'
import { orderBy } from 'lodash'
import ReactTable from 'react-table'

import { singleSheetDownload } from '@/helpers/xlsx'
import { sortKeysMap } from '@/helpers/sortKeysMap'

import { Loading } from '@/components'
import Accordion from '@/components/accordion'
import Footnotes from '@/components/footnotes'
import { defaultString } from '@/models/defaults'
import NoData from '@/components/no-data'
import { generatePDFPayload } from '@/redux/api-payloads'
import type { Format as FormatType } from './format'
import Format from './format'

import TableHeader from './table-header'
import HeaderCell from './header-cell'
import HeaderCellSortable from './header-cell-sortable'
import Cell from './cell'
import CurrencyCell from './currency-cell'
import FooterCell from './footer-cell'
import FooterCellPercentage from './footer-cell-percentage'
import FooterCellTotal from './footer-cell-total'
import PercentageCell from './percentage-cell'
import TableActions from './table-actions'

import styles from './styles.scss'
import './react-table.css'

type GolcondaTableProps = {|
  ownerName: ?string,
  title?: string,
  notes?: ?Array<FootnoteType>,
  pdfKey: PDFKeysType,
  data: Array<TableFormats>,
  columns: Array<TableColumnType>,
  sortBy?: string,
  sortOrder?: SortOrderType,
  loading?: boolean,
  download?: boolean,
  accordion?: boolean,
  accordionTitle?: string,
  noDataText?: string,
  printPDF?: ?(payload: ApiPayloadType<PrintPayload>) => void,
  printPending?: boolean,
  _printMeta: ?{ id: string },
  ppaTable?: boolean,
  vulTable?: boolean,
  hideIcons?: boolean,
  onSortingUpdate?: ({
    sortBy?: string,
    sortOrder?: SortOrderType,
  }) => void,
|}

type GolcondaTableState = {|
  sortBy: string,
  sortOrder: SortOrderType,
|}

class GolcondaTable extends React.Component<GolcondaTableProps, GolcondaTableState> {
  format: FormatType

  static defaultProps = {
    noDataText: '',
    accordionTitle: '',
    accordion: false,
    download: false,
    notes: null,
    sortBy: '',
    sortOrder: 'desc',
    title: '',
    printPDF: null,
    printPending: false,
    _printMeta: null,
    ppaTable: false,
    vulTable: false,
    hideIcons: false,
    loading: false,
  }

  constructor(props: GolcondaTableProps) {
    super(props)

    this.format = new Format({
      title: props.title,
      columns: props.columns,
      ownerName: props.ownerName,
      ppaTable: props.ppaTable,
      vulTable: props.vulTable,
    })

    this.state = {
      sortBy: defaultString(props.sortBy),
      sortOrder: props.sortOrder || 'desc',
    }
  }

  componentDidMount() {
    this.detectOverflowingCells()
    this.formatDollarSignsAndDashes()
  }

  componentDidUpdate(prevProps: GolcondaTableProps) {
    const { data } = this.props
    if (data !== prevProps.data) {
      this.detectOverflowingCells()
    }
    this.formatDollarSignsAndDashes()
  }

  sortData = (sortBy: string) => {
    const { onSortingUpdate } = this.props
    const { sortOrder } = this.state

    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc'

    this.setState({
      sortBy,
      sortOrder: newOrder,
    })

    if (onSortingUpdate) {
      onSortingUpdate({
        sortBy,
        sortOrder: newOrder,
      })
    }
  }

  showNoDataComponent = (tableWidth: number) => {
    const { noDataText, loading } = this.props
    if (!loading && noDataText) {
      return <NoData isTable type={noDataText} tableWidth={tableWidth} />
    }
    return null
  }

  renderLoading = (tableWidth: number) => {
    const { loading } = this.props
    return loading ? <Loading isTable tableWidth={tableWidth} /> : null
  }

  shouldGroupColumn = () => {
    const { columns } = this.props
    const { sortBy } = this.state
    // eslint-disable-next-line no-unused-vars
    for (const col of columns) {
      if ((col.accessor === sortBy || col.sortBy === sortBy) && col.group) {
        return true
      }
    }

    return false
  }

  formatData = (data: TableDataType, groupBy?: string, subtotalColumn?: string) => {
    const rows = this.format.resolveData(data, groupBy, subtotalColumn)
    this.format.setTableState(rows)
    return rows
  }

  getTableAsXLSX = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation()
    const { download } = this.props
    const tableAsString: ?PayloadSheet = download ? this.format.getTableAsXLSXInput() : null
    if (tableAsString) {
      singleSheetDownload(tableAsString)
    }
  }

  getPrintPayload = (): PrintPayload => {
    const { pdfKey } = this.props
    const { sortBy, sortOrder } = this.state

    let sortKey =
      pdfKey !== '' && sortBy !== '' && Object.prototype.hasOwnProperty.call(sortKeysMap, pdfKey)
        ? sortKeysMap[pdfKey][sortBy]
        : null

    if (sortKey && sortOrder === 'desc') {
      sortKey = `-${sortKey}`
    }

    return {
      pdfKey,
      sortKey,
    }
  }

  getSortedData = () => {
    const { data } = this.props
    const { sortBy, sortOrder } = this.state

    if (sortBy) {
      // $FlowFixMe
      return orderBy(data, [sortBy], [sortOrder])
    }

    return data
  }

  printPayload = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation()
    const { printPDF, pdfKey } = this.props
    const printDataPayload = this.getPrintPayload()
    const printPayload = generatePDFPayload(printDataPayload, { id: pdfKey || '' })
    if (printPDF) {
      printPDF(printPayload)
    }
  }

  detectOverflowingCells = () => {
    const cells = document.querySelectorAll(
      '.tableCellMain, .tableCell, .tableCellMain button, .tableCell button',
    )
    if (cells.length) {
      Array.from(cells).forEach(cell => {
        const overflowing = cell.clientWidth < cell.scrollWidth
        if (
          overflowing &&
          cell.className.indexOf('subtotal') === -1 &&
          cell.className.indexOf('currency') === -1
        ) {
          cell.classList.add('overflowing')
        }
      })
    }
  }

  formatDollarSignsAndDashes = () => {
    const currencyCells = document.querySelectorAll('[data-accessor^=currency-]')
    if (currencyCells.length) {
      const currencyCellMap = Array.from(currencyCells)
      const currencyAccessors = new Set(currencyCellMap.map(cell => cell.dataset.accessor))
      currencyAccessors.forEach(accessor => {
        const cells = document.querySelectorAll(`[data-accessor=${accessor}] span`)
        let longestCellWidth = Math.max(...Array.from(cells).map(cell => cell.offsetWidth))

        const empty = Array.from(cells).filter(cell => {
          cell.style.width = 'initial' /* eslint-disable-line no-param-reassign */
          return Number.isNaN(parseInt(cell.innerHTML.replace('(', '').replace(')', ''), 10))
        })

        const notEmpty = Array.from(cells).filter(cell => !empty.includes(cell))
        let averageNotEmptyWidth =
          notEmpty.map(cell => cell.offsetWidth).reduce((a, b) => a + b, 0) / notEmpty.length

        const allEmpty = empty.length === Array.from(cells).length
        const someEmpty = empty.length > 0
        if (allEmpty) {
          // If all the cells are empty we need to force this to have a higher value
          longestCellWidth = 53
          averageNotEmptyWidth = 53
        }
        const dollarSignCells = document.querySelectorAll(`[data-accessor=${accessor}].dollarSign`)
        Array.from(dollarSignCells).forEach(dollarSignCell => {
          const dollarSignEl = dollarSignCell.querySelector('span.dollarSignText')
          if (dollarSignEl) {
            if (someEmpty && longestCellWidth < 53) {
              longestCellWidth = 53
            }
            dollarSignEl.style.right = `${longestCellWidth + 27}px`
          }
        })

        // Set the width of the zero value dashes so they can be properly centered.
        const zeroValueCells = document.querySelectorAll(
          `[data-accessor=${accessor}].nullValue span.value`,
        )
        Array.from(zeroValueCells).forEach(zeroValueCell => {
          zeroValueCell.style.width = `${averageNotEmptyWidth}px` /* eslint-disable-line no-param-reassign */
        })
      })
    }
  }

  render() {
    const {
      title,
      notes,
      columns,
      loading,
      accordion,
      accordionTitle,
      printPending,
      pdfKey,
      _printMeta,
      hideIcons = false,
    } = this.props

    const { sortBy, sortOrder } = this.state
    const printPendingTable = !!(printPending && _printMeta && _printMeta.id === pdfKey)
    const sortedData = this.getSortedData()

    const formattedColumns = this.format.generateColumns(
      sortBy,
      sortOrder,
      this.sortData,
      sortedData,
      columns,
      loading,
      pdfKey,
    )
    const tableWidth = this.format.getTableWidth(formattedColumns)

    // ensure we never hide rows
    const pageSize = sortedData.length * 2

    const shouldGroup = this.shouldGroupColumn()
    const subtotalColumn = shouldGroup ? columns[0].accessor : undefined
    const groupBy = shouldGroup ? sortBy : undefined

    const inlineStyles = { maxWidth: `${tableWidth}px` }

    const isEmpty = sortedData.length === 0 && !loading
    const className = isEmpty ? 'empty-table' : ''

    let notesComponent = null
    if (notes && notes.length && !isEmpty) {
      notesComponent = <Footnotes notes={notes} />
    }

    if (accordion) {
      const accordionIcon = (
        <TableActions download={this.getTableAsXLSX} noData={isEmpty} loading={loading} />
      )
      return (
        <section className={styles.contentRow}>
          <TableHeader
            title={title}
            maxWidth={tableWidth}
            noData={isEmpty}
            hideIcons={hideIcons}
            loading={loading}
          />
          <Accordion title={accordionTitle || ''} icon={accordionIcon}>
            <section style={inlineStyles}>
              <ReactTable
                loading={loading}
                data={sortedData}
                minRows={0}
                className={className}
                pageSize={pageSize}
                columns={formattedColumns}
                resolveData={d => this.formatData(d, groupBy, subtotalColumn)}
                showPagination={false}
                LoadingComponent={() => this.renderLoading(tableWidth)}
                NoDataComponent={() => this.showNoDataComponent(tableWidth)}
              />
            </section>
            {notesComponent}
          </Accordion>
        </section>
      )
    }

    return (
      <section className={styles.contentRow}>
        <TableHeader
          title={title}
          download={this.getTableAsXLSX}
          noData={isEmpty}
          print={this.printPayload}
          printPending={printPending}
          printPendingTable={printPendingTable}
          maxWidth={tableWidth}
          hideIcons={hideIcons}
          loading={loading}
        />
        <section style={inlineStyles} className={styles.scrollableTable}>
          <ReactTable
            loading={loading}
            data={sortedData}
            minRows={0}
            className={className}
            pageSize={pageSize}
            columns={formattedColumns}
            resolveData={d => this.formatData(d, groupBy, subtotalColumn)}
            showPagination={false}
            LoadingComponent={() => this.renderLoading(tableWidth)}
            NoDataComponent={() => this.showNoDataComponent(tableWidth)}
          />
        </section>
        {notesComponent}
      </section>
    )
  }
}

export {
  TableHeader,
  HeaderCell,
  HeaderCellSortable,
  Cell,
  CurrencyCell,
  FooterCell,
  FooterCellPercentage,
  FooterCellTotal,
  PercentageCell,
}

export default GolcondaTable
