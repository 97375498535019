// @flow

import React from 'react'

import { Loading, PolicyDetailGeneral } from '@/components'
import { VULPolicy } from '@/models'
import { PDFKeys } from '@/constants'
import ApiService from '@/services/ApiService'

type PolicyDetailVULPropsState = {|
  vul: ?VULPolicy,
|}

type PolicyDetailVULProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  policyId: ?string,
|}

export class PolicyDetailVULModal extends React.Component<
  PolicyDetailVULProps,
  PolicyDetailVULPropsState,
> {
  state = {
    vul: null,
  }

  componentDidMount() {
    this.loadPolicy()
  }

  loadPolicy() {
    const { policyId } = this.props

    ApiService.getVULPolicyById(policyId).then(res => {
      if (res) {
        this.setState({ vul: res.data })
      }
    })
  }

  render() {
    const { vul } = this.state
    if (vul) {
      return (
        <div>
          <PolicyDetailGeneral policy={vul} isModal pdfKey={PDFKeys.vulPolicyDetail} />
        </div>
      )
    }
    return <Loading />
  }
}

export default PolicyDetailVULModal
