// @flow
import { merge } from 'lodash'
import moment from 'moment'

import { Strings, Enums, ModelTypes } from '@/constants'
import { capitalizeFirstLetter, camelObjectToSnakeObject } from '@/helpers'
import { Entity, PremiumPayment, Product } from '@/models'
import BasePolicy from '../base/base-policy'
import { defaultArray, defaultFloat, defaultNumber, defaultString, defaultEnum } from '../defaults'
import type { EntityDataType } from '../base/entity'
import type { PolicyDocumentDataType } from '../policies-documents/policy-document'
import type { ProductDataType } from '../product'

export type DisabilityDataType = {|
  account_id?: ?string,
  insured: ?Array<EntityDataType>,
  beneficiary: ?Array<EntityDataType>,
  cc_list: ?Array<EntityDataType>,
  cc_list_premium: ?Array<EntityDataType>,
  cc_list_policy_changes: ?Array<EntityDataType>,
  cc_list_statements: ?Array<EntityDataType>,
  id: ?string,
  inforce_guaranteed_duration_age: ?number,
  original_guaranteed_duration_age: ?number,
  premium_payments: ?Array<PremiumPaymentDataType>,
  owner: ?EntityDataType,
  permissioned_individuals: ?Array<Entity>,
  policy_documents: ?Array<PolicyDocumentDataType>,
  preferred_billing_method: ?$Values<typeof Enums.BillingMethod>,
  paid_to: ?string,
  premium_amount: ?number,
  monthly_benefit: number,
  elimination_period: ?number,
  benefit_duration: ?number,
  premium_mode: ?$Values<typeof Enums.PremiumMode>,
  premium_payor: ?EntityDataType,
  termination_date: ?string,
  policy_date: ?string,
  maturity_date: ?string,
  product: ?ProductDataType,
  scheduled_premium_duration: ?string,
  premium_payor_email: ?string,
  primary_contact_person_email: ?string,
  notes_visibility: ?boolean,
  premium_billing: ?boolean,
|}

const defaultDataValues = {
  account_id: null,
  insured: null,
  beneficiary: null,
  cc_list: null,
  cc_list_premium: null,
  cc_list_policy_changes: null,
  cc_list_statements: null,
  id: null,
  inforce_guaranteed_duration_age: null,
  original_guaranteed_duration_age: null,
  policy_date: null,
  maturity_date: null,
  premium_payments: null,
  owner: null,
  permissioned_individuals: null,
  policy_documents: null,
  preferred_billing_method: null,
  paid_to: null,
  premium_amount: null,
  monthly_benefit: 0,
  elimination_period: null,
  benefit_duration: null,
  premium_mode: null,
  premium_payor: null,
  termination_date: null,
  product: null,
  scheduled_premium_duration: null,
  premium_payor_email: null,
  primary_contact_person_email: null,
  notes_visibility: false,
  premium_billing: false,
}

export default class Disability extends BasePolicy {
  // Disability is always short model.
  accountId: string

  insured: Array<Entity>

  beneficiary: Array<Entity>

  ccList: Array<Entity>

  ccListPremium: Array<Entity>

  ccListPolicyChanges: Array<Entity>

  ccListStatements: Array<Entity>

  ccType: Array<Entity>

  id: string

  inforceGuaranteedDurationAge: number

  originalGuaranteedDurationAge: number

  policyDate: string

  maturityDate: string

  owner: Entity

  permissionedIndividuals: Array<{ name: string, excel_disable: boolean, pdf_disable: boolean }>

  policyDocuments: Array<string>

  preferredBillingMethod: $Values<typeof Enums.BillingMethod>

  paidTo: string

  premiumAmount: ?number

  monthlyBenefit: number

  eliminationPeriod: ?number

  benefitDuration: ?string

  premiumPayments: Array<PremiumPayment>

  terminationDate: string

  product: Product

  scheduledPremiumDuration: number

  premiumPayorEmail: string

  primaryContactPersonEmail: string

  modelName: typeof ModelTypes.Annuity

  notesVisibility: boolean

  premiumBilling: boolean

  constructor(rawData: Disability | DisabilityDataType = defaultDataValues) {
    const data: DisabilityDataType =
      rawData instanceof Disability
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    super(data)
    this.modelName = ModelTypes.Disability
    this.accountId = defaultString(data.account_id)
    this.insured = defaultArray(data.insured).map(e => new Entity(e))
    this.beneficiary = defaultArray(data.beneficiary).map(e => new Entity(e))
    this.ccList = defaultArray(data.cc_list).map(e => new Entity(e))
    this.ccListPremium = defaultArray(data.cc_list_premium).map(e => new Entity(e))
    this.ccListPolicyChanges = defaultArray(data.cc_list_policy_changes).map(e => new Entity(e))
    this.ccListStatements = defaultArray(data.cc_list_statements).map(e => new Entity(e))
    this.id = defaultString(data.id)
    this.inforceGuaranteedDurationAge = defaultNumber(data.inforce_guaranteed_duration_age)
    this.originalGuaranteedDurationAge = defaultNumber(data.original_guaranteed_duration_age)
    this.policyDate = defaultString(data.policy_date)
    this.maturityDate = defaultString(data.maturity_date)
    this.preferredBillingMethod = defaultEnum(data.preferred_billing_method, Enums.BillingMethod)
    this.paidTo = defaultString(data.paid_to)
    this.premiumAmount = defaultFloat(data.premium_amount)
    this.monthlyBenefit = defaultFloat(data.monthly_benefit)
    this.eliminationPeriod = defaultNumber(data.elimination_period)
    this.benefitDuration = defaultString(data.benefit_duration)
    this.premiumMode = defaultEnum(data.premium_mode, Enums.PremiumMode)
    this.scheduledPremiumDuration = defaultNumber(data.scheduled_premium_duration)
    this.owner = data.owner ? new Entity(data.owner) : new Entity()
    this.policyDocuments = defaultArray(data.policy_documents)
    this.terminationDate = defaultString(data.termination_date)
    this.permissionedIndividuals = defaultArray(data.permissioned_individuals)
    this.premiumPayments = defaultArray(data.premium_payments).map(d => new PremiumPayment(d))
    this.product = new Product(data.product || {})
    this.premiumPayorEmail = defaultString(data.premium_payor_email)
    this.primaryContactPersonEmail = defaultString(data.primary_contact_person_email)
    this.notesVisibility = data.notes_visibility || false
    this.premiumBilling = data.premium_billing || false
  }

  static modelName = ModelTypes.Disability

  getFaceValues(ccTypes: Array<string>) {
    if (!ccTypes || ccTypes.length === 0) {
      return ''
    }
    const ccTypeToValue = {
      statements: 4,
      premium: 5,
      policy_changes: 6,
    }

    const faceValues = ccTypes.map(ccType => ccTypeToValue[ccType] || '')

    return faceValues.sort().join(',')
  }

  get insuranceCompanyName(): string {
    return this.product?.insuranceCompany?.name || ''
  }

  get policyType(): string {
    return defaultString(this.product?.productType)
  }

  get title(): string {
    const { accountId, insuranceCompanyName } = this
    return `${insuranceCompanyName}\xA0 - \xA0${accountId}`
  }

  get ownerString(): string {
    return this.owner.formattedName
  }

  get insuredString(): string {
    return this.insured.map(i => i.formattedName).join(', ')
  }

  get annuitant(): Array<Entity> {
    return this.insured
  }

  get formattedMaturityDate(): string {
    if (this.maturityDate) {
      return moment(this.maturityDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get beneficiaryString(): string {
    return this.beneficiary.map(a => a.formattedName).join(', ')
  }

  get inforcePolicyCommunicationCCList(): Array<string> {
    if (this.notesVisibility) {
      return this.ccList.reduce(
        (acc, cur) =>
          cur && cur.email ? [...acc, `${cur.email}(${this.getFaceValues(cur.cc_type)})`] : acc,
        [],
      )
    }
    return this.ccList.reduce((acc, cur) => (cur && cur.email ? [...acc, cur.email] : acc), [])
  }

  get inforcePolicyCommunicationCCListPremium(): Array<string> {
    if (this.notesVisibility) {
      return this.ccListPremium.reduce(
        (acc, cur) =>
          cur && cur.email ? [...acc, `${cur.email}(${this.getFaceValues(cur.cc_type)})`] : acc,
        [],
      )
    }
    return this.ccListPremium.reduce(
      (acc, cur) => (cur && cur.email ? [...acc, cur.email] : acc),
      [],
    )
  }

  get inforcePolicyCommunicationCCListPolicyChanges(): Array<string> {
    if (this.notesVisibility) {
      return this.ccListPolicyChanges.reduce(
        (acc, cur) =>
          cur && cur.email ? [...acc, `${cur.email}(${this.getFaceValues(cur.cc_type)})`] : acc,
        [],
      )
    }
    return this.ccListPolicyChanges.reduce(
      (acc, cur) => (cur && cur.email ? [...acc, cur.email] : acc),
      [],
    )
  }

  get inforcePolicyCommunicationCCListStatements(): Array<string> {
    if (this.notesVisibility) {
      return this.ccListStatements.reduce(
        (acc, cur) =>
          cur && cur.email ? [...acc, `${cur.email}(${this.getFaceValues(cur.cc_type)})`] : acc,
        [],
      )
    }
    return this.ccListStatements.reduce(
      (acc, cur) => (cur && cur.email ? [...acc, cur.email] : acc),
      [],
    )
  }

  get scheduledPremiumDurationFormat(): string {
    if (this.scheduledPremiumDuration > 0) {
      const years = this.scheduledPremiumDuration > 1 ? Strings.years : Strings.year
      return `${this.scheduledPremiumDuration} ${years} ${Strings.fromInception}`
    }
    return '–'
  }

  get formattedTerminationDate(): string {
    if (this.terminationDate) {
      return moment(this.terminationDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get guaranteedDurationAgeFormat(): string {
    if (this.inforceGuaranteedDurationAge > 0) {
      const years = this.inforceGuaranteedDurationAge > 1 ? Strings.years : Strings.year
      return `${this.inforceGuaranteedDurationAge} ${years} ${Strings.fromInception}`
    }
    return '–'
  }

  get originalGuaranteedDurationAgeFormat(): string {
    if (this.originalGuaranteedDurationAge > 0) {
      const years = this.originalGuaranteedDurationAge > 1 ? Strings.years : Strings.year
      return `${this.originalGuaranteedDurationAge} ${years}`
    }
    return '–'
  }

  get formattedPremiumMode(): string {
    return capitalizeFirstLetter(this.premiumMode)
  }

  get detailFilename(): string {
    return Strings.disabilityDetailFilename(this.insuranceCompanyName, this.accountId)
  }

  get eliminationPeriodDaysFormatted(): string {
    return this.eliminationPeriod ? `${this.eliminationPeriod} Days` : ''
  }

  startedOnOrBefore(month: string): boolean {
    return moment(this.policyDate).isSameOrBefore(month)
  }

  get formattedPaidTo(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedPremiumDueDate(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('MM DD')
    }
    return Strings.dash
  }
}
