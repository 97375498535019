// @flow
import {
  Annuity,
  AnnuityShort,
  Client,
  Disability,
  IDFImage,
  IDFStatement,
  InsurancePolicy,
  InsurancePolicyShort,
  InsurancePolicyCalendar,
  PrivatePlacementCalendar,
  VULPolicyCalendar,
  PPAStatement,
  PPLIStatement,
  PrivatePlacement,
  PrivatePlacementShort,
  SessionStatus,
  User,
  VULPolicy,
  VULPolicyShort,
  VULStatement,
} from '@/models'
import type { AnnuityDataType } from '@/models/policies/annuity'
import type { IDFStatementDataType } from '@/models/idf/idf-statement'
import type { IDFImageDataType } from '@/models/idf/idf-image'
import type { InsurancePolicyDataType } from '@/models/policies/insurance-policy'
import type { PrivatePlacementDataType } from '@/models/policies/private-placement'
import type { VULPolicyDataType } from '@/models/policies/vul-policy'
import type { PPAStatementDataType } from '@/models/policies-statements/ppa-statement'
import type { PPLIStatementDataType } from '@/models/policies-statements/ppli-statement'
import type { VULStatementDataType } from '@/models/policies-statements/vul-statement'
import type { UserDataType } from '@/models/user'
import type { ClientDataType } from '@/models/client'
import type { DisabilityDataType } from '@/models/policies/disability'
import type { InsurancePolicyShortDataType } from '@/models/policies/insurance-policy-short'
import { PrivatePlacementShortDataType } from '@/models/policies/private-placement-short'
import type { VULPolicyShortDataType } from '@/models/policies/vul-policy-short'

export type LoginResponseNew = {|
  token?: string,
  status: string, // SUCCESS, DISABLED, 2FA_SENT
  last_two?: string,
|}

export const LoginResponse = (data: LoginResponseNew) => data

export const UserInfoResponse = (data: UserDataType) => {
  if (data.id || data.disabled) {
    return {
      user: new User(data),
    }
  }
  return null
}

export const ClientsResponse = (data: ClientDataType): Array<Client> =>
  data.map(item => new Client(item))

export const InsurancePolicyPage = (data: any) => data.map(item => new InsurancePolicy(item))

export const InsurancePolicies = (data: Array<InsurancePolicyDataType>): Array<InsurancePolicy> =>
  data.map(d => new InsurancePolicy(d))

export const InsurancePoliciesShort = (
  data: Array<InsurancePolicyShortDataType>,
): Array<InsurancePolicyShort> => data.map(d => new InsurancePolicyShort(d))

export const InsurancePoliciesCalendar = (
  data: Array<InsurancePolicyShortDataType>,
): Array<InsurancePolicyCalendar> => data.map(d => new InsurancePolicyCalendar(d))

export const PrivatePlacementsCalendar = (
  data: Array<PrivatePlacementDataType>,
): Array<PrivatePlacementCalendar> => data.map(d => new PrivatePlacementCalendar(d))

export const VULPoliciesCalendar = (
  data: Array<VULPolicyShortDataType>,
): Array<VULPolicyCalendar> => data.map(d => new VULPolicyCalendar(d))

export const InsurancePolicyDetails = (data: InsurancePolicyDataType): InsurancePolicy =>
  new InsurancePolicy(data)

export const Annuities = (data: Array<AnnuityDataType>): Array<Annuity> =>
  data.map(d => new Annuity(d))

export const AnnuitiesShort = (data: Array<AnnuityDataType>): Array<AnnuityShort> =>
  data.map(d => new AnnuityShort(d))

export const DisabilityPolicies = (data: Array<DisabilityDataType>): Array<Disability> =>
  data.map(d => new Disability(d))

export const AnnuityDetails = (data: AnnuityDataType): Annuity => new Annuity(data)

export const DisabilityDetails = (data: DisabilityDataType): Disability => new Disability(data)

export const PrivatePlacements = (data: Array<PrivatePlacementDataType>): Array<PrivatePlacement> =>
  data.map(d => new PrivatePlacement(d))

export const PrivatePlacementsShort = (
  data: Array<PrivatePlacementShortDataType>,
): Array<PrivatePlacementShort> => data.map(d => new PrivatePlacementShort(d))

export const PrivatePlacementDetails = (data: PrivatePlacementDataType): PrivatePlacement =>
  new PrivatePlacement(data)

export const VULPolicies = (data: Array<VULPolicyDataType>): Array<VULPolicy> =>
  data.map(d => new VULPolicy(d))

export const VULPoliciesShort = (data: Array<VULPolicyShortDataType>): Array<VULPolicyShort> =>
  data.map(d => new VULPolicyShort(d))

export const VULPolicyDetails = (data: VULPolicyDataType): VULPolicy => new VULPolicy(data)

export const IDFStatements = (data: Array<IDFStatementDataType>): Array<IDFStatement> =>
  data.map(s => new IDFStatement(s))

export const IDFImages = (data: Array<IDFImageDataType>): Array<IDFImage> =>
  data.map(s => new IDFImage(s))

export const PPAStatements = (data: Array<PPAStatementDataType>): Array<PPAStatement> =>
  data.map(p => new PPAStatement(p))

export const PPLIStatements = (data: Array<PPLIStatementDataType>): Array<PPLIStatement> =>
  data.map(p => new PPLIStatement(p))

export const VULStatements = (data: Array<VULStatementDataType>): Array<VULStatement> =>
  data.map(p => new VULStatement(p))

// sessions
export const SessionsStatuses = (data: Array<any>): Array<SessionStatus> =>
  data.map(d => new SessionStatus(d))

export default {
  Annuities,
  AnnuitiesShort,
  DisabilityPolicies,
  AnnuityDetails,
  DisabilityDetails,
  IDFStatements,
  IDFImages,
  InsurancePolicies,
  InsurancePoliciesShort,
  InsurancePoliciesCalendar,
  PrivatePlacementsCalendar,
  VULPoliciesCalendar,
  InsurancePolicyDetails,
  InsurancePolicyPage,
  LoginResponse,
  UserInfoResponse,
  ClientsResponse,
  PPAStatements,
  PPLIStatements,
  VULStatements,
  PrivatePlacements,
  PrivatePlacementsShort,
  PrivatePlacementDetails,
  VULPolicies,
  VULPoliciesShort,
  VULPolicyDetails,
  SessionsStatuses,
}
