// @flow
import { dispatchReducer, runReducers } from '@/helpers/dispatch-and-reduce'
import { startRequest, endRequest } from './shared-reducers'

export const ACTIONS = {
  MAKE_EVERY_REQUEST: 'MAKE_EVERY_REQUEST',
  MAKE_LATEST_REQUEST: 'MAKE_LATEST_REQUEST',
  START_REQUEST: 'START_REQUEST',
  END_REQUEST: 'END_REQUEST',
  RESTORE_INITIAL_STATE: 'RESTORE_INITIAL_STATE',
  UPDATE_META: 'UPDATE_META',
  STOP_POLLING: 'STOP_POLLING',
}

export const ErrorKeys = {
  loginUserError: 'loginUserError',
  acceptInviteError: 'acceptInviteError',
  resendInviteError: 'resendInviteError',
  forgotPasswordError: 'forgotPasswordError',
  resetPasswordError: 'resetPasswordError',
  portalSetPasswordError: 'portalSetPasswordError',
  portalSetUsernameError: 'portalSetUsernameError',
  portalSetEmailError: 'portalSetEmailError',
  PortalSetMobileError: 'PortalSetMobileError',
  verifyDeviceError: 'verifyDeviceError',
  generatePDFError: 'generatePDFError',
  generateDetailedPDFError: 'generateDetailedPDFError',
  generateConsolidatedPDFError: 'generateConsolidatedPDFError',
  checkPDFResultError: 'checkPDFResultError',
}

export const PendingKeys = {
  loginUserPending: 'loginUserPending',
  acceptInvitePending: 'acceptInvitePending',
  resendInvitePending: 'resendInvitePending',
  forgotPasswordPending: 'forgotPasswordPending',
  resetPasswordPending: 'resetPasswordPending',
  portalSetPasswordPending: 'portalSetPasswordPending',
  portalSetUsernamePending: 'portalSetUsernamePending',
  portalSetEmailPending: 'portalSetEmailPending',
  verifyDevicePending: 'verifyDevicePending',
  getPPLIStatementsPending: 'getPPLIStatementsPending',
  getPPLIStatementsShortPending: 'getPPLIStatementsShortPending',
  getVULStatementsPending: 'getVULStatementsPending',
  getVULStatementsShortPending: 'getVULStatementsShortPending',
  getPPAStatementsPending: 'getPPAStatementsPending',
  getPPAStatementsShortPending: 'getPPAStatementsShortPending',
  getInsurancePoliciesShortPending: 'getInsurancePoliciesShortPending',
  getPrivatePlacementsShortPending: 'getPrivatePlacementsShortPending',
  getAnnuityPoliciesPending: 'getAnnuityPoliciesPending',
  getAnnuityPoliciesShortPending: 'getAnnuityPoliciesShortPending',
  getDisabilityPoliciesShortPending: 'getDisabilityPoliciesShortPending',
  getInsurancePoliciesPending: 'getInsurancePoliciesPending',
  getPrivatePlacementsPending: 'getPrivatePlacementsPending',
  getVULPoliciesPending: 'getVULPoliciesPending',
  getVULPoliciesShortPending: 'getVULPoliciesShortPending',
  getIDFStatementsPending: 'getIDFStatementsPending',
  generatePDFPending: 'generatePDFPending',
  generateDetailedPDFPending: 'generateDetailedPDFPending',
  generateConsolidatedPDFPending: 'generateConsolidatedPDFPending',
  checkPDFResultPending: 'checkPDFResultPending',
  getXIRRRatesPending: 'getXIRRRatesPending',
  getXIRRRatesIDFPending: 'getXIRRRatesIDFPending',
  getIULMonthlyStatementsPending: 'getIULMonthlyStatementsPending',
  getIULAnnualStatementsPending: 'getIULAnnualStatementsPending',
  getIndexAccountRatesPending: 'getIndexAccountRatesPending',
  getConsolidatedStatementsPending: 'getConsolidatedStatementsPending',
}

export const MetaKeys = {
  checkPDFResultMeta: 'checkPDFResultMeta',
}

export const INITIAL_STATE = {
  loginUserError: null,
  loginUserPending: false,
  acceptInviteError: null,
  acceptInvitePending: false,
  resendInviteError: null,
  resendInvitePending: false,
  resetPasswordError: null,
  resetPasswordPending: false,
  forgotPasswordError: null,
  forgotPasswordPending: false,
  portalSetPasswordError: null,
  portalSetPasswordPending: false,
  portalSetEmailError: null,
  PortalSetMobileError: null,
  portalSetEmailPending: false,
  portalSetUsernameError: null,
  portalSetUsernamePending: false,
  verifyDeviceError: null,
  verifyDevicePending: false,
  getPPLIStatementsPending: true,
  getPPLIStatementsShortPending: true,
  getVULStatementsPending: true,
  getVULStatementsShortPending: true,
  getPPAStatementsPending: true,
  getPPAStatementsShortPending: true,
  getAnnuityPoliciesPending: true,
  getDisabilityPoliciesShortPending: true,
  getInsurancePoliciesShortPending: true,
  getPrivatePlacementsPending: true,
  getPrivatePlacementsShortPending: true,
  getVULPoliciesPending: true,
  getVULPoliciesShortPending: true,
  getIDFStatementsPending: true,
  checkPDFResultError: null,
  checkPDFResultPending: false,
  checkPDFResultMeta: null,
  generatePDFError: null,
  generatePDFPending: false,
  generateDetailedPDFError: null,
  generateDetailedPDFPending: false,
  generateConsolidatedPDFError: null,
  generateConsolidatedPDFPending: false,
  getXIRRRatesPending: true,
  getXIRRRatesIDFPending: true,
  getIULMonthlyStatementsPending: true,
  getIULAnnualStatementsPending: true,
  getIndexAccountRatesPending: true,
  getConsolidatedStatementsPending: true,
}

export function makeRequest<StateType, PayloadDataType>(
  state: StateType,
  { payload }: ApiPayloadType<PayloadDataType>, // eslint-disable-line
): StateType {
  return {
    ...state,
  }
}

export const updateMeta = (state: APIStoreState, payload: { key: string, _meta: Object }) => {
  const { key, _meta } = payload
  return {
    ...state,
    [key]: _meta,
  }
}

const restoreInitialState = () => ({
  ...INITIAL_STATE,
})

export const reduxSet = {
  makeRequest: dispatchReducer<$Values<StoreState>, ApiPayloadType<*>>(
    ACTIONS.MAKE_EVERY_REQUEST,
    makeRequest,
  ),
  makeLatestRequest: dispatchReducer<$Values<StoreState>, ApiPayloadType<*>>(
    ACTIONS.MAKE_LATEST_REQUEST,
    makeRequest,
  ),
  startRequest: dispatchReducer<APIStoreState, { [string]: ?null }>(
    ACTIONS.START_REQUEST,
    startRequest,
  ),
  endRequest: dispatchReducer<APIStoreState, ?{ [string]: ?Error }>(
    ACTIONS.END_REQUEST,
    endRequest,
  ),
  restoreInitialState: dispatchReducer<APIStoreState, { [string]: string }>(
    ACTIONS.RESTORE_INITIAL_STATE,
    restoreInitialState,
  ),
  updateMeta: dispatchReducer<APIStoreState, { key: string, _meta: Object }>( // eslint-disable-line no-undef
    ACTIONS.UPDATE_META,
    updateMeta,
  ),
}

export const reducer = (
  state: APIStoreState = { ...INITIAL_STATE },
  action: StandardAction,
): APIStoreState => runReducers(state, reduxSet, action)
