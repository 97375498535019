// @flow

import React from 'react'
import { connect } from 'react-redux'

import * as yup from 'yup'
import type { ValidationError } from 'yup'

import { classnames } from '@/helpers'
import { Input, PrimaryButton } from '@/components'
import selectors from '@/selectors'
import Access from '@/constants/accessibility'
import { ErrorKeys } from '@/redux/api'
import { User } from '@/models'
import { Strings } from '@/constants'
import styles from './styles.scss'

const schema = yup.object().shape({
  newEmail1: yup
    .string()
    .email(Strings.auth.settingsError.emailInvalid)
    .required(Strings.auth.settingsError.newEmail),
  newEmail2: yup
    .string()
    .email(Strings.auth.settingsError.emailInvalid)
    .oneOf([yup.ref('newEmail1')], Strings.auth.settingsError.emailDoesNotMatch)
    .required(Strings.auth.settingsError.newEmail),
})

const FieldNames = {
  oldEmail: 'oldEmail',
  newEmail1: 'newEmail1',
  newEmail2: 'newEmail2',
}

type FormErrors = {
  newEmail1: string,
  newEmail2: string,
}

type StateToPropsType = {|
  user: ?User,
  error: ?string,
|}

type PortalSetEmailFormProps = {|
  submitForm: (values: PortalUpdateUserInfoPayload) => {},
  success: boolean,
  ...StateToPropsType,
|}

type PortalSetEmailFormState = {|
  newEmail1: string,
  newEmail2: string,
  errors?: FormErrors,
  nonUniqueError: string,
|}

export class PortalSetEmailForm extends React.Component<
  PortalSetEmailFormProps,
  PortalSetEmailFormState,
> {
  constructor(props: PortalSetEmailFormProps) {
    super(props)

    this.state = {
      newEmail1: '',
      newEmail2: '',
      nonUniqueError: '',
    }
  }

  formatErrors = (errors: Array<ValidationError>): FormErrors => {
    const formatErrors: FormErrors = {}
    errors.forEach((err: ValidationError) => {
      formatErrors[err.path] = err.message
    })
    return formatErrors
  }

  onSubmit = (event?: DOMEvent) => {
    const { user } = this.props

    if (event) {
      event.preventDefault()
    }

    const { submitForm } = this.props
    const { newEmail1, newEmail2 } = this.state

    const values = {
      newEmail1,
      newEmail2,
    }

    const isUnique = this.checkUnique(user, newEmail1)

    if (isUnique) {
      try {
        schema.validateSync(values, { abortEarly: false })
        this.setState({ errors: undefined }, () => {
          submitForm({
            email: newEmail1,
          })
        })
      } catch (err) {
        const errors: FormErrors = this.formatErrors(err.inner)
        this.setState({ errors })
      }
    }
  }

  checkUnique = (user: ?User, newEmail: string): boolean => {
    if (user && user.email === newEmail) {
      this.setState({ nonUniqueError: Strings.settings.email.nonUnique })
      return false
    }
    this.setState({ nonUniqueError: '' })
    return true
  }

  onNewEmail1Change = (value: string) => {
    this.setState({ newEmail1: value })
  }

  onNewEmail2Change = (value: string) => {
    this.setState({ newEmail2: value })
  }

  render() {
    const { user, error, success } = this.props
    const { errors, newEmail1, newEmail2, nonUniqueError } = this.state

    const emailSuccess =
      !error &&
      !nonUniqueError &&
      !!(newEmail1 && newEmail1 === newEmail2 && !errors?.newEmail1 && !errors?.newEmail2)

    // display text of any associated errors
    const email1Error = nonUniqueError || errors?.newEmail1 || (errors?.newEmail2 ? true : error)

    // only display text of validation errors under 2nd email input, else just make the input border red (hence coercion to booleans)
    const email2Error = !!nonUniqueError || errors?.newEmail2 || !!error

    if (success) {
      return (
        <div className={classnames(styles.successWrapper)}>
          <h4>{Strings.settings.email.title}</h4>
          <p>{Strings.settings.email.success}</p>
        </div>
      )
    }

    return (
      <form className={styles.portalModalForm} onSubmit={this.onSubmit}>
        <div>
          <h4 className={styles.portalModalHeader}>{Strings.settings.email.title}</h4>
          <div className={classnames(styles.inputRow)}>
            <Input
              type="text"
              label={Strings.auth.emailCurrent}
              name={FieldNames.oldEmail}
              placeholder={(user && user.email) || Strings.auth.emailCurrent}
              onChange={() => {}}
              success={emailSuccess}
              disabled
            />
          </div>
          <div className={classnames(styles.inputRow)}>
            <Input
              type="email"
              label={Strings.auth.emailNew}
              name={FieldNames.newEmail1}
              placeholder={Strings.auth.emailNew}
              onChange={this.onNewEmail1Change}
              error={email1Error}
              success={emailSuccess}
            />
            <Input
              type="email"
              label={Strings.auth.emailNewConfirm}
              name={FieldNames.newEmail2}
              placeholder={Strings.auth.emailNewConfirm}
              onChange={this.onNewEmail2Change}
              error={email2Error}
              success={emailSuccess}
            />
          </div>
        </div>
        <PrimaryButton
          type="submit"
          ariaLabel={Access.Components.PortalSettingsModal.EmailSubmit}
          onClick={this.onSubmit}
          title={Strings.settings.email.title}
          className={styles.portalModalDefaultBtn}
        />
      </form>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  error: selectors.api.getError(state, ErrorKeys.portalSetEmailError),
})

export default connect(mapStateToProps)(PortalSetEmailForm)
