// @flow

import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { ACTIONS as USER_ACTIONS } from '@/redux/user'
import { ACTIONS as POLICY_ACTIONS } from '@/redux/policy'
import { ACTIONS as SESSIONS_ACTIONS } from '@/redux/sessions'
import * as api from './api'
import * as user from './user'
import * as policy from './policy'
import * as sessions from './sessions'

export { api, user, sessions }

export default function* root(): GeneratorType {
  yield all([
    api.makeEveryRequest(),
    api.makeLatestRequest(),
    takeLatest(USER_ACTIONS.PASSWORD_RESET, user.resetPassword),
    takeLatest(USER_ACTIONS.LOGIN_SUCCESS, user.loginSuccess),
    takeLatest(USER_ACTIONS.ACCEPT_USER_INVITE_N, user.inviteAccepted),
    takeLatest(USER_ACTIONS.LOGIN_ERROR, user.loginError),
    takeLatest(USER_ACTIONS.CLEAR_USER_SESSION, user.clearCookie),
    takeLatest(USER_ACTIONS.LOGOUT_CURRENT_USER, user.logoutCurrentUser),
    takeLatest(USER_ACTIONS.SET_EMAIL_SUCCESS, user.portalSetEmailSuccess),
    takeLatest(USER_ACTIONS.SET_NEW_PASSWORD_SUCCESS, user.portalSetPasswordSuccess),
    takeLatest(USER_ACTIONS.SET_USERNAME_SUCCESS, user.portalSetUsernameSuccess),
    takeLatest(USER_ACTIONS.SET_MOBILE_SUCCESS, user.portalSetMobileSuccess),
    takeLatest(USER_ACTIONS.ACCEPT_USER_INVITE, user.loginSuccess),
    takeLatest(USER_ACTIONS.HYDRATE_USER, user.hydrateUser),
    takeLatest(USER_ACTIONS.SET_ACTIVE_USER, user.setActiveUser),
    takeLatest(USER_ACTIONS.INACTIVITY_WARNING_RESPONSE, user.inactivityWarningResponse),
    takeLatest(USER_ACTIONS.LOAD_DATA, user.loadData),
    takeLatest(USER_ACTIONS.LOAD_SHORT_DATA, user.loadShortData),
    takeLatest(POLICY_ACTIONS.GENERATE_PDF_SUCCESS, policy.checkPDFResult),
    takeLatest(POLICY_ACTIONS.GENERATE_DETAILED_PDF_SUCCESS, policy.checkPDFResult),
    takeLatest(POLICY_ACTIONS.GENERATE_CONSOLIDATED_PDF_SUCCESS, policy.checkPDFResult),
    takeLatest(POLICY_ACTIONS.CHECK_PDF_RESULT_SUCCESS, policy.checkPDFResultSuccess),
    takeLatest(POLICY_ACTIONS.CHECK_PDF_RESULT_ERROR, policy.checkPDFResultError),
    // User Session Actions
    takeLatest(USER_ACTIONS.ADD_USER_SESSION, user.addUserSession),
    takeLatest(USER_ACTIONS.SET_CURRENT_USER_SESSION, user.setCurrentUserSession),
    // sessions pooling
    sessions.watchSessionsStatusesPolling(),
    takeEvery(SESSIONS_ACTIONS.SESSIONS_POOLING_DATA_RECEIVED, sessions.poolingDataReceived),
    takeEvery(SESSIONS_ACTIONS.LOAD_CURRENT_USER_SESSIONS, sessions.loadCurrentUserSessions),
    takeEvery(SESSIONS_ACTIONS.END_SESSIONS, sessions.endSessions),
  ])
}
