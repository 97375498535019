// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import Access from '@/constants/accessibility'
import { Checkbox } from '@/components'
import { Images, Strings } from '@/constants'

import { ColoredLabel, FilterDropdownCriterium } from './filter-criterium'
import styles from './styles.scss'

type PolicyFilterProps = {|
  onChange: (filterState: {
    activeFilters: OptionsType<string>,
    showAll: boolean,
  }) => void,
  filters: OptionsType<string>,
  open?: boolean,
  title: string,
  storybookShowUnchecked?: boolean, // Storybook only prop for rendering with all unchecked
  defaultFilters?: Array<string>,
|}

type PolicyFilterState = {|
  filters: {
    [string]: boolean,
  },
  open: boolean,
  showAll: boolean,
|}

const ToggleFilters = styled.img`
  ${props => (props.open ? `transform: rotate(180deg);` : `transform: rotate(0deg);`)}
`

export class PolicyFilter extends React.Component<PolicyFilterProps, PolicyFilterState> {
  static defaultProps = {
    open: false,
    storybookShowUnchecked: false,
    defaultFilters: [],
  }

  constructor(props: PolicyFilterProps) {
    super(props)

    const { storybookShowUnchecked, defaultFilters = [], filters } = props
    let initialCheckedState = defaultFilters.length === 0
    if (storybookShowUnchecked) {
      initialCheckedState = false
    }
    this.state = {
      filters: filters.reduce((acc, cur) => {
        acc[cur.value] = defaultFilters.includes(cur.value.toString())
        return acc
      }, {}),
      open: props.open || false,
      showAll: initialCheckedState,
    }
  }

  componentDidUpdate = (prevProps: PolicyFilterProps, prevState: PolicyFilterState) => {
    if (this.filtersNowLoading(prevState)) {
      this.setFiltersInitialState()
    }

    if (this.filtersWereUpdated(prevState)) {
      this.handleOnChange()
    }
  }

  setFiltersInitialState = () => {
    const { filters: propFilters, defaultFilters = [] } = this.props
    const filters = propFilters.reduce((acc, cur) => {
      acc[cur.value] = defaultFilters.includes(cur.value.toString())
      return acc
    }, {})
    this.setState(() => ({ filters, showAll: defaultFilters.length === 0 }), this.handleOnChange)
  }

  handleOnChange = () => {
    const { onChange } = this.props
    const { showAll } = this.state
    onChange({
      activeFilters: this.activeFiltersList(),
      showAll,
    })
  }

  handleToggleOpen = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleShowAll = () => {
    this.setState(prevState => {
      const { filters: activeFilters, showAll: prevShowAll } = prevState
      const showAll = !prevShowAll
      const filters = Object.keys(activeFilters).reduce((acc, cur) => {
        acc[cur] = false
        return acc
      }, {})
      return { filters, showAll }
    })
  }

  handleFilterToggle = (option: { label: string, value: string }) => {
    this.setState(prevState => {
      const { filters } = prevState
      return {
        filters: {
          ...filters,
          [option.value]: !filters[option.value],
        },
        showAll: false,
      }
    })
  }

  activeFiltersList = (): OptionsType<string> => {
    const { filters: activeFilters } = this.state
    const { filters } = this.props
    return filters.filter(
      (criterium: { label: string, value: string }) => activeFilters[criterium.value],
    )
  }

  filtersNowLoading = (prevState: PolicyFilterState) => {
    const { filters: prevStateFilters } = prevState
    const { filters } = this.props
    return !Object.keys(prevStateFilters).length && filters.length
  }

  filtersWereUpdated = (prevState: PolicyFilterState): boolean => {
    const { filters, showAll } = this.state
    const { filters: prevFilters, showAll: prevShowAll } = prevState
    const updated =
      Object.keys(filters).some(
        (criterium: string) => prevFilters[criterium] !== filters[criterium],
      ) || showAll !== prevShowAll
    return updated
  }

  renderShowAll = () => {
    const { showAll } = this.state
    const showAllLabel = (
      <ColoredLabel checked={showAll}>{Strings.consolidatedAllAccounts}</ColoredLabel>
    )
    return (
      <div className={styles.criterium}>
        <Checkbox
          id={Strings.consolidatedAllAccounts}
          label={showAllLabel}
          name={Strings.consolidatedAllAccounts}
          ariaLabel={`${Access.Components.FilterDropdown.Checkbox(
            Strings.consolidatedAllAccounts,
          )}`}
          onChange={this.handleShowAll}
          checked={showAll}
          leftLabel
        />
      </div>
    )
  }

  renderFilterOption = (filterCriterium: { label: string, value: string }, index: number) => {
    const { filters: activeFilters, showAll } = this.state
    const id = `${index}-${filterCriterium.label}`
    return (
      <FilterDropdownCriterium
        key={id}
        active={!showAll && activeFilters[filterCriterium.value]}
        ariaLabel={`${Access.Components.FilterDropdown.Checkbox(filterCriterium.label)}`}
        filterOption={filterCriterium}
        onClick={this.handleFilterToggle}
        id={id}
      />
    )
  }

  renderFiltersList = () => {
    const { filters } = this.props
    const { open } = this.state

    if (open) {
      return (
        <div className={styles.criteriaContainer}>
          <div className={styles.criteria}>
            {this.renderShowAll()}
            {filters.map(this.renderFilterOption)}
          </div>
        </div>
      )
    }
    return null
  }

  renderFiltersTitle = () => {
    const { title } = this.props
    const { open } = this.state

    return (
      <button
        type="button"
        onClick={this.handleToggleOpen}
        aria-label={Access.Components.FilterDropdown.ToggleOpen}
      >
        <span className={styles.title}>{title}</span>
        <ToggleFilters
          className={styles.toggleDropdown}
          src={Images.downCaretBlue}
          alt={Strings.toggleFilters}
          open={open}
        />
      </button>
    )
  }

  render() {
    return (
      <div className={styles.filterDropdown}>
        {this.renderFiltersTitle()}
        {this.renderFiltersList()}
      </div>
    )
  }
}

export default PolicyFilter
