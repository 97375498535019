// @flow

import { css } from '@emotion/core'
import styles from './styles.scss'

const { smallerFont, standardFont, lightGrey, lighterGrey } = styles

export default {
  icon: (size: 'md' | 'lg') => css`
    ${size === 'md' &&
    `
      height: 26px;
    `}
    ${size === 'lg' &&
    `
      height: 140px;
    `}
  `,
  caption: (size: 'md' | 'lg') => css`
    ${size === 'md' &&
    `
    font-size: ${smallerFont};
  `}
    ${size === 'lg' &&
    `
    font-size: ${standardFont};
  `}
  `,
  highlight: (highlighted: ?boolean) => css`
    ${highlighted &&
    `
        border: 1px solid ${lightGrey};
        background-color: ${lighterGrey};
    `}
  `,
}
