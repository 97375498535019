// @flow
import { merge } from 'lodash'
import { Enums } from '@/constants'
import { InsuranceCompany } from '@/models'
import { camelObjectToSnakeObject } from '@/helpers'
import BaseModel from '../base/base-model'
import { defaultEnum, defaultNumber, defaultString } from '../defaults'
import type { InsuranceCompanyDataType } from '../insurance-company'

export type IDFProductDataType = {|
  id?: ?string,
  product_name?: ?string,
  description?: ?string,
  insurance_company?: ?InsuranceCompanyDataType,
  minimum_subscription?: ?number,
  lockup_period?: ?number,
  lockup_period_unit?: ?$Values<typeof Enums.PeriodUnit>,
  liquidation_notification?: ?number,
  liquidation_notification_unit?: ?$Values<typeof Enums.PeriodUnit>,
  liquidation_frequency?: ?$Values<typeof Enums.PeriodUnit>,
|}

const defaultDataValues = {
  id: null,
  product_name: null,
  description: null,
  insurance_company: null,
  minimum_subscription: null,
  lockup_period: null,
  lockup_period_unit: null,
  liquidation_notification: null,
  liquidation_notification_unit: null,
  liquidation_frequency: null,
}

export default class IDFProduct extends BaseModel {
  id: string

  productName: string

  description: string

  insuranceCompany: InsuranceCompany

  minimumSubscription: number

  lockupPeriod: number

  lockupPeriodUnit: $Values<typeof Enums.PeriodUnit>

  liquidationNotification: number

  liquidationNotificationUnit: $Values<typeof Enums.PeriodUnit>

  liquidationFrequency: $Values<typeof Enums.PeriodUnit>

  constructor(rawData: IDFProduct | IDFProductDataType = defaultDataValues) {
    super()
    const data: IDFProductDataType =
      rawData instanceof IDFProduct
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    this.id = defaultString(data.id)
    this.productName = defaultString(data.product_name)
    this.description = defaultString(data.description)
    this.insuranceCompany = data.insurance_company
      ? new InsuranceCompany(data.insurance_company)
      : new InsuranceCompany()
    this.minimumSubscription = defaultNumber(data.minimum_subscription)
    this.lockupPeriod = defaultNumber(data.lockup_period)
    this.lockupPeriodUnit = defaultEnum(data.lockup_period_unit, Enums.PeriodUnit)
    this.liquidationNotification = defaultNumber(data.liquidation_notification)
    this.liquidationNotificationUnit = defaultEnum(
      data.liquidation_notification_unit,
      Enums.PeriodUnit,
    )
    this.liquidationFrequency = defaultEnum(data.liquidation_frequency, Enums.PeriodUnit)
  }
}
