// @flow

import React from 'react'
import { connect } from 'react-redux'

import selectors from '@/selectors'
import { ActionCreators } from '@/redux'

import { Strings, Images, Routes } from '@/constants'

import { Link, withRouter } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import styles from './styles.scss'
import StorageService from '@/helpers/StorageService'

type HeaderSessionListState = {|
  sessionsList: Array<UserSession>,
|}

type StateToPropsType = {|
  user: ?User,
|}

type DispatchProps = {|
  setCurrentUserSession: (session: UserSession) => void,
|}

type HeaderSessionListProps = {|
  onLinkClick: () => void,
  history: RouterHistory,
  ...StateToPropsType,
  ...DispatchProps,
|}

class HeaderSessionsList extends React.Component<HeaderSessionListProps, HeaderSessionListState> {
  state = {
    sessionsList: [],
  }

  expiredTip = (<div className={styles.expiredTip}>{Strings.multiSessions.sessionExpired}</div>)

  componentDidMount() {
    this.initSessionsList()
  }

  onSessionClick = (session: UserSession) => {
    const { setCurrentUserSession, onLinkClick, history } = this.props
    if (session.expired) {
      const sessionsList = StorageService.getSessionsList()
      StorageService.setSessionsList(sessionsList.filter(s => s.token !== session.token))
      history.push(Routes.auth.Login)
      return
    }
    setCurrentUserSession(session)
    onLinkClick()
  }

  initSessionsList() {
    const sessions = StorageService.getSessionsList()
    this.setState({ sessionsList: sessions })
  }

  renderSessionsList = () => {
    const { sessionsList } = this.state
    const { user } = this.props
    // might be filtered by current token from store
    const excludeCurrentUser = sessionsList.filter(session => session.email !== user?.email)
    if (excludeCurrentUser.length === 0) return null
    return (
      <div className={styles.sessionsList}>
        {excludeCurrentUser.map(session => this.renderSessionItem(session))}
      </div>
    )
  }

  renderSessionItem(session: UserSession) {
    const userFirstLetter = (session.name || '-').charAt(0).toUpperCase()

    return (
      <button
        key={session.email}
        className={styles.sessionItem}
        onClick={() => this.onSessionClick(session)}
        type="button"
      >
        <div className={styles.sessionItemAvatar}>{userFirstLetter}</div>
        <span>{session.name}</span>
        {session.expired && this.expiredTip}
      </button>
    )
  }

  render() {
    const { onLinkClick } = this.props
    return (
      <div className={styles.sessionsWrapper}>
        <span className={styles.sessionsListTitle}>{Strings.multiSessions.otherProfiles}</span>
        {this.renderSessionsList()}
        <Link className={styles.addSessionLink} to={Routes.auth.Login} onClick={onLinkClick}>
          <img src={Images.addGray} alt={Strings.logout} />
          {Strings.multiSessions.addSession}
        </Link>
      </div>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setCurrentUserSession: payload =>
    dispatch(ActionCreators.user.setCurrentUserSession.dispatch(payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderSessionsList))
