// @flow
import BaseModel from './base/base-model'
import { defaultObject } from './defaults'

export type XIRRRateIDFDataType = {|
  products: ?{
    [string]: { month: number, year: number },
  },
  total: ?{ month: ?number, year: ?number },
|}

export default class XIRRRateIDF extends BaseModel {
  products: {
    [string]: { month: number, year: number },
  }

  total: { month: number, year: number }

  constructor(
    data: XIRRRateIDFDataType = {
      products: null,
      total: {
        month: null,
        year: null,
      },
    },
  ) {
    super()

    this.products = defaultObject(data.products)
    this.total = defaultObject(data.total)
  }
}
