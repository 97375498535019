// @flow

import React from 'react'

import { classnames } from '@/helpers'

import { Strings, Images } from '@/constants'
import Access from '@/constants/accessibility'

import jsStyles from '../styles'
import styles from './styles.scss'

type PortalWrapperProps = {|
  blackTop?: boolean,
  closeModal: () => void,
  children: React$Node,
|}

export class PortalWrapper extends React.Component<PortalWrapperProps> {
  static defaultProps = {
    blackTop: false,
  }

  handleBackgroundClick = (e: SyntheticMouseEvent<>) => {
    const { closeModal } = this.props
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  handleButtonPress = () => {
    const { closeModal } = this.props

    closeModal()
  }

  handleKeyPress = (e: SyntheticKeyboardEvent<>) => {
    const { closeModal } = this.props
    if (e.key === 'Escape' || e.key === 'Esc') {
      closeModal()
    }
  }

  render() {
    const { children, blackTop } = this.props

    return (
      <div className={classnames(styles.modalOverlay)}>
        <div
          aria-label={Access.Components.Modal.Wrapper}
          className={classnames(styles.modalContainer)}
          role="button"
          tabIndex={0}
          onClick={this.handleBackgroundClick}
          onKeyDown={this.handleKeyPress}
        >
          <div className={classnames(styles.portalModal)} css={[jsStyles.topPad(blackTop)]}>
            <div css={jsStyles.dark(blackTop)} className={styles.portalCloseBar}>
              <button
                aria-label={Access.Components.Modal.Close}
                className={styles.closePortalModalButton}
                type="button"
                onClick={this.handleButtonPress}
              >
                <img alt={Strings.close} src={Images.close} />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default PortalWrapper
