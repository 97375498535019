// @flow

import { dispatchReducer, runReducers } from '@/helpers/dispatch-and-reduce'
import { noOp } from './shared-reducers'

export const ACTIONS = {
  SESSIONS_POOLING_DATA_RECEIVED: 'SESSIONS_POOLING_DATA_RECEIVED',
  LOAD_CURRENT_USER_SESSIONS: 'LOAD_CURRENT_USER_SESSIONS',
  CURRENT_USER_SESSIONS_LOADED: 'CURRENT_USER_SESSIONS_LOADED',
  END_SESSIONS: 'END_SESSIONS',
  SESSIONS_ENDED: 'SESSIONS_ENDED',
}

export const INITIAL_STATE: SessionsStoreState = {
  sessions: [],
}

const setCurrentUserSessions = (state: SessionsStoreState, payload: string) => ({
  ...state,
  sessions: payload,
})

export const reduxSet = {
  poolingDataReceived: dispatchReducer<SessionsStoreState, { [string]: string }>(
    ACTIONS.SESSIONS_POOLING_DATA_RECEIVED,
    noOp,
  ),
  loadCurrentUserSessionsList: dispatchReducer<SessionsStoreState, string>(
    ACTIONS.LOAD_CURRENT_USER_SESSIONS,
    noOp,
  ),
  endSessions: dispatchReducer<SessionsStoreState, string>(ACTIONS.END_SESSIONS, noOp),
  sessionsEnded: dispatchReducer<SessionsStoreState, string>(ACTIONS.SESSIONS_ENDED, noOp),
  currentUserSessionsLoaded: dispatchReducer<SessionsStoreState, { [string]: string }>(
    ACTIONS.CURRENT_USER_SESSIONS_LOADED,
    setCurrentUserSessions,
  ),
}

export const reducer = (
  state: SessionsStoreState = { ...INITIAL_STATE },
  action: StandardAction,
): SessionsStoreState => runReducers(state, reduxSet, action)
