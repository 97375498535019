// @flow
import * as React from 'react'
import { withRouter } from "react-router"
import moment from 'moment'
import { Chart, DownloadIcon, PolicyFilter, PrintIcon } from '@/components'
import { ModelTypes, Strings, Routes, Enums } from '@/constants'
import { classnames, getMonthYear, getSearchObject } from '@/helpers'
import { multiSheetsDownload } from '@/helpers/xlsx'
import { sortKeysMap } from '@/helpers/sortKeysMap'
import StorageService from '@/helpers/StorageService'
import {
  IDF,
  IDFImage,
  IDFStatement,
  PPLIStatement,
  VULPolicy,
  VULPolicyShort,
  VULStatement,
  XIRRRate,
  XIRRRateIDF,
} from '@/models'
import {
  generateConsolidatedPDFPayload,
  generateXIRRRatesIDFPayload,
  generateXIRRRatesPayload,
} from '@/redux/api-payloads'
import {
  AccountSummaryPPATable,
  AccountSummaryPPLITable,
  AccountSummaryVULTable,
} from './tables/account-summary'
import {
  AccountDetailPPATable,
  AccountDetailPPLITable,
  AccountDetailVULTable,
} from './tables/account-detail'
import {
  AccountTransactionsPPATable,
  AccountTransactionsPPLITable,
  AccountTransactionsVULTable,
} from './tables/account-transactions'
import { IDFReturnsPPATable, IDFReturnsPPLITable, IDFReturnsVULTable } from './tables/idf-returns'
import styles from './styles.scss'
import { InceptionText } from './inception-text'
import IDFAvailabilityFundsPdf from './idf-availability-funds-pdf'
import IDFFactsheetsPdf from './idf-factsheets-pdf'

type Props = {|
  user?: User,
  match?: Match,
  accountDetailNotes: Array<FootnoteType>,
  accountSummaryNotes: Array<FootnoteType>,
  accountTransactionsNotes: Array<FootnoteType>,
  idfReturnsNotes: Array<FootnoteType>,
  statements: Array<PPStatementType>,
  loading: boolean,
  policyStrings: typeof Strings.ppa | typeof Strings.ppli | typeof Strings.vul,
  summaryTable:
    | typeof AccountSummaryPPLITable
    | typeof AccountSummaryPPATable
    | typeof AccountSummaryVULTable,
  detailTable:
    | typeof AccountDetailPPLITable
    | typeof AccountDetailPPATable
    | typeof AccountDetailVULTable,
  transactionsTable:
    | typeof AccountTransactionsPPLITable
    | typeof AccountTransactionsPPATable
    | typeof AccountTransactionsVULTable,
  returnsTable: typeof IDFReturnsPPLITable | typeof IDFReturnsPPATable | typeof IDFReturnsVULTable,
  idfs: { [string]: IDF },
  month: string,
  policies: { [string]: PPPolicyType },
  getAccountDetailDownloadData: (
    statements: Array<PPStatementType>,
    inception: boolean,
    month: ?string,
    ownerName: ?string,
    xirrRates: XIRRRate | Object,
    getInceptionDate: ?string,
    getPremiumInceptionDate: ?string,
  ) => PayloadSheet,
  getAccountSummaryDownloadData: (
    statements: Array<PPStatementType>,
    policies: { [string]: PPPolicyType },
    ownerName: ?Client | User,
  ) => PayloadSheet,
  getIDFReturnsDownloadData: (
    statements: Array<PPStatementType>,
    idfs: { [string]: IDF },
    ownerName: ?string,
    xirrRatesIdf: XIRRRateIDF | Object,
  ) => PayloadSheet,
  getAccountTransactionsDownloadData: (
    statements: Array<PPStatementType>,
    idfs: { [string]: IDF },
    policies: { [string]: PPPolicyType },
    ownerName: ?string,
    worksheetName: ?string,
  ) => PayloadSheet,
  detailModal: (payload: { policyId: string, policyType?: string, month?: ?string }) => void,
  ownerName: ?string,
  idfAvailabilityPdf: IDFImage,
  idfStatements: Array<IDFStatement>,
  idfStatementsLoading: boolean,
  pdfKey: PDFKeysType,
  printPDF?: ?(payload: ApiPayloadType<PrintConsolidatedPayload>) => void,
  getXIRR?: ?(payload: ApiPayloadType<XIRRPayload>) => void,
  printPending?: boolean,
  _printMeta: ?{ id: string },
  search: string,
  xirrRates: XIRRRate | Object,
  xirrRatesIdf: XIRRRateIDF | Object,
  xirrRatesLoading: boolean,
  xirrRatesIdfLoading: boolean,
  modelName: string,
|}

type StatementDetailState = {|
  filteredStatements: Array<PPStatementType>,
  showAll: boolean,
  accountSummarySorting: {
    sortBy?: string,
    sortOrder?: SortOrderType,
  },
|}

export class StatementDetail extends React.Component<Props, StatementDetailState> {
  static defaultProps = {
    printPDF: null,
    printPending: false,
    _printMeta: null,
  }

  constructor(props: Props) {
    super(props)

    const { statements } = props
    const filterIds = this.getFilterIds()

    this.state = {
      filteredStatements: this.filterStatements(statements),
      showAll: filterIds.length === 0,
      accountSummarySorting: {
        sortBy: 'formattedPolicyDate',
        sortOrder: 'desc',
      },
      filteredPolicies: [],
    }
  }

  componentDidMount() {
    const { loading } = this.props

    if (!loading) {
      this.calculateXirr()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: StatementDetailState) {
    const { idfs: prevIDFs, idfStatements: prevIDFStatements } = prevProps
    const { idfs, idfStatements, statements } = this.props

    const { filteredStatements: prevFilteredStatements, showAll: prevShowAll } = prevState
    const { filteredStatements, showAll } = this.state

    const idfsChanged = Object.keys(idfs).length !== Object.keys(prevIDFs).length
    const idfStatementsChanged = idfStatements.length !== prevIDFStatements.length

    if (idfsChanged || idfStatementsChanged) {
      const newFilteredStatements = this.filterStatements(statements)
      this.updateFilteredStatements(newFilteredStatements)
    }

    const filteredStatementsChanged =
      prevShowAll !== showAll || prevFilteredStatements.length !== filteredStatements.length
    const showAllChanged = prevShowAll !== showAll

    if (filteredStatementsChanged || showAllChanged) {
      this.calculateXirr()
    }
  }

  getAllProductsFactSheets() {
    const {
      xirrRatesIdf: { products },
    } = this.props
    return Object.entries(products)
      .filter(
        ([, productDetails]) => productDetails.idfFactsheets && productDetails.idfFactsheets.url,
      )
      .reduce(
        (filteredProducts, [productName, productDetails]) => ({
          ...filteredProducts,
          [productName]: {
            idfFactsheets: {
              url: productDetails.idfFactsheets.url,
              product: productDetails.idfFactsheets.product,
              date: productDetails.idfFactsheets.date,
            },
          },
        }),
        {},
      )
  }

  handlePolicyFilterUpdate = (filterState: {
    activeFilters: OptionsType<string>,
    showAll: boolean,
  }) => {
    const { statements } = this.props
    const { activeFilters, showAll } = filterState

    const activeIds = activeFilters.map((filter: { label: string, value: string }) => filter.value)
    const filteredStatements = statements.filter(
      (statement: PPStatementType) => activeIds.includes(statement.policyId) || showAll,
    )
    this.setState({ filteredStatements, showAll, filteredPolicies: activeFilters })
  }

  filterOptionsFromPolicies = (): OptionsType<string> => {
    const { policies, statements, month } = this.props

    return Object.values(policies)
      .reduce((acc: OptionsType<string>, curr: PPPolicyType) => {
        const hasStatement = statements.find(
          statement => statement.policyId === curr.id && statement.statementStart === month,
        )
        if (hasStatement) {
          const policyTitle = curr.title || ''
          const insured = curr.insuredString
          const labelForPolicy = `${policyTitle}\xa0 ${Strings.issuedOn(insured)}`
          acc.push({ label: labelForPolicy, value: curr.id, policyDate: curr.policyDate })
        }
        return acc
      }, [])
      .sort((a, b) => new Date(b.policyDate) - new Date(a.policyDate))
  }

  updateFilteredStatements = (filteredStatements: Array<PPStatementType>) => {
    this.setState({ filteredStatements })
  }

  isInceptionDateAfterIssueDate = () => {
    const { filteredStatements } = this.state
    const { policies } = this.props

    const vulSelected = filteredStatements[0] && filteredStatements[0] instanceof VULStatement

    if (!vulSelected) {
      return false
    }

    const sortedPolicies: Array<PPPolicyType> = Object.values(policies).sort(
      (a: PPPolicyType, b: PPPolicyType): number => new Date(a.policyDate) - new Date(b.policyDate),
    )
    const sortedStatements: Array<PPStatementType> = filteredStatements.sort(
      (a: PPStatementType, b: PPStatementType): number =>
        new Date(a.statementStartDate) - new Date(b.statementStartDate),
    )

    if (sortedPolicies.length && sortedStatements.length) {
      return (
        new Date(sortedStatements[0].statementStartDate) > new Date(sortedPolicies[0].policyDate)
      )
    }

    return false
  }

  getInceptionDate = () => {
    const { filteredPolicies } = this.state
    let sortedFilteredPolicies
    if (filteredPolicies?.length > 0) {
      sortedFilteredPolicies = filteredPolicies.sort(
        (a: PPPolicyType, b: PPPolicyType): number =>
          new Date(a.policyDate) - new Date(b.policyDate),
      )
    } else {
      sortedFilteredPolicies = this.filterOptionsFromPolicies().sort(
        (a: PPPolicyType, b: PPPolicyType): number =>
          new Date(a.policyDate) - new Date(b.policyDate),
      )
    }
    if (sortedFilteredPolicies.length) {
      return moment(sortedFilteredPolicies[0].policyDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
    }

    return ''
  }

  getPremiumInceptionDate = (statements) => {
    if(statements.length) {
      const transactionDates = statements.reduce((acc, statement) => {
        if(statement?.transactions.length) {
          const filteredTransactions = statement.transactions.filter(item => item.transactionType === Enums.TransactionType.PREMIUM_DEPOSIT)
          filteredTransactions.forEach((item) => {
            acc.push(moment(item.date)._d)
          });
        }
        return acc
      }, [])
      if(transactionDates.length) {
        transactionDates.sort((a, b) => a - b)
        return moment(transactionDates[0], 'YYYY-MM-DD').format('MMMM D, YYYY')
      }
    }
    return ''
  }

  formatAccountSummaryData = (
    statements: Array<PPStatementType>,
    policies: { [string]: PPPolicyType },
  ) =>
    statements.reduce(
      (acc, cur) => (policies[cur.policyId] ? [...acc, policies[cur.policyId]] : acc),
      [],
    )

  filterStatements = (statements: Array<PPStatementType>): Array<PPStatementType> => {
    const filterIds = this.getFilterIds()
    if (filterIds.length > 0) {
      return statements.filter((statement: PPStatementType) =>
        filterIds.includes(statement.policyId.toString()),
      )
    }
    return statements
  }

  calculateXirr = () => {
    const { filteredStatements, showAll } = this.state
    const { month, getXIRR, policies } = this.props

    if (!getXIRR) {
      return
    }

    const type =
      // eslint-disable-next-line no-nested-ternary
      filteredStatements[0] instanceof PPLIStatement
        ? 'PPLI'
        : filteredStatements[0] instanceof VULStatement
        ? 'VUL'
        : 'PPA'

    const date = moment(month).format('YYYY-MM')

    const activeIds = Object.values(policies).reduce((acc: Array<string>, curr: PPPolicyType) => {
      const hasStatement = filteredStatements.find(
        statement => statement.policyId === curr.id && statement.statementStart === month,
      )
      if (hasStatement) {
        acc.push(curr.id)
      }
      return acc
    }, [])

    const payload = showAll
      ? {
          date,
          type,
        }
      : {
          date,
          type,
          ids: activeIds,
        }

    getXIRR(generateXIRRRatesPayload(payload))

    getXIRR(generateXIRRRatesIDFPayload(payload))
  }

  getTablesAsXLSX = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation()
    const { filteredStatements } = this.state
    const sinceInceptionDate = this.getInceptionDate()
    const {
      ownerName,
      month,
      policies,
      modelName,
      getAccountSummaryDownloadData,
      getIDFReturnsDownloadData,
      getAccountDetailDownloadData,
      getAccountTransactionsDownloadData,
      idfs,
      idfStatements,
      loading,
      idfStatementsLoading,
      xirrRates,
      xirrRatesIdf,
    } = this.props

    const isPPA = modelName === ModelTypes.Annuity
    const isPPLI = modelName === ModelTypes.PrivatePlacement

    if (!loading && !idfStatementsLoading) {
      const activeIds = [...new Set(filteredStatements.map(f => f.policyId))]
      const currentPoliciesAccountIds = Object.values(policies).map(p => p.accountId)

      const filteredIdfStatements = idfStatements.filter(
        s => activeIds.includes(s.policyId) && s.statementStart === month,
      )
      const filteredIdfStatementsSinceInception = idfStatements.filter(
        s => activeIds.includes(s.policyId) && currentPoliciesAccountIds.includes(s.accountId),
      )
      const filteredMonthStatements = filteredStatements.filter(s => s.statementStart === month)
      const filteredAccountIds = activeIds
        .map(id => (policies[id] ? policies[id].accountId : null))
        .filter(Boolean)
      const inception = this.isInceptionDateAfterIssueDate()
      const sheets = [
        getAccountSummaryDownloadData(filteredMonthStatements, policies, ownerName),
        getIDFReturnsDownloadData(filteredMonthStatements, idfs, ownerName, xirrRatesIdf),
        getAccountDetailDownloadData(
          filteredStatements,
          inception,
          month,
          ownerName,
          xirrRates,
          sinceInceptionDate,
        ),
        getAccountTransactionsDownloadData(filteredIdfStatements, idfs, policies, ownerName),
      ]
      if (isPPA || isPPLI) {
        const worksheetName = isPPA
          ? Strings.ppa.worksheetName.accountTransactionsSinceInception
          : Strings.ppli.worksheetName.accountTransactionsSinceInception
        sheets.push(
          getAccountTransactionsDownloadData(
            filteredIdfStatementsSinceInception,
            idfs,
            policies,
            ownerName,
            worksheetName,
          ),
        )
      }
      const type =
        // eslint-disable-next-line no-nested-ternary
        filteredStatements[0] instanceof PPLIStatement
          ? 'PPLI'
          : filteredStatements[0] instanceof VULStatement
          ? 'VUL'
          : 'PPA'
      multiSheetsDownload(sheets, filteredAccountIds, type, month)
    }
  }

  getPrintPayload = () => {
    const {
      showAll,
      filteredStatements,
      accountSummarySorting: { sortBy, sortOrder },
    } = this.state

    const { pdfKey, month } = this.props

    let sortKey =
      pdfKey !== '' && sortBy !== '' && Object.prototype.hasOwnProperty.call(sortKeysMap, pdfKey)
        ? sortKeysMap[pdfKey][sortBy]
        : null

    if (sortKey && sortOrder === 'desc') {
      sortKey = `-${sortKey}`
    }

    const date = moment(month).format('YYYY-MM')

    const policyIds = [
      ...new Set(filteredStatements.map(filteredStatement => filteredStatement.policyId)),
    ]

    return showAll
      ? {
          date,
          pdfKey,
          sortKey,
        }
      : {
          date,
          pdfKey,
          sortKey,
          policyIds,
        }
  }

  printPayload = () => {
    const { printPDF, pdfKey, month } = this.props
    const printDataPayload = this.getPrintPayload()
    const printPayload = generateConsolidatedPDFPayload(printDataPayload, {
      id: `${pdfKey}-${month}`,
    })
    if (printPDF) {
      printPDF(printPayload)
    }
  }

  getFilterIds = () => {
    const { search } = this.props
    const searchObj = getSearchObject(search)
    let filterIds = []
    if (searchObj.filterIds) {
      filterIds = JSON.parse(decodeURIComponent(searchObj.filterIds))
    }
    return filterIds
  }

  getVariances = (): Array<VarianceType> => {
    const { month, policies, modelName } = this.props
    const { filteredStatements } = this.state

    return [
      ...filteredStatements
        .filter(
          (statement: PPStatementType) =>
            statement.statementStart === month && statement?.varianceNote,
        )
        .reduce((acc, statement) => {
          const policy = policies[statement.policyId]

          if (!policy) {
            return acc
          }

          return acc.set(statement.policyId, {
            modelName,
            varianceNote: statement.varianceNote,
            accountId: policy.accountId,
            insuranceCompanyName: policy.product.insuranceCompany.name,
          })
        }, new Map())
        .values(),
    ].sort((a, b) => {
      if (a.accountId < b.accountId) return -1
      if (a.accountId > b.accountId) return 1
      return 0
    })
  }

  checkProductsForIdfSheets(products = []) {
    return Object.keys(products).some(productName => {
      const product = products[productName]

      if (product.idfFactsheets) {
        const factsheets = product.idfFactsheets

        if (factsheets.url && this.isValidUrl(factsheets.url)) {
          return true
        }
      }
      return false
    })
  }

  // eslint-disable-next-line class-methods-use-this
  isValidUrl(url) {
    // Regular expression to validate URL format
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/

    return urlRegex.test(url)
  }

  renderTitlePolicyItems = (policyId: number, index: number, allIds: Array<number>): React.Node => {
    const { policies } = this.props
    const policy = policies[policyId.toString()]
    const policyTitle = policy?.title || ''
    const insured = policy?.insuredString
    return (
      <div key={policyTitle + policyId.toString()}>
        <h3 className={styles.sectionTitleActualCase}>
          {index < allIds.length - 1
            ? `${policyTitle}\xa0 ${Strings.issuedOn(insured)},`
            : `${policyTitle}\xa0 ${Strings.issuedOn(insured)}`}
        </h3>
      </div>
    )
  }

  renderTitleWithPolicies = () => {
    const { showAll, filteredStatements } = this.state
    const { policyStrings } = this.props
    let filteredPolicyNames
    if (showAll) {
      return (
        <div>
          <h3 className={styles.sectionTitleActualCase}>{policyStrings.titleConsolidated}</h3>
        </div>
      )
    }

    if (filteredStatements.length) {
      const statementIds = filteredStatements.reduce((acc: Array<number>, cur) => {
        if (!acc.includes(cur.policyId)) {
          acc.push(cur.policyId)
        }
        return acc
      }, [])
      filteredPolicyNames = statementIds.map(this.renderTitlePolicyItems)
    } else {
      filteredPolicyNames = (
        <div>
          <h3 className={styles.sectionTitleActualCase}>-</h3>
        </div>
      )
    }

    return (
      <div className={styles.titleFiltered}>
        <div className={styles.titleFilteredInner}>
          <h3 className={styles.sectionTitleActualCase}>{policyStrings.titleFiltered}</h3>
          <div className={styles.filteredPolicies}>{filteredPolicyNames}</div>
        </div>
      </div>
    )
  }

  renderPolicyFilterRow = () => {
    const {
      printPending,
      _printMeta,
      pdfKey,
      month,
      loading,
      idfStatementsLoading,
      xirrRatesLoading,
      xirrRatesIdfLoading,
      user,
    } = this.props
    const filterIds = this.getFilterIds()
    const pending = printPending && _printMeta?.id === `${pdfKey}-${month}`
    const disabled =
      printPending ||
      pending ||
      loading ||
      idfStatementsLoading ||
      xirrRatesLoading ||
      xirrRatesIdfLoading
    const activeClient = StorageService.getActiveUser()
    let disableExcelForUser = false
    let disbalePdfForUser = false

    if (
      user?.excel_disabled?.length > 0 &&
      activeClient &&
      user.excel_disabled.map(String).includes(String(activeClient))
    ) {
      disableExcelForUser = true
    }
    if (
      user?.pdf_disabled?.length > 0 &&
      activeClient &&
      user.pdf_disabled.map(String).includes(String(activeClient))
    ) {
      disbalePdfForUser = true
    }

    return (
      <div className={styles.policyFilterRow}>
        {this.renderTitleWithPolicies()}
        <div className={styles.rightContent}>
          <PolicyFilter
            filters={this.filterOptionsFromPolicies()}
            onChange={this.handlePolicyFilterUpdate}
            title={Strings.selectPolicies}
            open={false}
            defaultFilters={filterIds}
          />
          <div className={styles.buttonPadding}>
            {!disableExcelForUser ? (
              <DownloadIcon onClick={this.getTablesAsXLSX} disabled={disabled} />
            ) : null}
            {!disbalePdfForUser ? (
              <PrintIcon onClick={this.printPayload} pending={pending} disabled={disabled} />
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  renderHero = () => {
    const { statements, policyStrings, month, ownerName } = this.props
    const { showAll } = this.state
    let title = policyStrings.heroTitle
    if (!showAll && statements.length === 1) {
      title = policyStrings.heroTitleSingular
    }
    return (
      <div className={styles.hero}>
        <h2 className={styles.heroTitle}>{title}</h2>
        <div className={styles.subtitle}>
          <h3>{ownerName}</h3>
          <h3>{getMonthYear(month)}</h3>
        </div>
      </div>
    )
  }

  renderChart = () => {
    const { loading } = this.props
    const { filteredStatements } = this.state
    return (
      <div className={classnames(styles.section, styles.chartContainer)}>
        <h3 className={classnames(styles.sectionTitle, styles.title)}>
          {Strings.resultsSinceInception}
        </h3>
        <Chart loading={loading} policyStatements={filteredStatements} hideDateSelector noLineTension />
        {this.isInceptionDateAfterIssueDate() && (
          <div className={styles.inceptionContainer}>
            <InceptionText />
          </div>
        )}
      </div>
    )
  }

  renderAccountSummary = () => {
    const {
      accountSummaryNotes,
      ownerName,
      loading,
      policyStrings,
      policies,
      detailModal,
      month,
      pdfKey,
      summaryTable,
      user,
    } = this.props
    const { filteredStatements } = this.state
    const filteredMonthStatements = filteredStatements.filter(s => s.statementStart === month)
    const AccountSummaryTable = summaryTable
    const handleSortingUpdate = sortingConfig =>
      this.setState({ accountSummarySorting: sortingConfig })
    return (
      <div className={styles.section}>
        <h3 className={classnames(styles.sectionTitle, styles.tableTitle)}>
          {policyStrings.accountSummary}
        </h3>
        <AccountSummaryTable
          ownerName={ownerName}
          loading={loading}
          notes={accountSummaryNotes}
          month={month}
          policies={policies}
          statements={filteredMonthStatements}
          detailModal={detailModal}
          pdfKey={pdfKey}
          onSortingUpdate={handleSortingUpdate}
          user={user}
        />
      </div>
    )
  }

  renderFactsheets = () => {
    const { xirrRatesIdf } = this.props
    const factSheetAvailable = this.checkProductsForIdfSheets(xirrRatesIdf.products)
    let allFactsheets = []
    if (factSheetAvailable) {
      allFactsheets = this.getAllProductsFactSheets(xirrRatesIdf.products)
    }

    if (factSheetAvailable)
      return (
        <div className={classnames(styles.section, styles.bottomBorder)}>
          <h3 className={styles.sectionTitle}>IDF Manager Reporting</h3>
          <p className={styles.sectionSubtitle} />
          <IDFFactsheetsPdf allFactsheets={allFactsheets} />
        </div>
      )
    return ''
  }

  renderIDFReturns = () => {
    const {
      ownerName,
      loading,
      policyStrings,
      idfReturnsNotes,
      idfStatements,
      month,
      idfs,
      idfStatementsLoading,
      pdfKey,
      returnsTable,
      xirrRatesIdf,
      xirrRatesIdfLoading,
      user,
    } = this.props
    const { filteredStatements } = this.state
    const activeIds = [...new Set(filteredStatements.map(f => f.policyId))]
    const filteredIdfStatements = idfStatements.filter(s => activeIds.includes(s.policyId))
    const IDFReturnsTable = returnsTable
    const factSheetAvailable = this.checkProductsForIdfSheets(xirrRatesIdf.products)
    let notesToDisplay = []
    if (factSheetAvailable) {
      notesToDisplay = idfReturnsNotes
    }
    return (
      <div className={classnames(styles.section, styles.bottomBorder)}>
        <h3 className={classnames(styles.sectionTitle, styles.tableTitle)}>
          {policyStrings.idfReturns}
        </h3>
        <div className="idf-returns">
          <IDFReturnsTable
            ownerName={ownerName}
            loading={loading || idfStatementsLoading || xirrRatesIdfLoading}
            month={month}
            statements={filteredIdfStatements}
            idfs={idfs}
            notes={notesToDisplay}
            policyStrings={policyStrings}
            pdfKey={pdfKey}
            xirrRatesIdf={xirrRatesIdf}
            user={user}
          />
        </div>
      </div>
    )
  }

  renderAccountDetail = () => {
    const PPLIDetailRoute = Routes.portal.PPLIDetail
    const {
      match,
      accountDetailNotes,
      ownerName,
      loading,
      policyStrings,
      detailTable,
      idfStatementsLoading,
      pdfKey,
      xirrRates,
      xirrRatesLoading,
      month,
      user,
      idfStatements,
      policies,
    } = this.props
    const { filteredStatements } = this.state
    const variances = this.getVariances()
    const AccountDetailTable = detailTable
    const activeIds = [...new Set(filteredStatements.map(f => f.policyId))]
    const currentPoliciesAccountIds = Object.values(policies).map(p => p.accountId)

    // const filteredIdfStatements = idfStatements.filter(
    //   s => activeIds.includes(s.policyId) && s.statementStart === month,
    // )
    const filteredIdfStatementsSinceInception = idfStatements.filter(
      s => activeIds.includes(s.policyId) && currentPoliciesAccountIds.includes(s.accountId),
    )
    const sinceInceptionDate = match.path === PPLIDetailRoute ? this.getPremiumInceptionDate(filteredIdfStatementsSinceInception) : this.getInceptionDate()

    return (
      <div className={classnames(styles.section, styles.bottomBorder)}>
        <h3 className={classnames(styles.sectionTitle, styles.tableTitle)}>
          {policyStrings.accountDetail}
        </h3>
        <AccountDetailTable
          ownerName={ownerName}
          loading={loading || idfStatementsLoading || xirrRatesLoading}
          statements={filteredStatements}
          month={month}
          notes={accountDetailNotes}
          variances={variances}
          inception={this.isInceptionDateAfterIssueDate()}
          pdfKey={pdfKey}
          xirrRates={xirrRates}
          sinceInceptionDate={sinceInceptionDate}
          user={user}
        />
      </div>
    )
  }

  renderAccountTransactions = () => {
    const {
      accountTransactionsNotes,
      ownerName,
      idfStatementsLoading,
      policyStrings,
      month,
      idfs,
      idfStatements,
      pdfKey,
      policies,
      transactionsTable,
      user,
    } = this.props
    const AccountTransactionsTable = transactionsTable
    const { filteredStatements } = this.state
    const activeIds = [...new Set(filteredStatements.map(f => f.policyId))]
    const filteredIdfStatements = idfStatements.filter(
      s => activeIds.includes(s.policyId) && s.statementStart === month,
    )
    const variances = this.getVariances()
    return (
      <div className={classnames(styles.section, styles.bottomBorder, styles.accountTransactions)}>
        <h3 className={classnames(styles.sectionTitle, styles.tableTitle)}>
          {policyStrings.accountTransactions}
        </h3>
        <AccountTransactionsTable
          ownerName={ownerName}
          notes={accountTransactionsNotes}
          loading={idfStatementsLoading}
          statements={filteredIdfStatements}
          month={month}
          idfs={idfs}
          variances={variances}
          pdfKey={pdfKey}
          policies={policies}
          user={user}
        />
      </div>
    )
  }

  renderIDFAvailabilityFunds = () => {
    const { idfAvailabilityPdf, policyStrings, policies } = this.props
    const { filteredStatements } = this.state

    const activeIds = [...new Set(filteredStatements.map(f => f.policyId))]
    const filteredPolicies = activeIds.reduce((arr, key) => [...arr, policies[key]], [])

    const isDisabled = filteredPolicies.some(policy => policy?.disableIdf)
    const isExcluded = filteredPolicies.some(
      policy => policy instanceof VULPolicy || policy instanceof VULPolicyShort,
    )

    if (isDisabled || isExcluded || !idfAvailabilityPdf) {
      return null
    }

    // Leave locales argument undefined to get default locale
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
    const endMonthYear = `${idfAvailabilityPdf.jsDate.toLocaleString(undefined, {
      month: 'long',
    })}, ${idfAvailabilityPdf.jsDate.getFullYear()}`
    return (
      <div className={classnames(styles.section, styles.bottomBorder)}>
        <h3 className={styles.sectionTitle}>
          {policyStrings.idfFunds} <span className={styles.initialTextTransform}>(IDFs)</span>
        </h3>
        <p className={styles.sectionSubtitle}>{Strings.availabilityByIC}</p>
        <p className={styles.sectionSubtitle}>{Strings.asOfMonthYear(endMonthYear)}</p>

        <IDFAvailabilityFundsPdf idfAvailabilityPdf={idfAvailabilityPdf} />
      </div>
    )
  }

  renderDisclosure = () => {
    const { policyStrings } = this.props

    return (
      <div className={styles.disclosureWrapper}>
        <div className={styles.disclosure}>
          <h3>{Strings.disclosureTitle}</h3>
          {policyStrings.disclosure.map((text: string) => (
            <p key={text}>{text}</p>
          ))}
          {policyStrings.disclosureFooter && <em>{policyStrings.disclosureFooter}</em>}
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className={styles.headerContainer}>
          {this.renderPolicyFilterRow()}
          {this.renderHero()}
        </div>
        <div className={styles.content}>
          {this.renderChart()}
          {this.renderAccountSummary()}
          {this.renderIDFReturns()}
          {this.renderAccountDetail()}
          {this.renderAccountTransactions()}
          {this.renderFactsheets()}
          {this.renderIDFAvailabilityFunds()}
          {this.renderDisclosure()}
        </div>
      </>
    )
  }
}

export default withRouter(StatementDetail)
