// @flow

import * as React from 'react'
import { withRouter } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import { Strings, Routes } from '@/constants'
import Access from '@/constants/accessibility'
import { PrimaryButton, TermsAndConditions } from '@/components'
import { classnames } from '@/helpers'
import modal from '../modal'
import sharedStyles from '../styles.scss'
import styles from './styles.scss'

type TermsOfServiceProps = {|
  history: RouterHistory,
|}

type TermsOfServiceState = {|
  acceptBtnDisabled: boolean,
|}

const BUFFER = 25
export class TermsOfService extends React.Component<TermsOfServiceProps, TermsOfServiceState> {
  state = {
    acceptBtnDisabled: true,
  }

  scrollBox: React.Element = React.createRef()

  componentDidMount() {
    const { scrollBox } = this

    if (scrollBox.current) {
      const hasOverflowingContent = scrollBox.current.offsetHeight < scrollBox.current.scrollHeight
      if (!hasOverflowingContent) {
        this.setState({ acceptBtnDisabled: false })
      }
    }
  }

  getFooterMessage() {
    const { acceptBtnDisabled } = this.state
    return acceptBtnDisabled ? <p>{Strings.termsAndConditions.message}</p> : null
  }

  handleScroll = (event: SyntheticEvent<HTMLDivElement>) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget
    if (scrollTop >= scrollHeight - offsetHeight - BUFFER) {
      this.setState({ acceptBtnDisabled: false })
    }
  }

  handleAccept = () => {
    const { history } = this.props
    modal.remove()
    history.replace(Routes.auth.AcceptInvite, { accept_tos: true })
  }

  render() {
    const { acceptBtnDisabled } = this.state
    return (
      <div
        className={classnames(
          sharedStyles.modalContainer,
          sharedStyles.flexColumn,
          styles.termsWrapper,
        )}
      >
        <header className={styles.termsHeader}>
          <h3 className={sharedStyles.headerTextSmall}>{Strings.termsAndConditions.heading}</h3>
        </header>
        <div
          ref={this.scrollBox}
          className={styles.scrollingTextBox}
          onScroll={this.handleScroll}
          aria-label={Access.Components.TermsModal.TextContainer}
        >
          <p>{Strings.termsAndConditions.lastUpdated}</p>
          <TermsAndConditions />
        </div>
        <div className={styles.termsFooter}>
          {this.getFooterMessage()}
          <PrimaryButton
            className={styles.button}
            title={Strings.termsAndConditions.buttonAccept}
            ariaLabel={Access.Components.TermsModal.Accept}
            disabled={acceptBtnDisabled}
            onClick={this.handleAccept}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(TermsOfService)
