// @flow
import ApiService from '@/services/ApiService'

import { reduxSet as userReduxSet } from './user'
import { reduxSet as policyReduxSet } from './policy'
import { reduxSet as sessionsReduxSet } from './sessions'

const keyObj = obj =>
  Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = curr
    return acc
  }, {})

const endpoints = keyObj(ApiService)

// Manually creating this to avoid circular import
const storeKeys = {
  user: 'user',
  api: 'api',
  policy: 'policy',
  sessions: 'sessions',
}
const userReducers = keyObj(userReduxSet)
const policyReducers = keyObj(policyReduxSet)
const sessionsReducers = keyObj(sessionsReduxSet)

// The store typing is commented out because it is expecting the api keys in the story

export const acceptInvitePayload = (
  data: AcceptInvitePayload,
): ApiPayloadType<AcceptInvitePayload> => ({
  url: endpoints.acceptInvite,
  successReducerKey: userReducers.inviteAccepted,
  storeName: storeKeys.user,
  data,
})

export const resendInvitePayload = (
  data: ResendInvitePayload,
): ApiPayloadType<ResendInvitePayload> => ({
  url: endpoints.resendInvite,
  successReducerKey: userReducers.resendInviteSuccess,
  storeName: storeKeys.user,
  data,
})

export const forgotPasswordPayload = (
  data: ForgotPasswordPayload,
): ApiPayloadType<ForgotPasswordPayload> => ({
  url: endpoints.forgotPassword,
  data,
  successReducerKey: userReducers.forgotPassword,
  storeName: storeKeys.user,
})

export const resetPasswordPayload = (
  data: ResetPasswordPayload,
): ApiPayloadType<ResetPasswordPayload> => ({
  url: endpoints.resetPassword,
  data,
  successReducerKey: userReducers.resetPassword,
  storeName: storeKeys.user,
})

export const lockAccountPayload = (
  data: LockAccountPayload,
): ApiPayloadType<LockAccountPayload> => ({
  url: endpoints.lockAccount,
  data,
  successReducerKey: userReducers.lockAccount,
  storeName: storeKeys.user,
})

export const loginPayload = (data: LoginPayload): ApiPayloadType<LoginPayload> => ({
  url: endpoints.loginUser,
  data,
  successReducerKey: userReducers.loginSuccess,
  errorReducerKey: userReducers.loginError,
  storeName: storeKeys.user,
  _meta: { credentials: data, persistent: data.persistent },
})

export const portalSetPasswordPayload = (
  data: PortalSetPasswordPayload,
): ApiPayloadType<PortalSetPasswordPayload> => ({
  url: endpoints.portalSetPassword,
  data,
  successReducerKey: userReducers.portalSetPasswordSuccess,
  storeName: storeKeys.user,
})

export const portalSetMobilePayload = (
  data: PortalSetMobilePayload,
): ApiPayloadType<PortalSetMobilePayload> => ({
  url: endpoints.portalSetMobile,
  data,
  successReducerKey: userReducers.portalSetMobileSuccess,
  storeName: storeKeys.user,
})

export const portalSetUsernamePayload = (
  data: PortalUpdateUserInfoPayload,
): ApiPayloadType<PortalUpdateUserInfoPayload> => ({
  url: endpoints.portalSetUsername,
  data,
  successReducerKey: userReducers.portalSetUsernameSuccess,
  storeName: storeKeys.user,
})

export const portalSetEmailPayload = (
  data: PortalUpdateUserInfoPayload,
): ApiPayloadType<PortalUpdateUserInfoPayload> => ({
  url: endpoints.portalSetEmail,
  data,
  successReducerKey: userReducers.portalSetEmailSuccess,
  storeName: storeKeys.user,
})

export const verifyDevicePayload = (
  data: VerifyDevicePayload,
): ApiPayloadType<VerifyDevicePayload> => ({
  url: endpoints.verifyDevice,
  // $FlowFixMe
  data,
  successReducerKey: userReducers.loginSuccess,
  storeName: storeKeys.user,
  _meta: { persistent: data.persistent },
})

export const resendVerificationPayload = (
  data: ResendVerificationPayload,
): ApiPayloadType<ResendVerificationPayload> => ({
  url: endpoints.resendVerifyCode,
  data,
  successReducerKey: userReducers.resendVerificationSuccess,
  storeName: storeKeys.user,
})

export const clientListPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.clientList,
  data: {},
  successReducerKey: userReducers.clientListSuccess,
  storeName: storeKeys.user,
})

export const getInsurancePoliciesPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getInsurancePolicies,
  data: {},
  successReducerKey: policyReducers.getInsurancePoliciesSuccess,
  storeName: storeKeys.policy,
})

export const getInsurancePoliciesShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getInsurancePoliciesShort,
  data: {},
  successReducerKey: policyReducers.getInsurancePoliciesShortSuccess,
  storeName: storeKeys.policy,
})

export const getInsurancePoliciesCalendarPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getInsurancePoliciesCalendar,
  data: {},
  successReducerKey: policyReducers.getInsurancePoliciesCalendarSuccess,
  storeName: storeKeys.policy,
})

export const getPrivatePlacementsCalendarPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPrivatePlacementsCalendar,
  data: {},
  successReducerKey: policyReducers.getPrivatePlacementsCalendarSuccess,
  storeName: storeKeys.policy,
})

export const getVULStatementsCalendarPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getVULStatementsCalendar,
  data: {},
  successReducerKey: policyReducers.getVULStatementsCalendarSuccess,
  storeName: storeKeys.policy,
})

export const getFootnotesPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getFootnotes,
  data: {},
  successReducerKey: policyReducers.getFootnotesSuccess,
  storeName: storeKeys.policy,
})

export const getAnnuitiesPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getAnnuityPolicies,
  data: {},
  successReducerKey: policyReducers.getAnnuityPoliciesSuccess,
  storeName: storeKeys.policy,
})

export const getAnnuitiesShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getAnnuityPoliciesShort,
  data: {},
  successReducerKey: policyReducers.getAnnuityPoliciesShortSuccess,
  storeName: storeKeys.policy,
})

export const getDisabilityPoliciesShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getDisabilityPoliciesShort,
  data: {},
  successReducerKey: policyReducers.getDisabilityPoliciesShortSuccess,
  storeName: storeKeys.policy,
})

export const getIDFStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getIDFStatements,
  data: {},
  successReducerKey: policyReducers.getIDFStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getPrivatePlacementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPrivatePlacements,
  data: {},
  successReducerKey: policyReducers.getPrivatePlacementsSuccess,
  storeName: storeKeys.policy,
})

export const getPrivatePlacementsShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPrivatePlacementsShort,
  data: {},
  successReducerKey: policyReducers.getPrivatePlacementsShortSuccess,
  storeName: storeKeys.policy,
})

export const getPPLIStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPPLIStatements,
  data: {},
  successReducerKey: policyReducers.getPPLIStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getPPLIStatementsShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPPLIStatementsShort,
  data: {},
  successReducerKey: policyReducers.getPPLIStatementsShortSuccess,
  storeName: storeKeys.policy,
})

export const getVULPoliciesPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getVULPolicies,
  data: {},
  successReducerKey: policyReducers.getVULPoliciesSuccess,
  storeName: storeKeys.policy,
})

export const getVULPoliciesShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getVULPoliciesShort,
  data: {},
  successReducerKey: policyReducers.getVULPoliciesShortSuccess,
  storeName: storeKeys.policy,
})

export const getVULStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getVULStatements,
  data: {},
  successReducerKey: policyReducers.getVULStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getVULStatementsShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getVULStatementsShort,
  data: {},
  successReducerKey: policyReducers.getVULStatementsShortSuccess,
  storeName: storeKeys.policy,
})

export const getPPAStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPPAStatements,
  data: {},
  successReducerKey: policyReducers.getPPAStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getPPAStatementsShortPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getPPAStatementsShort,
  data: {},
  successReducerKey: policyReducers.getPPAStatementsShortSuccess,
  storeName: storeKeys.policy,
})

export const getIDFPdfAvailabilityPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getIDFPdfAvailability,
  data: {},
  successReducerKey: policyReducers.getIDFPdfAvailabilitySuccess,
  storeName: storeKeys.policy,
})

export const generatePDFPayload = (
  data: PrintPayload,
  _meta: { id: string },
): ApiPayloadType<PrintPayload> => ({
  url: endpoints.generatePDF,
  data,
  storeName: storeKeys.policy,
  successReducerKey: policyReducers.generatePDFSuccess,
  errorReducerKey: policyReducers.checkPDFError,
  _meta,
})

export const generateDetailedPDFPayload = (
  data: PrintDetailedPayload,
  _meta: { id: string },
): ApiPayloadType<PrintDetailedPayload> => ({
  url: endpoints.generateDetailedPDF,
  data,
  storeName: storeKeys.policy,
  successReducerKey: policyReducers.generateDetailedPDFSuccess,
  errorReducerKey: policyReducers.checkDetailedPDFError,
  _meta,
})

export const generateConsolidatedPDFPayload = (
  data: PrintConsolidatedPayload,
  _meta: { id: string },
): ApiPayloadType<PrintConsolidatedPayload> => ({
  url: endpoints.generateConsolidatedPDF,
  data,
  storeName: storeKeys.policy,
  successReducerKey: policyReducers.generateConsolidatedPDFSuccess,
  errorReducerKey: policyReducers.checkConsolidatedPDFError,
  _meta,
})

export const checkPDFResultPayload = (
  data: string,
  _meta: { id: string },
): ApiPayloadType<string> => ({
  url: endpoints.checkPDFResult,
  data,
  storeName: storeKeys.policy,
  pollPayloadCount: Number.MAX_SAFE_INTEGER, // Poll until it finishes or we get an error
  successReducerKey: policyReducers.checkPDFResultSuccess,
  errorReducerKey: policyReducers.checkPDFError,
  _meta,
})

export const generateXIRRRatesPayload = (data: XIRRPayload) => ({
  url: endpoints.getXIRRRates,
  data,
  storeName: storeKeys.policy,
  successReducerKey: policyReducers.getXIRRRatesSuccess,
  preSendReducerKey: policyReducers.getXIRRRatesError,
})

export const generateXIRRRatesIDFPayload = (data: XIRRPayload) => ({
  url: endpoints.getXIRRRatesIDF,
  data,
  storeName: storeKeys.policy,
  successReducerKey: policyReducers.getXIRRRatesIDFSuccess,
  errorReducerKey: policyReducers.getXIRRRatesIDFError,
})

export const getIULMonthlyStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getIULMonthlyStatements,
  data: {},
  successReducerKey: policyReducers.getIULMonthlyStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getIULAnnualStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getIULAnnualStatements,
  data: {},
  successReducerKey: policyReducers.getIULAnnualStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getIndexAccountRatesPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getIndexAccountRates,
  data: {},
  successReducerKey: policyReducers.getIndexAccountRatesSuccess,
  storeName: storeKeys.policy,
})

export const getGeneralDocumentsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getGeneralDocuments,
  data: {},
  successReducerKey: policyReducers.getGeneralDocumentsSuccess,
  storeName: storeKeys.policy,
})

export const getConsolidatedStatementsPayload = (): ApiPayloadType<{}> => ({
  url: endpoints.getConsolidatedStatements,
  data: {},
  successReducerKey: policyReducers.getConsolidatedStatementsSuccess,
  storeName: storeKeys.policy,
})

export const getClients = (): ApiPayloadType<{}> => ({
  url: endpoints.getClients,
  data: {},
  successReducerKey: userReducers.getClientsSuccess,
  storeName: storeKeys.user,
})

export const getSessionsStatuses = (tokens: Array<string>) => ({
  url: endpoints.getSessionsStatuses,
  data: { tokens },
  storeName: storeKeys.sessions,
  successReducerKey: sessionsReducers.poolingDataReceived,
})

export const getCurrentUserSessions = () => ({
  url: endpoints.getCurrentUserSessions,
  data: {},
  storeName: storeKeys.sessions,
  successReducerKey: sessionsReducers.currentUserSessionsLoaded,
})

export const endSessionsByIds = (ids: Array<string>) => ({
  url: endpoints.endSessions,
  data: ids,
  storeName: storeKeys.sessions,
  successReducerKey: sessionsReducers.sessionsEnded,
})

export default {
  acceptInvitePayload,
  getAnnuitiesPayload,
  getAnnuitiesShortPayload,
  getDisabilityPoliciesShortPayload,
  getInsurancePoliciesPayload,
  getInsurancePoliciesShortPayload,
  getPPAStatementsPayload,
  getPPLIStatementsPayload,
  getPPLIStatementsShortPayload,
  getVULStatementsPayload,
  getVULStatementsShortPayload,
  getVULPoliciesShortPayload,
  getPrivatePlacementsShortPayload,
  loginPayload,
  resendVerificationPayload,
  resetPasswordPayload,
  portalSetPasswordPayload,
  portalSetUsernamePayload,
  portalSetEmailPayload,
  portalSetMobilePayload,
  verifyDevicePayload,
  clientListPayload,
  lockAccountPayload,
  generateXIRRRatesPayload,
  generateXIRRRatesIDFPayload,
  getIULMonthlyStatementsPayload,
  getIULAnnualStatementsPayload,
  getIndexAccountRatesPayload,
  getClients,
  getGeneralDocumentsPayload,
  getConsolidatedStatementsPayload,
  getSessionsStatuses,
  getCurrentUserSessions,
  endSessionsByIds,
}
