// @flow

import React from 'react'
import { connect } from 'react-redux'

import * as yup from 'yup'
import type { ValidationError } from 'yup'

import { classnames } from '@/helpers'
import { Input, PrimaryButton } from '@/components'
import selectors from '@/selectors'
import Access from '@/constants/accessibility'
import { ErrorKeys } from '@/redux/api'
import { User } from '@/models'
import { Strings } from '@/constants'

import styles from './styles.scss'

const schema = yup.object().shape({
  newUsername1: yup.string().required(Strings.auth.settingsError.newUsername),
  newUsername2: yup
    .string()
    .oneOf([yup.ref('newUsername1')], Strings.auth.settingsError.usernameDoesNotMatch)
    .required(Strings.auth.settingsError.newUsername),
})

const FieldNames = {
  oldUsername: 'oldUsername',
  newUsername1: 'newUsername1',
  newUsername2: 'newUsername2',
}

type FormErrors = {
  newUsername1: string,
  newUsername2: string,
}

type StateToPropsType = {|
  user: ?User,
  error: ?string,
|}

type PortalSetUsernameFormProps = {|
  submitForm: (values: PortalUpdateUserInfoPayload) => {},
  success: boolean,
  ...StateToPropsType,
|}

type PortalSetUsernameFormState = {|
  newUsername1: string,
  newUsername2: string,
  errors?: FormErrors,
  nonUniqueError: string,
|}

export class PortalSetUsernameForm extends React.Component<
  PortalSetUsernameFormProps,
  PortalSetUsernameFormState,
> {
  constructor(props: PortalSetUsernameFormProps) {
    super(props)

    this.state = {
      newUsername1: '',
      newUsername2: '',
      nonUniqueError: '',
    }
  }

  formatErrors = (errors: Array<ValidationError>): FormErrors => {
    const formatErrors: FormErrors = {}
    errors.forEach((err: ValidationError) => {
      formatErrors[err.path] = err.message
    })
    return formatErrors
  }

  onSubmit = (event?: DOMEvent) => {
    if (event) {
      event.preventDefault()
    }

    const { submitForm, user } = this.props
    const { newUsername1, newUsername2 } = this.state

    const values = {
      newUsername1,
      newUsername2,
    }

    const isUnique = this.checkUnique(user, newUsername1)

    if (isUnique) {
      try {
        schema.validateSync(values, { abortEarly: false })
        this.setState({ errors: undefined }, () => {
          submitForm({
            username: newUsername1,
          })
        })
      } catch (err) {
        const errors: FormErrors = this.formatErrors(err.inner)
        this.setState({ errors })
      }
    }
  }

  checkUnique = (user: ?User, newUsername: string): boolean => {
    if (user && user.username === newUsername) {
      this.setState({ nonUniqueError: Strings.settings.username.nonUnique })
      return false
    }
    this.setState({ nonUniqueError: '' })
    return true
  }

  onNewUsername1Change = (value: string) => {
    this.setState({ newUsername1: value })
  }

  onNewUsername2Change = (value: string) => {
    this.setState({ newUsername2: value })
  }

  render() {
    const { error, success, user } = this.props
    const { errors, newUsername1, newUsername2, nonUniqueError } = this.state

    const usernameSuccess =
      !error &&
      !nonUniqueError &&
      !!(
        newUsername1 &&
        newUsername1 === newUsername2 &&
        !errors?.newUsername1 &&
        !errors?.newUsername2
      )

    // display text of any associated errors
    const username1Error =
      nonUniqueError || errors?.newUsername1 || (errors?.newUsername2 ? true : error)

    // only display text of validation errors under 2nd username input, else just make the input border red (hence coercion to booleans)
    const username2Error = !!nonUniqueError || errors?.newUsername2 || !!error

    if (success) {
      return (
        <div className={classnames(styles.successWrapper)}>
          <h4>{Strings.settings.username.title}</h4>
          <p>{Strings.settings.username.success}</p>
        </div>
      )
    }

    return (
      <form className={styles.portalModalForm} onSubmit={this.onSubmit}>
        <div>
          <h4 className={styles.portalModalHeader}>{Strings.settings.username.title}</h4>
          <div className={classnames(styles.inputRow)}>
            <Input
              type="text"
              label={Strings.auth.usernameCurrent}
              name={FieldNames.oldUsername}
              placeholder={user?.username || Strings.auth.usernameCurrent}
              onChange={() => {}}
              success={usernameSuccess}
              disabled
            />
          </div>
          <div className={classnames(styles.inputRow)}>
            <Input
              type="text"
              label={Strings.auth.usernameNew}
              name={FieldNames.newUsername1}
              placeholder={Strings.auth.usernameNew}
              onChange={this.onNewUsername1Change}
              error={username1Error}
              success={usernameSuccess}
            />
            <Input
              type="text"
              label={Strings.auth.usernameNewConfirm}
              name={FieldNames.newUsername2}
              placeholder={Strings.auth.usernameNewConfirm}
              onChange={this.onNewUsername2Change}
              error={username2Error}
              success={usernameSuccess}
            />
          </div>
        </div>
        <PrimaryButton
          type="submit"
          ariaLabel={Access.Components.PortalSettingsModal.UsernameSubmit}
          onClick={this.onSubmit}
          title={Strings.settings.username.title}
          className={styles.portalModalDefaultBtn}
        />
      </form>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  error: selectors.api.getError(state, ErrorKeys.portalSetUsernameError),
})

export default connect(mapStateToProps)(PortalSetUsernameForm)
