// @flow
import { merge } from 'lodash'
import moment from 'moment'

import { Entity, IDF, Product } from '@/models'
import { formatPrice, capitalizeFirstLetter, camelObjectToSnakeObject } from '@/helpers'
import { Strings, Enums, ModelTypes } from '@/constants'
import BasePolicy from '../base/base-policy'

import { defaultArray, defaultEnum, defaultFloat, defaultString } from '../defaults'

export type PrivatePlacementShortDataType = {|
  id: ?string,
  account_id: ?string,
  beneficiary: ?Array<EntityDataType>,
  cost_basis: ?number,
  insured: ?Array<EntityDataType>,
  insurance_proceeds: ?number,
  policy_date: ?string,
  owner: ?EntityDataType,
  policy_documents: ?Array<PolicyDocumentDataType>,
  premium_amount: ?number,
  premium_mode: ?$Values<typeof Enums.PremiumMode>,
  paid_to: ?string,
  product: ?ProductDataType,
  policy_gross_account_value: ?number,
  termination_date: ?string,
  latest_statement_end_date: ?string,
  idfs: ?Array<IDFDataType>,
  disable_idf: boolean,
  notes_visibility: ?boolean,
|}

const defaultDataValues = {
  id: null,
  beneficiary: null,
  cost_basis: null,
  account_id: null,
  insured: null,
  insurance_proceeds: null,
  policy_date: null,
  owner: null,
  policy_documents: null,
  premium_amount: null,
  premium_mode: null,
  paid_to: null,
  product: null,
  policy_gross_account_value: null,
  termination_date: null,
  latest_statement_end_date: null,
  idfs: null,
  disable_idf: false,
  notes_visibility: false,
}

export default class PrivatePlacementCalendar extends BasePolicy {
  id: string

  accountId: string

  costBasis: number

  beneficiary: Array<Entity>

  insured: Array<Entity>

  insuranceProceeds: number

  policyDate: string

  owner: Entity

  policyDocuments: Array<string>

  paidTo: string

  product: Product

  policyGrossAccountValue: number

  terminationDate: string

  latestStatementEndDate: string

  idfs: ?Array<IDF>

  disableIdf: boolean

  modelName: typeof ModelTypes.PrivatePlacement

  notesVisibility: boolean

  constructor(
    rawData: PrivatePlacementCalendar | PrivatePlacementShortDataType = defaultDataValues,
  ) {
    const data: PrivatePlacementShortDataType =
      rawData instanceof PrivatePlacementCalendar
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData.policy))
        : rawData.policy
    const paidTo: string = rawData.date

    super(data)

    this.modelName = ModelTypes.PrivatePlacement
    this.id = defaultString(data.id)
    this.accountId = defaultString(data.account_id)
    this.costBasis = defaultFloat(data.cost_basis)
    this.beneficiary = defaultArray(data.beneficiary).map(e => new Entity(e))
    this.insured = defaultArray(data.insured).map(i => new Entity(i))
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.policyDate = defaultString(data.policy_date)
    this.paidTo = defaultString(paidTo)
    this.owner = data.owner ? new Entity(data.owner) : new Entity()
    this.policyDocuments = defaultArray(data.policy_documents)
    this.premiumAmount = defaultFloat(data.premium_amount)
    this.premiumMode = defaultEnum(data.premium_mode, Enums.PremiumMode)
    this.product = data.product ? new Product(data.product) : new Product()
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.terminationDate = defaultString(data.termination_date)
    this.latestStatementEndDate = defaultString(data.latest_statement_end_date)
    this.idfs = defaultArray(data.idfs).map(i => new IDF(i))
    this.disableIdf = data.disable_idf
    this.notesVisibility = data.notes_visibility || false
  }

  static modelName = ModelTypes.PrivatePlacement

  get insuranceCompanyName(): string {
    return defaultString(this.product?.insuranceCompany?.name)
  }

  get title(): string {
    const { accountId, insuranceCompanyName } = this
    return `${insuranceCompanyName}\xA0 - \xA0${accountId}`
  }

  get policyType(): string {
    return defaultString(this.product.productType)
  }

  get beneficiaryString(): string {
    return this.beneficiary.map(a => a.formattedName).join(', ')
  }

  get ownerString(): string {
    return this.owner.formattedName
  }

  get insuredString(): string {
    return this.insured.map(i => i.formattedName).join(', ')
  }

  get formattedPremiumMode(): string {
    return capitalizeFirstLetter(this.premiumMode)
  }

  get formattedPaidTo(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get costBasisFormat(): string {
    return formatPrice(this.costBasis)
  }

  get insuranceProceedsFormat(): string {
    return formatPrice(this.insuranceProceeds)
  }

  get policyGrossAccountValueFormat(): string {
    return formatPrice(this.policyGrossAccountValue)
  }

  get formattedTerminationDate(): string {
    if (this.terminationDate) {
      return moment(this.terminationDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedAsOfDate(): ?string {
    return this.latestStatementEndDate
      ? moment(this.latestStatementEndDate).format('M/D/YYYY')
      : null
  }

  startedOnOrBefore(month: string): boolean {
    return moment(this.policyDate).isSameOrBefore(month)
  }

  get detailFilename(): string {
    return Strings.policyDetailFilename(this.insuranceCompanyName, this.accountId)
  }

  get formattedPremiumDueDate(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('MM DD')
    }
    return Strings.dash
  }
}
