// @flow

import api from './api'
import user from './user'
import policy from './policy'
import sessions from './sessions'
import router from './router'

export default {
  api,
  user,
  policy,
  sessions,
  router,
}
