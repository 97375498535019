// @flow

import React from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import type { ValidationError } from 'yup'
import { classnames } from '@/helpers'
import { PasswordInput, PrimaryButton } from '@/components'
import selectors from '@/selectors'

import { ErrorKeys } from '@/redux/api'
import Access from '@/constants/accessibility'
import { Strings, Regex } from '@/constants'
import styles from './styles.scss'

const schema = yup.object().shape({
  oldPassword: yup.string().required(Strings.auth.settingsError.currentPassword),
  newPassword1: yup
    .string()
    .min(8, Strings.auth.passwordValidErr.min)
    .max(50, Strings.auth.passwordValidErr.max)
    .matches(Regex.oneLower, Strings.auth.passwordValidErr.oneLower)
    .matches(Regex.oneUpper, Strings.auth.passwordValidErr.oneUpper)
    .matches(Regex.oneNumber, Strings.auth.passwordValidErr.oneNumber)
    .matches(Regex.oneSpecialChar, Strings.auth.passwordValidErr.oneSpecialChar)
    .required(Strings.auth.passwordRequired),
  newPassword2: yup
    .string()
    .oneOf([yup.ref('newPassword1')], Strings.auth.loginError.doesNotMatch)
    .required(Strings.auth.loginError.doesNotMatch),
})

const FieldNames = {
  oldPassword: 'oldPassword',
  newPassword1: 'newPassword1',
  newPassword2: 'newPassword2',
}

type FormErrors = {
  oldPassword: string,
  newPassword1: string,
  newPassword2: string,
}

type StateToPropsType = {|
  error: ?any,
  user: ?User,
|}

type PortalSetPasswordFormProps = {|
  submitForm: (values: PortalSetPasswordPayload) => ?null,
  success: boolean,
  error: ?string,
  ...StateToPropsType,
|}

type PortalSetPasswordFormState = {|
  oldPassword: FormStringField,
  newPassword1: FormStringField,
  newPassword2: FormStringField,
  errors?: FormErrors,
  passwordErrors: Array<string>,
  passwordErrorMessage: string,
|}

export class PortalSetPasswordForm extends React.Component<
  PortalSetPasswordFormProps,
  PortalSetPasswordFormState,
> {
  constructor(props: PortalSetPasswordFormProps) {
    super(props)

    this.state = {
      oldPassword: {
        dirty: false,
        value: '',
      },
      newPassword1: {
        dirty: false,
        value: '',
      },
      newPassword2: {
        dirty: false,
        value: '',
      },
      passwordErrors: [],
      passwordErrorMessage: '',
    }
  }

  formatErrors = (errors: Array<ValidationError>): FormErrors => {
    const formatErrors: FormErrors = {}
    errors.forEach((err: ValidationError) => {
      formatErrors[err.path] = err.message
    })
    return formatErrors
  }

  showPasswordErrors = () => {
    const { newPassword1 } = this.state

    yup
      .reach(schema, 'newPassword1')
      .validate(newPassword1.value, { abortEarly: false })
      .then(() => {
        this.setState({ passwordErrors: [] })
      })
      .catch(err => {
        this.setState({ passwordErrors: err.errors })
      })
  }

  setAllDirty = () => {
    this.setState(state => ({
      oldPassword: { ...state.oldPassword, dirty: true },
      newPassword1: { ...state.newPassword1, dirty: true },
      newPassword2: { ...state.newPassword2, dirty: true },
    }))
  }

  validateForm = () => {
    const { oldPassword, newPassword1, newPassword2 } = this.state
    const values = {
      oldPassword: oldPassword.value,
      newPassword1: newPassword1.value,
      newPassword2: newPassword2.value,
    }

    try {
      schema.validateSync(values, { abortEarly: false })
      this.setState({ errors: undefined }, this.setPasswordErrorMessage)
      return true
    } catch (err) {
      const errors: FormErrors = this.formatErrors(err.inner)
      this.setState({ errors }, this.setPasswordErrorMessage)
      return false
    }
  }

  onSubmit = (event?: DOMEvent) => {
    if (event) {
      event.preventDefault()
    }

    const { submitForm } = this.props
    const { oldPassword, newPassword1, newPassword2 } = this.state

    const isValid = this.validateForm()

    if (isValid) {
      submitForm({
        old_password: oldPassword.value,
        new_password1: newPassword1.value,
        new_password2: newPassword2.value,
      })
    } else {
      this.setAllDirty()
    }
  }

  onOldPasswordChange = (value: string) => {
    this.setState({ oldPassword: { dirty: true, value } }, this.validateForm)
  }

  onNewPassword1Change = (value: string) => {
    yup
      .reach(schema, 'newPassword1')
      .validate(value, { abortEarly: false })
      .then(() => {
        this.setState({ passwordErrors: [] })
      })
      .catch(err => {
        this.setState({ passwordErrors: err.errors })
      })

    this.setState(
      { newPassword1: { dirty: true, value: value.replace(/\s/g, '') } },
      this.validateForm,
    )
  }

  onNewPassword2Change = (value: string) => {
    this.setState(
      { newPassword2: { dirty: true, value: value.replace(/\s/g, '') } },
      this.validateForm,
    )
  }

  oldPasswordError = () => {
    const { error } = this.props
    const { errors, oldPassword } = this.state
    return oldPassword.dirty || error ? error || (errors && errors.oldPassword) : false
  }

  password1Error = () => {
    const { error } = this.props
    const { errors, newPassword1 } = this.state
    const frontendError = errors && errors.newPassword1
    const backendError = error && error.indexOf('password - ') > -1

    return newPassword1.dirty && (frontendError || backendError) ? '' : false
  }

  setPasswordErrorMessage = () => {
    const { error } = this.props
    const { errors, newPassword1 } = this.state
    const frontendErrorMessage = errors && errors.newPassword1 ? errors.newPassword1 : null
    const backendErrorMessage =
      error && error.indexOf('password - ') > -1 ? error[error.indexOf('password - ')] : ''

    if (newPassword1.dirty) {
      this.setState({ passwordErrorMessage: frontendErrorMessage || backendErrorMessage })
    }
  }

  password2Error = () => {
    const { errors, newPassword2 } = this.state
    return newPassword2.dirty ? errors && errors.newPassword2 : false
  }

  render() {
    const { success, user } = this.props
    const { passwordErrors, newPassword1, newPassword2, passwordErrorMessage, oldPassword } =
      this.state
    const password1Success = !!newPassword1.value && passwordErrors.length === 0
    const password2Success =
      passwordErrors.length === 0 &&
      !!(newPassword1.value && newPassword1.value === newPassword2.value)

    if (success) {
      return (
        <div className={classnames(styles.successWrapper)}>
          <h4>{Strings.settings.password.title}</h4>
          <p>{Strings.settings.password.success}</p>
        </div>
      )
    }
    const disabledSubmit = !password1Success || !password2Success || !oldPassword.value
    return (
      <form className={styles.portalModalForm} onSubmit={this.onSubmit}>
        <div>
          <h4 className={styles.portalModalHeader}>{Strings.settings.password.title}</h4>
          <div className={classnames(styles.inputRow)}>
            <PasswordInput
              type="password"
              label={Strings.auth.passwordCurrent}
              name={FieldNames.oldPassword}
              placeholder={Strings.auth.passwordCurrent}
              onChange={this.onOldPasswordChange}
              error={this.oldPasswordError()}
              meter={false}
            />
          </div>
          <div className={classnames(styles.inputRow)}>
            <PasswordInput
              label={Strings.auth.passwordNew}
              name={FieldNames.newPassword1}
              placeholder={Strings.auth.passwordNew}
              onChange={this.onNewPassword1Change}
              onFocus={this.showPasswordErrors}
              error={this.password1Error()}
              subtext=""
              success={password1Success}
              userInputs={user?.passwordInputs || []}
              value={newPassword1.value || ''}
            />

            <PasswordInput
              type="password"
              label={Strings.auth.passwordNewConfirm}
              name={FieldNames.newPassword2}
              placeholder={Strings.auth.passwordNewConfirm}
              onChange={this.onNewPassword2Change}
              error={this.password2Error()}
              success={password2Success}
              meter={false}
            />
          </div>
        </div>
        {newPassword1.dirty ? <span className={styles.boldRed}>{passwordErrorMessage}</span> : ''}
        <div>
          {' '}
          <div className={styles.passwordCriteria}>
            <b>Password criteria: </b>
          </div>
          <ul>
            <li>at least 8 characters,</li>
            <li>at least one uppercase,</li>
            <li>at least one lowercase,</li>
            <li>at least one number (0-9),</li>
            <li>{'at least one special character (`!@#$%^&*()+=_-{}[]|:;"\'?/<>,.)'}</li>
            <li>Avoid common words and phrases.</li>
          </ul>
        </div>
        <div className={styles.submitAlign}>
          <PrimaryButton
            type="submit"
            ariaLabel={Access.Components.PortalSettingsModal.PasswordSubmit}
            onClick={this.onSubmit}
            title={Strings.settings.password.title}
            className={styles.portalModalDefaultBtn}
            disabled={disabledSubmit}
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  error: selectors.api.getError(state, ErrorKeys.portalSetPasswordError),
})

export default connect(mapStateToProps)(PortalSetPasswordForm)
