// @flow

import { Strings, Enums } from '@/constants'
import { Annuity, Disability, Entity, InsurancePolicy, PrivatePlacement, VULPolicy } from '@/models'
import { formatPrice, dashIfEmptyArray } from '@/helpers'

const termModel = (policy: InsurancePolicy | PrivatePlacement) => [
  { fieldKey: 'insuranceCompany', value: policy.insuranceCompanyName },
  { fieldKey: 'accountNumber', value: policy.accountId },
  {
    fieldKey: 'insured',
    value: dashIfEmptyArray(policy.insured.map((ent: Entity) => ent.formattedName)),
  },
  { fieldKey: 'insuranceProceeds', value: formatPrice(policy.insuranceProceeds, '–') },
  { fieldKey: 'policyDate', value: policy.formattedPolicyDate },
  { fieldKey: 'policyType', value: policy.policyType },
  { fieldKey: 'premium', value: formatPrice(policy.premiumAmount, '-', 2) },
  { fieldKey: 'premiumMode', value: policy.formattedPremiumMode },
  {
    fieldKey: 'termLevelPremiumExpirationDate',
    value: policy.formattedTermLevelPremiumExpirationDate || Strings.dash,
  },
  {
    fieldKey: 'termConversionOptionExpirationDate',
    value: policy.formattedTermConversionOptionExpirationDate || Strings.dash,
  },
  {
    fieldKey: 'premiumDueDate',
    value: policy.formattedPaidTo || Strings.dash,
  },
  {
    fieldKey: 'termConversionLanguageChangeDate',
    value: policy.formattedTermConversionLanguageChangeDate || Strings.dash,
  },
  {
    fieldKey: 'scheduledPremiumDuration',
    value: policy.scheduledPremiumDurationFormat || Strings.dash,
  },
]

const universalModel = (policy: InsurancePolicy | PrivatePlacement | VULPolicy) => [
  { fieldKey: 'insuranceCompany', value: policy.insuranceCompanyName },
  { fieldKey: 'accountNumber', value: policy.accountId },
  {
    fieldKey: 'insured',
    value: dashIfEmptyArray(policy.insured.map((ent: Entity) => ent.formattedName)),
  },
  { fieldKey: 'insuranceProceeds', value: formatPrice(policy.insuranceProceeds, '–') },
  { fieldKey: 'policyDate', value: policy.formattedPolicyDate },
  { fieldKey: 'policyType', value: policy.policyType },
  { fieldKey: 'premium', value: formatPrice(policy.premiumAmount, '-', 2) },
  { fieldKey: 'premiumMode', value: policy.formattedPremiumMode },
  {
    fieldKey: 'premiumDueDate',
    value: policy.formattedPaidTo || Strings.dash,
  },
  {
    fieldKey: 'scheduledPremiumDuration',
    value: policy.scheduledPremiumDurationFormat || Strings.dash,
  },
]

const guaranteedModel = (policy: InsurancePolicy | PrivatePlacement) => [
  { fieldKey: 'insuranceCompany', value: policy.insuranceCompanyName },
  { fieldKey: 'accountNumber', value: policy.accountId },
  {
    fieldKey: 'insured',
    value: dashIfEmptyArray(policy.insured.map((ent: Entity) => ent.formattedName)),
  },
  { fieldKey: 'insuranceProceeds', value: formatPrice(policy.insuranceProceeds, '–') },
  { fieldKey: 'policyDate', value: policy.formattedPolicyDate },
  { fieldKey: 'policyType', value: policy.policyType },
  { fieldKey: 'premium', value: formatPrice(policy.premiumAmount, '-', 2) },
  { fieldKey: 'premiumMode', value: policy.formattedPremiumMode },
  {
    fieldKey: 'termLevelPremiumExpirationDate',
    value: policy.formattedTermLevelPremiumExpirationDate || Strings.dash,
  },
  {
    fieldKey: 'premiumDueDate',
    value: policy.formattedPaidTo || Strings.dash,
  },
  {
    fieldKey: 'scheduledPremiumDuration',
    value: policy.scheduledPremiumDurationFormat || Strings.dash,
  },
]

const privatePlacementLifeModel = (policy: PrivatePlacement) => [
  { fieldKey: 'insuranceCompany', value: policy.insuranceCompanyName },
  { fieldKey: 'accountNumber', value: policy.accountId },
  {
    fieldKey: 'insured',
    value: dashIfEmptyArray(policy.insured.map((ent: Entity) => ent.formattedName)),
  },
  { fieldKey: 'insuranceProceeds', value: formatPrice(policy.insuranceProceeds, '–') },
  { fieldKey: 'policyDate', value: policy.formattedPolicyDate },
  { fieldKey: 'policyType', value: policy.policyType },
  { fieldKey: 'premium', value: formatPrice(policy.premiumAmount, '-', 2) },
  { fieldKey: 'premiumMode', value: policy.formattedPremiumMode },
  {
    fieldKey: 'premiumDueDate',
    value: policy.formattedPaidTo || Strings.dash,
  },
  {
    fieldKey: 'scheduledPremiumDuration',
    value: policy.scheduledPremiumDurationFormat || Strings.dash,
  },
]

const privatePlacementAnnuityModel = (policy: Annuity) => [
  { fieldKey: 'insuranceCompany', value: policy.insuranceCompanyName },
  { fieldKey: 'accountNumber', value: policy.accountId },
  { fieldKey: 'annuitant', value: policy.insuredString },
  { fieldKey: 'policyDate', value: policy.formattedPolicyDate },
  { fieldKey: 'policyType', value: policy.policyType },
]

const disabilityPolicyDetailModel = (policy: Disability) => [
  { fieldKey: 'insuranceCompany', value: policy.insuranceCompanyName },
  { fieldKey: 'accountNumber', value: policy.accountId },
  {
    fieldKey: 'insured',
    value: dashIfEmptyArray(policy.insured.map((ent: Entity) => ent.formattedName)),
  },
  { fieldKey: 'monthlyBenefit', value: formatPrice(policy.monthlyBenefit, '-') },
  { fieldKey: 'policyDate', value: policy.formattedPolicyDate },
  { fieldKey: 'policyType', value: policy.policyType },
  { fieldKey: 'premium', value: formatPrice(policy.premiumAmount, '-', 2) },
  { fieldKey: 'premiumMode', value: policy.formattedPremiumMode },
  {
    fieldKey: 'premiumDueDate',
    value: policy.formattedPaidTo || Strings.dash,
  },
]

export const firstColFieldsMap = {
  [Enums.PolicyType.TERM]: termModel,
  [Enums.PolicyType.FIVE_YEAR_TERM]: termModel,
  [Enums.PolicyType.TEN_YEAR_TERM]: termModel,
  [Enums.PolicyType.FIFTEEN_YEAR_TERM]: termModel,
  [Enums.PolicyType.TWENTY_YEAR_TERM]: termModel,
  [Enums.PolicyType.TWENTY_FIVE_YEAR_TERM]: termModel,
  [Enums.PolicyType.THIRTY_YEAR_TERM]: termModel,
  [Enums.PolicyType.THIRTY_FIVE_YEAR_TERM]: termModel,
  [Enums.PolicyType.WHOLE]: universalModel,
  [Enums.PolicyType.GUARANTEED_UNIVERSAL_LIFE]: guaranteedModel,
  [Enums.PolicyType.GUARANTEED_VARIABLE_UNIVERSAL_LIFE]: guaranteedModel,
  [Enums.PolicyType.UNIVERSAL_LIFE]: universalModel,
  [Enums.PolicyType.INDEX_UNIVERSAL_LIFE]: universalModel,
  [Enums.PolicyType.VARIABLE_UNIVERSAL_LIFE]: universalModel,
  [Enums.PolicyType.PRIVATE_PLACEMENT_LIFE]: privatePlacementLifeModel,
  [Enums.PolicyType.PRIVATE_PLACEMENT_ANNUITY]: privatePlacementAnnuityModel,
  [Enums.PolicyType.LONG_TERM_DISABILITY]: disabilityPolicyDetailModel,
}

export default firstColFieldsMap
