// @flow
import BaseModel from '../base/base-model'
import { Strings } from '@/constants'
import { defaultBoolean, defaultString, makeDate, defaultNumber } from '../defaults'

export type IDFImageDataType = {|
  id?: ?string,
  url: ?string,
  name?: ?string,
  date: ?string,
  registered: ?boolean,
  page_number: ?number,
|}

export default class IDFImage extends BaseModel {
  id: string

  url: string

  name: string

  date: string

  registered: boolean

  pageNumber: number

  constructor(
    data: IDFImageDataType = {
      id: null,
      url: null,
      name: null,
      date: null,
      registered: null,
      page_number: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.url = defaultString(data.url)
    this.name = defaultString(data.name)
    this.date = defaultString(data.date)
    this.registered = defaultBoolean(data.registered)
    this.pageNumber = defaultNumber(data.page_number)
  }

  get jsDate() {
    return makeDate(this.date)
  }

  get label() {
    return Strings.availableIDFs
  }
}
