// @flow
import React, { useState } from 'react'
import { FactsheetOptions } from '../factsheet-options'
import styles from './styles.scss'
import PdfViewer from '../pdf-viewer'

type IDFFactsheetsProps = {|
  allFactsheets: {
    [string]: {
      idfFactsheets: {
        product: string,
      },
    },
  },
|}

export const IDFFactsheetsPdf = (props: IDFFactsheetsProps) => {
  const { allFactsheets } = props
  const modeOptions = Object.values(allFactsheets)
    .map(factSheet => factSheet.idfFactsheets)
    .filter(idfFactsheets => idfFactsheets)
    .map(idfFactsheets => ({
      label: idfFactsheets.product,
      value: idfFactsheets.product,
    }))

  const factsheetsPdfs = Object.values(allFactsheets)
    .filter(factSheet => factSheet.idfFactsheets && factSheet.idfFactsheets.url)
    .reduce(
      (factsheets, factSheet) => ({
        ...factsheets,
        [factSheet.idfFactsheets.product]: factSheet.idfFactsheets.url,
      }),
      {},
    )
  const [mode, setMode] = useState(modeOptions[0]?.value)

  return (
    <div className={styles.sliderContainer2}>
      <FactsheetOptions options={modeOptions} onChange={selectedMode => setMode(selectedMode)} />

      <PdfViewer src={factsheetsPdfs[mode]} />
    </div>
  )
}

export default IDFFactsheetsPdf
