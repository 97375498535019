// @flow
import * as React from 'react'
import classnames from '@/helpers'

import styles from './styles.scss'

type UiTableProps = {|
  children: React.Node,
  headers: Array<{
    label: string,
    align?: 'left' | 'center' | 'right',
  }>,
|}

class UiTable extends React.Component<UiTableProps> {
  render() {
    const { children, headers } = this.props
    return (
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr>
            {headers.map(header => (
              <th
                key={header.label}
                className={classnames(styles.header, styles[`header-${header.align || 'left'}`])}
              >
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    )
  }
}

export default UiTable
