// @flow
import React, { useEffect, useState } from 'react'
import { RadioInput } from '@/components'
import styles from './styles.scss'

type FactsheetOptionsProps = {|
  options: Array<{ label: string, value: string }>,
  onChange: (value: string) => void,
|}

export const FactsheetOptions = (props: FactsheetOptionsProps) => {
  const { options, onChange } = props

  const [activeOption, setActiveOption] = useState(options[0].value)

  useEffect(() => {
    onChange(activeOption)
  }, [activeOption])

  const renderOption = (option: { label: string, value: string }, index: number) => {
    const { label, value } = option
    const ariaLabel = `Select ${label}`
    const id = `${index}-${label}`

    return (
      <RadioInput
        key={id}
        name="factsheet-dropdown"
        ariaLabel={ariaLabel}
        label={label}
        value={value}
        id={id}
        checked={value === activeOption}
        onChange={setActiveOption}
      />
    )
  }

  return <div className={styles.factsheetOptions}>{options.map(renderOption)}</div>
}

export default FactsheetOptions
