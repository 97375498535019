import { css } from '@emotion/core'
import styles from './styles.scss'

const { error, success } = styles

const Styles = {
  input: props => css`
    ${!props.disabled && props.error
      ? `border: 1px solid ${error};
        &:focus, &:hover {
        border: 1px solid ${error};
      }`
      : ''}

    ${!props.disabled && props.success
      ? `border: 1px solid ${success};
        &:focus, &:hover {
        border: 1px solid ${success};
      }`
      : ''}
  `,
}

export default Styles
