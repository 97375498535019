// @flow

export function startRequest<StateType>(
  state: StateType,
  payload: ?{ [string]: ?null },
): StateType {
  return {
    ...state,
    pending: true,
    ...payload,
  }
}

export function endRequest<StateType>(state: StateType, payload: ?{ [string]: ?Error }): StateType {
  return {
    ...state,
    pending: false,
    ...payload,
  }
}

export const resetStoreKeyClosure =
  (initialState: *) =>
  (state: *, payload: string): * => ({
    ...state,
    [payload]: initialState[payload],
  })

export const noOp = (state: *) => ({
  ...state,
})

export function defaultReducer<StateType, PayloadType>(
  state: StateType,
  payload: PayloadType,
): StateType {
  return {
    ...state,
    ...payload,
  }
}

const apiReducers = {
  startRequest,
  endRequest,
}

export default apiReducers
