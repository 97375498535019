// @flow
import { IDFStatement } from '@/models'
import { Enums } from '@/constants'
import XIRR from './xirr'

const lessThan12Months = (value, days) => (value + 1) ** (days / 365) - 1

// First statement
const negate = value => (value === 0 ? 0 : value * -1)

const { TransactionType } = Enums
const FEES = [
  TransactionType.STRUCTURING_FEE,
  TransactionType.PREMIUM_TAX,
  TransactionType.M_AND_E_FEE,
  TransactionType.MORTALITY_CHARGE,
  TransactionType.LOAN_INTEREST,
  TransactionType.TRANSFER,
  TransactionType.PREMIUM_BASED_CHARGES,
  TransactionType.POLICY_EXPENSE_CHARGES,
]

const reduceTransactions = (idf, gross) =>
  idf.transactions.reduce((acc, idft) => {
    // Include all transactions for gross
    // Exclude fees for net
    if (gross || FEES.includes(idft.transactionType) === false) {
      const when = idft.transactionDate
      acc.push({ amount: negate(idft.transactionAmount), when })
    }
    return acc
  }, [])

const createTransactionData = (currS, gross = false) =>
  currS.idfStatements.reduce((accT, currT) => {
    const transactions = reduceTransactions(currT, gross)
    return accT.concat(transactions)
  }, [])

const addStartAndEnd = (statements, data) => {
  // Sorted by last to first
  const satById = statements.reduce((acc, curr) => {
    if (acc[curr.idfId]) {
      acc[curr.idfId].push(curr)
    } else {
      acc[curr.idfId] = [curr]
    }
    return acc
  }, {})
  // $FlowFixMe
  const { start: startSum, end: endSum } = Object.values(satById).reduce(
    (acc, curr: Array<IDFStatement>) => {
      curr.sort((a, b) => a.statementStartDate - b.statementStartDate)
      const firstStatement = curr[0]
      const lastStatement = curr[curr.length - 1]
      let start = null
      let end = null
      // start
      if (firstStatement) {
        const when = new Date(firstStatement.statementStartDate.getTime())
        when.setDate(new Date(firstStatement.statementStartDate.getDate() - 1))
        const amount = negate(firstStatement.beginningBalance)
        // Exclude first statement if 0
        if (amount !== 0) {
          start = {
            amount,
            when,
          }
        }
      }
      // end
      if (lastStatement) {
        end = { amount: lastStatement.accountValue, when: lastStatement.statementEndDate }
      }
      if (start) {
        if (acc.start) {
          acc.start.amount += start.amount
        } else {
          acc.start = start
        }
      }
      if (end) {
        if (acc.end) {
          acc.end.amount += end.amount
        } else {
          acc.end = end
        }
      }
      return acc
    },
    { start: null, end: null },
  )

  let completeData = data
  if (startSum && endSum) {
    completeData = [startSum, ...completeData, endSum]
  } else if (endSum) {
    completeData = [...completeData, endSum]
  }
  return completeData
}

export const calculateXIRR = (data: Array<{ when: Date, amount: number }>) => {
  let value = 0
  if (data.length > 0) {
    const period = Math.floor(
      Math.abs(data[data.length - 1].when.getTime() - data[0].when.getTime()) /
        (1000 * 60 * 60 * 24),
    )
    try {
      value = XIRR(data, { tolerance: 0.0000001, maxIterations: 100000 })
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      try {
        value = XIRR(data, { tolerance: 0.0000001, maxIterations: 100000 }, -0.1)
      } catch (e2) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.error(e2, '-0.1 guess failed')
        }
        value = 0
      }
    }
    if (period <= 365) {
      value = lessThan12Months(value, period)
    }
  }
  return parseFloat((value * 100).toFixed(2))
}

const xirr = (statements: Array<PPPolicyType>, gross: boolean = false) => {
  const data = statements.reduce((acc, curr) => [...acc, ...createTransactionData(curr, gross)], [])
  data.sort((a, b) => a.when - b.when)
  const completeData = addStartAndEnd(statements, data)
  const value = calculateXIRR(completeData)
  return value
}

export const xirrIDF = (statements: Array<IDFStatement>) => {
  const data = statements.reduce((acc, curr) => {
    const idfTransactionData = reduceTransactions(curr, true)
    const addedData = acc.concat(idfTransactionData)
    return addedData
  }, [])
  data.sort((a, b) => a.when - b.when)
  const completeData = addStartAndEnd(statements, data)
  return calculateXIRR(completeData)
}

export const xirrTotal = (statements: Array<PPPolicyType>) => {
  statements.sort((a, b) => a.statementStartDate - b.statementStartDate)
  const beginningAmount = statements.reduce((acc, curr) => {
    if (statements[0].statementStart === curr.statementStart) {
      return acc + curr.beginningBalance
    }
    return acc
  }, 0)
  const endingAmount = statements.reduce((acc, curr) => {
    if (statements[statements.length - 1].statementEnd === curr.statementEnd) {
      return acc + curr.accountValue
    }
    return acc
  }, 0)

  const when = new Date(statements[0].statementStartDate.getTime())
  when.setDate(new Date(statements[0].statementStartDate.getDate() - 1))
  const beginning =
    statements.length > 0
      ? { when, amount: negate(beginningAmount) }
      : { when: new Date(), amount: 0 }
  const data = statements.reduce((acc, curr) => [...acc, ...createTransactionData(curr, false)], [])
  const ending =
    statements.length > 0
      ? { when: statements[statements.length - 1].statementEndDate, amount: endingAmount }
      : { when: new Date(), amount: 0 }
  const values: Array<{ when: Date, amount: number }> = [...(Object.values(data): any), ending]
  if (beginning.amount !== 0) {
    values.push(beginning)
  }
  values.sort((a, b) => a.when - b.when)
  return calculateXIRR(values)
}

export const xirrIDFTotal = (statements: Array<IDFStatement>) => {
  statements.sort((a, b) => a.statementStartDate - b.statementStartDate)
  const beginningAmount = statements.reduce((acc, curr) => {
    if (statements[0].statementStart === curr.statementStart) {
      return acc + curr.beginningBalance
    }
    return acc
  }, 0)
  const endingAmount = statements.reduce((acc, curr) => {
    if (statements[statements.length - 1].statementEnd === curr.statementEnd) {
      return acc + curr.accountValue
    }
    return acc
  }, 0)

  const when = new Date(statements[0].statementStartDate.getTime())
  when.setDate(new Date(statements[0].statementStartDate.getDate() - 1))

  const beginning = { when, amount: negate(beginningAmount) }
  const data: { [string]: { when: Date, amount: number } } = statements.reduce((acc, curr) => {
    const idfTransactionData = reduceTransactions(curr, true)
    idfTransactionData.forEach(transac => {
      const date = transac.when.toString()
      if (acc[date] === undefined) {
        acc[date] = transac
      } else {
        acc[date].amount += transac.amount
      }
    })
    return acc
  }, {})
  const ending = { when: statements[statements.length - 1].statementEndDate, amount: endingAmount }
  const values: Array<{ when: Date, amount: number }> = [...(Object.values(data): any), ending]
  if (beginning.amount !== 0) {
    values.push(beginning)
  }
  values.sort((a, b) => a.when - b.when)
  return calculateXIRR(values)
}

export default xirr
