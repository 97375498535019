// @flow

import * as React from 'react'
import jsStyles from './styles'
import styles from './styles.scss'
import { classnames } from '@/helpers'

type CheckboxProps = {|
  ariaLabel: string,
  checked?: ?boolean,
  name: string,
  label?: string | React.Node,
  onChange?: (data: boolean) => void,
  onBlur?: () => void,
  // used in js styles
  error?: boolean, // eslint-disable-line react/no-unused-prop-types
  disabled?: boolean,
  // Left label is used in js styles
  leftLabel?: boolean, // eslint-disable-line react/no-unused-prop-types
  id?: string,
  inlineBlock?: boolean,
  filled?: boolean,
|}

type CheckboxState = {|
  checked: boolean,
  controlled: boolean,
|}

class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  static defaultProps = {
    checked: null,
    label: '',
    error: false,
    disabled: false,
    leftLabel: false,
    id: '',
    inlineBlock: false,
    filled: false,
    onBlur: () => {},
    onChange: () => {},
  }

  constructor(props: CheckboxProps) {
    super(props)

    const { checked: propsChecked } = props
    let controlled = false
    let checked = false
    if (propsChecked === false || propsChecked === true) {
      checked = propsChecked
      controlled = true
    }

    this.state = {
      checked,
      controlled,
    }
  }

  clickCheck = () => {
    const { onChange, disabled } = this.props
    if (!disabled) {
      this.setState(
        prevState => ({
          checked: !prevState.checked,
        }),
        () => {
          const { checked } = this.state
          if (onChange) {
            onChange(checked)
          }
        },
      )
    }
  }

  getId = (): string | null => {
    const { name, label, id } = this.props
    let checkboxId = ''
    if (id) {
      checkboxId = id
    } else if (typeof label === 'string') {
      checkboxId = `${label}-${name}`
    } else if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error('Checkbox component needs an id prop if the label is JSX')
    }
    return checkboxId
  }

  render() {
    const { checked: stateChecked, controlled } = this.state
    const {
      ariaLabel,
      name,
      label,
      disabled,
      checked: propsChecked,
      onBlur,
      inlineBlock,
      filled,
    } = this.props
    const id = this.getId()

    let checked = stateChecked
    if (controlled) {
      checked = propsChecked
    }

    return (
      <label
        className={classnames(
          styles.checkbox,
          inlineBlock && styles.checkboxInline,
          filled && styles.checkboxFilled,
        )}
        htmlFor={id}
        css={jsStyles.wrapper(this.props)}
      >
        <span
          className={classnames(
            styles.checkboxLabel,
            filled && checked && styles.checkboxLabelActive,
          )}
        >
          {label}
        </span>
        <input
          type="checkbox"
          onChange={this.clickCheck}
          name={name}
          aria-label={ariaLabel}
          id={id}
          disabled={disabled}
          checked={checked}
          onBlur={onBlur}
        />
        <span className={styles.checkmark} css={jsStyles.checkmark(this.props)} />
      </label>
    )
  }
}

export default Checkbox
