// @flow
import React from 'react'
import { Strings } from '@/constants'
import { formatPercentageValue } from '@/helpers'
import type { ModelKeys } from '@/models'

type FooterCellAverageProps = {|
  row: {|
    data: Array<{ [ModelKeys]: string | number, _original: Object }>,
  |},
  subtotalColumn: ModelKeys,
|}

const FooterCellPercentage = (props: FooterCellAverageProps) => {
  const { row, subtotalColumn } = props

  const penultimateRow = row.data[row.data.length - 2]
  const cutoff = !!(penultimateRow && penultimateRow[subtotalColumn] !== Strings.table.subtotal)

  let percent = 0
  if (row.data[0]) {
    percent = row.data[0]._original[subtotalColumn]
  }

  let className = 'tableCell footer'
  className += cutoff ? ' cutoff' : ''

  return (
    <div className={className}>
      <span className="footerPercentage">{formatPercentageValue(percent)}</span>
    </div>
  )
}

export default FooterCellPercentage
