/* eslint-disable max-lines */
// @flow
import { merge } from 'lodash'
import moment from 'moment'
import { Strings, Enums, ModelTypes } from '@/constants'
import { formatPrice, camelObjectToSnakeObject } from '@/helpers'

import { Entity, PremiumPayment, Product } from '@/models'
import BasePolicy from '../base/base-policy'
import type { PolicyDocumentDataType } from '../policies-documents/policy-document'
import type { PremiumPaymentDataType } from '../premium-payment'
import type { ProductDataType } from '../product'
import type { EntityDataType } from '../base/entity'

import { defaultArray, defaultFloat, defaultNumber, defaultString, defaultEnum } from '../defaults'

export type InsurancePolicyDataType = {|
  account_id: ?string,
  policy_account_value: ?number,
  premium_amount: ?number,
  beneficiary: ?Array<EntityDataType>,
  cc_list: ?Array<EntityDataType>,
  cost_basis: ?number,
  death_benefit_option: ?string,
  dividend_option: ?$Values<typeof Enums.DividendOption>,
  id: ?string,
  insurance_proceeds: ?number,
  insured: ?Array<EntityDataType>,
  maturity_date: ?string,
  policy_date: ?string,
  issue_state_or_country: ?string,
  inforce_guaranteed_duration_age: ?number,
  permissioned_individuals: ?Array<EntityDataType>,
  policy_documents: ?Array<PolicyDocumentDataType>,
  policy_gross_account_value: ?number,
  policy_loan_balance: ?number,
  preferred_billing_method: ?string,
  paid_to: ?string,
  premium_mode: ?$Values<typeof Enums.PremiumMode>,
  premium_payments: ?Array<PremiumPaymentDataType>,
  premium_payor: ?EntityDataType,
  premium_pricing_guarantee_duration: ?number,
  product: ?ProductDataType,
  riders: ?string,
  scheduled_death_benefit_option_change: ?string,
  scheduled_face_amount_reduction_1: ?string,
  scheduled_face_amount_reduction_2: ?string,
  scheduled_face_amount_reduction_3: ?string,
  scheduled_first_policy_distribution: ?string,
  scheduled_premium_duration: ?string,
  surrender_charge: ?number,
  term_conversion_language_change_date: ?string,
  term_conversion_option_expiration_date: ?string,
  term_level_premium_expiration_date: ?string,
  termination_date: ?string,
  owner: ?EntityDataType,
  latest_statement_end_date: ?string,
  premium_reminder_billing_email: ?string,
  premium_payor_email: ?string,
  primary_contact_person_email: ?string,
  notes_visibility: ?boolean,
|}

const defaultDataValues = {
  account_id: null,
  premium_amount: null,
  beneficiary: null,
  cc_list: null,
  cost_basis: null,
  death_benefit_option: null,
  dividend_option: null,
  id: null,
  insurance_proceeds: null,
  insured: null,
  maturity_date: null,
  policy_date: null,
  inforce_guaranteed_duration_age: null,
  issue_state_or_country: null,
  permissioned_individuals: null,
  policy_account_value: null,
  policy_documents: null,
  policy_gross_account_value: null,
  policy_loan_balance: null,
  preferred_billing_method: null,
  paid_to: null,
  premium_mode: null,
  premium_payments: null,
  premium_payor: null,
  premium_pricing_guarantee_duration: null,
  product: null,
  riders: null,
  scheduled_death_benefit_option_change: null,
  scheduled_face_amount_reduction_1: null,
  scheduled_face_amount_reduction_2: null,
  scheduled_face_amount_reduction_3: null,
  scheduled_first_policy_distribution: null,
  scheduled_premium_duration: null,
  surrender_charge: null,
  term_conversion_language_change_date: null,
  term_conversion_option_expiration_date: null,
  term_level_premium_expiration_date: null,
  termination_date: null,
  latest_statement_end_date: null,
  owner: null,
  premium_reminder_billing_email: null,
  premium_payor_email: null,
  primary_contact_person_email: null,
  notes_visibility: false,
}

export default class InsurancePolicy extends BasePolicy {
  accountId: string

  beneficiary: Array<Entity>

  ccList: Array<Entity>

  costBasis: number

  deathBenefitOption: string

  dividendOption: $Values<typeof Enums.DividendOption>

  id: string

  insuranceProceeds: number

  insured: Array<Entity>

  maturityDate: string

  policyDate: string

  inforceGuaranteedDurationAge: number

  issueStateOrCountry: string

  permissionedIndividuals: Array<{ name: string, excel_disable: boolean, pdf_disable: boolean }>

  policyAccountValue: number

  policyDocuments: Array<string>

  policyGrossAccountValue: number

  policyLoanBalance: number

  preferredBillingMethod: $Values<typeof Enums.BillingMethod>

  paidTo: string

  premiumPayments: Array<PremiumPayment>

  premiumPricingGuaranteeDuration: number

  product: Product

  riders: string

  scheduledDeathBenefitOptionChange: string

  scheduledFaceAmountReduction1: string

  scheduledFaceAmountReduction2: string

  scheduledFaceAmountReduction3: string

  scheduledFirstPolicyDistribution: string

  scheduledPremiumDuration: number

  surrenderCharge: number

  termConversionLanguageChangeDate: string

  termConversionOptionExpirationDate: string

  termLevelPremiumExpirationDate: string

  terminationDate: string

  owner: Entity

  latestStatementEndDate: string

  premiumReminderBillingEmail: string

  premiumPayorEmail: string

  primaryContactPersonEmail: string

  modelName: typeof ModelTypes.InsurancePolicy

  notesVisibility: boolean

  constructor(rawData: InsurancePolicy | InsurancePolicyDataType = defaultDataValues) {
    const data: InsurancePolicyDataType =
      rawData instanceof InsurancePolicy
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    super(data)
    this.id = defaultString(data.id)
    this.modelName = ModelTypes.InsurancePolicy
    this.accountId = defaultString(data.account_id)
    this.premiumAmount = defaultFloat(data.premium_amount)
    this.beneficiary = defaultArray(data.beneficiary).map(i => new Entity(i))
    this.ccList = defaultArray(data.cc_list).map(e => new Entity(e))
    this.costBasis = defaultFloat(data.cost_basis)
    this.deathBenefitOption = defaultString(data.death_benefit_option)
    this.dividendOption = defaultEnum(data.dividend_option, Enums.DividendOption)
    this.inforceGuaranteedDurationAge = defaultNumber(data.inforce_guaranteed_duration_age)
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.insured = defaultArray(data.insured).map(i => new Entity(i))
    this.maturityDate = defaultString(data.maturity_date)
    this.policyDate = defaultString(data.policy_date)
    this.issueStateOrCountry = defaultString(data.issue_state_or_country)
    this.policyAccountValue = defaultFloat(data.policy_account_value)
    this.policyDocuments = defaultArray(data.policy_documents)
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.policyLoanBalance = defaultFloat(data.policy_loan_balance)
    this.preferredBillingMethod = defaultEnum(data.preferred_billing_method, Enums.BillingMethod)
    this.paidTo = defaultString(data.paid_to)
    this.premiumMode = defaultEnum(data.premium_mode, Enums.PremiumMode)
    this.premiumPricingGuaranteeDuration = defaultNumber(data.premium_pricing_guarantee_duration)
    this.riders = defaultString(data.riders)
    this.scheduledDeathBenefitOptionChange = defaultString(
      data.scheduled_death_benefit_option_change,
    )
    this.scheduledFaceAmountReduction1 = defaultString(data.scheduled_face_amount_reduction_1)
    this.scheduledFaceAmountReduction2 = defaultString(data.scheduled_face_amount_reduction_2)
    this.scheduledFaceAmountReduction3 = defaultString(data.scheduled_face_amount_reduction_3)
    this.scheduledFirstPolicyDistribution = defaultString(data.scheduled_first_policy_distribution)
    this.scheduledPremiumDuration = defaultNumber(data.scheduled_premium_duration)
    this.surrenderCharge = defaultFloat(data.surrender_charge)
    this.termConversionLanguageChangeDate = defaultString(data.term_conversion_language_change_date)
    this.termConversionOptionExpirationDate = defaultString(
      data.term_conversion_option_expiration_date,
    )
    this.termLevelPremiumExpirationDate = defaultString(data.term_level_premium_expiration_date)
    this.terminationDate = defaultString(data.termination_date)
    this.owner = data.owner ? new Entity(data.owner) : new Entity()
    this.premiumPayments = defaultArray(data.premium_payments).map(d => new PremiumPayment(d))
    this.permissionedIndividuals = defaultArray(data.permissioned_individuals)
    this.product = data.product ? new Product(data.product) : new Product()
    this.latestStatementEndDate = defaultString(data.latest_statement_end_date)
    this.premiumReminderBillingEmail = defaultString(data.premium_reminder_billing_email)
    this.premiumPayorEmail = defaultString(data.premium_payor_email)
    this.primaryContactPersonEmail = defaultString(data.primary_contact_person_email)
    this.notesVisibility = data.notes_visibility || false
  }

  static modelName = ModelTypes.InsurancePolicy

  getFaceValues(ccTypes: Array<string>) {
    if (!ccTypes || ccTypes.length === 0) {
      return ''
    }
    const ccTypeToValue = {
      statements: 4,
      premium: 5,
      policy_changes: 6,
    }

    const faceValues = ccTypes.map(ccType => ccTypeToValue[ccType] || '')

    return faceValues.sort().join(',')
  }

  get insuranceCompanyName(): string {
    return defaultString(this.product?.insuranceCompany?.name)
  }

  get formattedDividendOption(): string {
    if (this.dividendOption === Enums.DividendOption.NOT_APPLICABLE) {
      return '-'
    }
    return this.dividendOption
  }

  get title(): string {
    const { accountId, insuranceCompanyName } = this
    return `${insuranceCompanyName}\xA0 - \xA0${accountId}`
  }

  get beneficiaryString(): string {
    return this.beneficiary.map(i => i.formattedName).join(', ')
  }

  get insuredString(): string {
    return this.insured.map(i => i.formattedName).join(', ')
  }

  get policyType(): string {
    return defaultString(this.product?.productType)
  }

  get ownerString(): string {
    return this.owner.formattedName
  }

  get premiumPricingGuaranteeDurationFormat(): string {
    if (this.premiumPricingGuaranteeDuration > 0) {
      const years = this.premiumPricingGuaranteeDuration > 1 ? Strings.years : Strings.year
      return `${this.premiumPricingGuaranteeDuration} ${years} ${Strings.fromInception}`
    }
    return '–'
  }

  get scheduledPremiumDurationFormat(): string {
    if (this.scheduledPremiumDuration > 0) {
      const years = this.scheduledPremiumDuration > 1 ? Strings.years : Strings.year
      return `${this.scheduledPremiumDuration} ${years} ${Strings.fromInception}`
    }
    return '–'
  }

  get formattedDeathBenefitOption(): string {
    if (
      this.deathBenefitOption === Enums.DeathBenefitOptions.one ||
      this.deathBenefitOption === Enums.DeathBenefitOptions.a
    ) {
      return Strings.level
    }
    if (
      this.deathBenefitOption === Enums.DeathBenefitOptions.two ||
      this.deathBenefitOption === Enums.DeathBenefitOptions.b
    ) {
      return Strings.increasing
    }
    if (
      this.deathBenefitOption === Enums.DeathBenefitOptions.CORRIDOR_7772 ||
      this.deathBenefitOption === Enums.DeathBenefitOptions.five
    ) {
      return Strings.corridor
    }
    return Strings.dash
  }

  get costBasisFormat(): string {
    return formatPrice(this.costBasis)
  }

  get inforcePolicyCommunicationCCList(): Array<string> {
    if (this.notesVisibility) {
      return this.ccList.reduce(
        (acc, cur) =>
          cur && cur.email ? [...acc, `${cur.email}(${this.getFaceValues(cur.cc_type)})`] : acc,
        [],
      )
    }
    return this.ccList.reduce((acc, cur) => (cur && cur.email ? [...acc, cur.email] : acc), [])
  }

  get formattedTermConversionOptionExpirationDate(): string {
    if (this.termConversionOptionExpirationDate) {
      return moment(this.termConversionOptionExpirationDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedTermLevelPremiumExpirationDate(): string {
    if (this.termLevelPremiumExpirationDate) {
      return moment(this.termLevelPremiumExpirationDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedPaidTo(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedTermConversionLanguageChangeDate(): string {
    if (this.termConversionLanguageChangeDate) {
      return moment(this.termConversionLanguageChangeDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledDeathBenefitOptionChange(): string {
    if (this.scheduledDeathBenefitOptionChange) {
      return moment(this.scheduledDeathBenefitOptionChange).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFaceAmountReduction1(): string {
    if (this.scheduledFaceAmountReduction1) {
      return moment(this.scheduledFaceAmountReduction1).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFaceAmountReduction2(): string {
    if (this.scheduledFaceAmountReduction2) {
      return moment(this.scheduledFaceAmountReduction2).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFaceAmountReduction3(): string {
    if (this.scheduledFaceAmountReduction3) {
      return moment(this.scheduledFaceAmountReduction3).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFirstPolicyDistribution(): string {
    if (this.scheduledFirstPolicyDistribution) {
      return moment(this.scheduledFirstPolicyDistribution).format('M/D/YYYY')
    }
    return ''
  }

  get formattedMaturityDate(): string {
    if (this.maturityDate) {
      return moment(this.maturityDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get accountValue(): number {
    return this.policyAccountValue
  }

  get formattedPremiumMode(): string {
    const { ANNUAL, SEMI_ANNUAL, QUARTERLY, MONTHLY, SINGLE_PAY, PERIODIC } = Enums.PremiumMode
    let mode = Strings.annual
    switch (this.premiumMode) {
      case ANNUAL:
        mode = Strings.annual
        break
      case SEMI_ANNUAL:
        mode = Strings.semiAnnual
        break
      case QUARTERLY:
        mode = Strings.quarterly
        break
      case MONTHLY:
        mode = Strings.monthy
        break
      case SINGLE_PAY:
        mode = Strings.singlePay
        break
      case PERIODIC:
        mode = Strings.periodic
        break
      default:
        mode = Strings.annual
    }
    return mode
  }

  get formattedAsOfDate(): ?string {
    return this.latestStatementEndDate
      ? moment(this.latestStatementEndDate).format('M/D/YYYY')
      : null
  }

  get detailFilename(): string {
    return Strings.policyDetailFilename(this.insuranceCompanyName, this.accountId)
  }

  get formattedPremiumDueDate(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('MM DD')
    }
    return Strings.dash
  }
}
