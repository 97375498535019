// @flow
import { dispatchReducer, runReducers } from '@/helpers/dispatch-and-reduce'
import {
  Annuity,
  Disability,
  FootnoteGroup,
  InsurancePolicy,
  PrivatePlacement,
  IDFImage,
  IDFStatement,
  PPAStatement,
  PPLIStatement,
  VULStatement,
  VULPolicy,
  XIRRRate,
  XIRRRateIDF,
  IULMonthlyStatement,
  IULAnnualStatement,
  IndexAccountRates,
  GeneralDocuments,
  InsurancePolicyShort,
  InsurancePolicyCalendar,
  PrivatePlacementCalendar,
  VULPolicyCalendar,
  PrivatePlacementShort,
  VULPolicyShort,
} from '@/models'
import {
  normalizePrivatePlacements,
  normalizePrivatePlacementsShort,
  normalizeAnnuities,
  normalizeFootnotes,
  normalizeInsurancePolicies,
  normalizeInsurancePoliciesShort,
  normalizeIdfStatements,
  normalizeVULPolicies,
  normalizeVULPoliciesShort,
  normalizeAnnuitiesShort,
  normalizeDisabilitiesShort,
} from './schemas'
import { noOp, defaultReducer } from './shared-reducers'
import AnnuityShort from '../models/policies/annuity-short'
import ConsolidatedStatement from '../models/policies-statements/consolidated-statement'

// Policy Actions
export const ACTIONS = {
  START_REQUEST: 'POLICY/START_REQUEST',
  END_REQUEST: 'POLICY/END_REQUEST',
  GET_ANNUITIES_SUCCESS: 'POLICY/GET_ANNUITIES_SUCCESS',
  GET_ANNUITIES_SHORT_SUCCESS: 'POLICY/GET_ANNUITIES_SHORT_SUCCESS',
  GET_DISABILITIES_SHORT_SUCCESS: 'POLICY/GET_DISABILITIES_SHORT_SUCCESS',
  GET_POLICIES_SUCCESS: 'POLICY/GET_POLICIES_SUCCESS',
  GET_POLICIES_SHORT_SUCCESS: 'POLICY/GET_POLICIES_SHORT_SUCCESS',
  GET_INSURANCE_POLICIES_CALENDAR: 'POLICY/GET_INSURANCE_POLICIES_CALENDAR',
  GET_PRIVATE_PLACEMENTS_CALENDAR: 'POLICY/GET_PRIVATE_PLACEMENTS_CALENDAR',
  GET_VUL_STATEMENTS_CALENDAR: 'POLICY/GET_VUL_STATEMENTS_CALENDAR',
  GET_PRIVATE_PLACEMENTS_SUCCESS: 'POLICY/GET_PRIVATE_PLACEMENTS_SUCCESS',
  GET_PRIVATE_PLACEMENTS_SHORT_SUCCESS: 'POLICY/GET_PRIVATE_PLACEMENTS_SHORT_SUCCESS',
  SET_SUMMARY_DATA: 'SET_SUMMARY_DATA',
  GET_IDF_STATEMENTS_SUCCESS: 'POLICY/GET_IDF_STATEMENTS_SUCCESS',
  GET_PPA_STATEMENTS_SUCCESS: 'POLICY/GET_PPA_STATEMENTS_SUCCESS',
  GET_PPA_STATEMENTS_SHORT_SUCCESS: 'POLICY/GET_PPA_STATEMENTS_SHORT_SUCCESS',
  GET_PPLI_STATEMENTS_SUCCESS: 'POLICY/GET_PPLI_STATEMENTS_SUCCESS',
  GET_PPLI_STATEMENTS_SHORT_SUCCESS: 'POLICY/GET_PPLI_STATEMENTS_SHORT_SUCCESS',
  GET_VUL_STATEMENTS_SUCCESS: 'POLICY/GET_VUL_STATEMENTS_SUCCESS',
  GET_VUL_STATEMENTS_SHORT_SUCCESS: 'POLICY/GET_VUL_STATEMENTS_SHORT_SUCCESS',
  GET_VUL_POLICIES_SUCCESS: 'POLICY/GET_VUL_POLICIES_SUCCESS',
  GET_VUL_POLICIES_SHORT_SUCCESS: 'POLICY/GET_VUL_POLICIES_SHORT_SUCCESS',
  SET_ACTIVE_USER: 'SET_ACTIVE_USER',
  GET_IDF_PDF_AVAILABILITY_SUCCESS: 'GET_IDF_PDF_AVAILABILITY_SUCCESS',
  GET_FOOTNOTES_SUCCESS: 'GET_FOOTNOTES_SUCCESS',
  CHECK_PDF_RESULT: 'CHECK_PDF_RESULT',
  GENERATE_PDF_SUCCESS: 'GENERATE_PDF_SUCCESS',
  GENERATE_DETAILED_PDF_SUCCESS: 'GENERATE_DETAILED_PDF_SUCCESS',
  GENERATE_CONSOLIDATED_PDF_SUCCESS: 'GENERATE_CONSOLIDATED_PDF_SUCCESS',
  CHECK_PDF_RESULT_SUCCESS: 'CHECK_PDF_RESULT_SUCCESS',
  CHECK_PDF_RESULT_ERROR: 'CHECK_PDF_RESULT_ERROR',
  CLEAR_XIRR_RATES: 'CLEAR_XIRR_RATES',
  GET_XIRR_RATES_SUCCESS: 'GET_XIRR_RATES_SUCCESS',
  GET_XIRR_RATES_ERROR: 'GET_XIRR_RATES_ERROR',
  GET_XIRR_RATES_IDF_SUCCESS: 'GET_XIRR_RATES_IDF_SUCCESS',
  GET_XIRR_RATES_IDF_ERROR: 'GET_XIRR_RATES_IDF_ERROR',
  GET_IUL_MONTHLY_STATEMENTS_SUCCESS: 'GET_IUL_MONTHLY_STATEMENTS_SUCCESS',
  GET_IUL_ANNUAL_STATEMENTS_SUCCESS: 'GET_IUL_ANNUAL_STATEMENTS_SUCCESS',
  GET_INDEX_ACCOUNT_RATES_SUCCESS: 'GET_INDEX_ACCOUNT_RATES_SUCCESS',
  GET_GENERAL_DOCUMENTS_SUCCESS: 'GET_GENERAL_DOCUMENTS_SUCCESS',
  GET_CONSOLIDATED_STATEMENTS_SUCCESS: 'GET_CONSOLIDATED_STATEMENTS_SUCCESS',
}

export const INITIAL_STATE: PolicyStoreState = {
  annuities: {},
  annuitiesShort: {},
  disabilityPoliciesShort: {},
  footnoteGroups: {},
  insurancePolicies: {},
  insurancePoliciesShort: {},
  insurancePoliciesCalendar: [],
  privatePlacementsCalendar: [],
  vulStatementsCalendar: [],
  ppaStatements: [],
  ppaStatementsShort: [],
  ppliStatements: [],
  ppliStatementsShort: [],
  vulStatements: [],
  vulStatementsShort: [],
  vulPolicies: {},
  vulPoliciesShort: {},
  privatePlacements: {},
  privatePlacementsShort: {},
  summary: null,
  idfs: {},
  idfStatements: {},
  annuityDocs: {},
  disabilityDocs: {},
  privatePlacementDocs: {},
  insurancePolicyDocs: {},
  vulPolicyDocs: {},
  idfPDFAvailabilityImages: null,
  xirrRates: {},
  xirrRatesIdf: {},
  iulMonthlyStatements: [],
  iulAnnualStatements: [],
  indexAccountRates: [],
  generalDocuments: [],
  consolidatedStatements: [],
  insurancePoliciesCalendarLoading: true,
  privatePlacementsCalendarLoading: true,
  vulStatementsCalendarLoading: true,
}

const setInsurancePolicyData = (state: PolicyStoreState, payload: Array<InsurancePolicy>) => {
  const normalized = normalizeInsurancePolicies(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const setInsurancePolicyDataShort = (
  state: PolicyStoreState,
  payload: Array<InsurancePolicyShort>,
) => {
  const normalized = normalizeInsurancePoliciesShort(state, payload)

  return {
    ...state,
    ...normalized,
  }
}

const setInsurancePolicyCalendarData = (
  state: PolicyStoreState,
  payload: Array<InsurancePolicyCalendar>,
) => ({
  ...state,
  insurancePoliciesCalendar: payload,
  insurancePoliciesCalendarLoading: false,
})

const setPrivatePlacementsCalendarData = (
  state: PolicyStoreState,
  payload: Array<PrivatePlacementCalendar>,
) => ({
  ...state,
  privatePlacementsCalendar: payload,
  privatePlacementsCalendarLoading: false,
})

const setVULStatementsCalendarData = (
  state: PolicyStoreState,
  payload: Array<VULPolicyCalendar>,
) => ({
  ...state,
  vulStatementsCalendar: payload,
  vulStatementsCalendarLoading: false,
})

const getAnnuityPoliciesSuccess = (state: PolicyStoreState, payload: Array<Annuity>) => {
  const normalized = normalizeAnnuities(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getAnnuityPoliciesShortSuccess = (state: PolicyStoreState, payload: Array<AnnuityShort>) => {
  const normalized = normalizeAnnuitiesShort(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getDisabilityPoliciesShortSuccess = (state: PolicyStoreState, payload: Array<Disability>) => {
  const normalized = normalizeDisabilitiesShort(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getPrivatePlacementsSuccess = (state: PolicyStoreState, payload: Array<PrivatePlacement>) => {
  const normalized = normalizePrivatePlacements(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getPrivatePlacementsShortSuccess = (
  state: PolicyStoreState,
  payload: Array<PrivatePlacementShort>,
) => {
  const normalized = normalizePrivatePlacementsShort(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getVULPoliciesSuccess = (state: PolicyStoreState, payload: Array<VULPolicy>) => {
  const normalized = normalizeVULPolicies(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getVULPoliciesShortSuccess = (state: PolicyStoreState, payload: Array<VULPolicyShort>) => {
  const normalized = normalizeVULPoliciesShort(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getIDFStatementsSuccess = (state: PolicyStoreState, payload: Array<IDFStatement>) => {
  const normalized = normalizeIdfStatements(state, payload)
  return {
    ...state,
    ...normalized,
  }
}

const getPPAStatementsSuccess = (state: PolicyStoreState, ppaStatements: Array<PPAStatement>) => ({
  ...state,
  ppaStatements,
})

const getPPAStatementsShortSuccess = (
  state: PolicyStoreState,
  ppaStatementsShort: Array<PPAStatement>,
) => ({
  ...state,
  ppaStatementsShort,
})

const getPPLIStatementsSuccess = (
  state: PolicyStoreState,
  ppliStatements: Array<PPLIStatement>,
) => ({
  ...state,
  ppliStatements,
})

const getPPLIStatementsShortSuccess = (
  state: PolicyStoreState,
  ppliStatementsShort: Array<PPLIStatement>,
) => ({
  ...state,
  ppliStatementsShort,
})

const getVULStatementsSuccess = (state: PolicyStoreState, vulStatements: Array<VULStatement>) => ({
  ...state,
  vulStatements,
})

const getVULStatementsShortSuccess = (
  state: PolicyStoreState,
  vulStatementsShort: Array<VULStatement>,
) => ({
  ...state,
  vulStatementsShort,
})

const getIDFPdfAvailabilitySuccess = (
  state: PolicyStoreState,
  payload: { idfimage: IDFImage },
) => ({
  ...state,
  idfPDFAvailabilityImages: payload.idfimage,
})

const getFootnotesSuccess = (
  state: PolicyStoreState,
  payload: { footnotegroup: Array<FootnoteGroup> },
) => {
  const footnoteGroups = normalizeFootnotes(state, payload.footnotegroup)
  return {
    ...state,
    footnoteGroups,
  }
}

const clearXIRRRates = (state: PolicyStoreState) => ({
  ...state,
  xirrRates: INITIAL_STATE.xirrRates,
  xirrRatesIdf: INITIAL_STATE.xirrRatesIdf,
})

const getXIRRRatesSuccess = (state: PolicyStoreState, payload: { xirrrate: XIRRRate }) => ({
  ...state,
  xirrRates: payload.xirrrate,
})

const getXIRRRatesError = (state: PolicyStoreState) => ({
  ...state,
  xirrRates: INITIAL_STATE.xirrRates,
})

const getXIRRRatesIDFSuccess = (
  state: PolicyStoreState,
  payload: { xirrrateidf: XIRRRateIDF },
) => ({
  ...state,
  xirrRatesIdf: payload.xirrrateidf,
})

const getXIRRRatesIDFError = (state: PolicyStoreState) => ({
  ...state,
  xirrRatesIdf: INITIAL_STATE.xirrRatesIdf,
})

const getIULMonthlyStatementsSuccess = (
  state: PolicyStoreState,
  payload: { iulmonthlystatement: Array<IULMonthlyStatement> },
) => ({
  ...state,
  iulMonthlyStatements: payload.iulmonthlystatement,
})

const getConsolidatedStatementsSuccess = (
  state: PolicyStoreState,
  payload: { consolidatedstatement: Array<ConsolidatedStatement> },
) => ({
  ...state,
  consolidatedStatements: payload.consolidatedstatement,
})

const getIULAnnualStatementsSuccess = (
  state: PolicyStoreState,
  payload: { iulannualstatement: Array<IULAnnualStatement> },
) => ({
  ...state,
  iulAnnualStatements: payload.iulannualstatement,
})

const getIndexAccountRatesSuccess = (
  state: PolicyStoreState,
  payload: { indexaccountrates: Array<IndexAccountRates> },
) => ({
  ...state,
  indexAccountRates: payload.indexaccountrates,
})

const getGeneralDocumentsSuccess = (
  state: PolicyStoreState,
  payload: { generalDocuments: Array<generalDocuments> },
) => ({
  ...state,
  generalDocuments: payload.generaldocuments,
})

const setInitialStore = () => ({
  ...INITIAL_STATE,
})

export const reduxSet = {
  getAnnuityPoliciesSuccess: dispatchReducer<PolicyStoreState, Array<Annuity>>(
    ACTIONS.GET_ANNUITIES_SUCCESS,
    getAnnuityPoliciesSuccess,
  ),
  getAnnuityPoliciesShortSuccess: dispatchReducer<PolicyStoreState, Array<AnnuityShort>>(
    ACTIONS.GET_ANNUITIES_SHORT_SUCCESS,
    getAnnuityPoliciesShortSuccess,
  ),
  getDisabilityPoliciesShortSuccess: dispatchReducer<PolicyStoreState, Array<Annuity>>(
    ACTIONS.GET_DISABILITIES_SHORT_SUCCESS,
    getDisabilityPoliciesShortSuccess,
  ),
  getInsurancePoliciesSuccess: dispatchReducer<PolicyStoreState, Array<InsurancePolicy>>(
    ACTIONS.GET_POLICIES_SUCCESS,
    setInsurancePolicyData,
  ),
  getInsurancePoliciesShortSuccess: dispatchReducer<PolicyStoreState, Array<InsurancePolicyShort>>(
    ACTIONS.GET_POLICIES_SHORT_SUCCESS,
    setInsurancePolicyDataShort,
  ),
  getInsurancePoliciesCalendarSuccess: dispatchReducer<
    PolicyStoreState,
    Array<InsurancePolicyCalendar>,
  >(ACTIONS.GET_INSURANCE_POLICIES_CALENDAR, setInsurancePolicyCalendarData),
  getPrivatePlacementsCalendarSuccess: dispatchReducer<
    PolicyStoreState,
    Array<InsurancePolicyCalendar>,
  >(ACTIONS.GET_PRIVATE_PLACEMENTS_CALENDAR, setPrivatePlacementsCalendarData),
  getVULStatementsCalendarSuccess: dispatchReducer<
    PolicyStoreState,
    Array<InsurancePolicyCalendar>,
  >(ACTIONS.GET_VUL_STATEMENTS_CALENDAR, setVULStatementsCalendarData),
  getPrivatePlacementsSuccess: dispatchReducer<PolicyStoreState, Array<PrivatePlacement>>(
    ACTIONS.GET_PRIVATE_PLACEMENTS_SUCCESS,
    getPrivatePlacementsSuccess,
  ),
  getPrivatePlacementsShortSuccess: dispatchReducer<PolicyStoreState, Array<PrivatePlacementShort>>(
    ACTIONS.GET_PRIVATE_PLACEMENTS_SHORT_SUCCESS,
    getPrivatePlacementsShortSuccess,
  ),
  getIDFStatementsSuccess: dispatchReducer<PolicyStoreState, Array<IDFStatement>>(
    ACTIONS.GET_IDF_STATEMENTS_SUCCESS,
    getIDFStatementsSuccess,
  ),
  getPPAStatementsSuccess: dispatchReducer<PolicyStoreState, Array<PPAStatement>>(
    ACTIONS.GET_PPA_STATEMENTS_SUCCESS,
    getPPAStatementsSuccess,
  ),
  getPPAStatementsShortSuccess: dispatchReducer<PolicyStoreState, Array<PPAStatement>>(
    ACTIONS.GET_PPA_STATEMENTS_SHORT_SUCCESS,
    getPPAStatementsShortSuccess,
  ),
  getPPLIStatementsSuccess: dispatchReducer<PolicyStoreState, Array<PPLIStatement>>(
    ACTIONS.GET_PPLI_STATEMENTS_SUCCESS,
    getPPLIStatementsSuccess,
  ),
  getPPLIStatementsShortSuccess: dispatchReducer<PolicyStoreState, Array<PPLIStatement>>(
    ACTIONS.GET_PPLI_STATEMENTS_SHORT_SUCCESS,
    getPPLIStatementsShortSuccess,
  ),
  getVULStatementsSuccess: dispatchReducer<PolicyStoreState, Array<VULStatement>>(
    ACTIONS.GET_VUL_STATEMENTS_SUCCESS,
    getVULStatementsSuccess,
  ),
  getVULStatementsShortSuccess: dispatchReducer<PolicyStoreState, Array<VULStatement>>(
    ACTIONS.GET_VUL_STATEMENTS_SHORT_SUCCESS,
    getVULStatementsShortSuccess,
  ),
  getVULPoliciesSuccess: dispatchReducer<PolicyStoreState, Array<VULPolicy>>(
    ACTIONS.GET_VUL_POLICIES_SUCCESS,
    getVULPoliciesSuccess,
  ),
  getVULPoliciesShortSuccess: dispatchReducer<PolicyStoreState, Array<VULPolicyShort>>(
    ACTIONS.GET_VUL_POLICIES_SHORT_SUCCESS,
    getVULPoliciesShortSuccess,
  ),
  setInitialStore: dispatchReducer<PolicyStoreState, null>(
    ACTIONS.SET_ACTIVE_USER,
    setInitialStore,
  ),
  getIDFPdfAvailabilitySuccess: dispatchReducer<PolicyStoreState, { idfimage: IDFImage }>( // eslint-disable-line no-undef
    ACTIONS.GET_IDF_PDF_AVAILABILITY_SUCCESS,
    getIDFPdfAvailabilitySuccess,
  ),
  getFootnotesSuccess: dispatchReducer<PolicyStoreState, { footnotegroup: Array<FootnoteGroup> }>( // eslint-disable-line no-undef
    ACTIONS.GET_FOOTNOTES_SUCCESS,
    getFootnotesSuccess,
  ),
  generatePDFSuccess: dispatchReducer<PolicyStoreState, {}>(ACTIONS.GENERATE_PDF_SUCCESS, noOp),
  generateDetailedPDFSuccess: dispatchReducer<PolicyStoreState, {}>(
    ACTIONS.GENERATE_DETAILED_PDF_SUCCESS,
    noOp,
  ),
  generateConsolidatedPDFSuccess: dispatchReducer<PolicyStoreState, {}>(
    ACTIONS.GENERATE_CONSOLIDATED_PDF_SUCCESS,
    noOp,
  ),
  checkPDFResult: dispatchReducer<PolicyStoreState, {}>(ACTIONS.CHECK_PDF_RESULT, noOp),
  checkPDFResultSuccess: dispatchReducer<PolicyStoreState, {}>(
    ACTIONS.CHECK_PDF_RESULT_SUCCESS,
    defaultReducer,
  ),
  checkPDFError: dispatchReducer<PolicyStoreState, {}>(ACTIONS.CHECK_PDF_RESULT_ERROR, noOp),
  clearXIRRRates: dispatchReducer<PolicyStoreState, any>(ACTIONS.CLEAR_XIRR_RATES, clearXIRRRates),
  getXIRRRatesError: dispatchReducer<PolicyStoreState, any>(
    ACTIONS.GET_XIRR_RATES_ERROR,
    getXIRRRatesError,
  ),
  getXIRRRatesSuccess: dispatchReducer<PolicyStoreState, any>(
    ACTIONS.GET_XIRR_RATES_SUCCESS,
    getXIRRRatesSuccess,
  ),
  getXIRRRatesIDFSuccess: dispatchReducer<PolicyStoreState, any>(
    ACTIONS.GET_XIRR_RATES_IDF_SUCCESS,
    getXIRRRatesIDFSuccess,
  ),
  getXIRRRatesIDFError: dispatchReducer<PolicyStoreState, any>(
    ACTIONS.GET_XIRR_RATES_IDF_ERROR,
    getXIRRRatesIDFError,
  ),
  getGeneralDocumentsSuccess: dispatchReducer<PolicyStoreState, Array<GeneralDocuments>>(
    ACTIONS.GET_GENERAL_DOCUMENTS_SUCCESS,
    getGeneralDocumentsSuccess,
  ),
  getIULMonthlyStatementsSuccess: dispatchReducer<
    PolicyStoreState,
    { iulmonthlystatement: Array<IULMonthlyStatement> },
  >(ACTIONS.GET_IUL_MONTHLY_STATEMENTS_SUCCESS, getIULMonthlyStatementsSuccess), // eslint-disable-line no-undef
  getIULAnnualStatementsSuccess: dispatchReducer<
    PolicyStoreState,
    { iulannualstatement: Array<IULAnnualStatement> },
  >(ACTIONS.GET_IUL_ANNUAL_STATEMENTS_SUCCESS, getIULAnnualStatementsSuccess), // eslint-disable-line no-undef
  getIndexAccountRatesSuccess: dispatchReducer<
    PolicyStoreState,
    { indexaccountrates: Array<IndexAccountRates> },
  >(ACTIONS.GET_INDEX_ACCOUNT_RATES_SUCCESS, getIndexAccountRatesSuccess), // eslint-disable-line no-undef
  getConsolidatedStatementsSuccess: dispatchReducer<
    PolicyStoreState,
    { consolidatedstatement: Array<ConsolidatedStatement> },
  >(ACTIONS.GET_CONSOLIDATED_STATEMENTS_SUCCESS, getConsolidatedStatementsSuccess), // eslint-disable-line no-undef
}

export const reducer = (
  state: PolicyStoreState = { ...INITIAL_STATE },
  action: StandardAction,
): PolicyStoreState => runReducers(state, reduxSet, action)
