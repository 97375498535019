// @flow

import React from 'react'

import { PDFKeys } from '@/constants'
import { Loading, PolicyDetailGeneral } from '@/components'
import {
  InsurancePolicy,
  InsurancePolicyShort,
  PrivatePlacement,
  VULPolicy,
  VULPolicyShort,
} from '@/models'
import ApiService from '@/services/ApiService'

type PolicyDetailLifeModalState = {|
  policy: ?InsurancePolicy | ?PrivatePlacement | ?VULPolicy,
|}

type PolicyDetailLifeModalProps = {|
  policyId: ?string,
  policyType: InsurancePolicy.modelName | PrivatePlacement.modelName | VULPolicy.modelName,
  printPDF: () => void,
  printPending: boolean,
|}

export class PolicyDetailLifeModal extends React.Component<
  PolicyDetailLifeModalProps,
  PolicyDetailLifeModalState,
> {
  state = {
    policy: null,
  }

  componentDidMount() {
    this.loadPolicy()
  }

  loadPolicy = () => {
    const { policyId, policyType } = this.props
    let apiCall = null

    if (policyType === InsurancePolicy.modelName) {
      apiCall = ApiService.getInsurancePolicyById
    } else if (policyType === PrivatePlacement.modelName) {
      apiCall = ApiService.getPrivatePlacementById
    } else if (policyType === VULPolicy.modelName) {
      apiCall = ApiService.getVULPolicyById
    }

    if (apiCall) {
      apiCall(policyId).then(res => {
        if (res) {
          this.setState({ policy: res.data })
        }
      })
    }
  }

  render() {
    const { printPDF, printPending } = this.props
    const { policy } = this.state

    let pdfKey = PDFKeys.ppliPolicyDetail

    if (policy instanceof InsurancePolicy || policy instanceof InsurancePolicyShort) {
      pdfKey = PDFKeys.insurancePolicyDetail
    } else if (policy instanceof VULPolicy || policy instanceof VULPolicyShort) {
      pdfKey = PDFKeys.vulPolicyDetail
    }

    if (policy) {
      return (
        <div>
          <PolicyDetailGeneral
            policy={policy}
            isModal
            pdfKey={pdfKey}
            printPDF={printPDF}
            printPending={printPending}
            download
          />
        </div>
      )
    }
    return <Loading />
  }
}

export default PolicyDetailLifeModal
