// @flow

import * as React from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { generateDetailedPDFPayload } from '@/redux/api-payloads'
import { singleSheetDownload } from '@/helpers/xlsx'
import type { RouterHistory } from 'react-router'
import { connect } from 'react-redux'
import selectors from '@/selectors'

import { Accordion, DownloadIcon, PrintIcon, Loading } from '@/components'
import { Strings } from '@/constants'
import { classnames, getSearchObject } from '@/helpers'
import { Annuity } from '@/models'

import styles from './styles.scss'

type PolicyDetailProps = {|
  clientName: string,
  filename: string,
  title: string,
  asOfDate?: string,
  download?: boolean,
  printPDF?: ?(payload: ApiPayloadType<PrintDetailedPayload>) => void,
  printPending?: boolean,
  _printMeta: ?{ id: string },
  pdfKey: PDFKeysType,
  policy: Annuity | InsurancePolicy,
  isModal?: boolean,
  firstColumnFields: Array<{ fieldKey: string, value: string | number | Array<string> }>,
  secondColumnFields: Array<{ fieldKey: string, value: string | number | Array<string> }>,
  thirdColumnFields: Array<{ fieldKey: string, value: string | number | Array<string> }>,
  hideIcons: boolean,
  history: RouterHistory,
  user: any,
  activeClient: any,
  showContentLoader?: boolean,
  onExpand?: () => void,
|}

const CURRENCY_REGEX = /^\$\d{1,3}(,\d{3})*(\.\d+)?$/

const ALWAYS_DISPLAY_AS_ARRAY_FIELDS = [
  'premiumPayorEmail',
  'primaryContactPersonEmail',
  'inforcePolicyCommunicationCCList',
  'permissionedIndividuals',
  'riders',
]

const PolicyDetailContainer = styled.div`
  ${props =>
    props.isModal
      ? `
      max-height: calc(100vh - 150px);
      overflow-y: auto;
    `
      : ''};
`

export class PolicyDetail extends React.Component<PolicyDetailProps> {
  static defaultProps = {
    isModal: false,
    download: false,
    printPDF: null,
    printPending: false,
    showContentLoader: false,
    _printMeta: null,
  }

  generatePayloadXLSX = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation()
    const { filename, firstColumnFields, secondColumnFields, thirdColumnFields } = this.props
    const fields = [...firstColumnFields, ...secondColumnFields, ...thirdColumnFields]

    const headerArray: SheetRow = fields.map(
      ({ fieldKey }: { fieldKey: string, value: string | Array<string> }) => ({
        v: Strings[fieldKey],
        t: 's',
      }),
    )

    const bodyArray: SheetRow = fields.map(
      ({ value }: { fieldKey: string, value: string | Array<string> }) => {
        if (Array.isArray(value)) {
          return {
            v: value.length > 0 ? value.join(', ') : '',
            t: 's',
          }
        }

        if (CURRENCY_REGEX.test(value)) {
          const valueParsed = Number(value.replace(/\$/g, '').replace(/,/g, ''))
          const noDecimals = !value.includes('.') || value.endsWith('.00')
          return {
            v: valueParsed,
            t: 'n',
            z: noDecimals ? '#,##0.00' : '#,###.##',
          }
        }

        return {
          v: value,
          t: 's',
        }
      },
    )

    const outputArray = [headerArray, bodyArray]

    const payload: PayloadSheet = {
      filename,
      data: outputArray,
    }
    singleSheetDownload(payload)
  }

  getPrintPayload = () => {
    const {
      pdfKey,
      policy: {
        id,
        product: { productType },
      },
    } = this.props

    return {
      pdfKey,
      policyId: id,
      policyType: productType,
    }
  }

  renderFlatField = (fieldName: string, value: string | number) => {
    const isEmpty = value === '-'
    return (
      <div className={classnames(styles.field, styles.flat, fieldName)} key={fieldName}>
        <span className={styles.bold}>{`${Strings[fieldName]}:`}</span>
        <span data-empty={isEmpty} className={styles.fieldValue}>
          {value}
        </span>
      </div>
    )
  }

  renderArrayField = (fieldKey: string, value: Array<string>) => {
    if (fieldKey) {
      return (
        <div className={styles.field} key={fieldKey}>
          <div className={styles.bold}>{`${Strings[fieldKey]}:`}</div>
          <div className={styles.listContainer}>
            {value.map((val: string) => {
              const parts = val.split(/\(([^)]+)\)/g)
              return (
                <div className={styles.listValue} key={`${fieldKey}-${val}`}>
                  {parts.map((part, index) => {
                    if (index % 2 === 0) {
                      return <span key={`${fieldKey}-${part}`}>{part} </span>
                    }
                    return (
                      <sup style={{ fontSize: '10px' }} key={`${fieldKey}-${part}`}>
                        {part}
                      </sup>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
    return ''
  }

  renderFields = (
    fields: Array<{ fieldKey: string, value: string | number | Array<string> }>,
  ): React.Node => (
    <div className={styles.column}>
      {fields.map(({ fieldKey, value }) =>
        Array.isArray(value) &&
        (value.length > 1 || ALWAYS_DISPLAY_AS_ARRAY_FIELDS.includes(fieldKey))
          ? this.renderArrayField(fieldKey, Array.from(new Set(value)))
          : this.renderFlatField(fieldKey, Array.isArray(value) ? value[0] : value),
      )}
    </div>
  )

  renderColumns = () => {
    const { firstColumnFields, secondColumnFields, thirdColumnFields } = this.props
    return (
      <div className={styles.expanded}>
        {this.renderFields(firstColumnFields)}
        {this.renderFields(secondColumnFields)}
        {this.renderFields(thirdColumnFields)}
      </div>
    )
  }

  renderNotes = () => {
    const { policy } = this.props

    return (
      <div className={styles.notesContainer}>
        {policy.notesVisibility || policy.product.productType === 'Index Universal Life' ? (
          <h3 className={styles.notesTitle}>{Strings.footnotesTitle}</h3>
        ) : null}
        <ol className={styles.notes}>
          {policy.notesVisibility ? (
            <>
              <li>Permissioned for Full Access to the Client Portal</li>
              <li>
                Permissioned for Limited Access to the Client Portal (Excel download restrictions)
              </li>
              <li>Permissioned for Limited Access to the Client Portal (PDF print restrictions)</li>
              <li>Receives Monthly PPLI Investment Account reporting emails</li>
              <li>Receives Premium Deposit Reminder emails</li>
              <li>
                Receives Option to Reduce Life Insurance Coverage emails, End of Level Term and
                Expiration of Term Conversion emails, and Illustration Review emails
              </li>{' '}
            </>
          ) : null}
          {policy.product.productType === 'Index Universal Life' ? (
            <li>
              Account value does not include the accrued value of Index Segments that have not
              reached their maturity date.
            </li>
          ) : null}
        </ol>
      </div>
    )
  }

  renderDownloadIcon = () => {
    const { download, hideIcons } = this.props
    if (download && !hideIcons) {
      return (
        <div className={styles.iconWrap}>
          <DownloadIcon onClick={this.generatePayloadXLSX} />
        </div>
      )
    }
    return null
  }

  renderPrintIcon = () => {
    const { printPDF, printPending, _printMeta, policy, hideIcons } = this.props

    if (printPDF && !hideIcons) {
      const pending = printPending && _printMeta?.id === policy.accountId
      return (
        <div className={styles.iconWrap}>
          <PrintIcon onClick={this.initPrint} pending={pending} disabled={printPending} />
        </div>
      )
    }
    return null
  }

  initPrint = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation()
    const { printPDF, policy } = this.props
    const { accountId } = policy
    if (printPDF) {
      const printDataPayload = this.getPrintPayload()
      const printPayload = generateDetailedPDFPayload(printDataPayload, { id: accountId })
      printPDF(printPayload)
    }
  }

  renderIcons = () => {
    const { user, activeClient } = this.props
    let disableExcelForUser = false
    let disbalePdfForUser = false
    if (
      user?.excel_disabled?.length > 0 &&
      activeClient &&
      user.excel_disabled.map(String).includes(String(activeClient.id))
    ) {
      disableExcelForUser = true
    }
    if (
      user?.pdf_disabled?.length > 0 &&
      activeClient &&
      user.pdf_disabled.map(String).includes(String(activeClient.id))
    ) {
      disbalePdfForUser = true
    }

    return (
      <div className={styles.accordionIcons}>
        {!disableExcelForUser ? this.renderDownloadIcon() : null}
        {!disbalePdfForUser ? this.renderPrintIcon() : null}
      </div>
    )
  }

  renderAccordionChild = () => {
    const { showContentLoader } = this.props
    if (showContentLoader) {
      return <Loading />
    }
    return (
      <>
        {this.renderColumns()}
        {this.renderNotes()}
      </>
    )
  }

  renderStandardView = () => {
    const { title, clientName, asOfDate, policy, history, onExpand } = this.props
    const { accountId } = getSearchObject(history.location.search)
    const insured = policy.insuredString

    return (
      <Accordion
        title={clientName}
        subtitle={`${title}\xa0 ${Strings.issuedOn(insured)}`}
        icon={this.renderIcons()}
        date={asOfDate}
        defaultOpen={accountId === policy.accountId}
        onExpand={onExpand}
      >
        {this.renderAccordionChild()}
      </Accordion>
    )
  }

  renderModalHeader = () => {
    const { title, clientName, asOfDate, policy } = this.props

    const insured = policy.insuredString

    return (
      <div className={styles.modalHeader}>
        <div className={styles.titleContent}>
          <div>
            <h3 className={styles.titleText}>{clientName}</h3>
            <span className={styles.subtitle}>
              {title}
              &nbsp; {Strings.issuedOn(insured)}
            </span>
            {asOfDate && <span className={styles.subtitle}>{Strings.asOfMonthYear(asOfDate)}</span>}
          </div>
          {this.renderIcons()}
        </div>
      </div>
    )
  }

  renderModalView = () => (
    <React.Fragment>
      <div className={styles.innerFormWrap}>
        {this.renderModalHeader()}
        <div className={styles.modalContent}>
          {this.renderColumns()}
          {this.renderNotes()}
        </div>
      </div>
    </React.Fragment>
  )

  render() {
    const { isModal } = this.props
    return (
      <PolicyDetailContainer className={styles.policyDetail} isModal={isModal}>
        <div className={styles.content}>
          {isModal ? this.renderModalView() : this.renderStandardView()}
        </div>
      </PolicyDetailContainer>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  activeClient: selectors.user.getActiveClient(state),
})

export default withRouter(connect(mapStateToProps)(PolicyDetail))
