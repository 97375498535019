import React from 'react'
import { components as SelectComponents } from 'react-select'
import { Images } from '@/constants'
import styles from './styles.scss'

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    boxSizing: 'borderBox',
    borderRadius: styles.borderRadius,
    width: '100vw',
    maxWidth: styles.dropdownWidth,
    boxShadow: state.selectProps.menuIsOpen ? styles.boxShadowDropdown : null,
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
    color: styles.black,
    minHeight: styles.dropdownHeight,
    fontSize: styles.standardFont,
    lineHeight: '20px',
    fontWeight: 'lighter',
    margin: 'auto',
    paddingTop: styles.spacer,
    paddingBottom: styles.spacer,
    paddingLeft: styles.dropdownPadLeft,
    backgroundColor: styles.white,
    ':hover': {
      color: styles.linkBlue,
    },
    ':active': {
      backgroundColor: styles.white,
    },
  }),
  control: provided => ({
    ...provided,
    boxSizing: 'borderBox',
    height: styles.inputHeight,
    cursor: 'auto',
    border: `1px solid ${styles.defaultGrey}`,
    color: styles.black,
    flex: 1,
    fontSize: styles.mediumFont,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: styles.standardLineHeight,
    margin: '8px',
    paddingLeft: '42px',
    background: 'transparent',
    boxShadow: 'none',
    borderRadius: styles.borderRadius,
    ':focus': {
      boxShadow: 'none',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: styles.grey,
    fontWeight: 500,
    fontSize: styles.mediumFont,
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    borderRadius: styles.borderRadius,
    backgroundColor: styles.white,
    overflow: 'auto',
    boxShadow: 'inherit',
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: styles.faintBlue,
    },
    '::-webkit-scrollbar': {
      width: '5px',
      borderRadius: styles.borderRadius,
      backgroundColor: 'transparent',
    },
  }),
  DropdownIndicator: provided => ({
    ...provided,
    paddingLeft: '24px',
  }),
}

const DropdownIndicator = props =>
  SelectComponents.DropdownIndicator && (
    <SelectComponents.DropdownIndicator {...props}>
      <img className={styles.searchIcon} src={Images.searchIcon} alt="Inspect" />
      <img className={styles.close} src={Images.dropdownClose} alt="Close" />
    </SelectComponents.DropdownIndicator>
  )

export const customComponents = { DropdownIndicator, IndicatorSeparator: null }

export default customStyles
