import { cloneDeep } from 'lodash'
import { schema, normalize } from 'normalizr'
import {
  Annuity,
  Client,
  FootnoteGroup,
  IDF,
  InsurancePolicy,
  PrivatePlacement,
  PolicyDocument,
  IDFStatement,
  VULPolicy,
  Disability,
  InsurancePolicyShort,
  PrivatePlacementShort,
  VULPolicyShort,
} from '@/models'
import AnnuityShort from '../models/policies/annuity-short'

const client = new schema.Entity(
  'clients',
  {},
  {
    processStrategy: entity => new Client(entity),
  },
)

const advisor = new schema.Entity('advisors', {
  client,
})

const idf = new schema.Entity(
  'idfs',
  {},
  {
    processStrategy: entity => new IDF(entity),
  },
)

const policyDocumentsSchema = new schema.Entity(
  'policyDocuments',
  {},
  {
    processStrategy: doc => new PolicyDocument(doc),
  },
)

const privatePlacementSchema = new schema.Entity(
  'privatePlacements',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new PrivatePlacement(policy),
  },
)

const privatePlacementSchemaShort = new schema.Entity(
  'privatePlacementsShort',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new PrivatePlacementShort(policy),
  },
)

const VULPolicySchema = new schema.Entity(
  'vulPolicies',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new VULPolicy(policy),
  },
)

const VULPolicySchemaShort = new schema.Entity(
  'vulPoliciesShort',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new VULPolicyShort(policy),
  },
)

const annuitiesSchema = new schema.Entity(
  'annuities',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new Annuity(policy),
  },
)

const annuitiesShortSchema = new schema.Entity(
  'annuitiesShort',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new AnnuityShort(policy),
  },
)

const disabilitiesShortSchema = new schema.Entity(
  'disabilityPoliciesShort',
  {
    idfs: [idf],
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new Disability(policy),
  },
)

const insurancePoliciesSchema = new schema.Entity(
  'insurancePolicies',
  {
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new InsurancePolicy(policy),
  },
)

const insurancePoliciesSchemaShort = new schema.Entity(
  'insurancePoliciesShort',
  {
    policyDocuments: [policyDocumentsSchema],
  },
  {
    processStrategy: policy => new InsurancePolicyShort(policy),
  },
)

const idfStatementsSchema = new schema.Entity(
  'idfStatements',
  {},
  {
    processStrategy: statement => new IDFStatement(statement),
  },
)

const footnoteGroupsSchema = new schema.Entity(
  'footnoteGroups',
  {},
  {
    idAttribute: 'groupType',
    processStrategy: footnoteGroup => new FootnoteGroup(footnoteGroup).footnotes,
  },
)

const normalizeInsurancePolicies = (state, payload) => {
  let insurancePolicies = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const insurancePolicyClone = cloneDeep(payload)
    const normalizedData = normalize(insurancePolicyClone, [insurancePoliciesSchema])
    ;({ insurancePolicies, policyDocuments } = normalizedData.entities)
  }
  return {
    insurancePolicies,
    insurancePolicyDocs: { ...state.insurancePolicyDocs, ...policyDocuments },
  }
}

const normalizeInsurancePoliciesShort = (state, payload) => {
  let insurancePoliciesShort = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const insurancePolicyClone = cloneDeep(payload)
    const normalizedData = normalize(insurancePolicyClone, [insurancePoliciesSchemaShort])
    ;({ insurancePoliciesShort, policyDocuments } = normalizedData.entities)
  }
  return {
    insurancePoliciesShort,
    insurancePolicyDocs: { ...state.insurancePolicyDocs, ...policyDocuments },
  }
}

const normalizeAnnuities = (state, payload) => {
  let annuities = {}
  let idfs = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const annuitiesClone = cloneDeep(payload)
    const normalizedData = normalize(annuitiesClone, [annuitiesSchema])
    ;({ annuities, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    annuities,
    idfs: { ...state.idfs, ...idfs },
    annuityDocs: { ...state.annuityDocs, ...policyDocuments },
  }
}

const normalizeAnnuitiesShort = (state, payload) => {
  let annuitiesShort = {}
  let idfs = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const annuitiesClone = cloneDeep(payload)
    const normalizedData = normalize(annuitiesClone, [annuitiesShortSchema])
    ;({ annuitiesShort, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    annuitiesShort,
    idfs: { ...state.idfs, ...idfs },
    annuityDocs: { ...state.annuityDocs, ...policyDocuments },
  }
}

const normalizeDisabilitiesShort = (state, payload) => {
  let disabilityPoliciesShort = {}
  let idfs = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const disabilitiesClone = cloneDeep(payload)
    const normalizedData = normalize(disabilitiesClone, [disabilitiesShortSchema])
    ;({ disabilityPoliciesShort, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    disabilityPoliciesShort,
    idfs: { ...state.idfs, ...idfs },
    disabilityDocs: { ...state.disabilityDocs, ...policyDocuments },
  }
}

const normalizePrivatePlacements = (state, payload) => {
  let idfs = {}
  let privatePlacements = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const privatePlacementsClone = cloneDeep(payload)
    const normalizedData = normalize(privatePlacementsClone, [privatePlacementSchema])

    ;({ privatePlacements, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    privatePlacements,
    idfs: { ...state.idfs, ...idfs },
    privatePlacementDocs: { ...state.privatePlacementDocs, ...policyDocuments },
  }
}

const normalizePrivatePlacementsShort = (state, payload) => {
  let idfs = {}
  let privatePlacementsShort = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const privatePlacementsClone = cloneDeep(payload)
    const normalizedData = normalize(privatePlacementsClone, [privatePlacementSchemaShort])

    ;({ privatePlacementsShort, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    privatePlacementsShort,
    idfs: { ...state.idfs, ...idfs },
    privatePlacementDocs: { ...state.privatePlacementDocs, ...policyDocuments },
  }
}

const normalizeVULPolicies = (state, payload) => {
  let idfs = {}
  let vulPolicies = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const vulPoliciesClone = cloneDeep(payload)
    const normalizedData = normalize(vulPoliciesClone, [VULPolicySchema])

    ;({ vulPolicies, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    vulPolicies,
    idfs: { ...state.idfs, ...idfs },
    vulPolicyDocs: { ...state.vulPolicyDocs, ...policyDocuments },
  }
}

const normalizeVULPoliciesShort = (state, payload) => {
  let idfs = {}
  let vulPoliciesShort = {}
  let policyDocuments = {}
  if (payload.length > 0) {
    const vulPoliciesClone = cloneDeep(payload)
    const normalizedData = normalize(vulPoliciesClone, [VULPolicySchemaShort])

    ;({ vulPoliciesShort, idfs, policyDocuments } = normalizedData.entities)
  }
  return {
    vulPoliciesShort,
    idfs: { ...state.idfs, ...idfs },
    vulPolicyDocs: { ...state.vulPolicyDocs, ...policyDocuments },
  }
}

const normalizeIdfStatements = (state, payload) => {
  let idfStatements = {}
  if (payload.length > 0) {
    const idfStatementsClone = cloneDeep(payload)
    const normalizedData = normalize(idfStatementsClone, [idfStatementsSchema])

    ;({ idfStatements } = normalizedData.entities)
  }
  return {
    idfStatements,
  }
}

const normalizeFootnotes = (state, payload) => {
  let footnoteGroups = {}
  if (payload.length > 0) {
    const footnoteGroupsClone = cloneDeep(payload)
    const normalizedData = normalize(footnoteGroupsClone, [footnoteGroupsSchema])
    ;({ footnoteGroups } = normalizedData.entities)
  }
  return footnoteGroups
}

export default advisor
export {
  client,
  advisor,
  normalizePrivatePlacements,
  normalizePrivatePlacementsShort,
  normalizeVULPolicies,
  normalizeVULPoliciesShort,
  normalizeAnnuities,
  normalizeAnnuitiesShort,
  normalizeFootnotes,
  normalizeInsurancePolicies,
  normalizeInsurancePoliciesShort,
  normalizeIdfStatements,
  normalizeDisabilitiesShort,
}
