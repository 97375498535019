// @flow
import * as React from 'react'
import { Strings } from '@/constants'
import styles from './styles.scss'
import jsStyles from './styles'

type AccordionProps = {|
  children: React.Node,
  title: string,
  id?: string,
  subtitle?: string,
  date?: string,
  icon?: React.Node | null,
  defaultOpen?: boolean,
  onExpand?: () => void,
|}

type AccordionState = {|
  open: boolean,
|}

class Accordion extends React.Component<AccordionProps, AccordionState> {
  static defaultProps = {
    subtitle: '',
    date: '',
    icon: null,
  }

  constructor(props: AccordionProps) {
    super(props)

    const { defaultOpen } = this.props

    this.state = { open: !!defaultOpen }
  }

  onClick = () => {
    const { onExpand } = this.props
    const { open } = this.state
    this.setState(prevState => ({ open: !prevState.open }))
    if (onExpand && !open) {
      onExpand()
    }
  }

  renderSubtitle = () => {
    const { subtitle } = this.props
    return subtitle ? <span className={styles.subtitle}>{subtitle}</span> : null
  }

  renderDate = () => {
    const { date } = this.props
    if (date) {
      return <span className={styles.date}>{Strings.asOfMonthYear(date)}</span>
    }
    return null
  }

  render() {
    const { children, title, icon, id } = this.props
    const { open } = this.state

    return (
      <div className={styles.accordion} id={id}>
        <div className={styles.accordionItem}>
          <div
            css={jsStyles.button(open)}
            className={styles.title}
            open={open}
            onClick={this.onClick}
            onKeyDown={this.onClick}
          >
            <div className={styles.titleContent}>
              <div>
                <h3 className={styles.titleText}>{title}</h3>
                {this.renderSubtitle()}
                {this.renderDate()}
              </div>
              {icon}
            </div>
          </div>
          <div css={jsStyles.panel(open)} className={styles.panel}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default Accordion
