// @flow

import React from 'react'
import styles from './styles.scss'
import { UiModal, SecondaryButton, PrimaryButton } from '@/components'
import Access from '@/constants/accessibility'
import { Strings } from '@/constants'

type ConfirmationModalProps = {
  title: string,
  loading?: boolean,
  onClose: () => void,
  onConfirm: () => void,
  children?: React.Node,
}

class ConfirmationModal extends React.Component<ConfirmationModalProps> {
  render() {
    const { onClose, onConfirm, title, children, loading } = this.props
    return (
      <UiModal title={title} onClose={onClose}>
        <div className={styles.content}>{children}</div>
        <div className={styles.actions}>
          <SecondaryButton
            title={Strings.settings.activeSessions.noButton}
            ariaLabel={Access.Components.Modal.No}
            disabled={loading}
            onClick={onClose}
            className={styles.actionsButton}
          />
          <PrimaryButton
            title={Strings.settings.activeSessions.yesButton}
            ariaLabel={Access.Components.Modal.Yes}
            disabled={loading}
            onClick={onConfirm}
            className={styles.actionsButton}
          />
        </div>
      </UiModal>
    )
  }
}

export default ConfirmationModal
