// @flow
import { merge } from 'lodash'
import { camelObjectToSnakeObject } from '@/helpers'
import BaseModel from './base/base-model'
import { defaultNumber, defaultString } from './defaults'

export type PremiumPaymentDataType = {|
  id: ?string,
  amount: ?number,
  start_date: ?string,
|}

const defaultDataValues = {
  id: null,
  amount: null,
  start_date: null,
}

export default class PremiumPayment extends BaseModel {
  id: string

  amount: number

  startDate: string

  constructor(rawData: PremiumPayment | PremiumPaymentDataType = defaultDataValues) {
    super()
    const data: PremiumPaymentDataType =
      rawData instanceof PremiumPayment
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData

    this.id = defaultString(data.id)
    this.amount = defaultNumber(data.amount)
    this.startDate = defaultString(data.start_date)
  }
}
