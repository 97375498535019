// @flow

import { css } from '@emotion/core'

import colors from '@/../src/styles/colors.scss'
import metrics from '@/../src/styles/metrics.scss'

const CALENDAR_WIDTH = 354

const Styles = {
  calendar: css`
    position: absolute;
    top: ${metrics.dateSelectorHeight};
    left: -${CALENDAR_WIDTH}px;
    z-index: ${metrics.zIndex2};

    .react-calendar {
      border: 0;
      border-radius: ${metrics.borderRadiusMD};
      box-shadow: ${colors.float40};
      font-family: 'OpenSans', sans-serif;
      width: ${CALENDAR_WIDTH}px;

      .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day {
        padding: 19px 0;
      }
    }

    .react-calendar__month-view__weekdays {
      padding: ${metrics.spacerXS} 0 0;
      font-size: ${metrics.standardFont};
    }
    .react-calendar__navigation {
      background-color: ${colors.textBoxGrey};
      font-size: ${metrics.mediumFont};
      height: ${metrics.tableRowHeight};
      letter-spacing: 0.2px;
      margin-bottom: 20px;
      border-bottom: 2px solid ${colors.lightestGrey};

      button {
        min-width: 50px;
      }
    }

    .react-calendar__navigation__label {
      font-size: ${metrics.mediumFont};
      font-weight: 600;
    }

    .react-calendar__navigation__label,
    .react-calendar__navigation__label:disabled,
    .react-calendar__navigation__prev-button:enabled:focus,
    .react-calendar__navigation__next-button:enabled:focus {
      background-color: ${colors.white};
      color: ${colors.almostBlack};
    }

    .react-calendar__navigation__next-button,
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__prev2-button {
      font-size: 25px;
      padding: 0;
      color: ${colors.mediumGrey};
    }

    .react-calendar__navigation__next-button:disabled,
    .react-calendar__navigation__next2-button:disabled,
    .react-calendar__navigation__prev-button:disabled,
    .react-calendar__navigation__prev2-button:disabled {
      color: ${colors.mediumGreyDisabled};
    }

    .react-calendar__month-view__days__day--weekend {
      color: ${colors.calendarDay};
    }

    abbr[title] {
      text-decoration: underline;
    }

    .react-calendar__navigation__label:enabled:hover,
    .react-calendar__navigation__prev-button:enabled:hover,
    .react-calendar__navigation__next-button:enabled:hover,
    .react-calendar__navigation__prev2-button:enabled:hover,
    .react-calendar__navigation__next2-button:enabled:hover,
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: ${colors.calendarHoverBlue};
    }

    .react-calendar__tile--active {
      background: ${colors.calendarHoverBlue};
      color: ${colors.almostBlack};
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${colors.calendarNeighboringMonth};
    }
  `,
  button: (open: boolean) =>
    open
      ? css`
          background: ${colors.lighterGrey};
        `
      : null,
  buttonText: (open: boolean) => css`
    background-color: transparent;
    ${open
      ? `
      color: ${colors.blue};
    `
      : `
      color: ${colors.grey};
    `}
  `,
  tooltip: (props: { top: number, left: number }) => css`
    top: ${props.top}px;
    left: ${props.top}px;
  `,
}

export default Styles
