// @flow

import * as React from 'react'

import { Strings, SettingsViewTypes, ModalTypes, ModalActions } from '@/constants'
import type { SettingsViewType } from '@/constants'

import {
  portalSetEmailPayload,
  portalSetPasswordPayload,
  portalSetUsernamePayload,
  portalSetMobilePayload,
} from '@/redux/api-payloads'
import Access from '@/constants/accessibility'
import eventManager from '../event-manager'

import PortalSetEmailForm from './portal-set-email-form'
import PortalSetUsernameForm from './portal-set-username-form'
import PortalSetPasswordForm from './portal-set-password-form'
import PortalSetMobileForm from './portal-set-mobile-number-form'

import jsStyles from '../styles'
import styles from './styles.scss'
import SettingsActiveSessionsTab from '../../settings/settings-active-sessions-tab'

type PortalSettingsOption = {|
  name: SettingsViewType,
  title: string,
|}

const SettingsViews: Array<PortalSettingsOption> =
  process.env.NODE_ENV === 'production'
    ? [
        {
          name: SettingsViewTypes.username,
          title: Strings.settings.username.title,
        },
        {
          name: SettingsViewTypes.email,
          title: Strings.settings.email.title,
        },
        {
          name: SettingsViewTypes.password,
          title: Strings.settings.password.title,
        },
        {
          name: SettingsViewTypes.activeSessions,
          title: Strings.settings.activeSessions.title,
        },
      ]
    : [
        {
          name: SettingsViewTypes.email,
          title: Strings.settings.email.title,
        },
        {
          name: SettingsViewTypes.mobile,
          title: Strings.settings.mobile.title,
        },
        {
          name: SettingsViewTypes.password,
          title: Strings.settings.password.title,
        },
        {
          name: SettingsViewTypes.activeSessions,
          title: Strings.settings.activeSessions.title,
        },
      ]

type SettingsModalProps = {|
  onSubmit: (payload: ApiPayloadType<any>) => void,
  emailSuccess: boolean,
  usernameSuccess: boolean,
  passwordSuccess: boolean,
  mobileSuccess: boolean,
|}

type SettingsModalState = {|
  active: SettingsViewType,
|}

class SettingsModal extends React.Component<SettingsModalProps, SettingsModalState> {
  constructor(props: SettingsModalProps) {
    super(props)

    this.state = {
      active: SettingsViews[0].name,
    }
  }

  setActive = (e: DOMEvent) => {
    e.persist()
    eventManager.emit(ModalActions.CHANGE_SETTINGS_TAB, ModalTypes.settings)
    const type = e.target.dataset.name
    this.setState({ active: type })
  }

  onSubmitEmail = (data: PortalUpdateUserInfoPayload) => {
    const { onSubmit } = this.props
    const payload = portalSetEmailPayload(data)
    onSubmit(payload)
  }

  onSubmitUsername = (data: PortalUpdateUserInfoPayload) => {
    const { onSubmit } = this.props
    const payload = portalSetUsernamePayload(data)
    onSubmit(payload)
  }

  onSubmitPassword = (data: PortalSetPasswordPayload) => {
    const { onSubmit } = this.props
    const payload = portalSetPasswordPayload(data)
    onSubmit(payload)
  }

  onSubmitMobile = (data: PortalSetMobilePayload) => {
    const { onSubmit } = this.props
    const payload = portalSetMobilePayload(data)
    onSubmit(payload)
  }

  isFormActive = (name: string) => {
    const { active } = this.state
    return name === active
  }

  renderForm = () => {
    const { active } = this.state

    const { emailSuccess, usernameSuccess, passwordSuccess, mobileSuccess } = this.props

    let formNode = null
    switch (active) {
      case SettingsViewTypes.email:
        formNode = (
          <PortalSetEmailForm
            {...this.props}
            submitForm={this.onSubmitEmail}
            success={emailSuccess}
          />
        )
        break
      case SettingsViewTypes.username:
        formNode = (
          <PortalSetUsernameForm
            {...this.props}
            submitForm={this.onSubmitUsername}
            success={usernameSuccess}
          />
        )
        break
      case SettingsViewTypes.password:
        formNode = (
          <PortalSetPasswordForm
            {...this.props}
            submitForm={this.onSubmitPassword}
            success={passwordSuccess}
          />
        )
        break
      case SettingsViewTypes.mobile:
        formNode = (
          <PortalSetMobileForm
            {...this.props}
            submitForm={this.onSubmitMobile}
            success={mobileSuccess}
          />
        )
        break
      case SettingsViewTypes.activeSessions:
        formNode = <SettingsActiveSessionsTab {...this.props} />
        break
      default:
        formNode = null
    }
    return formNode
  }

  renderList = (): Array<React.Node> =>
    SettingsViews.map(item => (
      <li
        role="menuitem"
        key={item.name}
        css={jsStyles.settingsMenuItem(this.isFormActive(item.name))}
        aria-label={Access.Components.NavLink}
        onClick={this.setActive}
        onKeyPress={() => {}}
        data-name={item.name}
      >
        {item.title}
      </li>
    ))

  render() {
    return (
      <section className={styles.portalModal}>
        <section className={styles.portalModalOptionsContainer}>
          <ul>{this.renderList()}</ul>
        </section>
        <section className={styles.portalModalFormsContainer}>{this.renderForm()}</section>
      </section>
    )
  }
}

export default SettingsModal
