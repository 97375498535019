// @flow

import { PDFKeys } from '@/constants'

const annuityPortfolio = {
  insuredString: 'insured_full_name',
  beneficiaryString: 'beneficiary_full_name',
  insuranceCompanyName: 'insurance_company',
  ownerString: 'owner_full_name',
  policyDate: 'policy_date',
}

const annuitySummary = {
  ownerString: 'owner_full_name',
}

const insuranceSummary = {
  ownerString: 'owner_full_name',
}

const insuranceInforce = {
  policyDate: 'policy_date',
  insuranceCompanyName: 'insurance_company',
  insuredString: 'insured_full_name',
  ownerString: 'owner_full_name',
  beneficiaryString: 'beneficiary_full_name',
}

const ppaStatementDetail = {
  formattedPolicyDate: 'policy_date',
  insuranceCompanyName: 'insurance_company',
  insuredString: 'insured_full_name',
  ownerString: 'owner_full_name',
  beneficiaryString: 'beneficiary_full_name',
}

const ppliStatementDetail = {
  formattedPolicyDate: 'policy_date',
  insuranceCompanyName: 'insurance_company',
  insuredString: 'insured_full_name',
  ownerString: 'owner_full_name',
  beneficiaryString: 'beneficiary_full_name',
}

const vulStatementDetail = {
  formattedPolicyDate: 'policy_date',
  insuranceCompanyName: 'insurance_company',
  insuredString: 'insured_full_name',
  ownerString: 'owner_full_name',
  beneficiaryString: 'beneficiary_full_name',
}

export const sortKeysMap = {
  [PDFKeys.annuityPortfolio]: annuityPortfolio,
  [PDFKeys.annuitySummary]: annuitySummary,
  [PDFKeys.insuranceSummary]: insuranceSummary,
  [PDFKeys.insuranceInforce]: insuranceInforce,
  [PDFKeys.ppaStatementDetail]: ppaStatementDetail,
  [PDFKeys.ppliStatementDetail]: ppliStatementDetail,
  [PDFKeys.vulStatementDetail]: vulStatementDetail,
}

export default sortKeysMap
