// @flow
import React from 'react'
import { Strings } from '@/constants'
import styles from './styles.scss'

type NoDocsProps = {|
  type?: string,
|}

const NoDocs = (props: NoDocsProps) => {
  const { type } = props

  return (
    <div className={styles.noDocsContainer}>
      <div className={styles.noDocsContent}>
        <p>{Strings.noDocs(type || Strings.noDocsType.policies)}</p>
        <p>
          {Strings.noDocsInstructions.line1}
          <a href={`mailto:${Strings.noDocsInstructions.link.url}`}>
            {Strings.noDocsInstructions.link.text}
          </a>
          {Strings.noDocsInstructions.line2}
        </p>
      </div>
    </div>
  )
}

export default NoDocs
