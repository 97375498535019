// @flow
import React, { useEffect, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import styles from './styles.scss'
import { Loading } from '@/components'
import { Images } from '@/constants'

// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/TextLayer.css'

type PdfViewerProps = {|
  src: string,
|}

const WORKER_LOCATION = '/lib/pdf.worker.min.js'

export const PdfViewer = (props: PdfViewerProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = WORKER_LOCATION

  const { src } = props

  const [allPagesCount, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [renderedPageNumber, setRenderedPageNumber] = useState(null)
  const [hasError, setError] = useState<boolean>(false)

  useEffect(() => {
    setPageNumber(1)
    setRenderedPageNumber(null)
    setNumPages(null)
    setError(false)
  }, [src])

  const onDocumentLoadSuccess = ({ numPages }): void => {
    setNumPages(numPages)
  }

  const onDocumentLoadError = (): void => {
    setError(true)
  }

  const nextAvailable = !!allPagesCount && pageNumber < allPagesCount
  const prevAvailable = !!allPagesCount && pageNumber > 1

  const isLoading = renderedPageNumber !== pageNumber

  const goToNextPage = (): void => {
    if (!nextAvailable) return
    setPageNumber(pageNumber + 1)
  }

  const goToPreviousPage = (): void => {
    if (!prevAvailable) return
    setPageNumber(pageNumber - 1)
  }

  const renderPagination = (): Array<React.Node> | React.Node => {
    if (hasError) return null
    if (!allPagesCount) {
      return <Loading />
    }
    return (
      <div className={styles.paginationControls}>
        <button
          type="button"
          disabled={!prevAvailable}
          className={styles.prevButton}
          onClick={goToPreviousPage}
        >
          <img src={Images.downCaret} alt="next" />
        </button>
        <span>
          {pageNumber}/{allPagesCount}
        </span>
        <button type="button" disabled={!nextAvailable} onClick={goToNextPage}>
          <img src={Images.downCaret} alt="next" />
        </button>
      </div>
    )
  }

  return (
    <div className={styles.pdfDocumentWrapper}>
      <Document file={src} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
        {isLoading && renderedPageNumber ? (
          <Page
            key={renderedPageNumber}
            pageNumber={renderedPageNumber}
            renderAnnotationLayer={false}
            renderTextLayer
            width={1000}
          />
        ) : null}
        <Page
          key={pageNumber}
          pageNumber={pageNumber}
          onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
          renderAnnotationLayer={false}
          renderTextLayer
          width={1000}
          className={`${isLoading ? styles.loadingPage : ''}`}
        />
      </Document>

      {renderPagination()}
    </div>
  )
}

export default PdfViewer
