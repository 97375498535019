// @flow

import { css } from '@emotion/core'
import { Images } from '@/constants'
import { spacerLG } from '@/../src/styles/metrics.scss'

const Styles = {
  button: (open: boolean) => css`
    &:after {
      background-image: url(${Images.downCaretBlue});
    }
    ${open
      ? `
        &:after {
          background-image: url(${Images.downCaretBlue});
          transform: rotate(180deg);
        }
      `
      : `
          transform: rotate(0deg);
        `}
  `,
  panel: (open: boolean) => css`
    overflow: hidden;

    ${open
      ? `
        max-height: none;
        margin-bottom: ${spacerLG};
        opacity: 1;
        transform: translateY(0);
      `
      : `
        max-height: 0;
        margin-bottom: 0;
        opacity: 0;
        transform: translateY(-25%);
      `}
  `,
}

export default Styles
