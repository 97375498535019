// @flow

import { css } from '@emotion/core'
import colors from '@/../src/styles/colors.scss'

const Styles = {
  button: (isActive: boolean) => css`
    border: 0;

    ${isActive
      ? `
          border-bottom: 3px solid ${colors.blue};
        `
      : `
          border-bottom: 3px solid transparent;
        `};
  `,
}

export default Styles
