// @flow

import * as React from 'react'

import { PolicyDetail } from '@/components'
import { Annuity, InsurancePolicy, PrivatePlacement } from '@/models'
import { firstColFieldsMap } from './first-col-fields-map'
import { secondColFieldsMap } from './second-col-fields-map'
import { thirdColFieldsMap } from './third-col-fields-map'

type PolicyDetailGeneralProps = {|
  _printMeta: ?{ id: string },
  download?: boolean,
  hideIcons?: boolean,
  isModal?: boolean,
  pdfKey: PDFKeysType,
  policy: Annuity | InsurancePolicy | PrivatePlacement | VULPolicy,
  printPDF?: ?(payload: ApiPayloadType<PrintDetailedPayload>) => void,
  printPending?: boolean,
  showContentLoader?: boolean,
  onExpand?: () => void,
|}

export class PolicyDetailGeneral extends React.Component<PolicyDetailGeneralProps> {
  static defaultProps = {
    isModal: false,
    download: false,
    printPending: false,
    printPDF: null,
    _printMeta: null,
    hideIcons: false,
    showContentLoader: false,
  }

  render() {
    const {
      isModal,
      policy,
      download,
      printPDF,
      printPending,
      pdfKey,
      _printMeta,
      hideIcons = false,
      showContentLoader = false,
      onExpand,
    } = this.props

    return (
      <PolicyDetail
        asOfDate={policy.formattedAsOfDate}
        clientName={policy.owner.formattedName}
        filename={policy.detailFilename}
        title={policy.title}
        // $FlowFixMe
        firstColumnFields={firstColFieldsMap[policy.product.productType](policy)}
        // $FlowFixMe
        secondColumnFields={secondColFieldsMap[policy.product.productType](policy)}
        // $FlowFixMe
        thirdColumnFields={thirdColFieldsMap[policy.product.productType](policy)}
        isModal={isModal}
        download={download}
        printPDF={printPDF}
        printPending={printPending}
        _printMeta={_printMeta}
        policy={policy}
        pdfKey={pdfKey}
        hideIcons={hideIcons}
        showContentLoader={showContentLoader}
        onExpand={onExpand}
      />
    )
  }
}

export default PolicyDetailGeneral
