// @flow

import React from 'react'

import { Loading, PolicyDetailGeneral } from '@/components'
import { Disability } from '@/models'
import { PDFKeys } from '@/constants'
import ApiService from '@/services/ApiService'

type PolicyDetailDisabilityState = {|
  disabilityPolicy: ?Disability,
|}

type PolicyDetailDisabilityProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  policyId: ?string,
  printPDF: () => void,
  printPending: boolean,
|}

export class PolicyDetailDisabilityModal extends React.Component<
  PolicyDetailDisabilityProps,
  PolicyDetailDisabilityState,
> {
  state = {
    disabilityPolicy: null,
  }

  componentDidMount() {
    this.loadDisabilityPolicy()
  }

  loadDisabilityPolicy() {
    const { policyId } = this.props

    ApiService.getDisabilityPolicyById(policyId).then(res => {
      if (res) {
        this.setState({ disabilityPolicy: res.data })
      }
    })
  }

  render() {
    const { printPDF, printPending } = this.props
    const { disabilityPolicy } = this.state
    if (disabilityPolicy) {
      return (
        <div>
          <PolicyDetailGeneral
            policy={disabilityPolicy}
            isModal
            pdfKey={PDFKeys.disabilityPolicyDetail}
            printPDF={printPDF}
            printPending={printPending}
            download
          />
        </div>
      )
    }
    return <Loading />
  }
}

export default PolicyDetailDisabilityModal
