// @flow
import * as React from 'react'
import { isEqual } from 'lodash'
import moment from 'moment'
import GolcondaTable from '@/components/table'
import Format from '@/components/table/format'
import { Strings } from '@/constants'
import { IDF, XIRRRateIDF } from '@/models'
import { makeDate } from '@/models/defaults'
import {
  ppliIDFReturnsColumns,
  ppaIDFReturnsColumns,
  vulIDFReturnsColumns,
  transformIDFReturnsData,
} from './idf-returns-data'
import IDFValueChart from '@/components/statement-detail/idf-value-chart'

type IDFReturnsTableProps = {|
  ownerName: ?string,
  statements: Array<PPStatementType>,
  loading: boolean,
  notes: Array<FootnoteType>,
  month: string,
  idfs: { [string]: IDF },
  policyStrings: typeof Strings.ppa | typeof Strings.ppli | typeof Strings.vul,
  pdfKey: PDFKeysType,
  getIDFReturnsColumns: (startDate: string, endDate: string, idfReturnsBefore: string) => Array<*>,
  xirrRatesIdf: XIRRRateIDF | Object,
|}

export const getPPLIIDFReturnsDownloadData = (
  statements: Array<PPStatementType>,
  idfs: { [string]: IDF },
  ownerName: ?string,
  xirrRatesIdf: XIRRRateIDF | Object,
) => {
  const idfStatements = statements.reduce((acc, cur) => [...acc, ...cur.idfStatements], [])
  const { idfReturnsBefore } = Strings.ppli
  let statementEnd = ''
  let statementStart = ''
  if (statements[0]) {
    ;[{ statementStart, statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  const [data, columns] = transformIDFReturnsData(
    ppliIDFReturnsColumns,
    idfStatements,
    idfs,
    statementStart,
    idfReturnsBefore,
    xirrRatesIdf,
  )
  return new Format({
    ownerName,
    statementEnd,
    columns,
    title: Strings.ppli.idfReturns,
    sheetname: Strings.ppli.worksheetName.idfReturns,
  }).getTableAsXLSXInput(data)
}

export const getPPAIDFReturnsDownloadData = (
  statements: Array<PPStatementType>,
  idfs: { [string]: IDF },
  ownerName: ?string,
  xirrRatesIdf: XIRRRateIDF | Object,
) => {
  const idfStatements = statements.reduce((acc, cur) => [...acc, ...cur.idfStatements], [])
  const { idfReturnsBefore } = Strings.ppa
  let statementEnd = ''
  let statementStart = ''
  if (statements[0]) {
    ;[{ statementStart, statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  const [data, columns] = transformIDFReturnsData(
    ppaIDFReturnsColumns,
    idfStatements,
    idfs,
    statementStart,
    idfReturnsBefore,
    xirrRatesIdf,
  )
  return new Format({
    ownerName,
    statementEnd,
    columns,
    title: Strings.ppa.idfReturns,
    sheetname: Strings.ppa.worksheetName.idfReturns,
    ppaTable: true,
  }).getTableAsXLSXInput(data)
}

export const getVULIDFReturnsDownloadData = (
  statements: Array<PPStatementType>,
  idfs: { [string]: IDF },
  ownerName: ?string,
  xirrRatesIdf: XIRRRateIDF | Object,
) => {
  const idfStatements = statements.reduce((acc, cur) => [...acc, ...cur.idfStatements], [])
  const { idfReturnsBefore } = Strings.vul
  let statementEnd = ''
  let statementStart = ''
  if (statements[0]) {
    ;[{ statementStart, statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  const [data, columns] = transformIDFReturnsData(
    vulIDFReturnsColumns,
    idfStatements,
    idfs,
    statementStart,
    idfReturnsBefore,
    xirrRatesIdf,
  )
  return new Format({
    ownerName,
    statementEnd,
    columns,
    title: Strings.vul.idfReturns,
    sheetname: Strings.vul.worksheetName.idfReturns,
    vulTable: true,
  }).getTableAsXLSXInput(data)
}

type IDFReturnsTableState = {|
  data: Array<TableFormats>,
|}

export class IDFReturnsTable extends React.Component<IDFReturnsTableProps, IDFReturnsTableState> {
  constructor(props: IDFReturnsTableProps) {
    super(props)

    this.state = {
      data: [],
    }
  }

  componentDidUpdate(prevProps: IDFReturnsTableProps) {
    const { loading: prevLoading, xirrRatesIdf: prevXirrRatesIdf } = prevProps
    const { loading, statements, month, idfs, policyStrings, getIDFReturnsColumns, xirrRatesIdf } =
      this.props

    if ((prevLoading && !loading) || !isEqual(prevXirrRatesIdf, xirrRatesIdf)) {
      const { idfReturnsBefore } = policyStrings
      const [data] = transformIDFReturnsData(
        getIDFReturnsColumns,
        statements,
        idfs,
        month,
        idfReturnsBefore,
        xirrRatesIdf,
      )

      this.updateState({ data })
    }
  }

  updateState(newState: IDFReturnsTableState) {
    this.setState(newState)
  }

  IDFReturnsColumns() {
    const { getIDFReturnsColumns, policyStrings, statements, idfs, month } = this.props

    const { idfReturnsBefore } = policyStrings
    const statementYear = month.slice(0, 4)
    let statementStartFormat = ''
    let statementEndFormat = ''
    const currentDate = makeDate(month)

    const [monthStatements] = statements.reduce(
      (acc, origCurr) => {
        const curr = origCurr
        const [monthAcc, yearAcc] = acc
        curr.idf = idfs[curr.idfId]
        if (curr.statementStart === month) {
          monthAcc.push(curr)
        }
        const currYear = curr.statementStart.slice(0, 4)
        if (statementYear === currYear && curr.statementStartDate <= currentDate) {
          yearAcc.push(curr)
        }
        return [monthAcc, yearAcc]
      },
      [[], []],
    )

    if (monthStatements[0]) {
      ;[{ statementStartFormat, statementEndFormat }] = monthStatements
    }

    return getIDFReturnsColumns(statementStartFormat, statementEndFormat, idfReturnsBefore)
  }

  render() {
    const { ownerName, loading, notes, policyStrings, pdfKey } = this.props
    const { data } = this.state

    const isPPLI = policyStrings.short === Strings.ppli.short

    let columns = this.IDFReturnsColumns()
    // Check if any record has a valid idf_factsheet value
    const hasValidFactsheet = data.some(
      record =>
        record.idf_factsheet !== '' &&
        record.idf_factsheet !== null &&
        record.idf_factsheet !== undefined,
    )

    // Remove the object with key-value pair "accessor": "idf_factsheet" from columns if all records have blank, null, or undefined idf_factsheet value
    if (!hasValidFactsheet) {
      columns = columns.filter(column => column.accessor !== 'idf_factsheet')
    }

    const hideIDFChartForEnvironment = process.env.HIDE_IDF_CHART === 'true';

    return (
      <>
        {!hideIDFChartForEnvironment && isPPLI && <IDFValueChart data={data} />}
        <div>
          <GolcondaTable
            ownerName={ownerName}
            loading={loading}
            data={data}
            columns={columns}
            notes={notes}
            download
            ppaTable={policyStrings.short === Strings.ppa.short}
            vulTable={policyStrings.short === Strings.vul.short}
            pdfKey={pdfKey}
          />
        </div>
      </>
    )
  }
}

type IDFReturnsTableWrapperProps = {|
  ownerName: ?string,
  statements: Array<PPStatementType>,
  loading: boolean,
  notes: Array<FootnoteType>,
  month: string,
  idfs: { [string]: IDF },
  policyStrings: typeof Strings.ppa | typeof Strings.ppli,
  pdfKey: PDFKeysType,
  xirrRatesIdf: XIRRRateIDF | Object,
|}

export const IDFReturnsPPATable = (props: IDFReturnsTableWrapperProps) => (
  <IDFReturnsTable {...props} getIDFReturnsColumns={ppaIDFReturnsColumns} />
)

export const IDFReturnsPPLITable = (props: IDFReturnsTableWrapperProps) => (
  <IDFReturnsTable {...props} getIDFReturnsColumns={ppliIDFReturnsColumns} />
)

export const IDFReturnsVULTable = (props: IDFReturnsTableWrapperProps) => (
  <IDFReturnsTable {...props} getIDFReturnsColumns={vulIDFReturnsColumns} />
)
