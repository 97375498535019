// @flow
import { matchPath } from 'react-router'
import { Routes } from '@/constants'

export const isHomepage = (path: string) => path === Routes.public.Home

export const isAuthRoute = (path: string) => {
  // using 'any' here is necessary because of how Flow works  https://davidwalsh.name/flow-object-values
  const authKeys = Object.keys(Routes.auth)

  return authKeys.some(ele => {
    const match = matchPath(path, {
      path: Routes.auth[ele],
      exact: false,
      strict: false,
    })
    return !!match
  })
}

export const isPortalRoute = (path: string) => {
  const portalKeys = Object.keys(Routes.portal)
  return portalKeys.some(ele => {
    const match = matchPath(path, {
      path: Routes.portal[ele],
      exact: false,
      strict: false,
    })
    return !!match
  })
}

export const isClientList = (path: string) => path === Routes.portal.ClientList

export const isTermsOfUse = (path: string) => path === Routes.portal.Terms

export const isPrivacyNotice = (path: string) => path === Routes.portal.PrivacyNotice

export const routeFilterMatcher = (path: string, exludedRoutes: string | Array<string>) => {
  // using 'any' here is necessary because of how Flow works  https://davidwalsh.name/flow-object-values
  let routesToMatch = [
    ...(Object.values(Routes.public): any),
    ...(Object.values(Routes.portal): any),
  ]
  let isMatch = false
  if (typeof exludedRoutes === 'string') {
    routesToMatch = routesToMatch.filter(route => route !== exludedRoutes)
  } else {
    routesToMatch = routesToMatch.filter(route => !exludedRoutes.includes(route))
  }
  isMatch = routesToMatch.includes(path)
  return isMatch
}

export default routeFilterMatcher
