// @flow

import * as React from 'react'
import { Strings, Routes } from '@/constants'
import { Anchor } from '@/components'
import styles from './styles.scss'

function TermsAndConditions() {
  const { termsAndConditions: terms, contactUsSection } = Strings
  return (
    <div className={styles.container}>
      <h3 className={styles.secondaryTitle}>{terms.overviewOfClient}</h3>
      <p>
        {terms.thisIsAHigh}
        <strong>{terms.byClicking}</strong>
        {terms.thisOverviewIs}
      </p>
      <ul className={styles.overviewList}>
        {terms.overviewList.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <p className={styles.endOfOverview}>
        <strong>{terms.endOfOverview}</strong>
      </p>
      <p className={styles.endOfOverviewMobile}>
        <strong>{terms.endOfOverviewMobile}</strong>
      </p>
      <h3 className={styles.secondaryTitle}>{terms.clientPortalTerms}</h3>
      <p>
        {terms.theseClientPortal}
        <strong>{terms.portalTerms}</strong>
        {terms.governYourAccess}
        <a aria-label={Strings.websiteText} href={Strings.websiteLink}>
          {Strings.websiteText}
        </a>
        {terms.andCollectively}
        <strong>{terms.portal}</strong>
        {terms.offeredByGolconda}
        <strong>{terms.golcondaQuotes}</strong>
        <span>{Strings.comma}</span>
        <strong>{terms.weUs}</strong>
        {Strings.or}
        <strong>{terms.our}</strong>
      </p>
      <p className={styles.noMarginTop}>{terms.thePortalIsOffered}</p>
      <p>
        {terms.pleaseReadThese}
        <strong>{terms.byClicking}</strong>
        {terms.theOverviewOf}
      </p>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.portalAccessAnd}</h4>
        <p>
          {terms.toAccessThePortalOrSome}
          <Anchor path={Routes.portal.PrivacyNotice} ariaLabel={terms.privacyNotice}>
            {terms.privacyNotice}
          </Anchor>
          {terms.andYouConsent}
        </p>
        <p>{terms.toAccessThePortalYou}</p>
        <p>{terms.youAgreeTo}</p>
        <p>
          {terms.toHelpMaintain}
          <a aria-label={Strings.clientServiceEmail} href={`mailto:${Strings.clientServiceEmail}`}>
            {`${Strings.clientServiceEmail}.`}
          </a>
          {terms.howeverIfYou}
        </p>
        <p>{terms.weHaveThe}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.portalProvidedAs}</h4>
        <p>{terms.theInformationCourtesy}</p>
        <p>{terms.theInformationUnderstanding}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.ownershipAndUse}</h4>
        <p>{terms.thePortalAnd}</p>
        <p className={styles.noMarginTop}>{terms.thesePortalTerms}</p>
        <ul className={styles.list}>
          {terms.theseportalTermsList.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <p>{terms.youAreStrictly}</p>
        <p>{terms.ifYouPrint}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.prohibitedUses}</h4>
        <p>
          {terms.youMayUse}
          <span className={styles.underline}>{terms.not}</span>
          {terms.toUseThe}
        </p>
        <ul className={styles.list}>
          {terms.youMayUseList.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <p>
          {terms.youFurther}
          <span className={styles.underline}>{terms.not}</span>
          {terms.to}
        </p>
        <ul className={styles.list}>
          {terms.youFurtherList.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.tradeMarks}</h4>
        <p>{terms.theGolcondaPartners}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.termination}</h4>
        <p>{terms.youAgreeThatWe}</p>
        <p>{terms.weReserveTheRightToWithdraw}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.changesToPortal}</h4>
        <p>{terms.weReserveTheRightToRevise}</p>
        <p>{terms.obligations}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.disclaimerOfWarranties}</h4>
        <p>{terms.weTryToKeep}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.indemnification}</h4>
        <p>
          {terms.youAgreeToIndemnify}
          <strong>{terms.indeminified}</strong>
          {terms.fromAndAgainst}
        </p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.governingLaw}</h4>
        <p>{terms.allMatters}</p>
        <p className={styles.noMarginTop}>{terms.anyLegal}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.misc}</h4>
        <p>{terms.noWaiver}</p>
        <p className={styles.noMarginTop}>{terms.ifAnyProvision}</p>
      </section>
      <section className={styles.romanSection}>
        <h4 className={styles.romanTitle}>{terms.contactUs}</h4>
        <p>{terms.ifYouHave}</p>
        <div className={styles.contactSection}>
          <div className={styles.contactSectionChild}>
            <strong>{contactUsSection.callUs}</strong>
          </div>
          <div className={styles.contactSectionChild}>
            <span>{contactUsSection.phone}</span>
          </div>
          <div className={styles.contactSectionChild}>
            <strong>{contactUsSection.emailus}</strong>
          </div>
          <div className={styles.contactSectionChild}>
            <span>
              <a aria-label={Strings.contactEmail} href={`mailto:${Strings.contactEmail}`}>
                {Strings.contactEmail}
              </a>
            </span>
          </div>
          <div className={styles.contactSectionChild}>
            <strong>{contactUsSection.writeToUs}</strong>
          </div>
          <div className={styles.contactSectionChild}>
            <p className={styles.addressLine}>{Strings.companyInfo.name}</p>
            <p className={styles.addressLine}>{Strings.companyInfo.address1}</p>
            <p
              className={styles.addressLine}
            >{`${Strings.companyInfo.city}, ${Strings.companyInfo.stateAbbr} ${Strings.companyInfo.zip}`}</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TermsAndConditions
