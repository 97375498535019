// @flow
import * as React from 'react'

import styles from './styles.scss'
import jsStyles from './styles'

type TabsProps = {|
  tabs: Array<{|
    id: string,
    name: string,
  |}>,
  activeTabId: string,
  onTabChange: (activeTabId: string) => void,
|}

export class Tabs extends React.Component<TabsProps> {
  handleTabChange = (id: string) => {
    const { onTabChange } = this.props

    onTabChange(id)
  }

  render() {
    const { tabs, activeTabId, onTabChange } = this.props

    return (
      <div className={styles.tabs}>
        {tabs.map(({ id, name }) => (
          <button
            key={id}
            type="button"
            css={jsStyles.button(activeTabId === id)}
            onClick={() => onTabChange(id)}
          >
            {name}
          </button>
        ))}
      </div>
    )
  }
}

export default Tabs
