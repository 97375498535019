// @flow

import * as React from 'react'
import { classnames } from '@/helpers'
import styles from './styles.scss'
import jsStyles from './styles'

export type InputProps = {|
  ariaLabel?: string,
  placeholder: ?string,
  error?: string | React.Node | boolean,
  subtext?: string | Array<string | ?React$Node>,
  success?: boolean | string,
  onChange: (value: string) => void,
  clean?: ?(value: string) => void,
  required?: boolean,
  disabled?: boolean,
  value?: ?string,
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
    | 'name',
  name: string,
  onFocus?: ?() => void,
  onBlur?: ?() => void,
  visible?: boolean,
  dropdown?: ?React.Node,
  className?: ?string,
  label?: string,
  testId: string,
|}

type InputState = {||}

class Input extends React.Component<InputProps, InputState> {
  inputRef: ?HTMLInputElement

  static defaultProps = {
    ariaLabel: '',
    type: 'text',
    visible: false,
    error: false,
    subtext: '',
    success: false,
    clean: null,
    onFocus: null,
    onBlur: null,
    className: null,
    label: '',
    required: false,
    disabled: false,
    dropdown: null,
    value: undefined,
    testId: '',
  }

  onFocus = () => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus()
    }
  }

  onBlur = () => {
    const { onBlur } = this.props
    if (onBlur) {
      onBlur()
    }
  }

  onChange = (e: DOMEvent) => {
    e.persist()
    const { onChange, clean } = this.props
    let { value } = e.target
    if (clean && value) {
      value = clean(value)
    }
    onChange(value || '')
  }

  focus = () => {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  renderError = () => {
    const { error } = this.props
    if (error && typeof error !== 'boolean') {
      return error
    }
    return null
  }

  renderSubtextAndErrors = () => {
    const { subtext } = this.props

    if (subtext && !this.renderError()) {
      return <span className={styles.subtext}>{subtext}</span>
    }

    if (subtext && this.renderError()) {
      return <span className={classnames(styles.subtext, styles.subtextError)}>{subtext}</span>
    }

    if (!subtext) {
      return <span className={styles.errorState}>{this.renderError()}</span>
    }

    return null
  }

  renderSuccessText = () => {
    const { success } = this.props
    if (typeof success === 'string') {
      return <span className={classnames(styles.subtext, styles.subtextSuccess)}>{success}</span>
    }
    return null
  }

  renderDropdown = () => {
    const { visible, dropdown } = this.props
    if (visible && dropdown) {
      return dropdown
    }
    return null
  }

  render() {
    const { className, label, name, ariaLabel, testId, error, success, ...rest } = this.props
    // Delete unused non DOM props
    delete rest.visible
    return (
      <div>
        <label id={label} className={styles.inputLabel} htmlFor={name}>
          {label}
        </label>
        <div className={styles.inputContainer}>
          <input
            ref={ref => {
              this.inputRef = ref
            }}
            id={name}
            name={name}
            className={classnames(styles.baseInput, className)}
            css={jsStyles.input({ error, success })}
            aria-label={ariaLabel}
            {...rest}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            data-testid={testId}
          />
          {this.renderDropdown()}
          {this.renderSubtextAndErrors()}
          {this.renderSuccessText()}
        </div>
      </div>
    )
  }
}

export default Input
