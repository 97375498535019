// @flow

import React from 'react'
import { Pie } from 'react-chartjs-2'
import { isEqual } from 'lodash'
import 'chartjs-plugin-style'
import {piecewise, interpolateRgb} from 'd3-interpolate'

import styles from './styles.scss'
import { formatPrice } from '@/helpers'

type IDFValueChartDataset = {|
  label: String,
  data: Array<Number>,
  backgroundColor: Array<String>,
  borderColor: Array<String>,
  borderWidth: Number,
|}

type IDFValueChartProps = {|
  data: Array<TableFormats>,
|}

type IDFValueChartState = {|
  labels: Array<String>,
  datasets: Array<IDFValueChartDataset>,
|}

function generateChartColors(count) {
  const colors = [];
  const interpolation = piecewise(interpolateRgb, [
    "#dc9e1d",
    "#5bad5f",
    "#0077b5",
    "#e12b2b",
    "#495357",
    "#262626",
  ])
  for(let i = 0; i < count; i++) {
    const int = interpolation(i / count);
    colors.push(int);
  }
  return colors || [];
}

const chartOptions = {
  tooltips: {
    callbacks: {
      label(tooltipItem, data) {
        return data.labels[tooltipItem.index]
      },
    },
  },
  legend: {
    position: 'bottom',
    onClick: e => e.stopPropagation(),
  },
}

export default class IDFValueChart extends React.Component<IDFValueChartProps, IDFValueChartState> {
  constructor(props: IDFValueChartProps) {
    super(props)
    this.state = {
      labels: [],
      datasets: [],
    }
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevProps: IDFValueChartProps) {
    const { data } = this.props
    if (!isEqual(prevProps.data, data)) {
      this.setChartData()
    }
  }

  setChartData() {
    const { data } = this.props
    const positiveValuesData = data.map(e => ({
      ...e,
      ending_balance: e.statement_ending_balance < 0 ? 0.1 : e.statement_ending_balance,
      // ending_balance is used for the chart, to hide negative values at the chart, statement_ending_balance is used for the tooltip, to keep the negative values visible
    }))

    const endingBalancesSum = positiveValuesData.reduce((acc, item) => acc + item.ending_balance, 0);

    const labelsList = positiveValuesData.map(
      (item) => {
        const percentFromTotal = (item.ending_balance / endingBalancesSum * 100).toFixed(2);
        return `${item.product_name} - ${formatPrice(item.statement_ending_balance)} (${percentFromTotal}%)`;
      },
    )
    const dataset = {
      label: 'IDF Value',
      data: positiveValuesData.map(item => item.ending_balance),
      backgroundColor: generateChartColors(positiveValuesData.length),
      borderWidth: 1,
    }

    this.setState({
      labels: labelsList,
      datasets: [dataset],
    })
  }

  render() {
    const { labels, datasets } = this.state
    const chartData = {
      labels,
      datasets,
    }
    if (datasets.length && labels.length) {
      return (
        <div className={styles.idfChartWrapper}>
          <Pie data={chartData} options={chartOptions} />
        </div>
      )
    }
    return null
  }
}
