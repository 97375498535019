// @flow
import * as Sentry from '@sentry/browser'
import { call, delay, put, select, take, takeEvery, takeLatest, cancel } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import ApiService from '@/services/ApiService'
import selectors from '@/selectors'
import { ACTIONS, ErrorKeys } from '@/redux/api'
import { ActionCreators } from '@/redux'
import { Strings } from '@/constants'
import { ACTIONS as USER_ACTIONS } from '@/redux/user'

const consoleError = error => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error) // eslint-disable-line no-console
  } else {
    Sentry.captureException(error)
  }
}

function* executeRequest(api: typeof ApiService, networkRequest): Array<Object> {
  const { url, data } = networkRequest
  let response = null
  let error = null
  try {
    // $FlowFixMe
    response = yield call(api[url], data)
    if (response.data.errors) {
      throw response.data.errors
    }
  } catch (e) {
    error = e
    consoleError(error)
  }
  // $FlowFixMe
  return [response, error]
}

function* makeRequest(action: { type: string, payload: ApiPayloadType<*> }): GeneratorType {
  const {
    storeName,
    successReducerKey,
    errorReducerKey,
    url,
    preSendReducerKey,
    data,
    nextUrl,
    _meta,
  } = action.payload
  let { pollPayloadCount } = action.payload
  const errorKey = `${url}Error`
  const pendingKey = `${url}Pending`
  const metaKey = `${url}Meta`
  try {
    yield put(
      ActionCreators.api.startRequest.dispatch({
        [errorKey]: null,
        error: null,
        [pendingKey]: true,
        [metaKey]: _meta,
      }),
    )
    if (preSendReducerKey) {
      yield put(ActionCreators[storeName][preSendReducerKey].dispatch(data))
    }
    let [resp, err] = yield call(executeRequest, ApiService, action.payload)
    if (err && pollPayloadCount && err?.response?.status !== 500) {
      while (err && err?.response?.status !== 500 && pollPayloadCount) {
        yield delay(3000)
        ;[resp, err] = yield call(executeRequest, ApiService, action.payload)
        pollPayloadCount -= 1
      }
    }

    if (err?.response?.status === 500) {
      // Internal server error stop polling
      pollPayloadCount = 0
    }
    if (err?.response?.status === 503) {
      // Heroku timeout try again
      yield delay(10000)
      yield makeRequest(action)
    } else {
      let error = null

      if (err?.response?.data) {
        // It wasn't a timeout error so something actually went wrong
        const errText = err.response.data.error || err.response.data.message
        const apiValidationErrors = {};
        Object.entries(err.response.data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            apiValidationErrors[key] = value;
          }
        });
        error = {
          statusCode: err.response.status,
          message: { message: errText },
          ...apiValidationErrors
        }

      } else if (err) {
        error = {
          statusCode: 504,
          message: Strings.genericServerError,
        }
      }
      // Only dispatch error if we are not polling
      if (!pollPayloadCount) {
        yield put(
          ActionCreators.api.endRequest.dispatch({
            [errorKey]: error,
            [pendingKey]: false,
            [metaKey]: null,
          }),
        )
      }
    }

    if (err?.response?.status === 401) {
      yield put({ type: USER_ACTIONS.LOGOUT_CURRENT_USER })
    }

    if (resp) {
      // Setup all the successful response
      if (successReducerKey) {
        if (ActionCreators[storeName][successReducerKey] === undefined) {
          if (process.env.NODE_ENV !== 'production') {
            console.error(`${successReducerKey} reducer key is undefined.`) // eslint-disable-line no-console
          }
        }
        let { data: respData } = resp
        if (_meta) {
          respData = { ...respData, _meta }
        }
        yield put(ActionCreators[storeName][successReducerKey].dispatch(respData))
        yield put(
          ActionCreators.api.endRequest.dispatch({
            error: null,
            [pendingKey]: false,
            [metaKey]: null,
          }),
        )
      }
      if (!err && nextUrl) {
        yield put(push(nextUrl))
      }
    }
  } catch (e) {
    consoleError(e)
    // Failure
    yield put(
      ActionCreators.api.endRequest.dispatch({ error: e, [pendingKey]: false, [metaKey]: null }),
    )
  } finally {
    // Check if we set an error and there is an error dispatch key
    const err = yield select(selectors.api.getErrorObj, ErrorKeys[errorKey])
    if (err && errorReducerKey) {
      if (ActionCreators[storeName][errorReducerKey] === undefined) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(`${errorReducerKey} reducer key is undefined.`) // eslint-disable-line no-console
        }
      }
      yield put(ActionCreators[storeName][errorReducerKey].dispatch())
    }
  }
}

function* makeLatestRequest(): GeneratorType {
  while (true) {
    const task = yield takeEvery(ACTIONS.MAKE_EVERY_REQUEST, makeRequest)
    yield take(ACTIONS.STOP_POLLING)
    yield cancel(task)
  }
}

function* makeEveryRequest(): GeneratorType {
  while (true) {
    const task = yield takeLatest(ACTIONS.MAKE_LATEST_REQUEST, makeRequest)
    yield take(ACTIONS.STOP_POLLING)
    yield cancel(task)
  }
}

export { makeRequest, makeEveryRequest, makeLatestRequest }
export default makeRequest
