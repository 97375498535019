// @flow
import React from 'react'
import { Strings } from '@/constants'
import styles from './styles.scss'

type NoDataProps = {|
  type: string,
  isTable?: boolean,
  tableWidth?: number,
|}

const NoData = (props: NoDataProps) => {
  const { type, isTable, tableWidth } = props
  const containerClass = isTable ? styles.noDataContainerTable : styles.noDataContainer
  let widthStyle = null
  if (isTable && tableWidth && tableWidth > 0) {
    widthStyle = { width: tableWidth }
  }

  return (
    <div className={containerClass} style={widthStyle}>
      <div className={styles.noDataContent}>
        <p>{Strings.noData(type)}</p>
        <p>
          {Strings.noDataQuestions.line1(type)}
          <a href={`mailto:${Strings.noDataQuestions.link.url}`}>
            {Strings.noDataQuestions.link.text}
          </a>
          {Strings.noDataQuestions.line2}
        </p>
      </div>
    </div>
  )
}

NoData.defaultProps = {
  type: Strings.noDataType.policies,
  isTable: false,
  tableWidth: 0,
}

export default NoData
