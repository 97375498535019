// @flow

import React from 'react'
import { connect } from 'react-redux'

import { Strings } from '@/constants'
import selectors from '@/selectors'
import { ActionCreators } from '@/redux'
import { SessionStatus } from '@/models'
import Access from '@/constants/accessibility'

import styles from './styles.scss'
import { PrimaryButton, Loading, Checkbox, ConfirmationModal } from '@/components'
import { UiTable, UiTableRow, UiTableCell } from '../ui-table'

type SettingsActiveSessionsTabState = {|
  selectedSessions: Array<number>,
  bulkLogoutLoading: boolean,
  showConfirmationModal: boolean,
|}

type StateToPropsType = {|
  sessions: ?Array<SessionStatus>,
|}

type DispatchProps = {|
  loadSessionsList: () => void,
  endSessions: (payload: ApiPayloadType<any>) => void,
|}

type SettingsActiveSessionsTabProps = {|
  ...StateToPropsType,
  ...DispatchProps,
|}

const tableHeaders = [
  {
    label: Strings.settings.activeSessions.headers.device,
  },
  {
    label: Strings.settings.activeSessions.headers.ip,
  },
  {
    label: Strings.settings.activeSessions.headers.location,
  },
  {
    label: Strings.settings.activeSessions.headers.started,
  },
  {
    label: Strings.settings.activeSessions.headers.expires,
  },
  {
    label: Strings.settings.activeSessions.headers.action,
    align: 'right',
  },
]

class SettingsActiveSessionsTab extends React.Component<
  SettingsActiveSessionsTabProps,
  SettingsActiveSessionsTabState,
> {
  state = {
    selectedSessions: [],
    bulkLogoutLoading: false,
    showConfirmationModal: false,
  }

  componentDidMount() {
    const { loadSessionsList } = this.props
    loadSessionsList()
  }

  openConfirmationModal = () => {
    this.setState({ showConfirmationModal: true })
  }

  closeConfirmationModal = () => {
    this.setState({ showConfirmationModal: false })
  }

  isSessionChecked = (id: number) => {
    const { selectedSessions } = this.state
    return selectedSessions.includes(id)
  }

  toggleSession = (id: number) => {
    const { selectedSessions } = this.state
    if (selectedSessions.includes(id)) {
      this.setState({
        selectedSessions: selectedSessions.filter(sessionId => sessionId !== id),
      })
    } else {
      this.setState({
        selectedSessions: [...selectedSessions, id],
      })
    }
  }

  logoutSelectedSessions = async () => {
    const { selectedSessions } = this.state
    const { endSessions } = this.props
    try {
      this.setState({ bulkLogoutLoading: true })
      await endSessions(selectedSessions)
      this.setState({ selectedSessions: [], bulkLogoutLoading: false })
      this.closeConfirmationModal()
    } catch (e) {
      this.setState({ bulkLogoutLoading: false })
    }
  }

  renderLogoutConfirmationSessionsList = () => {
    const { selectedSessions } = this.state
    const { sessions } = this.props
    const sessionsToLogout = sessions.filter(session => selectedSessions.includes(session.id))
    return (
      sessionsToLogout && (
        <table>
          <tbody>
            {sessionsToLogout.map(session => (
              <tr key={session.id} className={styles.logoutConfirmationRow}>
                <td>{session.device}</td>
                <td>{session.ip_address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    )
  }

  renderActions = () => {
    const { selectedSessions } = this.state
    return (
      <div className={styles.actionsRow}>
        <PrimaryButton
          type="submit"
          ariaLabel={Access.Components.ActiveSessionsModal.LogOut}
          onClick={this.openConfirmationModal}
          title={Strings.settings.activeSessions.logoutButton}
          disabled={selectedSessions.length === 0}
        />
      </div>
    )
  }

  renderSessionsList() {
    const { sessions } = this.props
    return sessions && sessions.map(session => this.renderSessionRow(session))
  }

  renderSessionRow(session: SessionStatus) {
    return (
      <UiTableRow key={session.id} isActive={session.is_current}>
        <UiTableCell>
          {session.device}
          {session.is_current && (
            <span className={styles.currentDeviceTip}>
              {Strings.settings.activeSessions.currentDevice}
            </span>
          )}
        </UiTableCell>
        <UiTableCell>{session.ip_address}</UiTableCell>
        <UiTableCell>{session.location}</UiTableCell>
        <UiTableCell>{session.createdDateFormatted}</UiTableCell>
        <UiTableCell>{session.expiresDateFormatted}</UiTableCell>
        <UiTableCell align="right">
          {!session.is_current && (
            <Checkbox
              ariaLabel={Strings.settings.activeSessions.logoutButton}
              label={Strings.settings.activeSessions.logoutButton}
              name={`${session.id}`}
              checked={this.isSessionChecked(session.id)}
              id={`${session.id}`}
              inlineBlock
              filled
              onChange={() => this.toggleSession(session.id)}
            />
          )}
        </UiTableCell>
      </UiTableRow>
    )
  }

  render() {
    const { sessions } = this.props
    const { bulkLogoutLoading, showConfirmationModal } = this.state
    return (
      <>
        {showConfirmationModal && (
          <ConfirmationModal
            title={Strings.settings.activeSessions.logoutConfirmation}
            loading={bulkLogoutLoading}
            onClose={this.closeConfirmationModal}
            onConfirm={this.logoutSelectedSessions}
          >
            {this.renderLogoutConfirmationSessionsList()}
          </ConfirmationModal>
        )}
        <div className={styles.sessionsListWrapper}>
          <div>
            <h4 className={styles.sessionsListTitle}>{Strings.settings.activeSessions.title}</h4>
            <div>
              {!sessions || sessions.length === 0 || bulkLogoutLoading ? (
                <Loading />
              ) : (
                <UiTable headers={tableHeaders}>{this.renderSessionsList()}</UiTable>
              )}
            </div>
          </div>
          {this.renderActions()}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  sessions: selectors.sessions.getSessionsList(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadSessionsList: payload =>
    dispatch(ActionCreators.sessions.loadCurrentUserSessionsList.dispatch(payload)),
  endSessions: payload => dispatch(ActionCreators.sessions.endSessions.dispatch(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsActiveSessionsTab)
