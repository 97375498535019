// @flow

import * as React from 'react'
import { Link } from 'react-router-dom'
import history from '@/helpers/history'
import { classnames } from '@/helpers'
import styles from './styles.scss'

type AnchorProps = {|
  ariaLabel: string,
  disabled?: boolean,
  label?: ?string,
  path?: string,
  children?: ?Array<React.Node> | React.Node,
  className?: string,
  onClick?: ?(event: SyntheticEvent<HTMLAnchorElement>) => void,
|}

const Anchor = (props: AnchorProps) => {
  const { ariaLabel, label, path, children, className, disabled, onClick } = props
  if (disabled) {
    return <span className={classnames(className, styles.disabledLink)}>{label || children}</span>
  }
  return (
    <Link
      aria-label={ariaLabel}
      className={classnames(className, styles.anchor)}
      to={path}
      onClick={onClick}
    >
      {label || children}
    </Link>
  )
}

Anchor.defaultProps = {
  disabled: false,
  label: '',
  path: history.location.pathname,
  children: null,
  className: styles.anchor,
  onClick: null,
}

const NavigationAnchor = (props: AnchorProps) => {
  const { className } = props
  return <Anchor {...props} className={classnames(styles.anchor, styles.navigation, className)} />
}
const TableAnchor = (props: AnchorProps) => {
  const { className } = props
  return <Anchor {...props} className={classnames(styles.anchor, styles.table, className)} />
}
const FooterAnchor = (props: AnchorProps) => {
  const { className } = props
  return <Anchor {...props} className={classnames(styles.footer, className)} />
}

NavigationAnchor.defaultProps = Anchor.defaultProps
TableAnchor.defaultProps = Anchor.defaultProps
FooterAnchor.defaultProps = Anchor.defaultProps

export { Anchor, NavigationAnchor, TableAnchor, FooterAnchor }
