// @flow
import BaseModel from './base/base-model'
import { defaultString } from './defaults'

export type IndexAccountRatesDataType = {|
  id: ?string,
  filename: ?string,
  date: ?string,
  uploaded_file: ?string,
  policy_id: ?string,
  url: ?string,
|}

export default class IndexAccountRates extends BaseModel {
  id: string

  filename: string

  date: string

  uploadedFile: string

  policyId: string

  url: string

  constructor(
    data: IndexAccountRatesDataType = {
      id: null,
      filename: null,
      date: null,
      uploaded_file: null,
      policy_id: null,
      url: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.filename = defaultString(data.filename)
    this.date = defaultString(data.date)
    this.uploadedFile = defaultString(data.uploaded_file)
    this.policyId = defaultString(data.policy_id)
    this.url = defaultString(data.url)
  }
}
