// @flow
import { Client, User } from '@/models'

const getUserStore = (store: StoreState): UserStoreState => store.user

const getUser = (store: StoreState): ?User => getUserStore(store).user

const getClients = (store: StoreState): { [string]: Client } => getUserStore(store).clients

const getActiveClient = (store: StoreState): ?User | Client => {
  const user = getUser(store)
  const clients = getClients(store)
  const activeClientId = getUserStore(store).activeClient
  if (user?.id === activeClientId || activeClientId === null) {
    return user
  }
  if (clients && activeClientId && clients[activeClientId]) {
    return clients[activeClientId]
  }
  return null
}

const getSortedClients = (store: StoreState): Array<Client> => {
  const clients = getClients(store)

  if (!clients) {
    return []
  }

  const clientsArr = Object.values(clients).sort((a: any, b: any) => {
    if (a?.lastName > b?.lastName) {
      return 1
    }
    if (a?.lastName < b?.lastName) {
      return -1
    }
    if (a?.lastName === b?.lastName) {
      if (a?.firstName > b?.firstName) {
        return 1
      }
      if (a?.firstName < b?.firstName) {
        return -1
      }
    }
    return 0
  })

  // $FlowFixMe
  return clientsArr
}

const getLastTwo = (store: StoreState): string => getUserStore(store).last_two

const getDisabled = (store: StoreState): boolean => !!getUserStore(store)?.user_disabled

export default {
  getUser,
  getLastTwo,
  getClients,
  getActiveClient,
  getSortedClients,
  getDisabled,
}
