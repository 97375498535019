// @flow

import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Access from '@/constants/accessibility'
import TestIds from '@/constants/accessibility/test-ids'
import { Images, Strings } from '@/constants'
import jsStyles from './styles'
import styles from './styles.scss'

type IconProps = {|
  ariaLabel?: string,
  imgSrc?: string,
  caption?: string,
  size?: 'md' | 'lg',
  onClick: (e: SyntheticMouseEvent<>) => void,
  pending?: boolean, // eslint-disable-line react/no-unused-prop-types
  disabled?: boolean, // eslint-disable-line react/no-unused-prop-types
  testId: string,
  highlighted?: boolean,
|}

type IconWrapperProps = {|
  ariaLabel?: string,
  disabled?: boolean, // eslint-disable-line react/no-unused-prop-types
  onClick: (e: SyntheticMouseEvent<>) => void,
  pending?: boolean,
|}

type PDFIconProps = {|
  ...IconWrapperProps,
  caption?: string,
  highlighted?: boolean,
|}

const defaultProps = {
  ariaLabel: '',
  imgSrc: '',
  caption: '',
  size: 'md',
  pending: false,
  disabled: false,
}

const defaultWrapperProps = {
  ariaLabel: '',
  disabled: false,
  pending: false,
}

const defaultPDFIconProps = {
  ...defaultWrapperProps,
  caption: '',
  pending: false,
  highlighted: false,
}

const StyledIconButton = styled.button`
  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `}
`

const StyledIcon = styled.img`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
    `}
`
const StyledIconLabel = styled.span`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
    `}
`

const Icon = (props: IconProps) => {
  const {
    ariaLabel,
    disabled,
    imgSrc,
    caption,
    onClick,
    size = 'md',
    pending,
    testId,
    highlighted,
  } = props
  const clickHandler = disabled ? () => {} : onClick
  return (
    <StyledIconButton
      className={styles.container}
      css={jsStyles.highlight(highlighted)}
      type="button"
      onClick={clickHandler}
      aria-label={ariaLabel}
      disabled={disabled || pending}
      data-testid={testId}
    >
      <StyledIcon
        className={styles.icon}
        src={imgSrc}
        alt={caption}
        css={jsStyles.icon(size)}
        disabled={disabled && !pending}
      />
      <StyledIconLabel
        className={styles.caption}
        css={jsStyles.caption(size)}
        disabled={disabled && !pending}
      >
        {caption}
      </StyledIconLabel>
    </StyledIconButton>
  )
}

const DownloadIcon = ({ ariaLabel, onClick, ...rest }: IconWrapperProps) => (
  <Icon
    ariaLabel={ariaLabel || Access.Components.Icon.DownloadIcon}
    onClick={onClick}
    imgSrc={Images.download}
    caption={Strings.downloadToExcel}
    {...rest}
    testId={TestIds.downloadIcon}
  />
)

const PrintIcon = ({ onClick, pending, ...rest }: IconWrapperProps) => {
  const imgSrc = pending ? Images.spinner : Images.print
  return (
    <Icon
      onClick={onClick}
      imgSrc={imgSrc}
      caption={Strings.print}
      pending={pending}
      {...rest}
      testId={TestIds.printIcon}
    />
  )
}

const IdfReportIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon onClick={onClick} imgSrc={Images.factsheet} {...rest} testId={TestIds.pdfIcon} />
)

const PDFIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon
    onClick={onClick}
    imgSrc={Images.pdf}
    caption={caption || Strings.downloadPDF}
    {...rest}
    size="lg"
    testId={TestIds.pdfIcon}
  />
)

const ExcelIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon
    onClick={onClick}
    imgSrc={Images.excel}
    caption={caption || Strings.downloadPDF}
    {...rest}
    size="lg"
    testId={TestIds.pdfIcon}
  />
)
const PPTIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon
    onClick={onClick}
    imgSrc={Images.ppt}
    caption={caption || Strings.downloadPDF}
    {...rest}
    size="lg"
    testId={TestIds.pdfIcon}
  />
)
const WordIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon
    onClick={onClick}
    imgSrc={Images.word}
    caption={caption || Strings.downloadPDF}
    {...rest}
    size="lg"
    testId={TestIds.pdfIcon}
  />
)
const ImageIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon
    onClick={onClick}
    imgSrc={Images.image}
    caption={caption || Strings.downloadPDF}
    {...rest}
    size="lg"
    testId={TestIds.pdfIcon}
  />
)
const OtherDocIcon = ({ caption, onClick, ...rest }: PDFIconProps) => (
  <Icon
    onClick={onClick}
    imgSrc={Images.other}
    caption={caption || Strings.downloadPDF}
    {...rest}
    size="lg"
    testId={TestIds.pdfIcon}
  />
)

Icon.defaultProps = defaultProps
DownloadIcon.defaultProps = defaultWrapperProps
PrintIcon.defaultProps = defaultWrapperProps
PDFIcon.defaultProps = defaultPDFIconProps
ExcelIcon.defaultProps = defaultWrapperProps
WordIcon.defaultProps = defaultWrapperProps
ImageIcon.defaultProps = defaultWrapperProps
OtherDocIcon.defaultProps = defaultWrapperProps

export {
  DownloadIcon,
  PrintIcon,
  PDFIcon,
  IdfReportIcon,
  ExcelIcon,
  WordIcon,
  PPTIcon,
  ImageIcon,
  OtherDocIcon,
}
