// @flow
import { merge } from 'lodash'
import moment from 'moment'
import { camelObjectToSnakeObject } from '@/helpers'
import { Enums } from '@/constants'
import { IDF } from '@/models'
import BaseModel from '../base/base-model'
import { defaultFloat, defaultString, makeDate, defaultEnum } from '../defaults'

export type IDFTransactionDataType = {|
  id?: ?string,
  date?: ?string,
  transaction_type?: ?string,
  transaction_amount?: ?number,
|}

const defaultDataValues = {
  id: null,
  date: null,
  transaction_type: null,
  transaction_amount: null,
}

export default class IDFTransaction extends BaseModel {
  id: string

  date: string

  description: string

  idf: IDF

  transactionAmount: number

  transactionType: string

  constructor(rawData: IDFTransaction | IDFTransactionDataType = defaultDataValues) {
    super()
    const data: IDFTransactionDataType =
      rawData instanceof IDFTransaction
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    this.id = defaultString(data.id)
    this.date = defaultString(data.date)
    this.transactionType = defaultEnum(data.transaction_type, Enums.TransactionType)
    this.transactionAmount = defaultFloat(data.transaction_amount)
  }

  get dateFormat(): string {
    return defaultString(moment(this.date).format('MM/DD/YYYY'))
  }

  get deposits(): number {
    if (this.transactionAmount > 0) {
      return defaultFloat(this.transactionAmount)
    }
    return 0
  }

  get withdrawals(): number {
    if (this.transactionAmount < 0) {
      return defaultFloat(this.transactionAmount)
    }
    return 0
  }

  get transactionDate(): Date {
    return makeDate(this.date)
  }
}
