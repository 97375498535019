/* eslint-disable max-lines */
// @flow
import { isEmpty, isEqual } from 'lodash'
import * as Sentry from '@sentry/browser'
import { Strings } from '@/constants'
import { makeDate } from '@/models/defaults'
import { PPAStatement, PPLIStatement, VULStatement, XIRRRate } from '@/models'
import type { PPStatementModelKeys } from '@/models'
import { xirrTotal } from '@/helpers/financial'

export type AccountDetailTableRow = {
  isBold?: boolean,
  isPercentage?: boolean,
  boldRow?: boolean,
  showDollarRow?: boolean,
  underlineRow?: boolean,
  showLine?: boolean,
  account_detail_name?: string,
  account_detail_month?: string | number,
  account_detail_year_to_date?: string | number,
  account_detail_since_inception?: string | number,
  product_name?: string,
  beginning_balance?: number,
  deposits_withdrawals?: number,
}

const sumFieldOnStatements = (
  fieldName: PPStatementModelKeys,
  statements: Array<PPStatementType>,
) => statements.reduce((acc, cur) => acc + cur[fieldName], 0)

const getFirstStatements = (allStatements: Array<*>) => {
  allStatements.sort((a, b) => a.statementStartDate - b.statementStartDate)
  const firstStatementsObj = allStatements.reduce((acc: PPStatementType, curr: PPStatementType) => {
    const statement = acc[curr.policyId]
    if (statement && curr.statementStartDate <= statement.statementStartDate) {
      acc[curr.policyId] = curr
    } else if (acc[curr.policyId] === undefined) {
      acc[curr.policyId] = curr
    }
    return acc
  }, {})
  const firstStatements = Object.values(firstStatementsObj)
  return firstStatements
}

const getLastStatements = (allStatements: Array<*>) => {
  allStatements.sort((a, b) => a.statementEndDate - b.statementEndDate)
  const lastStatementsObj = allStatements.reduce((acc: PPStatementType, curr: PPStatementType) => {
    const statement = acc[curr.policyId]
    if (statement && curr.statementEndDate >= statement.statementEndDate) {
      acc[curr.policyId] = curr
    } else if (acc[curr.policyId] === undefined) {
      acc[curr.policyId] = curr
    }
    return acc
  }, {})
  const lastStatements = Object.values(lastStatementsObj)
  return lastStatements
}

export const transformPPAStatementData = (
  allStatementsUnfiltered: Array<PPAStatement>,
  argMonth: ?string,
  xirrRates: XIRRRate | Object,
): Array<TableFormats> => {
  if (!allStatementsUnfiltered.length) {
    return { tableRows: [] }
  }
  const month = argMonth || ''
  const currentDate = makeDate(month)
  const currentYear = currentDate.getFullYear()

  const allStatements = allStatementsUnfiltered.filter(
    (statement: PPAStatement) => statement.statementStartDate <= currentDate,
  )

  const yearStatements = allStatements.filter(
    (statement: PPAStatement) => statement.statementYear === currentYear,
  )

  const monthStatements = yearStatements.filter(
    (statement: PPAStatement) => statement.statementStart === month,
  )

  const sinceInception = allStatements.sort((a, b) => a.statementStartDate - b.statementStartDate)
  const firstMonthStatements = yearStatements.reduce(
    (acc: Array<PPAStatement>, curr: PPAStatement) => {
      if (curr.statementStartMonth <= acc[0]?.statementStartMonth || acc[0] === undefined) {
        if (curr.statementStartMonth === acc[0]?.statementStartMonth) {
          acc.push(curr)
          return acc
        }
        return [curr]
      }
      return acc
    },
    [],
  )

  const firstStatements = getFirstStatements(allStatements)

  const lastStatements = getLastStatements(monthStatements)

  const beginningBalanceGross = sumFieldOnStatements('beginningBalanceGross', lastStatements)
  const beginningBalanceGrossYTD = sumFieldOnStatements(
    'beginningBalanceGross',
    firstMonthStatements,
  )
  const beginningBalanceGrossInception = sumFieldOnStatements(
    'beginningBalanceGross',
    firstStatements,
  )

  const endingBalance = sumFieldOnStatements('policyGrossAccountValue', lastStatements)

  const policyAccountDeposits = sumFieldOnStatements('policyAccountDeposits', lastStatements)
  const policyAccountDepositsYTD = sumFieldOnStatements('policyAccountDeposits', yearStatements)
  const policyAccountDepositsInception = sumFieldOnStatements(
    'policyAccountDeposits',
    sinceInception,
  )

  const policyAccountWithdrawals = sumFieldOnStatements('policyAccountWithdrawals', lastStatements)
  const policyAccountWithdrawalsYTD = sumFieldOnStatements(
    'policyAccountWithdrawals',
    yearStatements,
  )
  const policyAccountWithdrawalsInception = sumFieldOnStatements(
    'policyAccountWithdrawals',
    sinceInception,
  )

  const policyMAndEFee = sumFieldOnStatements('policyMAndEFee', lastStatements)
  const policyMAndEFeeYTD = sumFieldOnStatements('policyMAndEFee', yearStatements)
  const policyMAndEFeeInception = sumFieldOnStatements('policyMAndEFee', sinceInception)

  const idfReturnValue =
    endingBalance -
    (beginningBalanceGross + policyAccountDeposits + policyAccountWithdrawals + policyMAndEFee)
  const idfReturnValueYTD =
    endingBalance -
    (beginningBalanceGrossYTD +
      policyAccountDepositsYTD +
      policyAccountWithdrawalsYTD +
      policyMAndEFeeYTD)
  const idfReturnValueInception =
    endingBalance -
    (beginningBalanceGrossInception +
      policyAccountDepositsInception +
      policyAccountWithdrawalsInception +
      policyMAndEFeeInception)

  // TODO: To be removed after verification phase
  const debug = {
    backend: { ...xirrRates },
    frontend: {
      month: xirrTotal(monthStatements),
      year: xirrTotal(yearStatements),
      inception: xirrTotal(sinceInception),
    },
  }

  if (
    !isEmpty(debug.backend) &&
    !isEmpty(debug.frontend) &&
    !isEqual(debug.backend, debug.frontend)
  ) {
    // eslint-disable-next-line no-console
    console.log('[DEBUG] Discrepancy in XIRR calculation for Account Detail: ', debug)

    Sentry.captureMessage('Discrepancy in XIRR calculation for Account Detail', {
      extra: { debug },
    })
  }

  return {
    tableRows: [
      {
        isBold: true,
        account_detail_name: Strings.table.ppa.accountValueBeginning,
        account_detail_month: beginningBalanceGross,
        account_detail_year_to_date: beginningBalanceGrossYTD,
        account_detail_since_inception: beginningBalanceGrossInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppa.premiumDeposits,
        account_detail_month: policyAccountDeposits,
        account_detail_year_to_date: policyAccountDepositsYTD,
        account_detail_since_inception: policyAccountDepositsInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppa.policyAccountWithdrawals,
        account_detail_month: policyAccountWithdrawals,
        account_detail_year_to_date: policyAccountWithdrawalsYTD,
        account_detail_since_inception: policyAccountWithdrawalsInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppa.idfNetReturn,
        account_detail_month: idfReturnValue,
        account_detail_year_to_date: idfReturnValueYTD,
        account_detail_since_inception: idfReturnValueInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppa.MEFee,
        account_detail_month: policyMAndEFee,
        account_detail_year_to_date: policyMAndEFeeYTD,
        account_detail_since_inception: policyMAndEFeeInception,
        underlineRow: true,
        showLine: false,
      },
      {
        isBold: true,
        account_detail_name: Strings.table.ppa.accountValueEnding,
        account_detail_month: endingBalance,
        account_detail_year_to_date: endingBalance,
        account_detail_since_inception: endingBalance,
        showLine: false,
      },
      {
        isBold: true,
        isPercentage: true,
        account_detail_name: Strings.table.ppa.accountReturn,
        account_detail_month: xirrRates.month || 0,
        account_detail_year_to_date: xirrRates.year || 0,
        account_detail_since_inception: xirrRates.inception || 0,
        boldRow: true,
        showDollarRow: true,
        showLine: false,
      },
    ],
  }
}

export const transformPPLIStatementData = (
  allStatementsUnfiltered: Array<PPLIStatement>,
  argMonth: ?string,
  xirrRates: XIRRRate | Object,
): Array<TableFormats> => {
  if (!allStatementsUnfiltered.length) {
    return { tableRows: [] }
  }
  const month = argMonth || ''
  const currentDate = makeDate(month)
  const currentYear = currentDate.getFullYear()

  const allStatements = allStatementsUnfiltered.filter(
    (statement: PPLIStatement) => statement.statementStartDate <= currentDate,
  )

  const yearStatements = allStatements.filter(
    (statement: PPLIStatement) => statement.statementYear === currentYear,
  )

  const monthStatements = yearStatements.filter(
    (statement: PPLIStatement) => statement.statementStart === month,
  )

  const sinceInception = allStatements.sort((a, b) => a.statementStartDate - b.statementStartDate)
  const firstMonthStatements = yearStatements.reduce(
    (acc: Array<PPLIStatement>, curr: PPLIStatement) => {
      if (curr.statementStartMonth <= acc[0]?.statementStartMonth || acc[0] === undefined) {
        if (curr.statementStartMonth === acc[0]?.statementStartMonth) {
          acc.push(curr)
          return acc
        }
        return [curr]
      }
      return acc
    },
    [],
  )

  const firstStatements = getFirstStatements(allStatements)

  const lastStatements = getLastStatements(monthStatements)

  const beginningBalanceGross = sumFieldOnStatements('beginningBalanceGross', lastStatements)
  const beginningBalanceGrossYTD = sumFieldOnStatements(
    'beginningBalanceGross',
    firstMonthStatements,
  )
  const beginningBalanceGrossInception = sumFieldOnStatements(
    'beginningBalanceGross',
    firstStatements,
  )

  const policyAccountDeposits = sumFieldOnStatements('policyAccountDeposits', lastStatements)
  const policyAccountDepositsYTD = sumFieldOnStatements('policyAccountDeposits', yearStatements)
  const policyAccountDepositsInception = sumFieldOnStatements(
    'policyAccountDeposits',
    sinceInception,
  )

  const policyAccountWithdrawals = sumFieldOnStatements('policyAccountWithdrawals', lastStatements)
  const policyAccountWithdrawalsYTD = sumFieldOnStatements(
    'policyAccountWithdrawals',
    yearStatements,
  )
  const policyAccountWithdrawalsInception = sumFieldOnStatements(
    'policyAccountWithdrawals',
    sinceInception,
  )

  const policyStructuringFee = sumFieldOnStatements('policyStructuringFee', lastStatements)
  const policyStructuringFeeYTD = sumFieldOnStatements('policyStructuringFee', yearStatements)
  const policyStructuringFeeInception = sumFieldOnStatements('policyStructuringFee', sinceInception)

  const policyPremiumTax = sumFieldOnStatements('policyPremiumTax', lastStatements)
  const policyPremiumTaxYTD = sumFieldOnStatements('policyPremiumTax', yearStatements)
  const policyPremiumTaxInception = sumFieldOnStatements('policyPremiumTax', sinceInception)

  const policyMAndEFee = sumFieldOnStatements('policyMAndEFee', lastStatements)
  const policyMAndEFeeYTD = sumFieldOnStatements('policyMAndEFee', yearStatements)
  const policyMAndEFeeInception = sumFieldOnStatements('policyMAndEFee', sinceInception)

  const policyMortalityCharge = sumFieldOnStatements('policyMortalityCharge', lastStatements)
  const policyMortalityChargeYTD = sumFieldOnStatements('policyMortalityCharge', yearStatements)
  const policyMortalityChargeInception = sumFieldOnStatements(
    'policyMortalityCharge',
    sinceInception,
  )

  const endingBalance = sumFieldOnStatements('policyGrossAccountValue', lastStatements)

  const policyAccountValueNet = sumFieldOnStatements('accountValue', lastStatements)

  const outstandingPolicyLoan = sumFieldOnStatements('policyLoanBalance', lastStatements)

  const insuranceProceeds = sumFieldOnStatements('insuranceProceeds', lastStatements)

  const idfReturnValue =
    endingBalance -
    (beginningBalanceGross +
      policyAccountDeposits +
      policyAccountWithdrawals +
      policyStructuringFee +
      policyPremiumTax +
      policyMAndEFee +
      policyMortalityCharge)
  const idfReturnValueYTD =
    endingBalance -
    (beginningBalanceGrossYTD +
      policyAccountDepositsYTD +
      policyAccountWithdrawalsYTD +
      policyStructuringFeeYTD +
      policyPremiumTaxYTD +
      policyMAndEFeeYTD +
      policyMortalityChargeYTD)
  const idfReturnValueInception =
    endingBalance -
    (beginningBalanceGrossInception +
      policyAccountDepositsInception +
      policyAccountWithdrawalsInception +
      policyStructuringFeeInception +
      policyPremiumTaxInception +
      policyMAndEFeeInception +
      policyMortalityChargeInception)

  // TODO: To be removed after verification phase
  const debug = {
    backend: { ...xirrRates },
    frontend: {
      month: xirrTotal(monthStatements),
      year: xirrTotal(yearStatements),
      inception: xirrTotal(sinceInception),
    },
  }

  if (
    !isEmpty(debug.backend) &&
    !isEmpty(debug.frontend) &&
    !isEqual(debug.backend, debug.frontend)
  ) {
    // eslint-disable-next-line no-console
    console.log('[DEBUG] Discrepancy in XIRR calculation for Account Detail: ', debug)

    Sentry.captureMessage('Discrepancy in XIRR calculation for Account Detail', {
      extra: { debug },
    })
  }

  return {
    tableRows: [
      {
        isBold: true,
        account_detail_name: Strings.table.ppli.accountValueBeginning,
        account_detail_month: beginningBalanceGross,
        account_detail_year_to_date: beginningBalanceGrossYTD, // Starting balance of the year
        account_detail_since_inception: beginningBalanceGrossInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.premiumDeposits,
        account_detail_month: policyAccountDeposits,
        account_detail_year_to_date: policyAccountDepositsYTD,
        account_detail_since_inception: policyAccountDepositsInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.policyAccountWithdrawals,
        account_detail_month: policyAccountWithdrawals,
        account_detail_year_to_date: policyAccountWithdrawalsYTD,
        account_detail_since_inception: policyAccountWithdrawalsInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.structuringFee,
        account_detail_month: policyStructuringFee,
        account_detail_year_to_date: policyStructuringFeeYTD,
        account_detail_since_inception: policyStructuringFeeInception,
      },
      {
        account_detail_name: Strings.table.ppli.premiumTax,
        account_detail_month: policyPremiumTax,
        account_detail_year_to_date: policyPremiumTaxYTD,
        account_detail_since_inception: policyPremiumTaxInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.idfNetReturn,
        account_detail_month: idfReturnValue,
        account_detail_year_to_date: idfReturnValueYTD,
        account_detail_since_inception: idfReturnValueInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.MEFee,
        account_detail_month: policyMAndEFee,
        account_detail_year_to_date: policyMAndEFeeYTD,
        account_detail_since_inception: policyMAndEFeeInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.mortalityCharge,
        account_detail_month: policyMortalityCharge,
        account_detail_year_to_date: policyMortalityChargeYTD,
        account_detail_since_inception: policyMortalityChargeInception,
        showLine: true,
        underlineRow: true,
      },
      {
        isBold: true,
        account_detail_name: Strings.table.ppli.accountValueEnding,
        account_detail_month: endingBalance,
        account_detail_year_to_date: endingBalance,
        account_detail_since_inception: endingBalance,
        showLine: false,
      },
      {
        isBold: true,
        isPercentage: true,
        account_detail_name: Strings.table.ppli.accountReturn,
        account_detail_month: xirrRates.month || 0,
        account_detail_year_to_date: xirrRates.year || 0,
        account_detail_since_inception: xirrRates.inception || 0,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.ppli.outstandingPolicyLoanBalance,
        account_detail_month: outstandingPolicyLoan,
        account_detail_year_to_date: outstandingPolicyLoan,
        account_detail_since_inception: outstandingPolicyLoan,
        showLine: false,
      },
      {
        isBold: true,
        account_detail_name: Strings.table.ppli.accountValueNet,
        account_detail_month: policyAccountValueNet,
        account_detail_year_to_date: policyAccountValueNet,
        account_detail_since_inception: policyAccountValueNet,
        showLine: false,
      },
      {
        isBold: true,
        account_detail_name: Strings.table.ppli.insuranceProceeds,
        account_detail_month: insuranceProceeds,
        account_detail_year_to_date: insuranceProceeds,
        account_detail_since_inception: insuranceProceeds,
        showLine: false,
        boldRow: true,
        showDollarRow: true,
      },
    ],
  }
}

export const transformVULStatementData = (
  allStatementsUnfiltered: Array<VULStatement>,
  argMonth: ?string,
  xirrRates: XIRRRate | Object,
): Array<TableFormats> => {
  if (!allStatementsUnfiltered.length) {
    return { tableRows: [] }
  }
  const month = argMonth || ''
  const currentDate = makeDate(month)
  const currentYear = currentDate.getFullYear()

  const allStatements = allStatementsUnfiltered.filter(
    (statement: VULStatement) => statement.statementStartDate <= currentDate,
  )

  const yearStatements = allStatements.filter(
    (statement: VULStatement) => statement.statementYear === currentYear,
  )

  const monthStatements = yearStatements.filter(
    (statement: VULStatement) => statement.statementStart === month,
  )

  const sinceInception = allStatements.sort((a, b) => a.statementStartDate - b.statementStartDate)

  const firstMonthStatements = yearStatements.reduce(
    (acc: Array<VULStatement>, curr: VULStatement) => {
      if (curr.statementStartMonth <= acc[0]?.statementStartMonth || acc[0] === undefined) {
        if (curr.statementStartMonth === acc[0]?.statementStartMonth) {
          acc.push(curr)
          return acc
        }
        return [curr]
      }
      return acc
    },
    [],
  )

  const firstStatements = getFirstStatements(allStatements)

  const lastStatements = getLastStatements(monthStatements)

  const beginningBalanceGross = sumFieldOnStatements('beginningBalanceGross', lastStatements)
  const beginningBalanceGrossYTD = sumFieldOnStatements(
    'beginningBalanceGross',
    firstMonthStatements,
  )
  const beginningBalanceGrossInception = sumFieldOnStatements(
    'beginningBalanceGross',
    firstStatements,
  )

  const policyAccountDeposits = sumFieldOnStatements('policyAccountDeposits', lastStatements)
  const policyAccountDepositsYTD = sumFieldOnStatements('policyAccountDeposits', yearStatements)
  const policyAccountDepositsInception = sumFieldOnStatements(
    'policyAccountDeposits',
    sinceInception,
  )

  const policyAccountWithdrawals = sumFieldOnStatements('policyAccountWithdrawals', lastStatements)
  const policyAccountWithdrawalsYTD = sumFieldOnStatements(
    'policyAccountWithdrawals',
    yearStatements,
  )
  const policyAccountWithdrawalsInception = sumFieldOnStatements(
    'policyAccountWithdrawals',
    sinceInception,
  )

  const policyLoan = sumFieldOnStatements('policyLoan', lastStatements)
  const policyLoanYTD = sumFieldOnStatements('policyLoan', yearStatements)
  const policyLoanInception = sumFieldOnStatements('policyLoan', sinceInception)

  const premiumBasedCharges = sumFieldOnStatements('premiumBasedCharges', lastStatements)
  const premiumBasedChargesYTD = sumFieldOnStatements('premiumBasedCharges', yearStatements)
  const premiumBasedChargesInception = sumFieldOnStatements('premiumBasedCharges', sinceInception)

  const policyExpenseCharges = sumFieldOnStatements('policyExpenseCharges', lastStatements)
  const policyExpenseChargesYTD = sumFieldOnStatements('policyExpenseCharges', yearStatements)
  const policyExpenseChargesInception = sumFieldOnStatements('policyExpenseCharges', sinceInception)

  const endingBalance = sumFieldOnStatements('policyGrossAccountValue', lastStatements)

  const insuranceProceeds = sumFieldOnStatements('insuranceProceeds', lastStatements)

  const idfReturnValue =
    endingBalance -
    (beginningBalanceGross +
      policyAccountDeposits +
      policyAccountWithdrawals +
      policyLoan +
      premiumBasedCharges +
      policyExpenseCharges)
  const idfReturnValueYTD =
    endingBalance -
    (beginningBalanceGrossYTD +
      policyAccountDepositsYTD +
      policyAccountWithdrawalsYTD +
      policyLoanYTD +
      premiumBasedChargesYTD +
      policyExpenseChargesYTD)
  const idfReturnValueInception =
    endingBalance -
    (beginningBalanceGrossInception +
      policyAccountDepositsInception +
      policyAccountWithdrawalsInception +
      policyLoanInception +
      premiumBasedChargesInception +
      policyExpenseChargesInception)

  // TODO: To be removed after verification phase
  const debug = {
    backend: { ...xirrRates },
    frontend: {
      month: xirrTotal(monthStatements),
      year: xirrTotal(yearStatements),
      inception: xirrTotal(sinceInception),
    },
  }

  if (
    !isEmpty(debug.backend) &&
    !isEmpty(debug.frontend) &&
    !isEqual(debug.backend, debug.frontend)
  ) {
    // eslint-disable-next-line no-console
    console.log('[DEBUG] Discrepancy in XIRR calculation for Account Detail: ', debug)

    Sentry.captureMessage('Discrepancy in XIRR calculation for Account Detail', {
      extra: { debug },
    })
  }

  return {
    tableRows: [
      {
        isBold: true,
        account_detail_name: Strings.table.vul.accountValueBeginning,
        account_detail_month: beginningBalanceGross,
        account_detail_year_to_date: beginningBalanceGrossYTD, // Starting balance of the year
        account_detail_since_inception: beginningBalanceGrossInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.vul.premiumDeposits,
        account_detail_month: policyAccountDeposits,
        account_detail_year_to_date: policyAccountDepositsYTD,
        account_detail_since_inception: policyAccountDepositsInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.vul.premiumBasedCharges,
        account_detail_month: premiumBasedCharges,
        account_detail_year_to_date: premiumBasedChargesYTD,
        account_detail_since_inception: premiumBasedChargesInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.vul.policyAccountWithdrawals,
        account_detail_month: policyAccountWithdrawals,
        account_detail_year_to_date: policyAccountWithdrawalsYTD,
        account_detail_since_inception: policyAccountWithdrawalsInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.vul.policyLoan,
        account_detail_month: policyLoan,
        account_detail_year_to_date: policyLoanYTD,
        account_detail_since_inception: policyLoanInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.vul.netReturn,
        account_detail_month: idfReturnValue,
        account_detail_year_to_date: idfReturnValueYTD,
        account_detail_since_inception: idfReturnValueInception,
        showLine: false,
      },
      {
        account_detail_name: Strings.table.vul.policyExpenseCharges,
        account_detail_month: policyExpenseCharges,
        account_detail_year_to_date: policyExpenseChargesYTD,
        account_detail_since_inception: policyExpenseChargesInception,
        underlineRow: true,
        showLine: false,
      },
      {
        isBold: true,
        account_detail_name: Strings.table.vul.accountValueEnding,
        account_detail_month: endingBalance,
        account_detail_year_to_date: endingBalance,
        account_detail_since_inception: endingBalance,
        showLine: false,
      },
      {
        isBold: true,
        isPercentage: true,
        account_detail_name: Strings.table.vul.accountReturn,
        account_detail_month: xirrRates.month || 0,
        account_detail_year_to_date: xirrRates.year || 0,
        account_detail_since_inception: xirrRates.inception || 0,
        showLine: false,
      },
      {
        isBold: true,
        account_detail_name: Strings.table.vul.insuranceProceeds,
        account_detail_month: insuranceProceeds,
        account_detail_year_to_date: insuranceProceeds,
        account_detail_since_inception: insuranceProceeds,
        showLine: false,
        boldRow: true,
        showDollarRow: true,
      },
    ],
  }
}

export default {
  transformPPAStatementData,
  transformPPLIStatementData,
  transformVULStatementData,
}
