// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import colors from '@/../src/styles/colors.scss'
import { Checkbox } from '@/components'
import styles from './styles.scss'

type FilterDropdownCriteriumProps = {|
  active: boolean,
  ariaLabel: string,
  filterOption: { label: string, value: string },
  id: string,
  onClick: (filterOption: { label: string, value: string }) => void,
|}

export const ColoredLabel = styled.span`
  color: ${props => (props.checked ? colors.policyFilterBlue : colors.lightGrey)};
  white-space: nowrap;
`

export class FilterDropdownCriterium extends React.Component<FilterDropdownCriteriumProps> {
  handleOnClick = () => {
    const { filterOption, onClick } = this.props
    onClick(filterOption)
  }

  renderLabel = () => {
    const { active, filterOption } = this.props
    return <ColoredLabel checked={active}>{filterOption.label}</ColoredLabel>
  }

  render() {
    const { active, ariaLabel, filterOption, id } = this.props
    return (
      <div className={styles.criterium}>
        <Checkbox
          id={id}
          label={this.renderLabel()}
          name={filterOption.label}
          ariaLabel={ariaLabel}
          onChange={this.handleOnClick}
          checked={active}
          leftLabel
        />
      </div>
    )
  }
}

export default FilterDropdownCriterium
