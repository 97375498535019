// @flow
import { merge } from 'lodash'

import { camelObjectToSnakeObject } from '@/helpers'
import BaseModel from './base/base-model'
import { defaultString } from './defaults'

export type InsuranceCompanyDataType = {|
  name?: ?string,
  address?: ?string,
  telephone?: ?string,
  fax?: ?string,
  contact_name?: ?string,
  contact_role?: ?string,
  contact_telephone?: ?string,
  contact_fax?: ?string,
  contact_email?: ?string,
|}

const defaultDataValues = {
  name: null,
  address: null,
  telephone: null,
  fax: null,
  contact_name: null,
  contact_role: null,
  contact_telephone: null,
  contact_fax: null,
  contact_email: null,
}

export default class InsuranceCompany extends BaseModel {
  name: string

  address: string

  telephone: string

  fax: string

  contactName: string

  contactRole: string

  contactTelephone: string

  contactFax: string

  contactEmail: string

  constructor(rawData: InsuranceCompany | InsuranceCompanyDataType = defaultDataValues) {
    super()
    const data: InsuranceCompanyDataType =
      rawData instanceof InsuranceCompany
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData

    this.name = defaultString(data.name)
    this.address = defaultString(data.address)
    this.telephone = defaultString(data.telephone)
    this.fax = defaultString(data.fax)
    this.contactName = defaultString(data.contact_name)
    this.contactRole = defaultString(data.contact_role)
    this.contactTelephone = defaultString(data.contact_telephone)
    this.contactFax = defaultString(data.contact_fax)
    this.contactEmail = defaultString(data.contact_email)
  }
}
