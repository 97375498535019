// @flow

import colors from '@/../src/styles/colors.scss'
import { formatPrice } from '@/helpers'

const applyStylesFromObj = ($el: HTMLElement, styles: { [number | string]: string }) => {
  Object.keys(styles).forEach(style => {
    // $FlowFixMe
    $el.style[style] = styles[style] // eslint-disable-line
  })
}

const createElement = (
  type: string,
  attributes?: { [string]: string } = {},
  textContent?: string = '',
  children?: Array<HTMLElement> = [],
) => {
  const $el = document.createElement(type)
  $el.textContent = textContent
  Object.keys(attributes).forEach((key: string) => {
    $el.setAttribute(key, attributes[key])
  })
  children.forEach($child => {
    if ($child instanceof HTMLElement) {
      $el.appendChild($child)
    }
    if (Array.isArray($child)) {
      $el.appendChild(createElement(...$child))
    }
  })
  return $el
}

const tooltipStyles = {
  backgroundColor: colors.white,
  borderRadius: '5px',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
  height: '82px',
  marginTop: '35px',
}

const tableRootStyles = {
  zIndex: '100',
  position: 'relative',
  padding: '7px 10px',
  backgroundColor: colors.white,
  borderRadius: '5px',
}

const tooltipRightCaretStyles = {
  backgroundColor: colors.white,
  position: 'absolute',
  right: '0',
  top: '10px',
  width: '20px',
  height: '20px',
  marginRight: '-6px',
  transform: 'rotate(-45deg)',
  zIndex: '75',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
}

const tooltipLeftCaretStyles = {
  ...tooltipRightCaretStyles,
  left: tooltipRightCaretStyles.right,
  right: 'initial',
  marginLeft: tooltipRightCaretStyles.marginRight,
  marginRight: 'initial',
}

const titleStyles = {
  color: colors.grey,
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  textAlign: 'left',
  whiteSpace: 'pre',
}

const colorStyles = {
  background: '',
  float: 'left',
  height: '6px',
  width: '6px',
  marginLeft: '14px',
}

const labelStyles = {
  backgroundColor: colors.white,
  borderColor: '',
  borderWidth: '2px',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: '600',
  marginLeft: '7px',
  textAlign: 'left',
  width: '75px',
}

const encompassingDivStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}

const formatTitleLine = title => {
  const $el = createElement('span', {}, title, [])
  applyStylesFromObj($el, titleStyles)
  return createElement('tr', {}, '', [createElement('th', {}, '', [$el])])
}

const formatBodyLine = line => {
  const $label = createElement('div', {}, line.value, [])
  const $color = createElement('div', {}, '', [])
  const tooltipColors = line.colors
  const $encompassingDiv = createElement('div', {}, '', [$color, $label])

  colorStyles.background = tooltipColors.backgroundColor
  labelStyles.borderColor = tooltipColors.borderColor
  applyStylesFromObj($label, labelStyles)
  applyStylesFromObj($color, colorStyles)
  applyStylesFromObj($encompassingDiv, encompassingDivStyles)
  return createElement('tr', {}, '', [createElement('td', {}, '', [$encompassingDiv])])
}

export const createTooltip = (chartRef: React.Ref<any>) => {
  let tooltipCache = {
    date: null,
    caretX: 0,
    caretY: 0,
    height: 0,
  }
  return (tooltipModel: any) => {
    const statementDate = tooltipModel.title ? tooltipModel.title[0] : null
    let tooltipEl = document.getElementById('chartjs-tooltip')
    if (!tooltipEl) {
      tooltipEl = createElement('div', { id: 'chartjs-tooltip' }, '', [createElement('table')])
      applyStylesFromObj(tooltipEl, tooltipStyles)
      if (document && document.body) {
        document.body.appendChild(tooltipEl)
      }
    }

    if (tooltipModel.opacity === 0 || tooltipModel.dataPoints.length > 2) {
      tooltipEl.style.display = 'none'
      return
    }
    tooltipEl.style.display = 'block'

    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || []
      const bodyLines = tooltipModel.dataPoints.reduce((array, current, i) => {
        if (current.label === statementDate) {
          const dollarVal = formatPrice(current.value)
          array.push({ value: dollarVal, colors: tooltipModel.labelColors[i] })
        }
        return array
      }, [])

      const titleSpans = titleLines.map(formatTitleLine)
      const titleSection = createElement('thead', {}, '', titleSpans)

      const bodyItems = bodyLines.map(formatBodyLine)
      const bodySection = createElement('tbody', {}, '', bodyItems)

      const tableRoot = tooltipEl.querySelector('table')

      applyStylesFromObj(tableRoot, tableRootStyles)
      tableRoot.innerHTML = ''
      tableRoot.appendChild(titleSection)
      tableRoot.appendChild(bodySection)
    }

    let caretX
    let caretY
    let height
    if (tooltipCache.date === statementDate) {
      ;({ caretX, caretY, height } = tooltipCache)
    } else {
      ;({ caretX, caretY, height } = tooltipModel)
      tooltipCache = {
        date: statementDate,
        caretX: tooltipModel.caretX,
        caretY: tooltipModel.caretY,
        height: tooltipModel.height,
      }
    }

    const position = chartRef.current.chartInstance.canvas.getBoundingClientRect()
    const horizSpacer = 23
    tooltipEl.style.opacity = '1'
    tooltipEl.style.position = 'absolute'

    // Display to left of caret if past halfway point
    const chartHalfWayPoint = 1195 / 2 + 70
    let horizOffset = horizSpacer
    const { width: tooltipWidth } = tooltipEl.getBoundingClientRect()
    if (caretX > chartHalfWayPoint) {
      horizOffset = 0 - horizSpacer - tooltipWidth
    }

    // Add or replace the caret
    let tooltipCaretEl = document.getElementById('chartjs-tooltip-caret')
    if (tooltipCaretEl) {
      tooltipCaretEl.parentNode.removeChild(tooltipCaretEl)
    }
    tooltipCaretEl = createElement('div', { id: 'chartjs-tooltip-caret' })
    tooltipEl.appendChild(tooltipCaretEl)

    // Position caret based on halfway point
    if (caretX > chartHalfWayPoint) {
      applyStylesFromObj(tooltipCaretEl, tooltipRightCaretStyles)
    } else {
      applyStylesFromObj(tooltipCaretEl, tooltipLeftCaretStyles)
    }

    const leftPos = position.left + horizOffset + window.pageXOffset + caretX
    const topPos = position.top - height + window.pageYOffset + caretY

    tooltipEl.style.left = `${leftPos}px`
    tooltipEl.style.top = `${topPos}px`
  }
}

export default createTooltip
