// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import { getSearchObject, getSearchString } from '@/helpers'
import { ActionCreators } from '@/redux'
import { ModalTypes, ModalActions } from '@/constants'
import type { ModalType } from '@/constants'
import selectors from '@/selectors'
import modal from './modal'
import eventManager from './event-manager'
import PolicyDetailAnnuityModal from './policy-detail/annuity'
import PolicyDetailLifeModal from './policy-detail/life'
import PolicyDetailVulModal from './policy-detail/vul'
import SettingsModal from './portal-settings'
import TermsOfServiceModal from './terms-of-service'
import PortalWrapper from './wrappers/portal-wrapper'
import PublicWrapper from './wrappers/public-wrapper'
import InactivityWarning from './inactivity-warning'
import styles from './styles.scss'
import { Disability } from '@/models'
import PolicyDetailDisabilityModal from '@/components/modal/policy-detail/disability'

type StateToProps = {|
  printPending: boolean,
|}

type DispatchProps = {|
  makeLatest: (payload: ApiPayloadType<ModalPayload>) => void,
  logoutUser: () => void,
|}

type ModalProps = {|
  history: RouterHistory,
  type?: ?ModalType,
  ...DispatchProps,
  ...StateToProps,
|}

type ModalState = {|
  currentType: ?ModalType,
  currentDetailId: ?string,
  currentDetailMonth: ?string,
  currentPolicyType:
    | ?InsurancePolicy.modelName
    | ?PrivatePlacement.modelName
    | ?VULPolicy.modelName,
  portalSetEmailSuccess: boolean,
  portalSetPasswordSuccess: boolean,
  portalSetUsernameSuccess: boolean,
  portalSetMobileSuccess: boolean,
|}

export class Modal extends React.Component<ModalProps, ModalState> {
  static defaultProps = {
    type: null,
  }

  constructor(props: ModalProps) {
    super(props)

    this.state = {
      currentType: props.type,
      currentDetailId: null,
      currentDetailMonth: null,
      currentPolicyType: null,
      portalSetEmailSuccess: false,
      portalSetPasswordSuccess: false,
      portalSetUsernameSuccess: false,
      portalSetMobileSuccess: false,
    }
  }

  static getDerivedStateFromProps(props: ModalProps) {
    const result = {}
    const { history } = props
    if (history.location.search) {
      const queryParams = getSearchObject(history.location.search)
      if (queryParams.modal && ModalTypes[queryParams.modal]) {
        result.currentType = ModalTypes[queryParams.modal]
      }
    }

    return result
  }

  componentDidMount() {
    const { currentType } = this.state

    eventManager
      .register(ModalActions.RENDER_MODAL, type => this.setModal(type))
      .register(ModalActions.REMOVE_MODAL, () => this.removeModal())
      .register(ModalActions.UPDATE_CONTENT, name => this.setModalSuccess(name))
      .register(ModalActions.CHANGE_SETTINGS_TAB, () => this.changePortalSettingsTab())
      .register(ModalActions.SET_DETAIL_CONTENT, payload => this.setModalDetail(payload))
      .emit(ModalActions.DID_MOUNT)

    if (currentType) {
      eventManager.emit(ModalActions.RENDER_MODAL, currentType)
    }
  }

  componentDidUpdate(prevProps: ModalProps, prevState: ModalState) {
    const { currentType } = this.state

    if (currentType && prevState.currentType !== currentType)
      eventManager.emit(ModalActions.RENDER_MODAL, currentType)
  }

  componentWillUnmount() {
    eventManager.clear(ModalActions.RENDER_MODAL).emit(ModalActions.WILL_UNMOUNT)
  }

  closeModal = () => {
    modal.remove()
  }

  setModalSuccess = (successType: string) => {
    this.setState({ [successType]: true })
  }

  changePortalSettingsTab = () => {
    this.setState({
      portalSetEmailSuccess: false,
      portalSetPasswordSuccess: false,
      portalSetUsernameSuccess: false,
      portalSetMobileSuccess: false,
    })
  }

  setModalDetail = (payload: {
    policyId: string,
    policyType?:
      | InsurancePolicy.modelName
      | PrivatePlacement.modelName
      | VULPolicy.modelName
      | Disability.modelName,
    currentDetailMonth?: ?string,
  }) => {
    this.setState({
      currentDetailId: payload.policyId,
      currentPolicyType: payload.policyType,
      currentDetailMonth: payload.currentDetailMonth,
    })
  }

  setModal = (currentType: ModalType) => {
    this.setState({ currentType })
  }

  getModalComponent = () => {
    const { makeLatest, printPending, logoutUser } = this.props
    const {
      currentType,
      currentDetailId,
      currentDetailMonth,
      currentPolicyType,
      portalSetEmailSuccess,
      portalSetPasswordSuccess,
      portalSetUsernameSuccess,
      portalSetMobileSuccess,
    } = this.state

    let modalNode = null
    switch (currentType) {
      case ModalTypes.policyDetailLife:
        modalNode = (
          <PortalWrapper closeModal={this.closeModal} blackTop>
            <PolicyDetailLifeModal
              {...this.props}
              policyId={currentDetailId}
              policyType={currentPolicyType}
              month={currentDetailMonth}
              printPDF={makeLatest}
              printPending={printPending}
            />
          </PortalWrapper>
        )
        break
      case ModalTypes.policyDetailVul:
        modalNode = (
          <PortalWrapper closeModal={this.closeModal} blackTop>
            <PolicyDetailVulModal
              {...this.props}
              policyId={currentDetailId}
              policyType={currentPolicyType}
              month={currentDetailMonth}
              printPDF={makeLatest}
              printPending={printPending}
            />
          </PortalWrapper>
        )
        break
      case ModalTypes.policyDetailAnnuity:
        modalNode = (
          <PortalWrapper closeModal={this.closeModal} blackTop>
            <PolicyDetailAnnuityModal
              {...this.props}
              policyId={currentDetailId}
              month={currentDetailMonth}
              printPDF={makeLatest}
              printPending={printPending}
            />
          </PortalWrapper>
        )
        break
      case ModalTypes.policyDetailDisability:
        modalNode = (
          <PortalWrapper closeModal={this.closeModal} blackTop>
            <PolicyDetailDisabilityModal
              {...this.props}
              policyId={currentDetailId}
              month={currentDetailMonth}
              printPDF={makeLatest}
              printPending={printPending}
            />
          </PortalWrapper>
        )
        break
      case ModalTypes.settings:
        modalNode = (
          <PortalWrapper closeModal={this.closeModal}>
            <SettingsModal
              onSubmit={makeLatest}
              emailSuccess={portalSetEmailSuccess}
              passwordSuccess={portalSetPasswordSuccess}
              usernameSuccess={portalSetUsernameSuccess}
              mobileSuccess={portalSetMobileSuccess}
            />
          </PortalWrapper>
        )
        break
      case ModalTypes.termsOfService:
        modalNode = (
          <PublicWrapper closeModal={this.closeModal} fullScreen>
            <TermsOfServiceModal />
          </PublicWrapper>
        )
        break
      case ModalTypes.inactivityWarning:
        modalNode = (
          <PublicWrapper closeModal={this.closeModal} className={styles.noBorder}>
            <InactivityWarning onLogoutNow={logoutUser} />
          </PublicWrapper>
        )

        break
      default:
        modalNode = null
    }
    return modalNode
  }

  removeModal() {
    const { history } = this.props
    const searchObj = getSearchObject(history.location.search)
    delete searchObj.modal
    delete searchObj.next
    history.push({
      hash: history.location.hash,
      pathname: history.location.pathname,
      search: getSearchString(searchObj),
    })
    this.setState({
      currentType: null,
      portalSetEmailSuccess: false,
      portalSetPasswordSuccess: false,
      portalSetUsernameSuccess: false,
      portalSetMobileSuccess: false,
    })
  }

  render() {
    const modalNode = this.getModalComponent()
    if (modalNode) {
      return modalNode
    }
    return null
  }
}

const mapStateToProps = (state: StoreState): StateToProps => ({
  printPending: selectors.api.getPrintDetailedPending(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  makeLatest: payload => dispatch(ActionCreators.api.makeLatestRequest.dispatch(payload)),
  logoutUser: () => dispatch(ActionCreators.user.clearUserSession.dispatch()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal))
