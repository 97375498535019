// @flow
import BaseModel from '../base/base-model'
import { defaultNumber, defaultString } from '../defaults'

export type FootnoteDataType = {|
  id: ?string,
  note: ?string,
  ordering: ?number,
|}

export default class Footnote extends BaseModel {
  id: string

  note: string

  ordering: number

  constructor(
    data: FootnoteDataType = {
      id: null,
      note: null,
      ordering: null,
    },
  ) {
    super()

    this.id = defaultString(data.id)
    this.note = defaultString(data.note)
    this.ordering = defaultNumber(data.ordering)
  }
}
