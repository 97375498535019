// @flow

import { Strings, Enums } from '@/constants'
import { Annuity, Disability, InsurancePolicy, PrivatePlacement, VULPolicy } from '@/models'
import { formatPrice } from '@/helpers'

const termModel = (policy: InsurancePolicy | PrivatePlacement) => [
  { fieldKey: 'costBasis', value: policy.costBasisFormat },
  { fieldKey: 'policyGrossAccountValue', value: formatPrice(policy.policyGrossAccountValue, '–') },
  { fieldKey: 'surrenderCharge', value: formatPrice(policy.surrenderCharge, '–') },
  { fieldKey: 'policyLoanBalance', value: formatPrice(policy.policyLoanBalance, '–') },
  { fieldKey: 'policyNetAccountValue', value: formatPrice(policy.policyAccountValue, '–') },
  { fieldKey: 'dividendOption', value: policy.formattedDividendOption || Strings.dash },

  {
    fieldKey: 'premiumPricingGuaranteeDuration',
    value: policy.premiumPricingGuaranteeDurationFormat || Strings.dash,
  },
  ...(policy.riders
    ? [
        {
          fieldKey: 'riders',
          value: policy.riders.split(','),
        },
      ]
    : []),
]

const wholeModel = (policy: InsurancePolicy | PrivatePlacement) => [
  { fieldKey: 'costBasis', value: policy.costBasisFormat },
  { fieldKey: 'policyGrossAccountValue', value: formatPrice(policy.policyGrossAccountValue, '–') },
  { fieldKey: 'surrenderCharge', value: formatPrice(policy.surrenderCharge, '–') },
  { fieldKey: 'policyLoanBalance', value: formatPrice(policy.policyLoanBalance, '–') },
  { fieldKey: 'policyNetAccountValue', value: formatPrice(policy.policyAccountValue, '–') },
  { fieldKey: 'dividendOption', value: policy.formattedDividendOption || Strings.dash },

  {
    fieldKey: 'maturityDate',
    value: policy.formattedMaturityDate || Strings.dash,
  },
  ...(policy.riders
    ? [
        {
          fieldKey: 'riders',
          value: policy.riders.split(','),
        },
      ]
    : []),
]

const guaranteedModel = (policy: InsurancePolicy | PrivatePlacement) => [
  { fieldKey: 'costBasis', value: policy.costBasisFormat },
  { fieldKey: 'policyGrossAccountValue', value: formatPrice(policy.policyGrossAccountValue, '–') },
  { fieldKey: 'surrenderCharge', value: formatPrice(policy.surrenderCharge, '–') },
  { fieldKey: 'policyLoanBalance', value: formatPrice(policy.policyLoanBalance, '–') },
  { fieldKey: 'policyNetAccountValue', value: formatPrice(policy.policyAccountValue, '–') },

  {
    fieldKey: 'premiumPricingGuaranteeDuration',
    value: policy.premiumPricingGuaranteeDurationFormat || Strings.dash,
  },
  {
    fieldKey: 'maturityDate',
    value: policy.formattedMaturityDate || Strings.dash,
  },
  ...(policy.riders
    ? [
        {
          fieldKey: 'riders',
          value: policy.riders.split(','),
        },
      ]
    : []),
]

const universalLifeModel = (policy: InsurancePolicy | PrivatePlacement | VULPolicy) => [
  { fieldKey: 'costBasis', value: policy.costBasisFormat },
  { fieldKey: 'policyGrossAccountValue', value: formatPrice(policy.policyGrossAccountValue, '–') },
  { fieldKey: 'surrenderCharge', value: formatPrice(policy.surrenderCharge, '–') },
  { fieldKey: 'policyLoanBalance', value: formatPrice(policy.policyLoanBalance, '–') },
  { fieldKey: 'policyNetAccountValue', value: formatPrice(policy.policyAccountValue, '–') },

  {
    fieldKey: 'deathBenefitOption',
    value: policy.formattedDeathBenefitOption || Strings.dash,
  },
  {
    fieldKey: 'scheduledDeathBenefitOptionChangeDate',
    value: policy.formattedScheduledDeathBenefitOptionChange || Strings.dash,
  },
  {
    fieldKey: 'scheduledFaceAmountReductionFirstDate',
    value: policy.formattedScheduledFaceAmountReduction1 || Strings.dash,
  },
  {
    fieldKey: 'scheduledFaceAmountReductionSecondDate',
    value: policy.formattedScheduledFaceAmountReduction2 || Strings.dash,
  },
  {
    fieldKey: 'scheduledFaceAmountReductionThirdDate',
    value: policy.formattedScheduledFaceAmountReduction3 || Strings.dash,
  },
  ...(policy.formattedScheduledFirstPolicyDistribution
    ? [
        {
          fieldKey: 'scheduledFirstDistributionDate',
          value: policy.formattedScheduledFirstPolicyDistribution,
        },
      ]
    : []),
  {
    fieldKey: 'maturityDate',
    value: policy.formattedMaturityDate || Strings.dash,
  },
  ...(policy.riders
    ? [
        {
          fieldKey: 'riders',
          value: policy.riders.split(','),
        },
      ]
    : []),
]

const privatePlacementLifeModel = (policy: PrivatePlacement) => [
  { fieldKey: 'costBasis', value: policy.costBasisFormat },
  { fieldKey: 'policyGrossAccountValue', value: formatPrice(policy.policyGrossAccountValue, '–') },
  { fieldKey: 'surrenderCharge', value: formatPrice(policy.surrenderCharge, '–') },
  { fieldKey: 'policyLoanBalance', value: formatPrice(policy.policyLoanBalance, '–') },
  { fieldKey: 'policyNetAccountValue', value: formatPrice(policy.policyAccountValue, '–') },

  {
    fieldKey: 'deathBenefitOption',
    value: policy.formattedDeathBenefitOption || Strings.dash,
  },
  {
    fieldKey: 'scheduledDeathBenefitOptionChangeDate',
    value: policy.formattedScheduledDeathBenefitOptionChange || Strings.dash,
  },
  {
    fieldKey: 'scheduledFaceAmountReductionFirstDate',
    value: policy.formattedScheduledFaceAmountReduction1 || Strings.dash,
  },
  {
    fieldKey: 'scheduledFaceAmountReductionSecondDate',
    value: policy.formattedScheduledFaceAmountReduction2 || Strings.dash,
  },
  {
    fieldKey: 'scheduledFaceAmountReductionThirdDate',
    value: policy.formattedScheduledFaceAmountReduction3 || Strings.dash,
  },
  ...(policy.formattedScheduledFirstPolicyDistribution
    ? [
        {
          fieldKey: 'scheduledFirstDistributionDate',
          value: policy.formattedScheduledFirstPolicyDistribution,
        },
      ]
    : []),
  {
    fieldKey: 'maturityDate',
    value: policy.formattedMaturityDate || Strings.dash,
  },
  ...(policy.riders
    ? [
        {
          fieldKey: 'riders',
          value: policy.riders.split(','),
        },
      ]
    : []),
]

const privatePlacementAnnuityModel = (policy: Annuity) => [
  { fieldKey: 'costBasis', value: policy.costBasisFormat },
  { fieldKey: 'policyGrossAccountValue', value: formatPrice(policy.policyGrossAccountValue, '–') },
  { fieldKey: 'surrenderCharge', value: formatPrice(policy.surrenderCharge, '–') },
  { fieldKey: 'policyNetAccountValue', value: formatPrice(policy.annuityAccountValue, '–') },
  { fieldKey: 'maturityDate', value: policy.formattedMaturityDate || Strings.dash },
  ...(policy.riders
    ? [
        {
          fieldKey: 'riders',
          value: policy.riders.split(','),
        },
      ]
    : []),
]

const disabilityPolicyDetailModel = (policy: Disability) => [
  { fieldKey: 'benefitDuration', value: policy.benefitDuration },
  { fieldKey: 'eliminationPeriod', value: policy.eliminationPeriodDaysFormatted },
]

export const thirdColFieldsMap = {
  [Enums.PolicyType.TERM]: termModel,
  [Enums.PolicyType.FIVE_YEAR_TERM]: termModel,
  [Enums.PolicyType.TEN_YEAR_TERM]: termModel,
  [Enums.PolicyType.FIFTEEN_YEAR_TERM]: termModel,
  [Enums.PolicyType.TWENTY_YEAR_TERM]: termModel,
  [Enums.PolicyType.TWENTY_FIVE_YEAR_TERM]: termModel,
  [Enums.PolicyType.THIRTY_YEAR_TERM]: termModel,
  [Enums.PolicyType.THIRTY_FIVE_YEAR_TERM]: termModel,
  [Enums.PolicyType.WHOLE]: wholeModel,
  [Enums.PolicyType.GUARANTEED_UNIVERSAL_LIFE]: guaranteedModel,
  [Enums.PolicyType.GUARANTEED_VARIABLE_UNIVERSAL_LIFE]: guaranteedModel,
  [Enums.PolicyType.UNIVERSAL_LIFE]: universalLifeModel,
  [Enums.PolicyType.INDEX_UNIVERSAL_LIFE]: universalLifeModel,
  [Enums.PolicyType.VARIABLE_UNIVERSAL_LIFE]: universalLifeModel,
  [Enums.PolicyType.PRIVATE_PLACEMENT_LIFE]: privatePlacementLifeModel,
  [Enums.PolicyType.PRIVATE_PLACEMENT_ANNUITY]: privatePlacementAnnuityModel,
  [Enums.PolicyType.LONG_TERM_DISABILITY]: disabilityPolicyDetailModel,
}

export default thirdColFieldsMap
