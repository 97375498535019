// @flow

import React from 'react'
import jsStyles from './styles'
import styles from './styles.scss'

type ToggleProps = {|
  ariaLabel: string,
  name: string,
  label?: string,
  onChange: (data: any) => void,
  checked: boolean,
  value?: any,
  error?: boolean,
  disabled?: boolean,
|}

class Toggle extends React.Component<ToggleProps> {
  static defaultProps = {
    label: '',
    value: null,
    error: false,
    disabled: false,
  }

  clickCheck = () => {
    const { onChange, value, disabled } = this.props
    if (!disabled) {
      onChange(value)
    }
  }

  render() {
    const { ariaLabel, name, label, value, checked, error, disabled } = this.props
    // This is not undefined it has a default value $FlowExpectedError
    const id = `${label}-${name}`

    return (
      <label
        id={label}
        htmlFor={id}
        className={styles.container}
        css={jsStyles.container(this.props)}
      >
        <span css={jsStyles.label(this.props)}>{label}</span>
        <input
          type="checkbox"
          checked={checked}
          onChange={this.clickCheck}
          name={label}
          aria-label={ariaLabel}
          id={id}
          value={value}
          disabled={disabled}
        />
        <div className={styles.toggle} css={jsStyles.toggle(this.props)}>
          <span className={styles.switch} />
        </div>
        <span css={jsStyles.span(this.props)} error={error} />
      </label>
    )
  }
}

export default Toggle
