// @flow
import BaseModel from './base/base-model'
import Advisor from './advisor'
import type { AdvisorDataType } from './advisor'
import { defaultArray, defaultString, defaultBoolean, defaultNumber } from './defaults'

export type UserDataType = {|
  email: ?string,
  id: ?string,
  agency: ?string,
  username: ?string,
  advisors: ?Array<AdvisorDataType>,
  first_name: ?string,
  last_name: ?string,
  disabled: ?boolean,
  failed_tfa_attempts: ?number,
  restricted_access?: ?boolean,
  country_code: ?string,
  country_name: ?string,
  phone: ?string,
  excel_disabled: ?Array<string>,
  pdf_disabled: ?Array<string>,
|}

export default class User extends BaseModel {
  email: string

  id: string

  agency: string

  username: string

  firstName: string

  lastName: string

  advisors: Array<Advisor>

  _clients: ?Array<string>

  disabled: boolean

  failed_tfa_attempts: number

  country_code: string

  country_name: string

  phone: string

  hasRestrictedAccess: boolean

  excel_disabled: ?Array<string>

  pdf_disabled: ?Array<string>

  constructor(
    data: UserDataType = {
      email: null,
      id: null,
      agency: null,
      username: null,
      first_name: null,
      last_name: null,
      advisors: null,
      disabled: null,
      failed_tfa_attempts: null,
      country_code: null,
      country_name: null,
      phone: null,
      restricted_access: null,
      excel_disabled: null,
      pdf_disabled: null,
    },
  ) {
    super()
    this.email = defaultString(data.email)
    this.id = defaultString(data.id)
    this.agency = defaultString(data.agency)
    this.username = defaultString(data.username)
    this.firstName = defaultString(data.first_name)
    this.lastName = defaultString(data.last_name)
    this.advisors = defaultArray(data.advisors).map(a => new Advisor(a))
    this._clients = null
    this.disabled = defaultBoolean(data.disabled)
    this.failed_tfa_attempts = defaultNumber(data.failed_tfa_attempts)
    this.country_code = data.country_code || '+1'
    this.country_name = data.country_name || 'United States'
    this.phone = data.phone || ''
    this.hasRestrictedAccess = defaultBoolean(data.restricted_access)
    this.excel_disabled = defaultArray(data.excel_disabled) || []
    this.pdf_disabled = defaultArray(data.pdf_disabled) || []
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  set clients(clients: Array<string>) {
    // Can't change clients once they've been set
    if (this._clients === null) {
      this._clients = clients
    } else {
      // eslint-disable-next-line no-console
      console.warn('Clients are set once by normalizr')
    }
  }

  get clients(): ?Array<string> {
    return this._clients
  }

  get passwordInputs(): Array<string> {
    const userInputs = []
    if (this.username) {
      userInputs.push(this.username)
    }
    if (this.email) {
      userInputs.push(this.email)
    }
    if (this.firstName) {
      userInputs.push(this.firstName)
    }
    if (this.lastName) {
      userInputs.push(this.lastName)
    }
    return userInputs
  }
}
