// @flow
import { merge } from 'lodash'
import { camelObjectToSnakeObject } from '@/helpers'
import BaseModel from './base/base-model'
import { defaultString } from './defaults'

export type ClientDataType = {|
  email: ?string,
  id: ?string,
  first_name: ?string,
  firstName?: string,
  last_name: ?string,
  lastName?: string,
  display_name?: string,
  displayName?: string,
|}

const defaultDataValues = {
  email: null,
  id: null,
  first_name: null,
  last_name: null,
  display_name: null,
}

export default class Client extends BaseModel {
  email: string

  id: string

  firstName: string

  lastName: string

  displayName: string

  constructor(rawData: Client | ClientDataType = defaultDataValues) {
    super()
    const data: ClientDataType =
      rawData instanceof Client
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    this.email = defaultString(data.email)
    this.id = defaultString(data.id)
    this.firstName = defaultString(data.first_name)
    this.lastName = defaultString(data.last_name)
    this.displayName = defaultString(data.display_name)
  }

  get displayNameOrFullName(): string {
    return this.displayName || this.fullName
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}
