// @flow
import { ModalTypes, ModalActions } from '@/constants'
import type { ModalType } from '@/constants'
import eventManager from './event-manager'

const emitEvent = (
  event,
  payload?:
    | ModalType
    | {
        policyId: string,
        policyType?: InsurancePolicy.modelName | PrivatePlacement.modelName | VULPolicy.modelName,
      },
) => {
  if (eventManager.mounted === true) {
    eventManager.emit(event, payload)
  }
}

const modal = Object.assign({
  remove: () => emitEvent(ModalActions.REMOVE_MODAL),
  setPassword: () => emitEvent(ModalActions.RENDER_MODAL, ModalTypes.setPassword),
  settings: () => emitEvent(ModalActions.RENDER_MODAL, ModalTypes.settings),
  policyDetailAnnuity: (payload: { policyId: string, month?: ?string }) => {
    emitEvent(ModalActions.RENDER_MODAL, ModalTypes.policyDetailAnnuity)
    const { policyId, month } = payload
    emitEvent(ModalActions.SET_DETAIL_CONTENT, { policyId, month })
  },
  policyDetailLife: (payload: { policyId: string, policyType?: string, month?: ?string }) => {
    emitEvent(ModalActions.RENDER_MODAL, ModalTypes.policyDetailLife)
    const { policyId, policyType, month } = payload
    emitEvent(ModalActions.SET_DETAIL_CONTENT, { policyId, policyType, month })
  },
  policyDetailDisability: (payload: { policyId: string, policyType?: string, month?: ?string }) => {
    emitEvent(ModalActions.RENDER_MODAL, ModalTypes.policyDetailDisability)
    const { policyId, policyType, month } = payload
    emitEvent(ModalActions.SET_DETAIL_CONTENT, { policyId, policyType, month })
  },
  policyDetailVul: (payload: { policyId: string, policyType?: string, month?: ?string }) => {
    emitEvent(ModalActions.RENDER_MODAL, ModalTypes.policyDetailVul)
    const { policyId, policyType, month } = payload
    emitEvent(ModalActions.SET_DETAIL_CONTENT, { policyId, policyType, month })
  },
  updateStatus: (name: string) => emitEvent(ModalActions.UPDATE_CONTENT, name),
  setDetailContent: (payload: { policyId: string, policyType?: string, month?: ?string }) =>
    emitEvent(ModalActions.SET_DETAIL_CONTENT, payload),
  inactivityWarning: () => emitEvent(ModalActions.RENDER_MODAL, ModalTypes.inactivityWarning),
  termsOfService: () => emitEvent(ModalActions.RENDER_MODAL, ModalTypes.termsOfService),
})

eventManager
  .register(ModalActions.DID_MOUNT, () => {
    eventManager.mounted = true
  })
  .register(ModalActions.WILL_UNMOUNT, () => {
    eventManager.mounted = false
  })

export default modal
