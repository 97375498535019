// @flow

import { css } from '@emotion/core'
import { blueGreySelected, blueGrey, verticalLG } from './styles.scss'

export default {
  visibility: (requestSent: boolean) => css`
    ${requestSent
      ? `
        visibility: hidden
      `
      : `
        visibility: visible
        `}
  `,
  settingsMenuItem: (active: boolean) => css`
    ${active
      ? `
    font-weight: bold;
    background: ${blueGreySelected};
    `
      : `
    font-weight: normal;
    background: transparent;
    `}
  `,
  size: (props: { fullScreen?: boolean, width?: string }) => css`
    width: ${props.width || 'auto'};
    ${props.fullScreen &&
    `
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: 100%;
      height: 100%;
    `}
  `,
  dark: (dark: ?boolean) => css`
    ${dark ? `background-color: ${blueGrey};` : ''}
  `,
  topPad: (pad: ?boolean) => css`
    ${pad ? `padding-top: ${verticalLG};` : ''}
  `,
}
