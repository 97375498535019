// @flow
import React from 'react'
import styles from './styles.scss'
import { IdfReportIcon } from '../icon'

type CellProps = {|
  onClick?: ?() => void,
  value: string | number | Object,
  first?: boolean,
  single?: boolean,
  accessor: string,
  alignClassName?: ?'left' | 'center' | 'right',
  factSheetIcon: boolean,
|}

const handleLinkClick = value => {
  window.open(value, '_blank')
}

const Cell = (props: CellProps) => {
  const { value, first, single, onClick, accessor, alignClassName, factSheetIcon } = props
  let className = value === 'Subtotal' ? 'tableCellMain subtotal' : 'tableCellMain'
  className += single ? ' single' : ''
  className += first ? ' first' : ''
  className += alignClassName ? ` ${alignClassName}` : ''

  let content = ''
  if (factSheetIcon && value) {
    content = (
      <span className={styles.IdfFactsheet}>
        <IdfReportIcon onClick={() => handleLinkClick(value)} />
      </span>
    )
  } else {
    content =
      onClick && value ? (
        <button type="button" onClick={onClick} className={styles.cellButton}>
          {value}
        </button>
      ) : (
        value
      )
  }

  return (
    <div className={className} data-accessor={`cell-${accessor}`}>
      {content}
    </div>
  )
}

Cell.defaultProps = {
  onClick: null,
  alignClassName: null,
  factSheetIcon: false,
  single: false,
  first: false,
}

export default Cell
