// @flow
import { call, put, select, take } from 'redux-saga/effects'
import ApiService from '@/services/ApiService'
import StorageService from '@/helpers/StorageService'
import history from '@/helpers/history'
import ReactGA from '@/helpers/ga'
import { getSearchObject, getSearchString, parseUrl } from '@/helpers'
import selectors from '@/selectors'
import { Routes, ModalSuccessFlags, LOGIN_STATUSES } from '@/constants'
import modal from '@/components/modal/modal'
import { ActionCreators } from '@/redux'
import { ErrorKeys, ACTIONS as API_ACTIONS } from '@/redux/api'
import { ACTIONS as USER_ACTIONS } from '@/redux/user'
import {
  fetchAnnuities,
  fetchIDFStatements,
  fetchInsurancePolicies,
  fetchInsurancePoliciesShort,
  fetchPPAStatements,
  fetchPPPLIStatements,
  fetchPPPLIStatementsShort,
  fetchVULStatements,
  fetchVULStatementsShort,
  fetchPrivatePlacements,
  fetchPrivatePlacementsShort,
  fetchIDFPdfAvailability,
  fetchFootnotes,
  fetchVULPolicies,
  fetchVULPoliciesShort,
  fetchIULMonthlyStatements,
  fetchIULAnnualStatements,
  fetchIndexAccountRates,
  fetchGeneralDocuments,
  fetchAnnuitiesShort,
  fetchPPAStatementsShort,
  fetchDisabilityPoliciesShort,
  fetchConsolidatedStatements,
} from './policy'
import { getClients, endSessionsByIds } from '@/redux/api-payloads'
import { LoginResponseNew } from '@/models/mappers'

const parseNext = () => {
  const { next } = getSearchObject(history.location.search)
  const nextDecoded = next ? decodeURIComponent(next) : ''
  const nextUrl = nextDecoded ? parseUrl(nextDecoded) : {}

  return {
    nextDecoded,
    nextUrl,
  }
}

function* fetchClients(): GeneratorType {
  const payload = getClients()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* loadShortData(): GeneratorType {
  yield fetchFootnotes()
  yield fetchInsurancePoliciesShort()
  yield fetchVULPoliciesShort()
  yield fetchPrivatePlacementsShort()
  yield fetchAnnuitiesShort()

  yield fetchDisabilityPoliciesShort()

  yield fetchVULStatementsShort()
  yield fetchPPAStatementsShort()
  yield fetchPPPLIStatementsShort()

  yield fetchIULMonthlyStatements()
  yield fetchIULAnnualStatements()
  yield fetchIndexAccountRates()
  yield fetchConsolidatedStatements();

  yield fetchIDFPdfAvailability()
  yield fetchGeneralDocuments()
  yield fetchIDFStatements()
}

export function* loadData(): GeneratorType {
  yield fetchAnnuities()
  yield fetchIDFStatements()
  yield fetchInsurancePolicies()
  yield fetchPPPLIStatements()
  yield fetchVULStatements()
  yield fetchPPAStatements()
  yield fetchPrivatePlacements()
  yield fetchVULPolicies()
  yield fetchFootnotes()
  yield fetchIDFPdfAvailability()
  yield fetchGeneralDocuments()
}

export function* loginSuccess(action: { type: string, payload: LoginResponseNew }): GeneratorType {
  const { token, status, last_two } = action.payload
  if (!!token && status === LOGIN_STATUSES.SUCCESS) {
    ApiService.setupInstance({ token })
    const response = yield call(ApiService.me)
    const { user } = response.data

    yield fetchClients()
    yield take(USER_ACTIONS.GET_CLIENTS_SUCCESS)
    const clientsObj = yield select(state => state.user.clients)
    const clientsArr = clientsObj ? Object.values(clientsObj) : []
    const { nextDecoded, nextUrl } = parseNext()
    const { activeUser: queryParamActiveUser } = getSearchObject(nextUrl.search || '')
    const storedActiveUser = yield call(StorageService.getActiveUser)
    const defaultActiveUser = clientsArr && clientsArr[0] ? clientsArr[0].id : user.id
    const activeClientUser = queryParamActiveUser || storedActiveUser || defaultActiveUser
    ApiService.setupInstance({ token }, activeClientUser)

    yield put(ActionCreators.user.setUser.dispatch({ user }))
    yield put(ActionCreators.user.setActiveUser.dispatch(activeClientUser))

    ReactGA.set({ userId: user.id })
    const { persistent } = action.payload?._meta || {} // might be user later
    const userSession: UserSession = {
      token,
      name: user.fullName,
      email: user.email,
      persistent,
      expired: false,
    }
    yield put(ActionCreators.user.addUserSession.dispatch(userSession))
    if (nextDecoded) {
      history.push(nextDecoded)
    } else if (clientsArr.length > 0) {
      history.push(Routes.portal.ClientList)
    } else if (user.hasRestrictedAccess) {
      history.push(Routes.portal.PPLI)
    } else {
      history.push(Routes.portal.ClientHome)
    }
    StorageService.setAuth(token)
  } else if (status === LOGIN_STATUSES.TWO_FA_SENT) {
    if (token) {
      ApiService.setupInstance({ token })
    }
    yield put(ActionCreators.user.setLastTwo.dispatch({ last_two }))
    const { next } = getSearchObject(history.location.search)
    history.push({
      pathname: Routes.auth.VerifyDevice,
      search: getSearchString(next ? { next } : {}),
      state: {
        meta: action.payload?._meta || {},
      },
    })
  } else if (status === LOGIN_STATUSES.DISABLED) {
    yield put(ActionCreators.user.setUserDisabled.dispatch())
  }
}

export function* loginError(): GeneratorType {
  const error = yield select(selectors.api.getErrorObj, ErrorKeys.loginUserError)
  if (error && error.message && error.message.code === 1) {
    const { token } = error.message.detail
    history.push(Routes.auth.ResetPassword, { token, compromised: true })
  }
}

export function inviteAccepted(): GeneratorType {
  history.push(Routes.auth.Login)
}

export function* resetPassword(): GeneratorType {
  yield call(modal.remove)
  history.push(Routes.public.Home)
}

export function* portalSetEmailSuccess(): GeneratorType {
  yield call(modal.updateStatus, ModalSuccessFlags.email)
}

export function* portalSetPasswordSuccess(): GeneratorType {
  yield call(modal.updateStatus, ModalSuccessFlags.password)
}

export function* portalSetUsernameSuccess(): GeneratorType {
  yield call(modal.updateStatus, ModalSuccessFlags.username)
}

export function* portalSetMobileSuccess(): GeneratorType {
  yield call(modal.updateStatus, ModalSuccessFlags.mobile)
}

export function* clearCookie(): GeneratorType {
  const currentSessionToken = yield call(StorageService.getAuth)
  yield put({ type: API_ACTIONS.STOP_POLLING })
  yield call(StorageService.deleteActiveUser)

  if (currentSessionToken) {
    const currentToken = currentSessionToken.token
    StorageService.removeTokenFromSessionsList(currentToken)
    yield call(StorageService.deleteAuth)
    try {
      yield call(ApiService.logoutUser)
      yield call(ApiService.clearAuthToken)
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e) // eslint-disable-line no-console
      }
    }
    history.push(Routes.public.Home)
  }
}

export function* logoutCurrentUser(): GeneratorType {
  // same as clearCookie, but to be called on 401th status
  const currentSessionToken = yield call(StorageService.getAuth)
  yield put({ type: API_ACTIONS.STOP_POLLING })
  yield call(StorageService.deleteActiveUser)
  yield call(ApiService.clearAuthToken)

  if (currentSessionToken) {
    const currentToken = currentSessionToken.token
    StorageService.removeTokenFromSessionsList(currentToken)
    yield call(StorageService.deleteAuth)
    try {
      yield call(ApiService.logoutUser)
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e) // eslint-disable-line no-console
      }
    }
  }
  history.push(Routes.auth.Login)
}

export function* hydrateUser(): GeneratorType {
  const token = StorageService.getAuth()
  if (token) {
    try {
      ApiService.setupInstance(token)
      const activeClient = yield select(state => state.user.activeClient)
      if (activeClient) return
      const response = yield call(ApiService.me)
      const { user } = response.data
      yield put(ActionCreators.user.setUser.dispatch({ user }))
      yield fetchClients()
      yield take(USER_ACTIONS.GET_CLIENTS_SUCCESS)
      const clientsObj = yield select(state => state.user.clients)
      const clientsArr = Object.values(clientsObj)
      const { activeUser: queryParamActiveUser } = getSearchObject(
        decodeURIComponent(history.location.search),
      )
      const storedActiveUser = yield call(StorageService.getActiveUser)
      const defaultActiveUser = clientsArr[0] ? clientsArr[0].id : user.id
      const activeClientUser = queryParamActiveUser || storedActiveUser || defaultActiveUser
      ApiService.setupInstance(token, activeClientUser)
      yield put(ActionCreators.user.setActiveUser.dispatch(activeClientUser))
      ReactGA.set({ userId: user.id })
    } catch (e) {
      const { pathname, search, hash } = history.location
      const next = hash ? `${pathname}${search}${hash}` : `${pathname}${search}`

      StorageService.deleteAuth()
      yield call(ApiService.clearAuthToken)
      if (next !== '/') {
        history.push(`${Routes.auth.Login}?next=${encodeURIComponent(next)}`)
      } else {
        history.push(Routes.public.Home)
      }
    }
  }
}

export function* setActiveUser(action: { type: string, payload: string }): GeneratorType {
  // it sets active CLIENT
  yield call(ApiService.setActiveClientHeader, action.payload)
  yield call(StorageService.setActiveUser, action.payload)
  yield put(ActionCreators.user.loadShortData.dispatch())
}

export function* inactivityWarningResponse(): GeneratorType {
  const user = yield select(selectors.user.getUser)
  // Show warning if a user is on any page of the site and logged in
  if (user) {
    yield call(modal.inactivityWarning)
  }
}

// user session actions
export function* addUserSession(action: { type: string, payload: UserSession }): GeneratorType {
  let storedSessions: Array<UserSession> = yield call(StorageService.getSessionsList)
  const sameUserSessions = storedSessions.filter(session => session.email === action.payload.email)
  if (sameUserSessions && sameUserSessions.length) {
    const tokensList = sameUserSessions.map(session => session.token)
    const statuses = yield call(ApiService.getSessionsStatuses, { tokens: tokensList })
    if (statuses.data && statuses.data.length) {
      const sameUserSessionsIds = statuses.data.map(session => session.id)
      const endSessionsPayload = endSessionsByIds(sameUserSessionsIds)
      yield put(ActionCreators.api.makeRequest.dispatch(endSessionsPayload))
    }
  }
  storedSessions = storedSessions.filter(session => session.email !== action.payload.email)
  const newSessions = storedSessions.concat(action.payload)
  yield call(StorageService.setSessionsList, newSessions)
}

export function* setCurrentUserSession(action: {
  type: string,
  payload: UserSession,
}): GeneratorType {
  const { token } = action.payload
  ApiService.setupInstance({ token })
  StorageService.setAuth(token)

  const response = yield call(ApiService.me)
  const { user } = response.data

  yield fetchClients()
  yield take(USER_ACTIONS.GET_CLIENTS_SUCCESS)
  const clientsObj = yield select(state => state.user.clients)
  const clientsArr = clientsObj ? Object.values(clientsObj) : []
  const defaultActiveUser = clientsArr && clientsArr[0] ? clientsArr[0].id : user.id
  const activeClientUser = defaultActiveUser
  ApiService.setupInstance({ token }, activeClientUser)

  yield put(ActionCreators.user.setUser.dispatch({ user }))
  yield put(ActionCreators.user.setActiveUser.dispatch(activeClientUser))

  ReactGA.set({ userId: user.id })

  if (clientsArr.length > 0) {
    history.push(Routes.portal.ClientList)
  } else if (user.hasRestrictedAccess) {
    history.push(Routes.portal.PPLI)
  } else {
    history.push(Routes.portal.ClientHome)
  }
}

export default {
  loginSuccess,
  inviteAccepted,
  resetPassword,
  portalSetEmailSuccess,
  portalSetPasswordSuccess,
  portalSetUsernameSuccess,
  portalSetMobileSuccess,
  setActiveUser,
  inactivityWarningResponse,
  loadData,
  loadShortData,
  addUserSession,
  setCurrentUserSession,
}
