// @flow
import { Enums } from '@/constants'

import BaseModel from '../base/base-model'
import { defaultEnum, defaultString } from '../defaults'

export type IULAnnualStatementDataType = {|
  id: ?string,
  policy_id: ?string,
  date: ?string,
  uploaded_file: ?string,
  uploaded_file_type: ?string,
|}

export default class IULAnnualStatement extends BaseModel {
  id: string

  policyId: string

  date: string

  uploadedFile: string

  uploadedFileType: string

  constructor(
    data: IULAnnualStatementDataType = {
      id: null,
      policy_id: null,
      date: null,
      uploaded_file: null,
      uploaded_file_type: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.policyId = defaultString(data.policy_id)
    this.date = defaultString(data.date)
    this.uploadedFile = defaultString(data.uploaded_file)
    this.uploadedFileType = defaultEnum(data.uploaded_file_type, Enums.IULUploadedFileType)
  }
}
