// @flow
import * as React from 'react'
import moment from 'moment'
import { Strings } from '@/constants'
import { Footnotes } from '@/components'
import GolcondaTable from '@/components/table'
import Format from '@/components/table/format'
import { PPAStatement, IDF, IDFTransaction, VULStatement } from '@/models'
import styles from '../styles.scss'
import { VariancesNote } from './variances-note'
import {
  ppliAccountTransactionsColumns,
  ppaAccountTransactionsColumns,
  vulAccountTransactionsColumns,
  transformIDFTransactionsData,
} from './account-transactions-data'

type AccountTransactionsTableProps = {|
  ownerName: ?string,
  notes: Array<FootnoteType>,
  statements: Array<PPStatementType>,
  loading: boolean,
  month: string,
  idfs: { [string]: IDF },
  variances: Array<VarianceType>,
  pdfKey: PDFKeysType,
  policies: { [string]: PPPolicyType },
  getAccountTransactionsColumns: Array<*>,
|}

export const getPPLIAccountTransactionsDownloadData = (
  statements: Array<PPStatementType>,
  idfs: { [string]: IDF },
  policies: { [string]: PPPolicyType },
  ownerName: ?string,
  worksheetName: ?string,
) => {
  const transactions: Array<{
    idf: IDF,
    transactions: Array<IDFTransaction>,
  }> = statements.reduce((acc, cur) => {
    if (!idfs[cur.idfId]) {
      return acc
    }

    const reduceTransactions = {
      idf: idfs[cur.idfId],
      transactions: cur.transactions,
    }

    reduceTransactions.idf.policyId = cur.policyId

    acc.push(reduceTransactions)

    return acc
  }, [])
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  const data = transformIDFTransactionsData(transactions, policies)?.sort(
    (a, b) => new Date(b.dateFormat) - new Date(a.dateFormat),
  )
  return new Format({
    ownerName,
    statementEnd,
    columns: ppliAccountTransactionsColumns,
    title: Strings.ppli.accountTransactions,
    sheetname: worksheetName || Strings.ppli.worksheetName.accountTransactions,
  }).getTableAsXLSXInput(data)
}

export const getPPAAccountTransactionsDownloadData = (
  statements: Array<PPStatementType>,
  idfs: { [string]: IDF },
  policies: { [string]: PPPolicyType },
  ownerName: ?string,
  worksheetName: ?string,
) => {
  const transactions: Array<{
    idf: IDF,
    transactions: Array<IDFTransaction>,
  }> = statements.reduce((acc, cur) => {
    if (!idfs[cur.idfId]) {
      return acc
    }

    const reduceTransactions = {
      idf: idfs[cur.idfId],
      transactions: cur.transactions,
    }

    reduceTransactions.idf.policyId = cur.policyId

    acc.push(reduceTransactions)

    return acc
  }, [])
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  const data = transformIDFTransactionsData(transactions, policies)?.sort(
    (a, b) => new Date(b.dateFormat) - new Date(a.dateFormat),
  )
  return new Format({
    ownerName,
    statementEnd,
    columns: ppaAccountTransactionsColumns,
    title: Strings.ppa.accountTransactions,
    sheetname: worksheetName || Strings.ppa.worksheetName.accountTransactions,
    ppaTable: true,
  }).getTableAsXLSXInput(data)
}

export const getVULAccountTransactionsDownloadData = (
  statements: Array<PPStatementType>,
  idfs: { [string]: IDF },
  policies: { [string]: PPPolicyType },
  ownerName: ?string,
) => {
  const transactions: Array<{
    idf: IDF,
    transactions: Array<IDFTransaction>,
  }> = statements.reduce((acc, cur) => {
    if (!idfs[cur.idfId]) {
      return acc
    }

    const reduceTransactions = {
      idf: idfs[cur.idfId],
      transactions: cur.transactions,
    }

    reduceTransactions.idf.policyId = cur.policyId

    acc.push(reduceTransactions)

    return acc
  }, [])
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  const data = transformIDFTransactionsData(transactions, policies)
  return new Format({
    ownerName,
    statementEnd,
    columns: vulAccountTransactionsColumns,
    title: Strings.vul.accountTransactions,
    sheetname: Strings.vul.worksheetName.accountTransactions,
    vulTable: true,
  }).getTableAsXLSXInput(data)
}

export class AccountTransactionsTable extends React.Component<AccountTransactionsTableProps> {
  monthlyStatements = () => {
    const { month, statements } = this.props
    statements.filter(s => s.statementStart === month)
    return statements
  }

  allIDFfTransactions = () => {
    const { idfs } = this.props
    const statements = this.monthlyStatements()

    const transactions: Array<{
      idf: IDF,
      transactions: Array<IDFTransaction>,
    }> = statements.reduce((acc, cur) => {
      if (!idfs[cur.idfId]) {
        return acc
      }

      const reduceTransactions = {
        idf: idfs[cur.idfId],
        transactions: cur.transactions,
      }

      reduceTransactions.idf.policyId = cur.policyId

      acc.push(reduceTransactions)

      return acc
    }, [])
    return transactions
  }

  renderVariances = () => {
    const { variances } = this.props

    if (variances.length) {
      return <VariancesNote variances={variances} />
    }
    return null
  }

  renderFootnotes = () => {
    const { notes } = this.props
    if (notes.length) {
      return <Footnotes notes={notes} />
    }
    return null
  }

  // eslint-disable-next-line class-methods-use-this
  groupTransactions(arr) {
    const result = []
    const keys = ['dateFormat', 'transaction_type', 'idfProductName'] // array of keys to merge duplicates by
    arr.forEach(item => {
      const keyValues = keys.map(key => item[key])
      const existingObj = result.find(obj => keys.every((key, i) => obj[key] === keyValues[i]))
      if (existingObj) {
        existingObj.withdrawals += item.withdrawals
        existingObj.deposits += item.deposits
      } else {
        result.push(Object.assign({}, item))
      }
    })
    return result
  }

  render() {
    const {
      ownerName,
      loading,
      statements,
      pdfKey,
      policies,
      getAccountTransactionsColumns,
      variances,
    } = this.props
    const transactions = this.allIDFfTransactions()
    let data = transformIDFTransactionsData(transactions, policies)
    const ppaSelected = statements[0] && statements[0] instanceof PPAStatement
    const vulSelected = statements[0] && statements[0] instanceof VULStatement
    let columnData = getAccountTransactionsColumns
    if (
      (pdfKey === 'ppli-statement-detail' || pdfKey === 'ppa-statement-detail') &&
      data?.length > 1 &&
      variances?.length > 1
    ) {
      data = this.groupTransactions(data)
      columnData = columnData.filter(({ accessor }) => accessor !== 'accountId')
    }
    return (
      <div className={styles.removeTableMarginBottom}>
        <GolcondaTable
          ownerName={ownerName}
          loading={loading}
          data={data}
          columns={columnData}
          sortBy="date"
          sortOrder="desc"
          download
          ppaTable={ppaSelected}
          vulTable={vulSelected}
          pdfKey={pdfKey}
        />
        {!!data.length && this.renderVariances()}
        {this.renderFootnotes()}
      </div>
    )
  }
}

type AccountTransactionsTableWrapperProps = {|
  ownerName: ?string,
  notes: Array<FootnoteType>,
  statements: Array<PPStatementType>,
  loading: boolean,
  month: string,
  idfs: { [string]: IDF },
  variances: Array<VarianceType>,
  pdfKey: PDFKeysType,
  policies: { [string]: PPPolicyType },
|}

export const AccountTransactionsPPATable = (props: AccountTransactionsTableWrapperProps) => (
  <AccountTransactionsTable
    {...props}
    getAccountTransactionsColumns={ppaAccountTransactionsColumns}
  />
)

export const AccountTransactionsPPLITable = (props: AccountTransactionsTableWrapperProps) => (
  <AccountTransactionsTable
    {...props}
    getAccountTransactionsColumns={ppliAccountTransactionsColumns}
  />
)

export const AccountTransactionsVULTable = (props: AccountTransactionsTableWrapperProps) => (
  <AccountTransactionsTable
    {...props}
    getAccountTransactionsColumns={vulAccountTransactionsColumns}
  />
)
