// @flow
import BaseModel from '../base/base-model'
import { defaultString } from '../defaults'

export type ConsolidatedStatementDataType = {|
  id: ?string,
  url: ?string,
  filename: ?string,
|}

export default class ConsolidatedStatement extends BaseModel {
  id: string

  url: string

  filename: string

  constructor(
    data: ConsolidatedStatementDataType = {
      id: null,
      url: null,
      filename: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.url = defaultString(data.url)
    this.filename = defaultString(data.filename)
  }
}
