// @flow
import moment from 'moment'
import { Enums } from '@/constants'
import { formatPrice } from '@/helpers'
import { Entity, PremiumPayment } from '@/models'

export default class BasePolicy {
  premiumPayments: Array<PremiumPayment>

  premiumAmount: number

  premiumMode: $Values<typeof Enums.PremiumMode>

  policyDate: string

  premiumPayor: Entity

  constructor(data: *) {
    this.premiumPayor = data.premium_payor ? new Entity(data.premium_payor) : new Entity()
  }

  get premium(): number {
    return this.annualPremium
  }

  get premiumFormat(): string {
    return formatPrice(this.premium)
  }

  get policyDateMoment(): moment {
    return moment(this.policyDate)
  }

  get formattedPolicyDate(): string {
    return this.policyDateMoment.format('M/D/YYYY')
  }

  premiumAmountAtDate(date: ?string): number {
    if (this.premiumPayments.length) {
      if (date) {
        const premiumPayment = this.premiumPayments.find((premium: PremiumPayment) =>
          moment(premium.startDate).isBefore(moment(date)),
        )
        if (premiumPayment) {
          return premiumPayment.amount
        }
      }
      return this.premiumPayments[0].amount
    }
    return 0
  }

  get annualPremium(): number {
    let premium = this.premiumAmount
    switch (this.premiumMode) {
      case Enums.PremiumMode.SEMI_ANNUAL:
        premium = this.premiumAmount * 2
        break
      case Enums.PremiumMode.QUARTERLY:
        premium = this.premiumAmount * 4
        break
      case Enums.PremiumMode.MONTHLY:
        premium = this.premiumAmount * 12
        break
      default:
        premium = this.premiumAmount
    }
    return premium
  }
}
