// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import type { RouterHistory } from 'react-router-dom'

type ScrollToTopProps = {
  history: RouterHistory,
  children: React.Node,
}

class ScrollToTop extends React.Component<ScrollToTopProps> {
  componentDidMount() {
    const { history } = this.props

    let prevPathname = history.location.pathname

    history.listen(({ pathname }) => {
      if (pathname !== prevPathname) {
        window.scrollTo(0, 0)

        prevPathname = pathname
      }
    })
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default withRouter(ScrollToTop)
