/* eslint-disable max-lines */
// @flow
import { merge } from 'lodash'
import moment from 'moment'
import { Strings, Enums, ModelTypes } from '@/constants'
import { formatPrice, camelObjectToSnakeObject } from '@/helpers'

import { Entity, Product } from '@/models'
import BasePolicy from '../base/base-policy'

import { defaultArray, defaultFloat, defaultString, defaultEnum } from '../defaults'

export type InsurancePolicyShortDataType = {|
  account_id: ?string,
  premium_amount: ?number,
  beneficiary: ?Array<EntityDataType>,
  cost_basis: ?number,
  id: ?string,
  insurance_proceeds: ?number,
  insured: ?Array<EntityDataType>,
  policy_date: ?string,
  policy_documents: ?Array<PolicyDocumentDataType>,
  policy_gross_account_value: ?number,
  paid_to: ?string,
  premium_mode: ?$Values<typeof Enums.PremiumMode>,
  product: ?ProductDataType,
  termination_date: ?string,
  owner: ?EntityDataType,
  latest_statement_end_date: ?string,
  notes_visibility: ?boolean,
|}

const defaultDataValues = {
  account_id: null,
  premium_amount: null,
  beneficiary: null,
  cost_basis: null,
  id: null,
  insurance_proceeds: null,
  insured: null,
  policy_date: null,
  policy_documents: null,
  policy_gross_account_value: null,
  paid_to: null,
  premium_mode: null,
  product: null,
  termination_date: null,
  latest_statement_end_date: null,
  owner: null,
  notes_visibility: false,
}

export default class InsurancePolicyCalendar extends BasePolicy {
  accountId: string

  beneficiary: Array<Entity>

  costBasis: number

  id: string

  insuranceProceeds: number

  insured: Array<Entity>

  policyDate: string

  policyDocuments: Array<string>

  policyGrossAccountValue: number

  paidTo: string

  product: Product

  terminationDate: string

  owner: Entity

  latestStatementEndDate: string

  modelName: typeof ModelTypes.InsurancePolicy

  notesVisibility: boolean

  constructor(rawData: InsurancePolicyCalendar | InsurancePolicyShortDataType = defaultDataValues) {
    const data: InsurancePolicyShortDataType =
      rawData instanceof InsurancePolicyCalendar
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData.policy))
        : rawData.policy
    const paidTo: string = rawData.date

    super(data)

    this.id = defaultString(data.id)
    this.modelName = ModelTypes.InsurancePolicy
    this.accountId = defaultString(data.account_id)
    this.premiumAmount = defaultFloat(data.premium_amount)
    this.beneficiary = defaultArray(data.beneficiary).map(i => new Entity(i))
    this.costBasis = defaultFloat(data.cost_basis)
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.insured = defaultArray(data.insured).map(i => new Entity(i))
    this.policyDate = defaultString(data.policy_date)
    this.policyDocuments = defaultArray(data.policy_documents)
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.paidTo = defaultString(paidTo)
    this.premiumMode = defaultEnum(data.premium_mode, Enums.PremiumMode)
    this.terminationDate = defaultString(data.termination_date)
    this.owner = data.owner ? new Entity(data.owner) : new Entity()
    this.product = data.product ? new Product(data.product) : new Product()
    this.latestStatementEndDate = defaultString(data.latest_statement_end_date)
    this.notesVisibility = data.notes_visibility || false
  }

  static modelName = ModelTypes.InsurancePolicy

  getFaceValues(ccTypes: Array<string>) {
    if (!ccTypes || ccTypes.length === 0) {
      return ''
    }
    const ccTypeToValue = {
      statements: 4,
      premium: 5,
      policy_changes: 6,
    }

    const faceValues = ccTypes.map(ccType => ccTypeToValue[ccType] || '')

    return faceValues.sort().join(',')
  }

  get insuranceCompanyName(): string {
    return defaultString(this.product?.insuranceCompany?.name)
  }

  get title(): string {
    const { accountId, insuranceCompanyName } = this
    return `${insuranceCompanyName}\xA0 - \xA0${accountId}`
  }

  get beneficiaryString(): string {
    return this.beneficiary.map(i => i.formattedName).join(', ')
  }

  get insuredString(): string {
    return this.insured.map(i => i.formattedName).join(', ')
  }

  get policyType(): string {
    return defaultString(this.product?.productType)
  }

  get ownerString(): string {
    return this.owner.formattedName
  }

  get costBasisFormat(): string {
    return formatPrice(this.costBasis)
  }

  get formattedPaidTo(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedPremiumMode(): string {
    const { ANNUAL, SEMI_ANNUAL, QUARTERLY, MONTHLY, SINGLE_PAY, PERIODIC } = Enums.PremiumMode
    let mode = Strings.annual
    switch (this.premiumMode) {
      case ANNUAL:
        mode = Strings.annual
        break
      case SEMI_ANNUAL:
        mode = Strings.semiAnnual
        break
      case QUARTERLY:
        mode = Strings.quarterly
        break
      case MONTHLY:
        mode = Strings.monthy
        break
      case SINGLE_PAY:
        mode = Strings.singlePay
        break
      case PERIODIC:
        mode = Strings.periodic
        break
      default:
        mode = Strings.annual
    }
    return mode
  }

  get formattedAsOfDate(): ?string {
    return this.latestStatementEndDate
      ? moment(this.latestStatementEndDate).format('M/D/YYYY')
      : null
  }

  get detailFilename(): string {
    return Strings.policyDetailFilename(this.insuranceCompanyName, this.accountId)
  }

  get formattedPremiumDueDate(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('MM DD')
    }
    return Strings.dash
  }
}
