// @flow
import React from 'react'
import styled from '@emotion/styled'
import Select from 'react-select'
import styles from './styles.scss'

type StyledReactProps = {
  error: ?string,
}

const StyledReact = (props: StyledReactProps) => {
  const { error } = props
  const StyleSelect = styled(Select)`
    ${error
      ? `
      > div {
        border-bottom-color: ${styles.error};
      }
    `
      : ''};
  `
  return <StyleSelect {...props} />
}

export default StyledReact
