// @flow
import React from 'react'

import { formatTableValue } from '@/helpers'

type CurrencyCellProps = {|
  row: RowType,
  first?: boolean,
  single?: boolean,
  showTotal: boolean,
  decimals?: number,
  bold?: boolean,
  showDollar?: boolean,
  underline?: boolean,
  accessor: string,
|}

const CurrencyCell = (props: CurrencyCellProps) => {
  const { bold, row, first, single, showDollar, showTotal, decimals, underline, accessor } = props

  const isLast = row.original.last
  // If the first column's row.value is `Subtotal` we assume we are in a subtotal row
  const isSubtotal = row.original[Object.keys(row.original)[0]] === 'Subtotal'

  let className =
    row.viewIndex === 0 || showDollar ? 'tableCell currency dollarSign' : 'tableCell currency'
  className += typeof row.value === 'number' && row.value === 0 ? ' nullValue' : ''
  className += isLast && showTotal ? ' last' : ''
  className += single ? ' single' : ''
  className += first ? ' first' : ''
  className += isSubtotal && showTotal ? ' subtotal' : ''
  className += bold ? ' bold' : ''
  className += underline ? ' underline' : ''

  return (
    <div className={className} data-accessor={`currency-${accessor}`}>
      <span className="value">{formatTableValue(row.value, isSubtotal, decimals)}</span>
      <span className="dollarSignText">$</span>
    </div>
  )
}

CurrencyCell.defaultProps = {
  bold: false,
  decimals: 0,
  showDollar: false,
  underline: false,
  first: false,
}

export default CurrencyCell
