import { css } from '@emotion/core'
import styles from './styles.scss'

const Styles = {
  span: props => css`
    ${props.error
      ? `
          color: ${styles.red};
        `
      : ``};
  `,
  label: props => css`
    ${props.disabled
      ? `
      cursor: auto;
      color: ${styles.disabledGrey};
    `
      : `
      color: ${styles.black};
    `};
  `,
  toggle: props => css`
    ${props.disabled
      ? `
      cursor: auto;
      background: ${styles.disabledGrey} !important;
    `
      : ``}
  `,
  container: props => css`
    ${props.disabled
      ? `
    cursor: auto;
  `
      : `
    cursor: pointer;
    `}
  `,
}

export default Styles
