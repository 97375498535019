// @flow
import * as React from 'react'
// WiDont: Avoid widowed words appearing on a single line in HTML by inserting a non-breaking space
// unicode character before the last word in the string of text.
export const widont = (text: string) => text.replace(/([^\s])\s+([^\s]+)\s*$/, '$1\u00a0$2')

// replaceInStrings: Replace within a nested object full of strings
export const replaceInStrings = (stringObj: any, replace: string, replaceWith: string) => {
  const obj = stringObj
  Object.keys(obj).forEach(key => {
    let el = obj[key]
    if (typeof el === 'string') {
      const re = new RegExp(replace, 'g')
      el = el.replace(re, replaceWith)
      obj[key] = el
    }
    if (typeof el === 'object') {
      if (React.isValidElement(el)) {
        el.props.children = el.props.children.map(child => {
          if (typeof child === 'string') {
            const re = new RegExp(replace, 'g')
            return child.replace(re, replaceWith)
          }
          return replaceInStrings(child, replace, replaceWith)
        })
      } else if (el) {
        replaceInStrings(el, replace, replaceWith)
      }
    }
  })
  return obj
}

export default { widont, replaceInStrings }
