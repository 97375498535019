import { css } from '@emotion/core'
import styles from './styles.scss'

const { gold, secondaryBtnActive, white, blue, lightBlue, lightGold, darkGold, darkBlue } = styles

const Styles = {
  button: props => css`
    ${props.variant === 'primary'
      ? `
          background: ${gold};
          border: 1px solid ${gold};
          border: none;
          color: ${white};
          :hover {
            border: 1px solid ${lightGold};
            background: ${lightGold}
          }
          :active {
            border: 1px solid ${darkGold};
            background: ${darkGold};
          }
      `
      : ''}

    ${props.variant === 'secondary'
      ? `
          background: ${white};
          border: 1px solid ${gold};
          color: ${props.disabled ? white : gold};
          :hover {
            background: ${gold};
            color: ${white};
          }
          :active {
            background: ${secondaryBtnActive};
            color: ${white};
          }
          `
      : ''}

    ${props.variant === 'secondaryBlue'
      ? `
          background: ${blue};
          border: 1px solid ${blue};
          border: none;
          color: ${white};
          :hover {
            border: 1px solid ${lightBlue};
            background: ${lightBlue}
          }
          :active {
            border: 1px solid ${darkBlue};
            background: ${darkBlue};
          }
      `
      : ''}
  `,
}
export default Styles
