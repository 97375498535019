// @flow

import * as React from 'react'
import {
  Annuity,
  AnnuityShort,
  Disability,
  InsurancePolicy,
  InsurancePolicyShort,
  PrivatePlacement,
  PrivatePlacementShort,
  VULPolicy,
  VULPolicyShort,
} from '@/models'
import { PolicyDetailGeneral } from '@/components'
import ApiService from '@/services/ApiService'

type PolicyDetailExpandableCardProps = {|
  policy:
    | Annuity
    | AnnuityShort
    | InsurancePolicy
    | InsurancePolicyShort
    | PrivatePlacement
    | PrivatePlacementShort
    | VULPolicy
    | VULPolicyShort
    | Disability,
  pdfKey: PDFKeysType,
  printPDF?: ?(payload: ApiPayloadType<PrintDetailedPayload>) => void,
  printPending?: boolean,
  _printMeta: ?{ id: string },
|}

type PolicyDetailExpandableCardState = {|
  policyDetails: ?Annuity | ?InsurancePolicy | ?PrivatePlacement | ?VULPolicy | ?Disability,
  loading: boolean,
|}

export default class PolicyDetailExpandableCard extends React.Component<
  PolicyDetailExpandableCardProps,
  PolicyDetailExpandableCardState,
> {
  state = {
    policyDetails: null,
    loading: false,
  }

  onPolicyExpanded = () => {
    const { policy } = this.props
    const { policyDetails } = this.state
    if (policyDetails) return
    let apiCall = null

    if (policy instanceof InsurancePolicy || policy instanceof InsurancePolicyShort) {
      apiCall = ApiService.getInsurancePolicyById
    } else if (policy instanceof Annuity || policy instanceof AnnuityShort) {
      apiCall = ApiService.getAnnuityPolicyById
    } else if (policy instanceof PrivatePlacement || policy instanceof PrivatePlacementShort) {
      apiCall = ApiService.getPrivatePlacementById
    } else if (policy instanceof VULPolicy || policy instanceof VULPolicyShort) {
      apiCall = ApiService.getVULPolicyById
    } else if (policy instanceof Disability) {
      apiCall = ApiService.getDisabilityPolicyById
    }

    if (apiCall) {
      this.setState({ loading: true })
      apiCall(policy.id)
        .then(res => {
          if (res) {
            this.setState({ policyDetails: res.data, loading: false })
          }
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  render() {
    const { printPDF, printPending, _printMeta, pdfKey, policy } = this.props
    const { policyDetails, loading } = this.state

    return (
      <PolicyDetailGeneral
        policy={policyDetails || policy}
        pdfKey={pdfKey}
        download
        printPDF={printPDF}
        printPending={printPending}
        _printMeta={_printMeta}
        showContentLoader={loading}
        onExpand={this.onPolicyExpanded}
      />
    )
  }
}
