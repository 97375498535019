// @flow
import React from 'react'
import { Strings, Images } from '@/constants'
import styles from './styles.scss'

const Throbber = () => (
  <div className={styles.throbber}>
    <img className={styles.circular} src={Images.spinner} alt={Strings.loading} />
  </div>
)

export default Throbber
