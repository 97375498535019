// @flow
import { isEmpty, isEqual, debounce } from 'lodash'
import * as Sentry from '@sentry/browser'

import { IDFStatement, IDFTransaction, XIRRRateIDF } from '@/models'
import { makeDate } from '@/models/defaults'
import { xirrIDF, xirrIDFTotal } from '@/helpers/financial'
import { Strings, MaxTableColumnWidths, TableColumnWidths, TablePropAccessors } from '@/constants'

export type IDFReturnsRow = {
  statement_return_value?: number,
  statement_ending_balance?: number,
  statement_gross_return_month?: string,
  statement_gross_return_ytd?: string,
}

const checkDiscrepancy = debounce(debug => {
  if (
    !isEmpty(debug.backend) &&
    !isEmpty(debug.frontend) &&
    !isEqual(debug.backend, debug.frontend)
  ) {
    // eslint-disable-next-line no-console
    console.log('[DEBUG] Discrepancy in XIRR calculation for IDF Returns: ', debug)

    Sentry.captureMessage('Discrepancy in XIRR calculation for IDF Returns', {
      extra: { debug },
    })
  }
}, 2000)

const sumFieldOnStatements = (
  fieldName: $Keys<IDFTransaction> | $Keys<IDFStatement>,
  statements: Array<IDFTransaction | IDFStatement>,
  // $FlowFixMe
) => statements.reduce((acc: number, cur: IDFTransaction | IDFStatement) => acc + cur[fieldName], 0)

export const ppliIDFReturnsColumns = (
  startDate: string,
  endDate: string,
  idfReturnsBefore: string,
) => [
  {
    name: Strings.table.idf,
    accessor: TablePropAccessors.product_name,
    footerText: Strings.table.total,
    maxWidth: MaxTableColumnWidths.maxIDF,
    width: TableColumnWidths.idf,
  },
  {
    name: Strings.table.idfValue,
    subtitle: startDate,
    accessor: TablePropAccessors.beginning_balance,
    headerStyle: { flexDirection: 'column' },
    isCurrency: true,
    width: TableColumnWidths.idfValue,
    showTotal: true,
  },
  {
    name: Strings.table.depositsWithdrawals,
    accessor: TablePropAccessors.deposits_withdrawals,
    isCurrency: true,
    width: TableColumnWidths.depositsWithdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
    showTotal: true,
  },
  {
    name: Strings.table.idfReturn,
    accessor: TablePropAccessors.statement_return_value,
    isCurrency: true,
    width: TableColumnWidths.idfReturn,
    showTotal: true,
  },
  {
    name: Strings.table.idfValue,
    subtitle: endDate,
    accessor: TablePropAccessors.statement_ending_balance,
    headerStyle: { flexDirection: 'column' },
    isCurrency: true,
    width: TableColumnWidths.idfValue,
    showTotal: true,
  },
  {
    accessor: null,
    name: idfReturnsBefore,
    columns: [
      {
        name: Strings.table.month,
        accessor: TablePropAccessors.statement_gross_return_month,
        nested: true,
        isPercentage: true,
        width: TableColumnWidths.idfReturnsBeforeSubField,
        subtotalColumn: TablePropAccessors.statement_gross_return_month_total,
      },
      {
        name: Strings.table.yearToDate,
        accessor: TablePropAccessors.statement_gross_return_ytd,
        nested: true,
        isPercentage: true,
        width: TableColumnWidths.idfReturnsBeforeSubField,
        subtotalColumn: TablePropAccessors.statement_gross_return_ytd_total,
      },
    ],
  },
  {
    name: 'IDF Manager Reporting',
    accessor: TablePropAccessors.idf_factsheet,
    headerStyle: { flexDirection: 'column' },
    width: TableColumnWidths.idfValue,
    onClick: 'onClick',
    factSheetIcon: true,
  },
]

export const ppaIDFReturnsColumns = (
  startDate: string,
  endDate: string,
  idfReturnsBefore: string,
) => [
  {
    name: Strings.table.idf,
    accessor: TablePropAccessors.product_name,
    footerText: Strings.table.total,
    maxWidth: MaxTableColumnWidths.maxIDF,
    width: TableColumnWidths.idf,
  },
  {
    name: Strings.table.idfValue,
    subtitle: startDate,
    accessor: TablePropAccessors.beginning_balance,
    headerStyle: { flexDirection: 'column' },
    isCurrency: true,
    width: TableColumnWidths.idfValue,
    showTotal: true,
  },
  {
    name: Strings.table.depositsWithdrawals,
    accessor: TablePropAccessors.deposits_withdrawals,
    isCurrency: true,
    width: TableColumnWidths.depositsWithdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
    showTotal: true,
  },
  {
    name: Strings.table.idfReturn,
    accessor: TablePropAccessors.statement_return_value,
    isCurrency: true,
    width: TableColumnWidths.idfReturn,
    showTotal: true,
  },
  {
    name: Strings.table.idfValue,
    subtitle: endDate,
    accessor: TablePropAccessors.statement_ending_balance,
    headerStyle: { flexDirection: 'column' },
    isCurrency: true,
    width: TableColumnWidths.idfValue,
    showTotal: true,
  },
  {
    accessor: null,
    name: idfReturnsBefore,
    columns: [
      {
        name: Strings.table.month,
        accessor: TablePropAccessors.statement_gross_return_month,
        nested: true,
        isPercentage: true,
        width: TableColumnWidths.idfReturnsBeforeSubField,
        subtotalColumn: TablePropAccessors.statement_gross_return_month_total,
      },
      {
        name: Strings.table.yearToDate,
        accessor: TablePropAccessors.statement_gross_return_ytd,
        nested: true,
        isPercentage: true,
        width: TableColumnWidths.idfReturnsBeforeSubField,
        subtotalColumn: TablePropAccessors.statement_gross_return_ytd_total,
      },
    ],
  },
  {
    name: 'IDF Manager Reporting',
    accessor: TablePropAccessors.idf_factsheet,
    headerStyle: { flexDirection: 'column' },
    width: TableColumnWidths.idfValue,
    onClick: 'onClick',
    factSheetIcon: true,
  },
]

export const vulIDFReturnsColumns = (
  startDate: string,
  endDate: string,
  idfReturnsBefore: string,
) => [
  {
    name: Strings.table.vul.investmentSubaccount,
    accessor: TablePropAccessors.product_name,
    footerText: Strings.table.total,
    maxWidth: MaxTableColumnWidths.maxIDF,
    width: TableColumnWidths.idf,
  },
  {
    name: Strings.table.vul.subaccountValue,
    subtitle: startDate,
    accessor: TablePropAccessors.beginning_balance,
    headerStyle: { flexDirection: 'column' },
    isCurrency: true,
    width: TableColumnWidths.subaccountValue,
    showTotal: true,
  },
  {
    name: Strings.table.depositsWithdrawals,
    accessor: TablePropAccessors.deposits_withdrawals,
    isCurrency: true,
    width: TableColumnWidths.depositsWithdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
    showTotal: true,
  },
  {
    name: Strings.table.vul.subaccountReturn,
    accessor: TablePropAccessors.statement_return_value,
    isCurrency: true,
    width: TableColumnWidths.idfReturn,
    showTotal: true,
  },
  {
    name: Strings.table.vul.subaccountValue,
    subtitle: endDate,
    accessor: TablePropAccessors.statement_ending_balance,
    headerStyle: { flexDirection: 'column' },
    isCurrency: true,
    width: TableColumnWidths.subaccountValue,
    showTotal: true,
  },
  {
    accessor: null,
    name: idfReturnsBefore,
    columns: [
      {
        name: Strings.table.month,
        accessor: TablePropAccessors.statement_gross_return_month,
        nested: true,
        isPercentage: true,
        width: TableColumnWidths.subaccountReturnsBeforeSubField,
        subtotalColumn: TablePropAccessors.statement_gross_return_month_total,
      },
      {
        name: Strings.table.yearToDate,
        accessor: TablePropAccessors.statement_gross_return_ytd,
        nested: true,
        isPercentage: true,
        width: TableColumnWidths.subaccountReturnsBeforeSubField,
        subtotalColumn: TablePropAccessors.statement_gross_return_ytd_total,
      },
    ],
  },
]

export const transformIDFReturnsData = (
  getIDFReturnsColumns: (startDate: string, endDate: string, idfReturnsBefore: string) => Array<*>,
  statements: Array<IDFStatement>,
  idfs: { [string]: IDF },
  month: string,
  idfReturnsBefore: string,
  xirrRatesIDF: XIRRRateIDF,
): [Array<TableFormats>, Array<TableColumnType>] => {
  const statementYear = month.slice(0, 4)
  let statementStartFormat = ''
  let statementEndFormat = ''
  const currentDate = makeDate(month)
  const [monthStatements, yearStatements] = statements.reduce(
    (acc, origCurr) => {
      const curr = origCurr
      const [monthAcc, yearAcc] = acc
      curr.idf = idfs[curr.idfId]
      if (curr.statementStart === month) {
        monthAcc.push(curr)
      }
      const currYear = curr.statementStart.slice(0, 4)
      if (statementYear === currYear && curr.statementStartDate <= currentDate) {
        yearAcc.push(curr)
      }
      return [monthAcc, yearAcc]
    },
    [[], []],
  )
  if (monthStatements[0]) {
    ;[{ statementStartFormat, statementEndFormat }] = monthStatements
  }
  const columns = getIDFReturnsColumns(statementStartFormat, statementEndFormat, idfReturnsBefore)
  const reduceToProductObj = (acc, curr) => {
    if (curr._idf) {
      const { productName } = curr._idf.product
      if (Array.isArray(acc[productName])) {
        acc[productName].push(curr)
      } else {
        acc[productName] = [curr]
      }
    }
    return acc
  }
  const monthStatementsByProducts = monthStatements.reduce(reduceToProductObj, {})
  const debug = {
    backend: { ...xirrRatesIDF },
    frontend: {
      products: {},
      total: {},
    },
  }
  const data = (Object.values(monthStatementsByProducts): any)
    .map(s => {
      const { productName } = s[0]._idf?.product
      const xirrByProductId = xirrRatesIDF.products ? xirrRatesIDF.products[productName] : {}
      const xirrTotal = xirrRatesIDF.total ? xirrRatesIDF.total : {}
      const idfFactsheetUrl = xirrByProductId?.idfFactsheets
        ? xirrByProductId.idfFactsheets.url
        : ''
      if (
        xirrRatesIDF &&
        xirrRatesIDF.products &&
        Object.prototype.hasOwnProperty.call(xirrRatesIDF.products, productName)
      ) {
        debug.frontend.products[productName] = {
          month: xirrIDF(s),
          year: xirrIDF(
            yearStatements.filter(
              y => y._idf && y._idf?.product.productName === s[0]._idf?.product.productName,
            ),
          ),
        }
      }

      debug.frontend.total = {
        month: xirrIDFTotal(monthStatements),
        year: xirrIDFTotal(yearStatements),
      }

      return {
        product_name: productName,
        beginning_balance: sumFieldOnStatements('beginningBalance', s),
        deposits_withdrawals: s.reduce((acc, curr) => {
          let a = acc
          a += sumFieldOnStatements('transactionAmount', curr.transactions)
          return a
        }, 0),
        statement_return_value: sumFieldOnStatements('returnValue', s),
        statement_ending_balance: sumFieldOnStatements('accountValue', s),
        statement_gross_return_month: xirrByProductId?.month || 0,
        statement_gross_return_ytd: xirrByProductId?.year || 0,
        statement_gross_return_month_total: xirrTotal?.month || 0,
        statement_gross_return_ytd_total: xirrTotal?.year || 0,
        idf_factsheet: idfFactsheetUrl,
      }
    })
    .sort((a, b) => {
      // The IDFs should be listed in alphabetically order...
      if (a.product_name < b.product_name) return -1
      if (a.product_name > b.product_name) return 1
      return 0
    })

  // ...except Money Market which should be always at the end
  const moneyMarketIndex = data.findIndex(a => a.product_name === 'Money Market')

  if (moneyMarketIndex !== -1) {
    data.push(data.splice(moneyMarketIndex, 1).pop())
  }

  checkDiscrepancy(debug)
  return [data, columns]
}
