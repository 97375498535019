// @flow
import { css } from '@emotion/core'
import moment from 'moment'
import type Moment from 'moment'
import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-style'
import metrics from '@/../src/styles/metrics.scss'
import { Calendar, Loading } from '@/components'
import type { DateType } from '@/constants'
import {
  CHART_OPTIONS,
  secondaryDatasetOptions,
  primaryDatasetOptions,
  yLinePlugin,
} from './constants'
import styles from './styles.scss'

type SmoothLineChartProps = {|
  labels?: ?Array<string>,
  primaryDataset: {
    label: string,
    data: Array<number>,
  },
  secondaryDataset: {
    label: string,
    data: Array<number>,
  },
  startDate: Moment,
  endDate: Moment,
  minStartDate: Moment,
  maxEndDate: Moment,
  handleDateChange: (date: Moment, which: DateType) => void,
  loading: boolean,
  title?: ?string,
  hideDateSelector?: boolean,
  noLineTension?: boolean,
|}

const CHART_HEIGHT = 240
const CHART_WIDTH = 1145

type ChartContainerProps = {
  loading: boolean,
  children: React.Node,
}

const ChartContainer = ({ loading, children }: ChartContainerProps) => (
  <div
    css={css`
      ${loading &&
      css`
        height: ${metrics.chartContainerHeight};
      `}
    `}
  >
    {children}
  </div>
)

export class SmoothLineChart extends React.Component<SmoothLineChartProps> {
  _chartRef = React.createRef()

  static defaultProps = {
    hideDateSelector: false,
    labels: null,
    title: null,
  }

  // componentDidMount() {
  // Chart.plugins.register(yLinePlugin)
  // }

  static getChartData = (
    labels: ?Array<string>,
    primaryDataset: { label: string, data: Array<number> },
    secondaryDataset: { label: string, data: Array<number> },
    noLineTension: boolean = false
  ) => {
    // in cases where dataset only contains a single value, copies it so that chart will show a flat line the full width of chart
    if (primaryDataset.data.length === 1) {
      const length = secondaryDataset.data.length > 1 ? secondaryDataset.data.length : 2
      for (let i = 0; i < length; i++) {
        primaryDataset.data.push(primaryDataset.data[0])
      }
    }
    if (secondaryDataset.data.length === 1) {
      const length = primaryDataset.data.length > 1 ? primaryDataset.data.length : 2
      for (let i = 0; i < length; i++) {
        secondaryDataset.data.push(secondaryDataset.data[0])
      }
    }
    let tickLabels = labels
    if (labels && labels[0] && labels.length === 1) {
      // Ensure that if only one value, label is copied
      tickLabels = [labels[0], labels[0]]
    }

    const chartLabels: Array<string> =
      tickLabels || [...Array(primaryDataset.data.length).keys()].map(() => '')

    const chartData = (canvas?: HTMLCanvasElement) => {
      const primaryDatasetOptionsInfo = {...primaryDatasetOptions(canvas)}
      const secondaryDatasetOptionsInfo = {...secondaryDatasetOptions(canvas)}
      if(noLineTension) {
        primaryDatasetOptionsInfo.lineTension = 0
        secondaryDatasetOptionsInfo.lineTension = 0
      }
      return {
        labels: chartLabels,
        datasets: [
          {
            ...primaryDatasetOptionsInfo,
            data: primaryDataset.data,
            label: primaryDataset.label,
          },
          {
            ...secondaryDatasetOptionsInfo,
            data: secondaryDataset.data,
            label: secondaryDataset.label,
          },
        ],
      };
    }

    return chartData
  }

  handleDateChange = (date: string, which: DateType) => {
    const { handleDateChange } = this.props
    const momentObj = moment(date)
    handleDateChange(momentObj, which)
  }

  renderLegend = () => {
    const { primaryDataset, secondaryDataset } = this.props
    return (
      <div className={styles.legend}>
        <span className={styles.legendItem}>
          <span className={styles.primaryLegend} />
          {primaryDataset.label}
        </span>
        <span className={styles.legendItem}>
          <span className={styles.secondaryLegend} />
          {secondaryDataset.label}
        </span>
      </div>
    )
  }

  renderLegendAndCalendar = () => {
    const { hideDateSelector, startDate, endDate, minStartDate, maxEndDate } = this.props

    let dateSelector = (
      <Calendar
        handleDateChange={this.handleDateChange}
        startDate={startDate}
        endDate={endDate}
        minStartDate={minStartDate}
        maxEndDate={maxEndDate}
      />
    )
    if (hideDateSelector) {
      dateSelector = null
    }

    return (
      <div className={styles.legendRow}>
        {this.renderLegend()}
        <div className={styles.dateSettings}>
          <div className={styles.calendarContainer}>{dateSelector}</div>
        </div>
      </div>
    )
  }

  renderChartTitle = () => {
    const { title } = this.props
    if (title) {
      return (
        <div className={styles.titleContainer}>
          <h3>{title}</h3>
        </div>
      )
    }
    return null
  }

  renderChart = () => {
    const { labels, primaryDataset, secondaryDataset, noLineTension } = this.props
    const chartOptions = CHART_OPTIONS(this._chartRef);
    return (
      <>
        {this.renderChartTitle()}
        {this.renderLegendAndCalendar()}
        <div className={styles.chart} id="chart-cont">
          <Line
            ref={this._chartRef}
            data={SmoothLineChart.getChartData(labels, primaryDataset, secondaryDataset, noLineTension)}
            options={chartOptions}
            height={CHART_HEIGHT}
            width={CHART_WIDTH}
            plugins={[yLinePlugin]}
            id="chart-js"
          />
        </div>
      </>
    )
  }

  renderSpinner = () => {
    const { loading } = this.props
    return loading ? <Loading /> : null
  }

  render() {
    const { loading } = this.props
    const content = loading ? this.renderSpinner() : this.renderChart()
    return (
      <ChartContainer loading={loading} className={styles.smoothLineChart}>
        {content}
      </ChartContainer>
    )
  }
}

export default SmoothLineChart
