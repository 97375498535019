// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Redirect } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import StorageService from '@/helpers/StorageService'
import selectors from '@/selectors'
import { User } from '@/models'
import { Routes } from '@/constants'

type StateToPropsType = {
  user: ?User,
}

type PrivateRouteProps = StateToPropsType & {
  component: React.Component<any, any>,
  exact?: boolean,
  path: string,
  history: RouterHistory,
}

class PrivateRoute extends React.Component<PrivateRouteProps> {
  static defaultProps = {
    exact: false,
  }

  render = () => {
    const { component, user, path, exact, history } = this.props
    const { pathname, search, hash } = history.location
    const next = encodeURIComponent(hash ? `${pathname}${search}${hash}` : `${pathname}${search}`)

    const authToken = StorageService.getAuth()

    if (user || authToken) {
      return <Route exact={exact} path={path} component={component} />
    }

    return <Redirect to={`${Routes.auth.Login}?next=${next}`} />
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
})

export default withRouter(connect(mapStateToProps)(PrivateRoute))
