// @flow
import { get, groupBy as _groupBy } from 'lodash'
import React from 'react'
import moment from 'moment'

import { defaultBoolean, defaultString } from '@/models/defaults'

import {
  HeaderCell,
  HeaderCellSortable,
  Cell,
  CurrencyCell,
  FooterCell,
  FooterCellPercentage,
  FooterCellTotal,
  PercentageCell,
} from '@/components/table'
import { TableColumnWidths, TablePropAccessors, Strings } from '@/constants'
import styles from './styles.scss'

type FormatProps = {|
  ownerName: ?string,
  statementEnd?: string,
  title?: string,
  sheetname?: string,
  columns: Array<TableColumnType>,
  ppaTable?: boolean,
  vulTable?: boolean,
|}

const nestedHeaderStyles = {
  height: styles.legendItemHeight,
  position: 'relative',
  top: styles.legendItemHeight,
}

const nestingHeaderStyles = {
  ...nestedHeaderStyles,
  height: `calc(${styles.legendItemHeight} - 1px)`,
  marginBottom: '1px',
  top: 0,
}

export const terminatedFilter = (item: *) => {
  if (item.terminationDate) {
    return moment(item.terminationDate).isBefore(moment(), 'day')
  }
  return false
}

export const inforceFilter = (item: *) => {
  if (item.terminationDate) {
    return moment(item.terminationDate).isAfter(moment(), 'day')
  }
  return true
}

export const currentYearFilter = (item: *) => {
  if (item.paidTo) {
    return moment(item.paidTo).isSameOrAfter(moment(), 'year')
  }
  return false
}

export class Format {
  ownerName: ?string

  statementEnd: ?string

  title: string

  sheetname: string

  columns: Array<TableColumnType>

  tableState: Array<*>

  ppaTable: boolean

  vulTable: boolean

  constructor(data: FormatProps) {
    this.ownerName = data.ownerName
    this.statementEnd = data.statementEnd
    this.title = defaultString(data.title)
    this.sheetname = defaultString(data.sheetname)
    this.columns = data.columns
    this.tableState = []
    this.ppaTable = defaultBoolean(data.ppaTable)
    this.vulTable = defaultBoolean(data.vulTable)
  }

  static getTerminatedPolicies = (policies: *) => policies.filter(terminatedFilter)

  static getInforcePolicies = (policies: *) => policies.filter(inforceFilter)

  static getCurrentYearPolicies = (policies: *) => policies.filter(currentYearFilter)

  setColumnWidth = (
    data: *,
    accessor: ModelKeys,
    headerText: string,
    maxWidth: number,
    minWidth: number,
  ) => {
    const magicSpacing = 10.5

    const cellLength = Math.max(
      ...data.map(row => {
        let value = ''

        if (typeof accessor === 'string') {
          value = get(row, accessor)
        } else {
          value = accessor(row)
        }

        if (typeof value === 'number') {
          return value.toString().length
        }
        if (value) {
          return (value.replace(/\W/g, '') || '').length
        }

        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.error('Value was undefined', { value, row, accessor })
        }

        // if value ends up undefined return a default
        return 1
      }),
      headerText.length,
    )

    return Math.max(minWidth, Math.min(maxWidth, cellLength * magicSpacing))
  }

  setTableState = (data: Array<*>) => {
    this.tableState = data
  }

  getTableFilename = () => {
    const clientName = this.ownerName ? `  -  ${this.ownerName}` : ''
    const defaultFilename = `${this.title}${clientName}`

    const annuityPortfolioTitle = `${Strings.annuityPortfolio.title}  -  ${Strings.table.inforcePolicies}`
    const lifePortfolioTitle = `${Strings.insurancePortfolio.title}  -  ${Strings.table.inforcePolicies}`
    const lifeCalendarTitle = `${Strings.insurancePortfolio.title}  -  ${Strings.table.premiumCalendar}`

    const ppaTableTitles = [
      Strings.ppa.accountSummary,
      Strings.ppa.accountDetail,
      Strings.ppa.accountTransactions,
    ]
    const ppliTableTitles = [
      Strings.ppli.accountSummary,
      Strings.ppli.accountDetail,
      Strings.ppli.accountTransactions,
    ]
    const vulTableTitles = [
      Strings.vul.accountSummary,
      Strings.vul.accountDetail,
      Strings.vul.accountTransactions,
    ]

    if (this.title === Strings.annuityPortfolio.title) {
      return Strings.annuityPortfolioSummaryFilename(clientName)
    }
    if (this.title === Strings.insurancePortfolio.title) {
      return Strings.lifePortfolioSummaryFilename(clientName)
    }
    if (this.title === annuityPortfolioTitle) {
      return Strings.annuityPortfolioInforceFilename(clientName)
    }
    if (this.title === lifePortfolioTitle) {
      return Strings.lifePortfolioInforceFilename(clientName)
    }
    if (this.title === lifeCalendarTitle) {
      return Strings.lifePortfolioCalendarFilename(clientName)
    }

    if (this.title === Strings.ppa.idfReturns) {
      if (this.ppaTable) {
        return Strings.ppaIDFFilename(this.statementEnd)
      }
      if (this.vulTable) {
        return Strings.vulIDFFilename(this.statementEnd)
      }
      return Strings.ppliIDFFilename(this.statementEnd)
    }
    if (ppaTableTitles.includes(this.title)) {
      return Strings.ppaFilename(this.statementEnd, this.title)
    }
    if (ppliTableTitles.includes(this.title)) {
      return Strings.ppliFilename(this.statementEnd, this.title)
    }
    if (vulTableTitles.includes(this.title)) {
      return Strings.vulFilename(this.statementEnd, this.title)
    }
    return defaultFilename
  }

  flattenColumns = (columns: Array<TableColumnType>) =>
    columns.reduce((acc: Array<TableColumnType>, item: TableColumnType) => {
      if (item.columns) {
        return [...acc, ...this.flattenColumns(item.columns)]
      }

      return [...acc, item]
    }, [])

  getTableAsXLSXInput = (data?: Array<*>): PayloadSheet => {
    const headerArray: SheetRow = []
    const templateArray: Array<ModelKeys> = []
    const outputArray: Array<SheetRow> = []

    const filename = this.getTableFilename()
    const { sheetname } = this

    let tableData = data
    if (!tableData) {
      tableData = this.tableState
    }

    if (!tableData.length) {
      return {
        filename,
        sheetname,
        data: [],
      }
    }

    const columns = this.flattenColumns(this.columns)

    // Create array of fields to include in XLSX as well as header titles
    columns.forEach((item: TableColumnType) => {
      headerArray.push({
        v: item.name,
        t: 's',
      })
      templateArray.push(item.accessor)
    })

    // Add header titles to array
    outputArray.push(headerArray)

    // Cycle through data and create array of required data
    tableData
      .filter(item => item.ownerString !== 'Subtotal')
      .forEach((item: *) => {
        const row: SheetRow = []
        templateArray.forEach((attr: ModelKeys, index: number) => {
          const value: { v: string | number, t: string, z?: string } = {
            v: '_',
            t: 's',
          }

          if (item) {
            value.v = item[attr]
            value.t = typeof item[attr] === 'number' ? 'n' : 's'
          } else if (process.env.NODE_ENV !== 'production') {
            // If the data doesn't match item can be undefined
            // eslint-disable-next-line no-console
            console.error('Item was undefined', { item, attr })
          }

          // $FlowFixMe: Even though this is a string, suppress it
          const valueAsMomentObj = moment(value.v, ['YYYY-MM-DD', 'MM DD'], true)

          if (valueAsMomentObj.isValid()) {
            const { format } = valueAsMomentObj.creationData()

            value.v =
              format === 'YYYY-MM-DD'
                ? valueAsMomentObj.format('MM/DD/YYYY')
                : valueAsMomentObj.format('MM-DD')
          }

          if (typeof value.v === 'number') {
            value.z = item.isPercentage || columns[index].isPercentage ? '0.00%' : '#,##0.00'
            value.v = item.isPercentage || columns[index].isPercentage ? value.v / 100 : value.v
          }

          row.push(value)
        })
        outputArray.push(row)
      })

    return {
      filename,
      sheetname,
      data: outputArray,
    }
  }

  getTableWidth = (table: Array<*>) => {
    let width = 0
    table.forEach(item => {
      if (!item.accessor && item.columns) {
        item.columns.forEach(i => {
          width += i.width
        })
      } else {
        width += item.width
      }
    })
    return width
  }

  getHeader = (
    item: TableColumnType,
    sortBy: string,
    sortOrder: SortOrderType,
    sortData: Function,
  ) => {
    if (!item) return null

    const subtitle = item.subtitle ? (
      <div className={styles.headerSubtitle}>{item.subtitle}</div>
    ) : null

    const superscript = item.superscript ? (
      <div className={styles.headerSuperscript}>{item.superscript}</div>
    ) : null

    let headerStyles = { ...item.headerStyle }
    if (item.columns) {
      headerStyles = { ...headerStyles, ...nestingHeaderStyles }
    }
    if (item.nested) {
      headerStyles = { ...headerStyles, ...nestedHeaderStyles }
    }

    if (item.sortable) {
      const sortByValue = item.sortBy || item.accessor
      return (
        <HeaderCellSortable
          active={sortBy === sortByValue}
          sortOrder={sortOrder}
          sortData={() => sortData(sortByValue)}
          style={headerStyles}
          accessor={item.accessor}
        >
          {item.name}
          {subtitle}
          {superscript}
        </HeaderCellSortable>
      )
    }

    return (
      <HeaderCell style={headerStyles} accessor={item.accessor}>
        {item.name}
        {subtitle}
        {superscript}
      </HeaderCell>
    )
  }

  getCell = (item: TableColumnType, row: RowType) => {
    if (item) {
      const bold = item.shouldBold ? item.shouldBold(row) : false
      const showDollar = item.shouldShowDollar ? item.shouldShowDollar(row) : false
      const underline = item.shouldUnderline ? item.shouldUnderline(row) : false
      if (item.isCurrency && !row.original.isPercentage) {
        return (
          <CurrencyCell
            bold={bold}
            underline={underline}
            first={row.original.first}
            single={row.original.single}
            decimals={item.decimals}
            row={row}
            showDollar={showDollar}
            showTotal={item.showTotal || false}
            accessor={item.accessor}
          />
        )
      }

      if ((item.isPercentage || row.original.isPercentage) && typeof row.value === 'number') {
        return <PercentageCell row={row} />
      }
      let cellValue = null
      if (item.cellFormat) {
        cellValue = item.cellFormat(row)
      }

      return (
        <Cell
          first={row.original.first}
          single={row.original.single}
          value={cellValue || row.value}
          onClick={item.onClick}
          accessor={item.accessor}
          alignClassName={item.alignClassName}
          factSheetIcon={item.factSheetIcon}
        />
      )
    }
    return null
  }

  getFooter = (item: TableColumnType, row: any, loading?: boolean) => {
    const monthYearFooterEmpty =
      (item.accessor === TablePropAccessors.statement_gross_return_month ||
        item.accessor === TablePropAccessors.statement_gross_return_ytd) &&
      row.data.length === 0
    if (!item || loading || monthYearFooterEmpty) return null
    if (item.showTotal) {
      return (
        <FooterCellTotal
          row={row}
          value={item.accessor}
          decimals={item.decimals}
          subtotalColumn={this.columns[0].accessor}
          accessor={item.accessor}
        />
      )
    }

    if (item.isPercentage) {
      return (
        <FooterCellPercentage
          row={row}
          subtotalColumn={item.subtotalColumn || this.columns[0].accessor}
        />
      )
    }

    if (item.footerText) {
      return <FooterCell>{item.footerText}</FooterCell>
    }

    return null
  }

  getSumColumns = () => {
    const sumColumns = {}

    this.columns.forEach((item: TableColumnType) => {
      if (item.showTotal) {
        sumColumns[item.accessor] = 0
      }
    })

    return sumColumns
  }

  generateColumns = (
    sortBy: string,
    sortOrder: SortOrderType,
    sortData: Function,
    data: Array<TableFormats>,
    columns: Array<TableColumnType>,
    loading: boolean,
    pdfKey: string,
  ) => {
    const newArray: Array<*> = columns.map((item: TableColumnType) => {
      const width = item.maxWidth
        ? this.setColumnWidth(
            data,
            item.accessor,
            item.name,
            item.maxWidth,
            item.width || TableColumnWidths[item.accessor],
          )
        : item.width || TableColumnWidths[item.accessor]

      return {
        Header: this.getHeader(item, sortBy, sortOrder, sortData),
        Cell: row => (row.original.hide ? null : this.getCell(item, row)),
        columns: item.columns
          ? this.generateColumns(sortBy, sortOrder, sortData, data, item.columns, false, pdfKey)
          : null,
        Footer: row => this.getFooter(item, row, loading),
        accessor: item.accessor,
        width,
        sortable: false,
        resizable: false,
      }
    })

    return newArray
  }

  resolveData = (data: TableDataType, groupBy?: string, subtotalColumn?: ModelKeys) => {
    if (data.length === 0) {
      return []
    }

    const formattedData = data.map(item => {
      const newItem = item
      newItem.last = false
      newItem.first = false
      newItem.single = false
      return newItem
    })

    const rows = []
    const groups = _groupBy(formattedData, groupBy)
    const numGroups = Object.keys(groups).length
    // This is used to keep track of the columns we are summing but not for summations
    const sumColumnsObj = this.getSumColumns()
    const totalSums = {}
    Object.keys(groups).forEach((key: string, index: number) => {
      const obj = groups[key]
      const groupLength = obj.length
      if (groupLength > 1) {
        const sums = this.getSumColumns()
        obj.forEach((item, i) => {
          const newItem = item

          if (i === 0) {
            newItem.first = true
          }

          if (subtotalColumn && numGroups > 1) {
            const keys = Object.keys(sums)
            keys.forEach(k => {
              if (item[k]) {
                sums[k] += item[k]
              }
            })

            if (groupLength === i - 1) {
              newItem.last = Object.prototype.hasOwnProperty.call(newItem, 'showLine')
                ? newItem.showLine
                : true
            } else {
              newItem.last = Object.prototype.hasOwnProperty.call(newItem, 'showLine')
                ? newItem.showLine
                : false
            }
          }

          if (numGroups === 1 && groupLength === i + 1) {
            newItem.last = Object.prototype.hasOwnProperty.call(newItem, 'showLine')
              ? newItem.showLine
              : true
          }

          rows.push(newItem)
          if (Object.keys(sums).length > 0) {
            Object.keys(sums).forEach(sumKey => {
              totalSums[sumKey] = (totalSums[sumKey] || 0) + newItem[sumKey]
            })
          }
        })
        if (subtotalColumn && numGroups > 1) {
          rows.push({
            [subtotalColumn]: 'Subtotal',
            last: numGroups === index + 1,
            ...sums,
          })
        }
      } else {
        const temp = obj[0]
        temp.last = groupLength === 1 && numGroups === index + 1
        temp.single = true
        rows.push(temp)
        if (Object.keys(sumColumnsObj).length > 0) {
          Object.keys(sumColumnsObj).forEach(sumKey1 => {
            totalSums[sumKey1] = (totalSums[sumKey1] || 0) + temp[sumKey1]
          })
        }
      }
    })
    if (subtotalColumn) {
      rows.push({
        [subtotalColumn]: 'Total',
        last: true,
        hide: true,
        ...totalSums,
      })
    }

    return rows
  }
}

export default Format
