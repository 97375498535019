// @flow

export default {
  Screens: {
    HomePage: {
      LoginButton: 'Accessibility/Screens/Homepage/LoginButton',
    },
    About: {
      SmallPhoto: (name: string) => `Accessibility/Screens/About/SmallPhoto/${name}`,
      BigPhoto: 'Accessibility/Screens/About/BigPhoto',
      ContactBtn: 'Accessibility/Screens/About/ContactBtn',
    },
    InviteScreen: {
      SignUpButton: 'Accessibility/Screens/InviteScreen/SignUpButton',
      LoginButton: 'Accessibility/Screens/InviteScreen/LoginButton',
    },
    PasswordReset: {
      PasswordInput: 'Accessibility/Screens/PasswordReset/PasswordInput',
    },
    BrokenLink: {
      HomePageButton: 'Accessibility/Screens/BrokenLink/HomePageButton',
    },
    ClientList: {
      Client: (name: string) => `Accessibility/Screens/ClientList/${name}`,
      ExportToExcel: `Accessibility/Screens/ClientList/ExportToExcel`,
    },
  },
  Components: {
    Button: {
      ResetPassword: 'Reset Password',
      ResendRegistrationLink: 'Resend Registration Link',
    },
    Icon: {
      DownloadIcon: 'Components/Icon/DownloadIcon',
      PrintIcon: 'Components/Icon/PrintIcon',
      PDFIcon: 'Components/Icon/PDFIcon',
      StatementIcon: 'Components/Icon/StatementIcon',
    },
    NavLink: 'Components/NavLink',
    NavLinkItem: (label: string) => `Components/NavLink/${label}`,
    Chart: {
      ToggleStartCalendar: 'Components/Chart/ToggleStartCalendar',
      ToggleEndCalendar: 'Components/Chart/ToggleEndCalendar',
    },
    FilterDropdown: {
      ToggleOpen: `Components/FilterDropdown/ToggleOpen`,
      Checkbox: (label: string) => `Components/FilterDropdown/Checkbox/${label}`,
    },
    TermsModal: {
      TextContainer: 'Modal/TermsModal/TextContainer',
      Accept: 'Modal/TermsModal/Accept',
    },
    Modal: {
      Wrapper: 'Modal/Wrapper',
      Close: 'Modal/Close',
      LoginSubmit: 'Modal/LoginSubmit',
      AcceptInvite: {
        Agreeemnt: 'Modal/AcceptInvite/Agreeemnt',
      },
      StayLoggedIn: 'Modal/StayLoggedIn',
      LogOut: 'Modal/LogOut',
      Yes: 'Modal/Yes',
      No: 'Modal/No',
    },
    PortalSettingsModal: {
      EmailSubmit: 'PortalModal/EmailSubmit',
      UsernameSubmit: 'PortalModal/UsernameSubmit',
      PasswordSubmit: 'PortalModal/PasswordSubmit',
      MobileSubmit: 'PortalModal/MobileSubmit',
    },
    ActiveSessionsModal: {
      LogOut: 'ActiveSessionsModal/LogOut',
    },
    VerifyDevice: {
      Authenticate: 'Modal/VerifyDevice/Authenticate',
      Resend: 'Modal/VerifyDevice/Resend',
    },
    Anchor: {
      ForgotPassword: 'Components/Anchor/ForgotPassword',
      GolcondaEmail: 'Components/Anchor/GolcondaEmail',
      GolcondaPhone: 'Components/Anchor/GolcondaPhone',
    },
    StatementDetail: {
      LastPage: 'Components/StatementDetail/LastPage',
      NextPage: 'Components/StatementDetail/NextPage',
      Print: 'Components/StatementDetail/Print',
    },
  },
}
