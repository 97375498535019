// @flow
import BaseModel from './base/base-model'
import Client from './client'
import type { ClientDataType } from './client'
import { defaultBoolean, defaultString } from './defaults'

export type AdvisorDataType = {|
  client: ?ClientDataType,
  first_name: ?string,
  middle_name: ?string,
  last_name: ?string,
  company: ?string,
  address: ?string,
  email: ?string,
  fax: ?string,
  is_admin: ?boolean,
|}

export default class Advisor extends BaseModel {
  company: string

  firstName: string

  middleName: string

  lastName: string

  address: string

  email: string

  fax: string

  client: Client

  isAdmin: boolean

  constructor(
    data: AdvisorDataType = {
      client: null,
      company: null,
      address: null,
      email: null,
      fax: null,
      is_admin: null,
      first_name: null,
      middle_name: null,
      last_name: null,
    },
  ) {
    super()
    this.client = data.client ? new Client(data.client) : new Client()
    this.company = defaultString(data.company)
    this.address = defaultString(data.address)
    this.email = defaultString(data.email)
    this.fax = defaultString(data.fax)
    this.isAdmin = defaultBoolean(data.is_admin)
    this.firstName = defaultString(data.first_name)
    this.middleName = defaultString(data.middle_name)
    this.lastName = defaultString(data.last_name)
  }

  get formattedName(): string {
    return [this.firstName, this.middleName, this.lastName].filter((name: string) => name).join(' ')
  }
}
