// @flow
import React from 'react'
import styled from '@emotion/styled'
import zxcvbn from 'zxcvbn'
import { Strings } from '@/constants'
import Input from '.'
import type { InputProps } from '.'
import styles from './styles.scss'

type PasswordInputProps = {|
  ...InputProps,
  type: 'password',
  meter: boolean,
  userInputs: Array<string>,
  testId: string,
|}

const MeterBar = styled.div`
  background-color: ${(props: { active: boolean, color: string }) =>
    props.active ? props.color : styles.grey};
`

const StrengthText = styled.span`
  color: ${(props: { score: number }) =>
    props.score >= 3 ? styles.black : styles.error} !important;
`

const PasswordMeter = ({ score }: { score: number }) => {
  let color = styles.error
  if (score === 2) {
    color = styles.orange
  } else if (score > 2) {
    color = styles.success
  }
  return (
    <div className={styles.meter}>
      <MeterBar active={score >= 0} color={color} />
      <MeterBar active={score >= 1} color={color} />
      <MeterBar active={score >= 2} color={color} />
      <MeterBar active={score >= 3} color={color} />
      <MeterBar active={score === 4} color={color} />
    </div>
  )
}

const getScoreText = (score: number) => {
  let text = Strings.weak
  switch (score) {
    case 0:
      text = Strings.extremelyWeak
      break
    case 1:
      text = Strings.veryWeak
      break
    case 2:
      text = Strings.weak
      break
    case 3:
      text = Strings.strong
      break
    case 4:
      text = Strings.veryStrong
      break
    default:
      text = Strings.weak
  }
  return text
}

const PasswordInput = (props: PasswordInputProps) => {
  const { meter, value, subtext, userInputs, ...rest } = props
  const { score } = zxcvbn(value || '', userInputs)
  const withScore = getScoreText(score)
  let subTextWithScore = subtext
  if (meter && value) {
    const comp = (
      <StrengthText className={styles.subtext} score={score} key="strengthScore">
        {withScore}
      </StrengthText>
    )
    subTextWithScore = [comp, '\n', subtext]
  }

  return (
    <Input
      {...rest}
      value={value}
      type="password"
      visible
      subtext={subTextWithScore}
      dropdown={meter && value ? <PasswordMeter score={score} /> : null}
    />
  )
}

PasswordInput.defaultProps = {
  type: 'password',
  meter: true,
  userInputs: [],
  testId: '',
}

export default PasswordInput
