// @flow

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { Footer, Header } from '@/components'
import MobileMessage from '@/screens/mobile-message'
import { Links, PortalScreenSections, Routes } from '@/constants'
import Access from '@/constants/accessibility'
import selectors from '@/selectors'
import {
  isAuthRoute,
  isClientList,
  isHomepage,
  isPortalRoute,
  isPrivacyNotice,
  isTermsOfUse,
} from '@/helpers/routeChecking'
import { classnames } from '@/helpers'
import type { RouterHistory } from 'react-router'
import jsStyles from './styles'
import styles from './styles.scss'

type StateToPropsType = {|
  user: User,
  statements: {
    ppaStatementsShort: Array<PPAStatement>,
    ppliStatementsShort: Array<PPLIStatement>,
    vulStatementsShort: Array<VULStatement>,
    iulStatements: Array<IULMonthlyStatement | IULAnnualStatement | IndexAccountRates>,
  },
|}

type AppWrapperProps = {
  scrollActive?: boolean,
  children: ?React.Node,
  location: Object,
  history: RouterHistory,
  ...StateToPropsType,
}

function AppWrapper(props: AppWrapperProps) {
  const { children, scrollActive, location, user, statements } = props

  function getInitialActiveRoute() {
    const { pathname } = location
    const noParamsPathname = `/${pathname.split('/')[1]}`
    return Links.portal.find(link => {
      if (link.route === noParamsPathname) {
        return true
      }
      const sublinksObject = PortalScreenSections[link.label]
      if (sublinksObject) {
        const sublinks = sublinksObject.links.map(e => e.link)
        return sublinks.includes(noParamsPathname)
      }
      return false
    })
  }

  const [activePortalLink, setActivePortalLink] = useState(getInitialActiveRoute())

  useEffect(() => {
    setActivePortalLink(getInitialActiveRoute())
  }, [location.pathname])

  const isActive = (match, pathname: string, item: {| label: string, route: string |}) => {
    if (match) {
      return true
    }

    const sublinksObject = PortalScreenSections[item.label]

    if (!sublinksObject) {
      return false
    }

    const sublinks = sublinksObject.links.map(link => link.link)

    return sublinks.includes(pathname)
  }

  const hasStatements = (type: string) => statements[type] && !!statements[type].length

  const isVisible = (label: string) => {
    const sublinksObject = PortalScreenSections[label]

    if (
      user?.hasRestrictedAccess &&
      !(label === 'Customized Policy Statements' || label === 'Policy Documents')
    ) {
      return false
    }

    if (sublinksObject) {
      const { links } = sublinksObject
      const filteredSubLinksObject = links.filter(link => !link.type || hasStatements(link.type))
      return !links.length || !!filteredSubLinksObject.length
    }

    return true
  }

  const renderDropdown = (label: string) => {
    const sublinksObject = PortalScreenSections[label]

    if (sublinksObject) {
      const { links } = sublinksObject
      const filteredSublinksObject = links.filter(link => !link.type || hasStatements(link.type))
      return (
        <div className={styles.dropdownContainerInner}>
          {filteredSublinksObject.map(sublink => (
            <NavLink
              key={sublink.text}
              to={sublink.link}
              className={styles.childMenuLink}
              activeClassName={styles.childMenuLinkActive}
            >
              {sublink.text}
            </NavLink>
          ))}
        </div>
      )
    }
    return null
  }

  const inPortal = isPortalRoute(location.pathname)

  const withHeader = React.useMemo(
    () => !isHomepage(location.pathname) && !isAuthRoute(location.pathname),
    [location.pathname],
  )

  const withFooter = React.useMemo(() => !isHomepage(location.pathname), [location.pathname])

  const withPortalLinks = React.useMemo(
    () => user && inPortal && !isClientList(location.pathname),
    [user, inPortal, location.pathname],
  )

  const showMobileMessage = React.useMemo(
    () => !(isTermsOfUse(location.pathname) || isPrivacyNotice(location.pathname)),
    [inPortal, location.pathname],
  )

  const goToFirstAvailableCustomStatement = label => {
    const { history } = props
    const customStatementSublinks = PortalScreenSections[label].links
    const firstVisibleLink = customStatementSublinks.find(
      link => !link.type || hasStatements(link.type),
    )
    if (firstVisibleLink) {
      history.push(firstVisibleLink.link)
    }
  }

  let portalLinks = null
  const visibleLinks = Links.portal.filter(item => isVisible(item.label))
  if (withPortalLinks) {
    portalLinks = (
      <section className={styles.portalHeader}>
        <div className={styles.portalNavWrapper}>
          <ul className={user?.hasRestrictedAccess ? styles.fitContent : ''}>
            {visibleLinks.map(item => (
              <li
                key={item.label}
                aria-label={Access.Components.NavLink}
                className={user?.hasRestrictedAccess ? styles.marginMenu : ''}
              >
                <div className={styles.dropdownOuterContainer}>
                  <NavLink
                    activeClassName={styles.portalSelected}
                    key={item.label}
                    to={item.route}
                    isActive={(match, { pathname }) => isActive(match, pathname, item)}
                    onClick={e => {
                      setActivePortalLink(item)
                      if (item.route === Routes.portal.CustomizedPolicyStatements) {
                        goToFirstAvailableCustomStatement(item.label)
                        e.preventDefault()
                      }
                    }}
                  >
                    {item.label}
                  </NavLink>
                  {activePortalLink && item.route === activePortalLink.route && (
                    <div className={styles.dropdownContainer}>{renderDropdown(item.label)}</div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }

  return (
    <div
      className={classnames(
        styles.appContainer,
        isAuthRoute(location.pathname) ? styles.authAppContainer : null,
        !isAuthRoute(location.pathname) ? styles.portalAppContainer : null,
      )}
      css={jsStyles.modalCheck(scrollActive)}
    >
      {withHeader && <Header />}

      {showMobileMessage && <MobileMessage />}

      <div
        className={classnames(
          showMobileMessage ? styles.desktopOnly : null,
          isAuthRoute(location.pathname) ? styles.authChildren : null,
        )}
      >
        {portalLinks}
        {children}
      </div>

      {withFooter && <Footer authed={!!user} />}
    </div>
  )
}

AppWrapper.defaultProps = {
  scrollActive: true,
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  statements: {
    ppaStatementsShort: state.policy.ppaStatementsShort,
    ppliStatementsShort: state.policy.ppliStatementsShort,
    vulStatementsShort: state.policy.vulStatementsShort,
    iulStatements: [
      ...state.policy.iulMonthlyStatements,
      ...state.policy.iulAnnualStatements,
      ...state.policy.indexAccountRates,
    ],
  },
})

export default connect(mapStateToProps)(withRouter(AppWrapper))
