// @flow
const TestIds = {
  loginButton: 'loginButton',
  usernameInput: 'usernameInput',
  passwordInput: 'passwordInput',
  loginSubmitButton: 'loginSubmitButton',
  printIcon: 'printIcon',
  pdfIcon: 'pdfIcon',
  downloadIcon: 'downloadIcon',
}

export default TestIds
