// @flow

import React from 'react'
import { connect } from 'react-redux'

import * as yup from 'yup'
import type { ValidationError } from 'yup'
import Select, { components } from 'react-select'

import { classnames } from '@/helpers'
import { Input, PrimaryButton } from '@/components'
import selectors from '@/selectors'
import Access from '@/constants/accessibility'
import { ErrorKeys } from '@/redux/api'
import { User } from '@/models'
import { Strings, countries } from '@/constants'

import styles from './styles.scss'

const schema = yup.object().shape({
  newMobile1: yup.string().required(Strings.auth.settingsError.newMobile),
  newMobile2: yup
    .string()
    .oneOf([yup.ref('newMobile1')], Strings.auth.settingsError.mobileDoesNotMatch)
    .required(Strings.auth.settingsError.newMobile),
})

const FieldNames = {
  oldMobile: 'oldMobile',
  newMobile1: 'newMobile1',
  newMobile2: 'newMobile2',
}

type FormErrors = {
  newMobile1: string,
  newMobile2: string,
}

type StateToPropsType = {|
  user: ?User,
  error: ?string,
|}

type PortalSetMobileFormProps = {|
  submitForm: (values: PortalUpdateUserInfoPayload) => {},
  success: boolean,
  ...StateToPropsType,
|}

type PortalSetMobileFormState = {|
  newMobile1: string,
  newMobile2: string,
  errors?: FormErrors,
  nonUniqueError: string,
  countryCode: string,
|}

export class PortalSetMobileForm extends React.Component<
  PortalSetMobileFormProps,
  PortalSetMobileFormState,
> {
  constructor(props: PortalSetMobileFormProps) {
    super(props)

    this.state = {
      newMobile1: '',
      newMobile2: '',
      nonUniqueError: '',
      countryCode: '+1',
    }
  }

  formatErrors = (errors: Array<ValidationError>): FormErrors => {
    const formatErrors: FormErrors = {}
    errors.forEach((err: ValidationError) => {
      formatErrors[err.path] = err.message
    })
    return formatErrors
  }

  onSubmit = (event?: DOMEvent) => {
    if (event) {
      event.preventDefault()
    }

    const { submitForm, user } = this.props
    const { newMobile1, newMobile2, countryCode } = this.state

    const values = {
      newMobile1,
      newMobile2,
    }

    const isUnique = this.checkUnique(user, newMobile1)

    if (isUnique) {
      try {
        schema.validateSync(values, { abortEarly: false })
        this.setState({ errors: undefined }, () => {
          submitForm({
            phone: countryCode + newMobile1,
          })
        })
      } catch (err) {
        const errors: FormErrors = this.formatErrors(err.inner)
        this.setState({ errors })
      }
    }
  }

  checkUnique = (user: ?User, newmobile: string): boolean => {
    const { countryCode } = this.state
    if (user && user.phone === newmobile && user.country_code === countryCode) {
      this.setState({ nonUniqueError: Strings.settings.mobile.nonUnique })
      return false
    }
    this.setState({ nonUniqueError: '' })
    return true
  }

  onNewMobile1Change = (value: string) => {
    this.setState({ newMobile1: value.replace(/[^0-9]+/g, '') })
  }

  onNewMobile2Change = (value: string) => {
    this.setState({ newMobile2: value })
  }

  onCountryCodeChange = (option: any) => {
    this.setState({ countryCode: option.value })
  }

  render() {
    const { error, success, user } = this.props
    const { errors, newMobile1, newMobile2, nonUniqueError, countryCode } = this.state
    const mobileSuccess =
      !error &&
      !nonUniqueError &&
      !!(newMobile1 && newMobile1 === newMobile2 && !errors?.newMobile1 && !errors?.newMobile2)
    // display text of any associated errors
    const mobile1Error = nonUniqueError || errors?.newMobile1 || (errors?.newMobile2 ? true : error)

    // only display text of validation errors under 2nd mobile input, else just make the input border red (hence coercion to booleans)
    const mobile2Error = !!nonUniqueError || errors?.newMobile2 || !!error

    const SingleValue = props => (
      <components.SingleValue {...props}>{props.data.chipLabel}</components.SingleValue>
    )
    const countryCodes = []
    countries.forEach(country => {
      countryCodes.push({
        value: country.dialCode,
        label: `${country.name} ( ${country.dialCode} )`,
        chipLabel: country.dialCode,
      })
    })
    if (success) {
      return (
        <div className={classnames(styles.successWrapper)}>
          <h4>{Strings.settings.mobile.title}</h4>
          <p>{Strings.settings.mobile.success}</p>
        </div>
      )
    }

    return (
      <form className={styles.portalModalForm} onSubmit={this.onSubmit}>
        <h4 className={styles.portalModalHeader}>{Strings.settings.mobile.title}</h4>
        <div className={styles.flexContainer}>
          <Input
            type="text"
            label={Strings.auth.mobileCurrent}
            name={FieldNames.oldMobile}
            placeholder={
              user && user.phone ? user?.country_code + user?.phone : Strings.auth.mobileCurrent
            }
            onChange={() => {}}
            success={mobileSuccess}
            disabled
          />
        </div>
        <div>New Mobile Number</div>
        <div className={styles.flexContainer}>
          {' '}
          <div className={styles.selectAligner}>
            <Select
              options={countryCodes}
              components={{ SingleValue }}
              onChange={this.onCountryCodeChange}
              defaultValue={{ label: `United States( +1 )`, value: '+1', chipLabel: '+1' }}
              value={{ label: countryCode, value: countryCode, chipLabel: countryCode }}
            />{' '}
          </div>
          <Input
            type="text"
            name={FieldNames.newMobile1}
            placeholder={Strings.auth.mobileNew}
            onChange={this.onNewMobile1Change}
            error={mobile1Error}
            success={mobileSuccess}
            value={newMobile1}
          />
        </div>
        <div>Confirm New Mobile Number</div>
        <div className={styles.flexContainer}>
          {' '}
          <div className={styles.selectAligner}>
            <Select
              options={countryCodes}
              components={{ SingleValue }}
              onChange={this.onCountryCodeChange}
              defaultValue={{ label: `United States( +1 )`, value: '+1', chipLabel: '+1' }}
              value={{ label: countryCode, value: countryCode, chipLabel: countryCode }}
            />{' '}
          </div>
          <Input
            type="text"
            name={FieldNames.newMobile2}
            placeholder={Strings.auth.mobileNewConfirm}
            onChange={this.onNewMobile2Change}
            error={mobile2Error}
            success={mobileSuccess}
          />
        </div>
        <PrimaryButton
          type="submit"
          ariaLabel={Access.Components.PortalSettingsModal.MobileSubmit}
          onClick={this.onSubmit}
          title={Strings.settings.mobile.title}
          className={styles.portalModalDefaultBtn}
        />
      </form>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  error: selectors.api.getError(state, ErrorKeys.PortalSetMobileError),
})

export default connect(mapStateToProps)(PortalSetMobileForm)
