// @flow
import { call, put, delay, take } from 'redux-saga/effects'
import { ActionCreators } from '@/redux'
import StorageService from '@/helpers/StorageService'
import { getSessionsStatuses, getCurrentUserSessions, endSessionsByIds } from '@/redux/api-payloads'
import { SessionStatus } from '@/models'
import { ACTIONS as SESSIONS_ACTIONS } from '@/redux/sessions'

export function* fetchSessionsStatuses() {
  try {
    const sessionsStored: Array<UserSession> = yield call(StorageService.getSessionsList)
    if (sessionsStored.length) {
      const tokens = sessionsStored.map(e => e.token)
      const payload = getSessionsStatuses(tokens)
      yield put(ActionCreators.api.makeRequest.dispatch(payload))
    }
  } catch (error) {
    // Handle error
  }
}

export function* watchSessionsStatusesPolling() {
  while (true) {
    yield fetchSessionsStatuses()
    yield delay(60000)
  }
}

export function* poolingDataReceived(action: { type: string, payload: Array<SessionStatus> }) {
  const statusesReceived = action.payload
  try {
    const sessionsStored: Array<UserSession> = yield call(StorageService.getSessionsList)

    const sessionsUpdated = sessionsStored.map((session: UserSession, idx: number) => {
      const status = statusesReceived[idx]
      if (status) {
        return {
          ...session,
          expired: !status.id,
        }
      }
      return session
    })

    const onlyPersistentNonExpiredSessions = sessionsUpdated.filter(e => e.persistent || !e.expired)

    yield call(StorageService.setSessionsList, onlyPersistentNonExpiredSessions)

    const currentSession = yield call(StorageService.getAuth)

    if (currentSession) {
      const currentUserToken = currentSession.token
      const currentTokenSession = onlyPersistentNonExpiredSessions.find(
        e => e.token === currentUserToken,
      )
      if (currentTokenSession && currentTokenSession.expired) {
        yield put(ActionCreators.auth.logout.dispatch())
      }
    }
  } catch (error) {
    // Handle error
  }
}

export function* loadCurrentUserSessions() {
  const payload = getCurrentUserSessions()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* endSessions(action: { type: string, payload: Array<number> }) {
  const payload = endSessionsByIds(action.payload)
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
  yield take(SESSIONS_ACTIONS.SESSIONS_ENDED)
  yield loadCurrentUserSessions()
}
