// @flow

import * as React from 'react'
import { Strings } from '@/constants'
import styles from './styles.scss'

type FootnotesProps = {
  notes: Array<FootnoteType>,
}

const Footnotes = (props: FootnotesProps) => {
  const { notes } = props
  const list = notes.map(listItem => <li key={listItem.id}>{listItem.note}</li>)
  if (list.length > 0) {
    return (
      <div className={styles.notesContainer}>
        <h3 className={styles.notesTitle}>{Strings.footnotesTitle}</h3>
        <ol className={styles.notes}>{list}</ol>
      </div>
    )
  }
  return null
}

export default Footnotes
