// @flow
import React from 'react'

import styles from './styles.scss'
import PdfViewer from '../pdf-viewer'
import { Strings } from '@/constants'

type IDFAvailabilityFundsProps = {|
  idfAvailabilityPdf: IDFImage,
|}

export const IDFAvailabilityFundsPdf = (props: IDFAvailabilityFundsProps) => {
  const { idfAvailabilityPdf } = props

  return (
    <div className={styles.sliderContainer}>
      <p className={styles.idfsListTitle}>{Strings.availableIDFs}</p>
      <div className={styles.pdfDocumentSingle} key={idfAvailabilityPdf.id}>
        <PdfViewer key={idfAvailabilityPdf.url} src={idfAvailabilityPdf.url} />
      </div>
    </div>
  )
}

export default IDFAvailabilityFundsPdf
