// @flow
import * as React from 'react'
import moment from 'moment'
import GolcondaTable from '@/components/table'
import Format from '@/components/table/format'
import { Strings } from '@/constants'
import { PPAStatement, VULStatement } from '@/models'

import {
  ppaAccountSummaryColumns,
  ppliAccountSummaryColumns,
  vulAccountSummaryColumns,
} from './account-summary-data'

type AccountSummaryTableProps = {|
  user?: User,
  ownerName: ?string,
  month: ?string,
  policies: { [string]: PPPolicyType },
  statements: Array<PPStatementType>,
  loading: boolean,
  notes: Array<FootnoteType>,
  detailModal: (payload: { policyId: string, policyType?: string, month?: ?string }) => void,
  getAccountSummaryColumns: ((row: *) => React.Node) => Array<*>,
  pdfKey: PDFKeysType,
  onSortingUpdate?: ({
    sortBy?: string,
    sortOrder?: SortOrderType,
  }) => void,
  sinceInceptionDate: ?string,
|}

const formatAccountSummaryData = (
  monthStatements: Array<PPStatementType>,
  policies: { [string]: PPPolicyType },
) => {
  if (policies.constructor === Object && Object.keys(policies).length) {
    monthStatements.sort((a, b) => a.statementEndDate - b.statementEndDate)

    const lastStatementsObj = monthStatements.reduce(
      (acc: PPStatementType, curr: PPStatementType) => {
        const statement = acc[curr.policyId]
        if (statement && curr.statementEndDate >= statement.statementEndDate) {
          acc[curr.policyId] = curr
        } else if (acc[curr.policyId] === undefined) {
          acc[curr.policyId] = curr
        }
        return acc
      },
      {},
    )

    const lastStatements: Array<PPStatementType> = Object.values(lastStatementsObj)

    return lastStatements.reduce((acc, cur) => {
      const policy = policies[cur.policyId]

      if (policy) {
        const policyCopy = Object.assign(Object.create(Object.getPrototypeOf(policy)), policy)

        policyCopy.costBasis = cur.costBasis
        policyCopy.policyGrossAccountValue = cur.policyGrossAccountValue
        policyCopy.insuranceProceeds = cur.insuranceProceeds

        return [...acc, policyCopy]
      }

      return acc
    }, [])
  }

  return []
}

export const getAccountPPASummaryDownloadData = (
  statements: Array<PPAStatement>,
  policies: { [string]: PPPolicyType },
  ownerName: ?string,
) => {
  const tableData = formatAccountSummaryData(statements, policies)
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  return new Format({
    ownerName,
    statementEnd,
    columns: ppaAccountSummaryColumns(),
    title: Strings.ppa.accountSummary,
    sheetname: Strings.ppa.worksheetName.accountSummary,
    ppaTable: true,
  }).getTableAsXLSXInput(tableData)
}

export const getAccountPPLISummaryDownloadData = (
  statements: Array<PPLIStatement>,
  policies: { [string]: PPPolicyType },
  ownerName: ?string,
) => {
  const tableData = formatAccountSummaryData(statements, policies)
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  return new Format({
    ownerName,
    statementEnd,
    columns: ppliAccountSummaryColumns(),
    title: Strings.ppli.accountSummary,
    sheetname: Strings.ppli.worksheetName.accountSummary,
  }).getTableAsXLSXInput(tableData)
}

export const getAccountVULSummaryDownloadData = (
  statements: Array<VULStatement>,
  policies: { [string]: PPPolicyType },
  ownerName: ?string,
) => {
  const tableData = formatAccountSummaryData(statements, policies)
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements.sort((a, b) =>
      moment(a.statementStart).isBefore(moment(b.statementStart)) ? 1 : -1,
    )
  }
  return new Format({
    ownerName,
    statementEnd,
    columns: vulAccountSummaryColumns(),
    title: Strings.vul.accountSummary,
    sheetname: Strings.vul.worksheetName.accountSummary,
    vulTable: true,
  }).getTableAsXLSXInput(tableData)
}

export class AccountSummaryTable extends React.Component<AccountSummaryTableProps> {
  policyModalCellFormat = (row: *) => {
    const { user } = this.props
    const hasRestrictedAccess = user?.hasRestrictedAccess || false
    if (!row.value) return null
    if (hasRestrictedAccess) return <span className="tableCellMain single">{row.value}</span>
    const callback = () => {
      const { detailModal, month } = this.props
      detailModal({
        policyId: row.original.id,
        policyType: row.original.modelName,
        month,
      })
    }

    return (
      <button type="button" className="tableLink" onClick={callback}>
        {row.value}
      </button>
    )
  }

  ppaSelected = () => {
    const { statements } = this.props
    return statements[0] && statements[0] instanceof PPAStatement
  }

  vulSelected = () => {
    const { statements } = this.props
    return statements[0] && statements[0] instanceof VULStatement
  }

  render() {
    const {
      sinceInceptionDate,
      ownerName,
      policies,
      statements,
      loading,
      notes,
      getAccountSummaryColumns,
      pdfKey,
      onSortingUpdate,
    } = this.props
    const formattedStatements: Array<PPStatementType> = formatAccountSummaryData(
      statements,
      policies,
    )

    const accountSummaryColumns = getAccountSummaryColumns(this.policyModalCellFormat)
    const sortedStatements = formattedStatements.sort((a, b) => {
      const dateA = new Date(a.policyDate)
      const dateB = new Date(b.policyDate)
      return dateB.getTime() - dateA.getTime()
    })

    return (
      <div>
        <GolcondaTable
          ownerName={ownerName}
          loading={loading}
          notes={notes}
          data={sortedStatements}
          columns={accountSummaryColumns}
          download
          ppaTable={this.ppaSelected()}
          vulTable={this.vulSelected()}
          pdfKey={pdfKey}
          onSortingUpdate={onSortingUpdate}
          sinceInceptionDate={sinceInceptionDate}
        />
      </div>
    )
  }
}

type AccountSummaryTableWrapperProps = {|
  ownerName: ?string,
  month: ?string,
  policies: { [string]: PPPolicyType },
  statements: Array<PPStatementType>,
  loading: boolean,
  notes: Array<FootnoteType>,
  detailModal: (payload: { policyId: string, policyType?: string, month?: ?string }) => void,
  pdfKey: PDFKeysType,
  onSortingUpdate?: ({
    sortBy?: string,
    sortOrder?: SortOrderType,
  }) => void,
|}

export const AccountSummaryPPATable = (props: AccountSummaryTableWrapperProps) => (
  <AccountSummaryTable {...props} getAccountSummaryColumns={ppaAccountSummaryColumns} />
)

export const AccountSummaryPPLITable = (props: AccountSummaryTableWrapperProps) => (
  <AccountSummaryTable {...props} getAccountSummaryColumns={ppliAccountSummaryColumns} />
)

export const AccountSummaryVULTable = (props: AccountSummaryTableWrapperProps) => (
  <AccountSummaryTable {...props} getAccountSummaryColumns={vulAccountSummaryColumns} />
)
