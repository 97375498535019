// @flow

import { Enums } from '@/constants'
import { Annuity, Entity, InsurancePolicy, PrivatePlacement, VULPolicy } from '@/models'
import { dashIfEmptyArray } from '@/helpers'
import { orderBy } from 'lodash'

const buildPremiumPayorEmail = policy => {
  const { premiumPayorEmail } = policy

  if (premiumPayorEmail) {
    return [premiumPayorEmail]
  }

  return []
}

const buildPrimaryContactPersonEmail = policy => {
  const { primaryContactPersonEmail } = policy

  if (primaryContactPersonEmail) {
    return [primaryContactPersonEmail]
  }

  return []
}

const sortArrayById = arr => {
  const newArr = arr.map(el => {
    const obj = {
      ...el,
      id: parseInt(el.id, 10),
    }
    return obj
  })
  const sortedArr = orderBy(newArr, 'id')
  return sortedArr
}

const defaultModel = (policy: InsurancePolicy | PrivatePlacement | VULPolicy) => [
  { fieldKey: 'owner', value: policy.ownerString },
  {
    fieldKey: 'designee',
    value: dashIfEmptyArray(policy.owner.designee.map((ent: Entity) => ent.formattedName)),
  },
  {
    fieldKey: 'beneficiary',
    value: dashIfEmptyArray(policy.beneficiary.map((ent: Entity) => ent.formattedName)),
  },
  {
    fieldKey: 'premiumBillingAddress',
    value: dashIfEmptyArray(
      sortArrayById(policy.premiumPayor ? policy.premiumPayor.addresses : []).map(
        (ent: any) => ent.address,
      ),
    ),
  },
  { fieldKey: 'premiumPayorEmail', value: dashIfEmptyArray(buildPremiumPayorEmail(policy)) },
  {
    fieldKey: 'primaryContactPersonEmail',
    value: dashIfEmptyArray(buildPrimaryContactPersonEmail(policy)),
  },
  {
    fieldKey: 'permissionedIndividuals',
    value: dashIfEmptyArray(
      (policy.permissionedIndividuals || []).map(entity => {
        let value = entity.name
        if (policy.notesVisibility) {
          if (entity.excel_disable && entity.pdf_disable) {
            value += '(2,3)'
          } else if (entity.excel_disable) {
            value += '(2)'
          } else if (entity.pdf_disable) {
            value += '(3)'
          } else {
            value += '(1)'
          }
        }

        return value
      }),
    ),
  },
  {
    fieldKey: 'inforcePolicyCommunicationCCList',
    value: dashIfEmptyArray(policy.inforcePolicyCommunicationCCList),
  },
]

const privatePlacementLifeModel = (policy: PrivatePlacement) => [
  { fieldKey: 'owner', value: policy.ownerString },
  {
    fieldKey: 'designee',
    value: dashIfEmptyArray(policy.owner.designee.map((ent: Entity) => ent.formattedName)),
  },
  {
    fieldKey: 'beneficiary',
    value: dashIfEmptyArray(policy.beneficiary.map((ent: Entity) => ent.formattedName)),
  },
  {
    fieldKey: 'premiumBillingAddress',
    value: dashIfEmptyArray(
      sortArrayById(policy.premiumPayor ? policy.premiumPayor.addresses : []).map(
        (ent: any) => ent.address,
      ),
    ),
  },
  { fieldKey: 'premiumPayorEmail', value: dashIfEmptyArray(buildPremiumPayorEmail(policy)) },
  {
    fieldKey: 'primaryContactPersonEmail',
    value: dashIfEmptyArray(buildPrimaryContactPersonEmail(policy)),
  },
  {
    fieldKey: 'permissionedIndividuals',
    value: dashIfEmptyArray(
      (policy.permissionedIndividuals || []).map(entity => {
        let value = entity.name
        if (policy.notesVisibility) {
          if (entity.excel_disable && entity.pdf_disable) {
            value += '(2,3)'
          } else if (entity.excel_disable) {
            value += '(2)'
          } else if (entity.pdf_disable) {
            value += '(3)'
          } else {
            value += '(1)'
          }
        }

        return value
      }),
    ),
  },
  {
    fieldKey: 'inforcePolicyCommunicationCCList',
    value: dashIfEmptyArray(policy.inforcePolicyCommunicationCCList),
  },
]

const privatePlacementAnnuityModel = (policy: Annuity) => [
  { fieldKey: 'owner', value: policy.ownerString },
  {
    fieldKey: 'designee',
    value: dashIfEmptyArray(policy.owner.designee.map((ent: Entity) => ent.formattedName)),
  },
  {
    fieldKey: 'beneficiary',
    value: dashIfEmptyArray(policy.beneficiary.map((ent: Entity) => ent.formattedName)),
  },
  {
    fieldKey: 'premiumBillingAddress',
    value: dashIfEmptyArray(
      sortArrayById(policy.premiumPayor ? policy.premiumPayor.addresses : []).map(
        (ent: any) => ent.address,
      ),
    ),
  },
  { fieldKey: 'premiumPayorEmail', value: dashIfEmptyArray(buildPremiumPayorEmail(policy)) },
  {
    fieldKey: 'primaryContactPersonEmail',
    value: dashIfEmptyArray(buildPrimaryContactPersonEmail(policy)),
  },
  {
    fieldKey: 'permissionedIndividuals',
    value: dashIfEmptyArray(
      (policy.permissionedIndividuals || []).map(entity => {
        let value = entity.name
        if (policy.notesVisibility) {
          if (entity.excel_disable && entity.pdf_disable) {
            value += '(2,3)'
          } else if (entity.excel_disable) {
            value += '(2)'
          } else if (entity.pdf_disable) {
            value += '(3)'
          } else {
            value += '(1)'
          }
        }

        return value
      }),
    ),
  },
  {
    fieldKey: 'inforcePolicyCommunicationCCList',
    value: dashIfEmptyArray(policy.inforcePolicyCommunicationCCList),
  },
]

export const secondColFieldsMap = {
  [Enums.PolicyType.TERM]: defaultModel,
  [Enums.PolicyType.FIVE_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.TEN_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.FIFTEEN_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.TWENTY_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.TWENTY_FIVE_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.THIRTY_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.THIRTY_FIVE_YEAR_TERM]: defaultModel,
  [Enums.PolicyType.WHOLE]: defaultModel,
  [Enums.PolicyType.GUARANTEED_UNIVERSAL_LIFE]: defaultModel,
  [Enums.PolicyType.GUARANTEED_VARIABLE_UNIVERSAL_LIFE]: defaultModel,
  [Enums.PolicyType.UNIVERSAL_LIFE]: defaultModel,
  [Enums.PolicyType.INDEX_UNIVERSAL_LIFE]: defaultModel,
  [Enums.PolicyType.VARIABLE_UNIVERSAL_LIFE]: defaultModel,
  [Enums.PolicyType.PRIVATE_PLACEMENT_LIFE]: privatePlacementLifeModel,
  [Enums.PolicyType.PRIVATE_PLACEMENT_ANNUITY]: privatePlacementAnnuityModel,
  [Enums.PolicyType.LONG_TERM_DISABILITY]: defaultModel,
}

export default secondColFieldsMap
