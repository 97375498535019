import ReactGA from 'react-ga4'

// $FlowFixMe
import config from '@/agencies-config'

if (!__DEBUG__ && !STORYBOOK) {
  ReactGA.initialize(config.gaMeasurementID)
}

export default ReactGA
