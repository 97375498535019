// @flow
const { nonce } = window.golconda || {}

const logrocket = null
const sentry = null

const development: ConfigType = {
  landingPageURL: process.env.LANDING_PAGE_URL,
  apiURL: `${process.env.BACKEND_URL}/api/v1/`,
  staticPath: process.env.STATIC_PATH,
  gaMeasurementID: 'G-MC1QM2KZCG',
  version: process.env.VERSION,
  webpackDevServer: process.env.WEBPACK_DEV_SERVER,
  sentry,
  logrocket,
  nonce,
}

const staging: ConfigType = {
  landingPageURL: process.env.LANDING_PAGE_URL,
  apiURL: `${process.env.BACKEND_URL}/api/v1/`,
  staticPath: process.env.STATIC_PATH,
  gaMeasurementID: 'G-YSPYM02V66',
  version: process.env.VERSION,
  webpackDevServer: false,
  sentry: {
    token: 'https://5eeb11423a2244a69f54f968ff8fee7a@o1161457.ingest.sentry.io/6602406',
    release: process.env.VERSION,
    environment: process.env.SENTRY_ENVIRONMENT,
  },
  logrocket: {
    token: 'cr7sos/client-portal-staging',
    show_reporting_button: true,
    sha: process.env.VERSION,
  },
  nonce,
}

const production: ConfigType = {
  landingPageURL: process.env.LANDING_PAGE_URL,
  apiURL: `${process.env.BACKEND_URL}/api/v1/`,
  staticPath: process.env.STATIC_PATH,
  gaMeasurementID: 'G-E9K0734BVF',
  webpackDevServer: process.env.WEBPACK_DEV_SERVER,
  sentry: {
    token: 'https://5eeb11423a2244a69f54f968ff8fee7a@o1161457.ingest.sentry.io/6602406',
    release: process.env.VERSION,
    environment: process.env.SENTRY_ENVIRONMENT,
  },
  logrocket: {
    token: 'cr7sos/client-portal',
    show_reporting_button: false,
    sha: process.env.VERSION,
  },
  nonce,
}

const configs = {
  development,
  staging,
  production,
}

export default configs
