// @flow
/* eslint-disable max-len */

/*
  NOTE: The symbol ~ is reseved for creating double spacing.  Using a ~ will be replaced with `  `
*/

import moment from 'moment'
import { cloneDeep } from 'lodash'
import { replaceInStrings } from '@/helpers/typography'

const Strings = {
  clientFullName: 'Golconda Client Portal',
  websiteText: 'www.golconda.com',
  websiteLink: 'https://www.golconda.com',
  clientServiceEmail: 'clientservice@golconda.com',
  clientServicePhone: '(917) 781-0145',
  contactEmail: 'contact@golconda.com',
  contactPhone: '(917) 781-0140',
  or: ' or ',
  period: '.',
  dash: '–',
  terms: {
    title: 'Terms of Use',
  },
  privacyNotice: {
    title: 'Privacy Notice',
  },
  disclosures: {
    title: 'Disclosures',
  },
  socialMediaDisclosure: {
    title: 'Social Media Disclosure',
  },
  contact: {
    title: 'Contact Us',
    subtitle: 'Contact Information',
  },
  brokenLink: {
    title: 'Page Not Found',
    buttonTitle: 'Home Page',
    info: 'The page you were looking for was moved or doesn’t exist.~Please go to the Golconda Home Page by clicking the button below.',
  },
  browserUnsupported: {
    title: 'We’re sorry but your browser is not supported.',
    toContinueUpgrade: 'To continue, please upgrade to the latest version of ',
    chrome: 'Chrome',
    chromeUrl: 'https://www.google.com/chrome/',
    edge: 'Edge',
    edgeUrl: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
    safari: 'Safari',
    safariUrl: 'https://www.apple.com/safari/',
  },
  expiredScreen: {
    registrationTitle: 'We’re sorry but this registration link has expired',
    resetPasswordTitle: 'We’re sorry but this password reset link has expired',
    pleaseContact: 'Please contact us at ',
    toHaveANewRegistration: ' to have a new registration link sent.',
    toHaveANewPassword: ' to have a new password reset link sent.',
    disableAccountConfirmation: 'Are you sure you want to lock your account?',
    accountDisabled:
      'Your account has been successfully locked. A member of the Golconda team will reach out to reactivate your account.',
    asSoonAsPossible: ' as soon as possible.',
    registrationDescription: 'To obtain a new registration link please click on the button below.',
    registrationLinkHasBeenSent: 'An email has been sent to enable you to register.',
    unableToSendTheLink: 'We were not able to send the link, please contact us at ',
  },
  invite: {
    confirmPassword: 'Confirm password',
    createPassword: 'Create password',
    login: 'Login',
    signIn: 'Sign In',
    username: 'User Name',
    password1: 'password1',
    password2: 'password2',
    loginLegal:
      'By logging in users agree not to provide their User Name or Password to anyone without the express written permission of Golconda, LLC.',
    passwordWarning:
      'Password must be 8 characters and contain at least one letter, one number and one special character.',
    welcomeText: 'Welcome to Golconda!',
    clickSignIn: 'Click Sign in below to set your password and sign in.',
    expiredToken: 'Your invitation has expired.',
    anErrorHasOccurred: 'An error has occurred.',
    newInvitation: 'Please contact support@golconda.com to receive a new invitation.',
    sampleEmail: 'Michael.Liebeskind@golcanda.com',
    resendRegistrationLink: 'Resend Registration Link',
  },

  clientHome: {
    title: 'Portfolio Summary',
  },
  clientList: {
    title: 'Client Name',
  },
  annuityPortfolio: {
    title: 'Annuity Portfolio',
    headerLinkTitle: 'Annuity',
    portfolioLinkTitle: 'Portfolio',
    accountDetailLinkTitle: 'Account Detail',
  },
  disabilityPortfolio: {
    title: 'Disability Portfolio',
    insurancePortfolioPageTitle: 'Disability Insurance Portfolio',
    headerLinkTitle: 'Disability Insurance',
    portfolioLinkTitle: 'Portfolio',
    policyDetailLinkTitle: 'Policy detail',
  },
  disabilityPortfolioDocuments: {
    title: 'Policy Documents\u00a0 -\u00a0 Disability Portfolio',
  },
  annuityPortfolioDocuments: {
    title: 'Policy Documents\u00a0 -\u00a0 Annuity Portfolio',
    titleGeneral: 'Annuity Portfolio\u00a0 -\u00a0 General',
  },
  insurancePortfolio: {
    title: 'Life Insurance Portfolio',
    titleGeneral: 'Life Insurance Portfolio\u00a0 -\u00a0 General',
    headerLinkTitle: 'Life Insurance',
    portfolioLinkTitle: 'Portfolio',
    policyDetailLinkTitle: 'Policy detail',
  },
  insurancePortfolioDocuments: {
    title: 'Policy Documents\u00a0 -\u00a0 Life Insurance Portfolio',
  },
  privatePlacementPortfolio: {
    title: 'Private Placement Portfolio',
  },
  annuityPortfolioSummaryFilename: (clientName: string) => {
    const tableTitle = 'Annuity Portfolio Summary'
    return `${tableTitle}${clientName}`
  },
  lifePortfolioSummaryFilename: (clientName: string) => {
    const tableTitle = 'Life Insurance Portfolio Summary'
    return `${tableTitle}${clientName}`
  },
  annuityPortfolioInforceFilename: (clientName: string) => {
    const tableTitle = 'Annuity Portfolio\u00a0 -\u00a0 Inforce Policies'
    return `${tableTitle}${clientName}`
  },
  lifePortfolioInforceFilename: (clientName: string) => {
    const tableTitle = 'Life Insurance Portfolio\u00a0 -\u00a0 Inforce Policies'
    return `${tableTitle}${clientName}`
  },
  lifePortfolioCalendarFilename: (clientName: string) => {
    const tableTitle = 'Life Insurance Portfolio\u00a0 -\u00a0 Premium Calendar'
    return `${tableTitle}${clientName}.csv`
  },
  annuityDetailFilename: (insuranceCompanyName: string, accountId: string) => {
    const tableTitle = 'Annuity Detail\u00a0 -\u00a0 Annuity'
    const formattedAccountId = accountId ? `\u00a0 -\u00a0 ${accountId}` : ''
    const formattedInsuranceCompany = insuranceCompanyName
      ? `\u00a0 -\u00a0 ${insuranceCompanyName}`
      : ''
    return `${tableTitle}${formattedInsuranceCompany}${formattedAccountId}`
  },
  disabilityDetailFilename: (insuranceCompanyName: string, accountId: string) => {
    const tableTitle = 'Disability Detail\u00a0 -\u00a0 Disability'
    const formattedAccountId = accountId ? `\u00a0 -\u00a0 ${accountId}` : ''
    const formattedInsuranceCompany = insuranceCompanyName
      ? `\u00a0 -\u00a0 ${insuranceCompanyName}`
      : ''
    return `${tableTitle}${formattedInsuranceCompany}${formattedAccountId}`
  },
  policyDetailFilename: (insuranceCompanyName: string, accountId: string) => {
    const tableTitle = 'Policy Detail\u00a0 -\u00a0 Life'
    const formattedAccountId = accountId ? `\u00a0 -\u00a0 ${accountId}` : ''
    const formattedInsuranceCompany = insuranceCompanyName
      ? `\u00a0 -\u00a0 ${insuranceCompanyName}`
      : ''
    return `${tableTitle}${formattedInsuranceCompany}${formattedAccountId}`
  },
  ppaIDFFilename: (statementEnd: ?string) => {
    const tableTitle =
      'Golconda PPA Consolidated Statement\u00a0 -\u00a0 Insurance-Dedicated Fund Returns'
    const formattedStatementEnd = statementEnd
      ? `\u00a0 -\u00a0 ${moment(statementEnd, 'YYYY-MM-DD').format('MM YYYY')}`
      : ''
    return `${tableTitle}${formattedStatementEnd}`
  },
  ppaFilename: (statementEnd: ?string, tableName: string) => {
    const tableTitle = `Golconda PPA Consolidated Statement\u00a0 -\u00a0 ${tableName}`
    const formattedStatementEnd = statementEnd
      ? `\u00a0 -\u00a0 ${moment(statementEnd, 'YYYY-MM-DD').format('MM YYYY')}`
      : ''
    return `${tableTitle}${formattedStatementEnd}`
  },
  ppliIDFFilename: (statementEnd: ?string) => {
    const tableTitle =
      'Golconda PPLI Consolidated Statement\u00a0 -\u00a0 Insurance-Dedicated Fund Returns'
    const formattedStatementEnd = statementEnd
      ? `\u00a0 -\u00a0 ${moment(statementEnd, 'YYYY-MM-DD').format('MM YYYY')}`
      : ''
    return `${tableTitle}${formattedStatementEnd}`
  },
  vulIDFFilename: (statementEnd: ?string) => {
    const tableTitle =
      'Golconda VUL Consolidated Statement\u00a0 -\u00a0 Insurance-Dedicated Fund Returns'
    const formattedStatementEnd = statementEnd
      ? `\u00a0 -\u00a0 ${moment(statementEnd, 'YYYY-MM-DD').format('MM YYYY')}`
      : ''
    return `${tableTitle}${formattedStatementEnd}`
  },
  ppliFilename: (statementEnd: ?string, tableName: string) => {
    const tableTitle = `Golconda PPLI Consolidated Statement\u00a0 -\u00a0 ${tableName}`
    const formattedStatementEnd = statementEnd
      ? `\u00a0 -\u00a0 ${moment(statementEnd, 'YYYY-MM-DD').format('MM YYYY')}`
      : ''
    return `${tableTitle}${formattedStatementEnd}`
  },
  vulFilename: (statementEnd: ?string, tableName: string) => {
    const tableTitle = `Golconda VUL Consolidated Statement\u00a0 -\u00a0 ${tableName}`
    const formattedStatementEnd = statementEnd
      ? `\u00a0 -\u00a0 ${moment(statementEnd, 'YYYY-MM-DD').format('MM YYYY')}`
      : ''
    return `${tableTitle}${formattedStatementEnd}`
  },
  defaultSheetname: 'Sheet',
  policyDetail: {
    annuity: {
      title: 'Account Detail~-~Annuity',
      heading: 'Account Detail~-~Annuity Portfolio',
    },
    vul: {
      title: 'Policy Detail~-~Variable Universal Life',
    },
    life: {
      title: 'Policy Detail~-~Life Insurance Portfolio',
    },
    disability: {
      heading: 'Policy Detail~-~Disability',
    },
  },
  policyDocuments: {
    title: 'Policy Documents',
    clickPdf: 'Click on the document image to view a PDF of the full document.',
  },
  customizedPolicyStatements: {
    title: 'Customized Policy Statements',
  },
  fundPerformance: {
    title: 'Fund Performance',
  },
  iul: {
    title: 'IUL Statements',
    uploadedFileType: {
      '1': 'Collateral Report',
      '2': 'Low Point Letter',
      '3': 'Performance Report',
      '4': 'Reconciliation Statement',
    },
    tabs: {
      monthly: 'Monthly',
      annual: 'Annual',
      indexAccountRates: 'Index Account Rates',
    },
  },
  ppli: {
    short: 'PPLI',
    accountResults: 'PPLI INVESTMENT ACCOUNT POLICY RESULTS:~Consolidated (All Accounts)',
    accountResultsFiltered: 'PPLI INVESTMENT ACCOUNT POLICY RESULTS:~',
    accountDetail: 'PPLI Investment Account Policy Detail',
    accountSummary: 'PPLI Investment Account Policy Summary',
    accountTransactions: 'PPLI Investment Account Policy Transactions',
    heroTitle: 'Private Placement Life Insurance (PPLI) Investment Portfolio',
    heroTitleSingular: 'Private Placement Life Insurance (PPLI) Investment Account',
    idfFunds: 'Insurance-Dedicated Funds',
    idfReturns: 'Insurance-Dedicated Fund (IDF) Returns',
    idfReturnsBefore: 'IDF Returns~-~Before PPLI Account Charges',
    statements: 'PPLI Investment Portfolio Statements',
    title: 'PPLI Statements',
    titleConsolidated: 'PPLI INVESTMENT PORTFOLIO STATEMENT:~Consolidated (All Accounts)',
    titleFiltered: `PPLI INVESTMENT ACCOUNT POLICY STATEMENT:`,
    header: 'Private Placement Life Insurance (PPLI) Investment Portfolio',
    disclosure: [
      'Private Placement Life Insurance (PPLI) and Private Placement Annuity (PPA) Investment Accounts are unregistered securities products and are not subject to the same regulatory requirements as registered products.~As such, PPLI and PPA should only be presented to accredited investors or qualified purchasers as described by the Securities Act of 1933.',
      'This material is intended for informational purposes only, should not be construed as legal or tax advice, and is not intended to replace the advice of a qualified attorney or tax advisor.~If legal or tax advice is required, you should consult your attorney, or accountant.',
      'This information is not intended to constitute any future performance.~Future investment results and performance will vary and are not guaranteed.~Investments in securities involve risks, including the possible loss of principal.~When redeemed, units may be worth more or less than their original value.',
      'This information is being provided as a courtesy and is not intended as a replacement for the statements from the insurance company.~Current performance may be lower or higher than the performance data quoted.~The month, year-to-date, and since inception returns are derived using an internal rate of return ("IRR") calculation, which considers the timing and amount of cash flows as reported by the respective life insurance companies.~Returns for periods greater than 12 months are annualized.~Returns for periods less than 12 months are not annualized.~If there is any discrepancy between this information and life insurance company records, the latter will be controlling.',
      'Private Placement Life Insurance (PPLI), Private Placement Annuity (PPA), and Variable Universal Life (VUL) Investment Account values are subject to market-related fluctuations.~Current values should be obtained before taking action.~PPLI, PPA, and VUL Investment Account values are held in segregated asset accounts and are not subject to the claims paying ability of the insurance company.~Payments of Net Amount at Risk (NAR) at death, may be subject to the claims paying ability of the insurance company.~Please refer to your insurance policy and offering memorandum or prospectus for policy details.',
      'By accepting these materials, the recipient agrees to keep the materials and their content confidential and not to use the materials or the content for any purpose other than to evaluate a financial transaction with Golconda Partners, LLC and Mandorlo International, Ltd and The Leaders Group, Inc. of the type described in the materials, except that there is no limitation on disclosure of the tax treatment or tax structure of the products and/or transaction structures contained herein.',
      'Any unauthorized use or disclosure of these materials without the written consent of Golconda Partners, LLC and Mandorlo International, Ltd is prohibited.',
      'Securities offered through The Leaders Group, Inc., Member FINRA/SIPC, 26 W Dry Creek Circle, Suite 800, Littleton, CO 80120, (303) 797-9080.~Golconda Partners, LLC and Mandorlo International, Ltd are independently owned and operated.',
    ],
    disclosureFooter: `Proprietary and Confidential.~©${moment(new Date()).format(
      'Y',
    )} Golconda Partners, LLC and Mandorlo International, Ltd.~All rights reserved.`,
    worksheetName: {
      accountDetail: 'Account Detail',
      accountSummary: 'Account Summary',
      accountTransactions: 'Transactions Current',
      accountTransactionsSinceInception: 'Transactions SI',
      idfReturns: 'IDF Returns',
    },
  },
  vul: {
    short: 'VUL',
    accountResults: 'VUL INSURANCE PORTFOLIO RESULTS:~Consolidated (All Accounts)',
    accountResultsFiltered: 'VUL INSURANCE PORTFOLIO RESULTS:~',
    accountDetail: 'VUL Policy Detail',
    accountSummary: 'Variable Universal Life (VUL) Insurance Summary',
    accountTransactions: 'VUL Policy Transactions',
    heroTitle: 'Variable Universal Life (VUL) Insurance Portfolio',
    heroTitleSingular: 'Variable Universal Life (VUL) Investment Account',
    idfFunds: 'Insurance-Dedicated Funds',
    idfReturns: 'Investment Subaccount Returns',
    idfReturnsBefore: 'Subaccount Returns~-~Before VUL Policy Charges',
    statements: 'VUL Insurance Portfolio Statements',
    title: 'VUL Statements',
    titleConsolidated: 'VUL INVESTMENT ACCOUNT STATEMENT:~Consolidated (All Accounts)',
    titleFiltered: `VUL INVESTMENT ACCOUNT STATEMENT:`,
    header: 'Variable Universal Life (VUL) Insurance Portfolio',
    disclosure: [
      'Private Placement Life Insurance (PPLI) and Private Placement Annuity (PPA) Investment Accounts are unregistered securities products and are not subject to the same regulatory requirements as registered products.~As such, PPLI and PPA should only be presented to accredited investors or qualified purchasers as described by the Securities Act of 1933.',
      'This material is intended for informational purposes only, should not be construed as legal or tax advice, and is not intended to replace the advice of a qualified attorney or tax advisor.~If legal or tax advice is required, you should consult your attorney, or accountant.',
      'This information is not intended to constitute any future performance.~Future investment results and performance will vary and are not guaranteed.~Investments in securities involve risks, including the possible loss of principal.~When redeemed, units may be worth more or less than their original value.',
      'This information is being provided as a courtesy and is not intended as a replacement for the statements from the insurance company.~Current performance may be lower or higher than the performance data quoted.~The month, year-to-date, and since inception returns are derived using an internal rate of return ("IRR") calculation, which considers the timing and amount of cash flows as reported by the respective life insurance companies.~Returns for periods greater than 12 months are annualized.~Returns for periods less than 12 months are not annualized.~If there is any discrepancy between this information and life insurance company records, the latter will be controlling.',
      'Private Placement Life Insurance (PPLI), Private Placement Annuity (PPA), and Variable Universal Life (VUL) Investment Account values are subject to market-related fluctuations.~Current values should be obtained before taking action.~PPLI, PPA, and VUL Investment Account values are held in segregated asset accounts and are not subject to the claims paying ability of the insurance company.~Payments of Net Amount at Risk (NAR) at death, may be subject to the claims paying ability of the insurance company.~Please refer to your insurance policy and offering memorandum or prospectus for policy details.',
      'By accepting these materials, the recipient agrees to keep the materials and their content confidential and not to use the materials or the content for any purpose other than to evaluate a financial transaction with Golconda Partners, LLC and Mandorlo International, Ltd and The Leaders Group, Inc. of the type described in the materials, except that there is no limitation on disclosure of the tax treatment or tax structure of the products and/or transaction structures contained herein.',
      'Any unauthorized use or disclosure of these materials without the written consent of Golconda Partners, LLC and Mandorlo International, Ltd is prohibited.',
      'Securities offered through The Leaders Group, Inc., Member FINRA/SIPC, 26 W Dry Creek Circle, Suite 800, Littleton, CO 80120, (303) 797-9080.~Golconda Partners, LLC and Mandorlo International, Ltd are independently owned and operated.',
    ],
    disclosureFooter: `Proprietary and Confidential.~©${moment(new Date()).format(
      'Y',
    )} Golconda Partners, LLC and Mandorlo International, Ltd.~All rights reserved.`,
    worksheetName: {
      accountDetail: 'Policy Detail',
      accountSummary: 'Insurance Summary',
      accountTransactions: 'Policy Transactions',
      idfReturns: 'Investment Subaccount Returns',
    },
  },
  ppa: {
    short: 'PPA',
    accountResults: 'PPA INVESTMENT ACCOUNT RESULTS:~Consolidated (All Accounts)',
    accountResultsFiltered: 'PPA INVESTMENT ACCOUNT RESULTS:~',
    accountDetail: 'PPA Investment Account Detail',
    accountSummary: 'PPA Investment Account Summary',
    accountTransactions: 'PPA Investment Account Transactions',
    heroTitle: 'Private Placement Annuity (PPA) Investment Portfolio',
    heroTitleSingular: 'Private Placement Annuity (PPA) Investment Account',
    idfFunds: 'Insurance-Dedicated Funds',
    idfReturns: 'Insurance-Dedicated Fund (IDF) Returns',
    idfReturnsBefore: 'IDF Returns~-~Before PPA Account Charges',
    title: 'PPA Statements',
    header: 'Private Placement Annuity (PPA) Investment Portfolio',
    statements: 'PPA Investment Portfolio Statements',
    titleConsolidated: 'PRIVATE PLACEMENT ANNUITY ACCOUNT:~Consolidated (All Accounts)',
    titleFiltered: `PRIVATE PLACEMENT ANNUITY ACCOUNT:`,
    disclosure: [
      'Private Placement Life Insurance (PPLI) and Private Placement Annuity (PPA) Investment Accounts are unregistered securities products and are not subject to the same regulatory requirements as registered products.~As such, PPLI and PPA should only be presented to accredited investors or qualified purchasers as described by the Securities Act of 1933.',
      'This material is intended for informational purposes only, should not be construed as legal or tax advice, and is not intended to replace the advice of a qualified attorney or tax advisor.~If legal or tax advice is required, you should consult your attorney, or accountant.',
      'This information is not intended to constitute any future performance.~Future investment results and performance will vary and are not guaranteed.~Investments in securities involve risks, including the possible loss of principal.~When redeemed, units may be worth more or less than their original value.',
      'This information is being provided as a courtesy and is not intended as a replacement for the statements from the insurance company.~Current performance may be lower or higher than the performance data quoted.~The month, year-to-date, and since inception returns are derived using an internal rate of return ("IRR") calculation, which considers the timing and amount of cash flows as reported by the respective life insurance companies.~Returns for periods greater than 12 months are annualized.~Returns for periods less than 12 months are not annualized.~If there is any discrepancy between this information and life insurance company records, the latter will be controlling.',
      'Private Placement Life Insurance (PPLI), Private Placement Annuity (PPA), and Variable Universal Life (VUL) Investment Account values are subject to market-related fluctuations.~Current values should be obtained before taking action.~PPLI, PPA, and VUL Investment Account values are held in segregated asset accounts and are not subject to the claims paying ability of the insurance company.~Payments of Net Amount at Risk (NAR) at death, may be subject to the claims paying ability of the insurance company.~Please refer to your insurance policy and offering memorandum or prospectus for policy details.',
      'By accepting these materials, the recipient agrees to keep the materials and their content confidential and not to use the materials or the content for any purpose other than to evaluate a financial transaction with Golconda Partners, LLC and Mandorlo International, Ltd and The Leaders Group, Inc. of the type described in the materials, except that there is no limitation on disclosure of the tax treatment or tax structure of the products and/or transaction structures contained herein.',
      'Any unauthorized use or disclosure of these materials without the written consent of Golconda Partners, LLC and Mandorlo International, Ltd is prohibited.',
      'Securities offered through The Leaders Group, Inc., Member FINRA/SIPC, 26 W Dry Creek Circle, Suite 800, Littleton, CO 80120, (303) 797-9080.~Golconda Partners, LLC and Mandorlo International, Ltd are independently owned and operated.',
    ],
    disclosureFooter: `Proprietary and Confidential.~©${moment(new Date()).format(
      'Y',
    )} Golconda Partners, LLC and Mandorlo International, Ltd.~All rights reserved.`,
    worksheetName: {
      accountDetail: 'Account Detail',
      accountSummary: 'Account Summary',
      accountTransactions: 'Transactions Current',
      accountTransactionsSinceInception: 'Transactions SI',
      idfReturns: 'IDF Returns',
    },
  },
  table: {
    deposits: 'Deposits',
    withdrawals: '(Withdrawals)',
    depositsWithdrawals: 'Deposits / (Withdrawals)',
    transactionDate: 'Transaction Date',
    insuranceProceeds: 'Insurance Proceeds',
    accountValue: 'Account Value',
    subtotal: 'Subtotal',
    owner: 'Owner',
    total: 'Total',
    annuitant: 'Annuitant',
    description: 'Description',
    idf: 'IDF',
    idfReturn: 'IDF Return',
    idfValue: 'IDF Value',
    insuranceCompany: 'Insurance Company',
    accountNumber: 'Account Number',
    insured: 'Insured(s)',
    policyDate: 'Policy Date',
    month: 'Month',
    policyType: 'Policy Type',
    annualPremium: 'Annual Premium',
    costBasis: 'Cost Basis',
    benefitDuration: 'Benefit Duration',
    eliminationPeriod: 'Elimination Period',
    beneficiary: 'Beneficiary',
    inforcePolicies: 'Inforce Policies',
    premiumCalendar: 'Premium Calendar',
    sinceInception: 'Since Inception',
    terminatedPolicies: 'Terminated Policies',
    terminatedPoliciesClick: 'Click Here to View Terminated Policies',
    yearToDate: 'Year To Date',
    monthlyBenefit: 'Monthly Benefit',
    ppa: {
      accountValueBeginning: 'PPA Account Value,~Beginning',
      premiumDeposits: 'Premium Deposits',
      policyAccountWithdrawals: 'Policy Account Withdrawals',
      idfNetReturn: 'IDF Net Return',
      MEFee: 'M&E Fee',
      accountValueEnding: 'PPA Account Value,~Ending',
      accountReturn: 'PPA Account Return',
    },
    ppli: {
      accountValueBeginning: 'PPLI Account Value,~Beginning',
      premiumDeposits: 'Premium Deposits',
      policyAccountWithdrawals: 'Policy Account Withdrawals',
      policyLoan: 'Policy Loan',
      structuringFee: 'Structuring Fee',
      premiumTax: 'Premium Tax',
      idfNetReturn: 'IDF Net Return',
      MEFee: 'M&E Fee',
      mortalityCharge: 'Mortality Charge',
      accountValueEnding: 'PPLI Account Value,~Ending',
      accountReturn: 'PPLI Account Return',
      insuranceProceeds: 'PPLI Insurance Proceeds',
      outstandingPolicyLoanBalance: 'Outstanding Policy Loan Balance',
      accountValueNet: 'PPLI Account Value,~Net',
    },
    vul: {
      accountValueBeginning: 'VUL Policy Value,~Beginning',
      premiumDeposits: 'Premium Deposits',
      premiumBasedCharges: 'Premium-Based Charges',
      policyExpenseCharges: 'Policy Expense Charges',
      policyAccountWithdrawals: 'Policy Withdrawals',
      policyLoan: 'Policy Loan',
      structuringFee: 'Structuring Fee',
      premiumTax: 'Premium Tax',
      netReturn: 'VUL Net Return',
      MEFee: 'M&E Fee',
      mortalityCharge: 'Mortality Charge',
      accountValueEnding: 'VUL Policy Value,~Ending',
      accountReturn: 'VUL Policy Return',
      insuranceProceeds: 'VUL Insurance Proceeds',
      policyNumber: 'Policy Number',
      policyValue: 'Policy Value',
      investmentSubaccount: 'Investment Subaccount',
      subaccountValue: 'Subaccount Value',
      subaccountReturn: 'Subaccount Return',
      subaccount: 'Subaccount',
    },
  },
  auth: {
    sendCodeAgain: 'Send code again',
    sendCodeViaVoice: 'Send code via voice call',
    callInitiated: 'Call initiated!',
    codeSent: 'Code Sent!',
    password: 'Password',
    passwordNew: 'New Password',
    passwordCreate: 'New Password',
    passwordConfirm: 'Confirm Password',
    passwordNewConfirm: 'Confirm New Password',
    passwordCurrent: 'Current Password',
    usernameCurrent: 'Current User Name',
    usernameNew: 'New User Name',
    usernameNewConfirm: 'Confirm New User Name',
    mobileCurrent: 'Current Mobile Number',
    mobileNew: 'New Mobile Number',
    mobileNewConfirm: 'Confirm New Mobile Number',
    emailCurrent: 'Current Email',
    emailNew: 'New Email',
    emailNewConfirm: 'Confirm New Email',
    sendResetLink: 'Send Password Reset Link',
    backToLogin: 'Back to Login',
    commonWordsMessage: 'This password is easy to guess and commonly used.',
    errorResettingPassword: 'There was an error resetting your password.',
    rememberMyDevice: 'Remember my device',
    persistentTooltip:
      'You will be automatically logged out of the Golconda Client Portal after 7 days of inactivity',
    weveDetectedCompromisedPassword:
      'We’ve detected that you password you are using has been detected in a data breach. To protect your account please reset a unique password.',
    difficultyResetting:
      'If you are experiencing any difficulty receiving a password reset link, please contact Tech Support at ',
    forgotPassword:
      'Forgot your password?~Please enter your User Name or email address and we’ll send you a password reset link via email.',
    anEmailHasBeenSent: 'An email has been sent to enable you to reset your password.',
    passwordRequired: 'Please enter a valid password',
    tooManyRequests:
      'Too many requests, please contact Tech Support at clientservice@golconda.com or (917) 781-0145.',
    resetPassword: 'Reset Password',
    expired: 'Expired',
    altError: 'Error',
    altSuccess: 'Success',
    termsAndConditions: {
      iAccept: 'By checking the box, you acknowledge and agree to the ',
      link: {
        text: 'Client Portal Terms of Use, Client Portal Privacy Notice, and Consumer Privacy Notice.',
        path: '',
      },
    },
    loginError: {
      username: 'Invalid User Name.',
      password: 'Invalid password.',
      doesNotMatch: 'Password does not match.',
      invalidAttempt: 'User Name/email or password not recognized.',
      serverError:
        'Your login credentials could not be verified due to a www.golconda.com server error.~We apologize for the inconvenience.~We are aware of the problem and are doing our best to solve it as quickly as possible.~Please try again later.',
    },
    passwordValidErr: {
      min: `Password is too short. It should have min. 8 characters.`,
      max: `Password is too long. It should have max. 50 characters.`,
      oneLower: 'Password has to have at least one lowercase character.',
      oneUpper: 'Password has to have at least one uppercase character.',
      oneNumber: 'Password has to have at least one number.',
      oneSpecialChar: 'Password has to have at least one special character.',
      commonWordsOrPhrases: 'Avoid common words and phrases.',
    },
    acceptInviteError: {
      termsAndConditions: 'The terms and conditions must be accepted.',
    },
    twoFactorAuth: 'Two-Factor Authentication',
    sentACodeTo: 'We’ve sent a code via text message to mobile number:',
    digitsAndLastTwo: (lastTwo: string) => ` xxx-xxx-xx${lastTwo}`,
    sharingCredentialsWarning:
      'Sharing a User Name and Password with a non-permissioned individual is a breach of confidentiality and a violation of the Terms of Use.~You should only Authenticate if you are the individual permissioned to access the Client Portal.',
    settingsError: {
      newMobile: 'Must enter new mobile number.',
      newEmail: 'Must enter new email address.',
      newUsername: 'Must enter new User Name.',
      currentEmail: 'Must enter current email.',
      currentUsername: 'Must enter current User Name.',
      currentPassword: 'Must enter current password.',
      currentMobile: 'Must enter current mobile number.',
      usernameDoesNotMatch: 'User Names do not match.',
      emailDoesNotMatch: 'Emails do not match.',
      mobileDoesNotMatch: 'Mobile numbers do not match.',
      emailInvalid: 'Please provide a valid email address.',
    },
    verifyDeviceError: {
      sorry:
        'I’m sorry.~There appears to be a problem.~Please click “Send code again” or “Send code via voice call” below.~If you continue to experience difficulty logging in,\n please contact Tech Support at ',
      incorrect:
        'Incorrect Code.~If you continue to experience difficulty logging in, please contact Tech Support at ',
    },
    verifyDeviceDisabledUser:
      'I’m sorry, there appears to be a problem.~Please contact Tech Support at ',
  },
  settings: {
    title: 'Settings',
    email: {
      title: 'Update Email',
      success: 'Your email has been changed.~A confirmation email has been sent to you',
      nonUnique: 'New email must be different from current email address.',
      alreadyUsed: 'Email address has already been used.',
    },
    password: {
      title: 'Update Password',
      success: 'Your password has been reset.~A confirmation email has been sent to you.',
    },
    username: {
      title: 'Update User Name',
      success: 'Your User Name has been changed.~A confirmation email has been sent to you.',
      nonUnique: 'New User Name must be different from current User Name.',
      alreadyUsed: 'User Name has already been used.',
    },
    mobile: {
      title: 'Update Mobile Number',
      success: 'Your mobile number has been changed.',
      nonUnique: 'New mobile number must be different from current mobile number.',
      alreadyUsed: 'Mobile number already in use with other account.',
      notValid: 'Enter a valid phone number and country code',
    },
    activeSessions: {
      title: 'Active Sessions',
      logoutButton: 'Log out',
      currentDevice: 'Your session',
      logoutConfirmation: 'Are you sure?',
      yesButton: 'Yes',
      noButton: 'No',
      headers: {
        device: 'Device',
        ip: 'IP',
        location: 'Location',
        started: 'Started',
        expires: 'Expires',
        action: 'Action',
      },
    },
  },
  success: 'Success!',
  login: 'Login',
  disableAccount: 'Lock your account',
  register: 'Register',
  loginHeader: 'Login',
  resetPassword: 'Reset Password',
  print: 'Print',
  downloadToExcel: 'Download to Excel',
  downloadPDF: 'Download PDF',
  authenticate: 'Authenticate',
  password: 'Password',
  username: 'User Name',
  usernameOrEmail: 'User Name or email',
  logBackInMessage: 'Your session has expired.~Please login to continue working.',
  inputCode: 'Input Code',
  inputCodeHere: 'Input code here',
  submit: 'Submit',
  close: 'Close',
  logout: 'Logout',
  forgotPassword: 'Forgot Password?',
  clientPortal: 'Client Portal',
  logo: 'Golconda Logo',
  footnotesTitle: 'Notes:',
  issuedOn: (insured: string) => `(Issued on ${insured})`,
  annuitantAtDocuments: (annuitant: string) => `(Annuitant:  ${annuitant})`,
  insuredAtDocuments: (insured: string) => `(Insured:  ${insured})`,
  indexAccountRates: 'Index Account Rates',
  companyInfo: {
    nameShort: 'Golconda',
    name: 'Golconda Partners, LLC',
    address1: '550 Fifth Avenue, 11th Floor',
    city: 'New York',
    state: 'New York',
    stateAbbr: 'NY',
    zip: '10036',
    phoneNumber: '(212) 715-8746',
  },
  copyright: `Proprietary and Confidential.~© Golconda Partners, LLC, and Mandorlo International, Ltd.`,
  allRightsReserved: '\xA0All rights reserved.',
  statement: {
    golcondaPartners:
      'Golconda Partners, LLC and/or its agents are presently licensed to broker traditional life insurance in the following states:~AL, AK, AZ, AR, CA, CO, CT, DE, DC, FL, GA, HI, ID, IL, IN, IA, KS, KY, LA, ME, MD, MA, MI, MN, MS, MO, MT, NE, NV, NH, NJ, NM, NY, NC, OH, OK, OR, RI, SC, SD, TN, TX, UT, VT, VA, WA, WV, WI, WY.~Insurance services are offered to residents of the above listed states.~However, we can be licensed in other states as dictated by client location and situation.',
    variableLife:
      'Variable life and annuity products, as well as other securities products, may be sold in the following states:~AL, AK, AR, CA, CO, CT, DE, DC, FL, GA, ID, IL, IN, IA, KY, ME, MD, MA, MN, MO, MT, NE, NV, NH, NJ, NY, NC, OH, OK, OR, SC, SD, TN, UT, VT, VA, WA, WV.~Securities products are offered to residents of the above listed states.~However, we can be licensed in other states as dictated by client location and situation.',
    securitiesOfferedOne: 'Securities offered through The Leaders Group, Inc., Member ',
    securitiesOfferedTwo:
      ', 26 W Dry Creek Circle, Suite 800, Littleton, CO 80120, (303) 797-9080.~Golconda Partners, LLC are independently owned and operated.~Check the background of this Firm and/or investment professional on ',
    finraLink: {
      text: 'FINRA',
      link: 'http://www.finra.org/',
    },
    sipcLink: {
      text: 'SIPC',
      link: 'http://www.sipc.org/',
    },
    brokerCheckLink: {
      text: 'FINRA’s BrokerCheck.',
      link: 'https://brokercheck.finra.org/',
    },
    privatePlacement:
      'Private Placement Life Insurance and Annuities are unregistered products and are not subject to the same regulatory requirements as registered products.~As such, Private Placement Life Insurance and Annuities can only be offered to accredited investors or qualified purchasers as described by the Securities Act of 1933.',
  },
  loginNotice: 'If you are experiencing any difficulty logging in, please contact Tech Support at ',
  disabledUserNotice: {
    line1: '* For your security, this account has been locked.',
    line2: '~Please contact Tech Support at ',
    line3: ' to restore access to your account',
  },
  resetPasswordNotice:
    'If you are experiencing any difficulty setting your new password, please contact Tech Support at ',
  registrationNotice:
    'If you are experiencing any difficulty completing your registration, please contact Tech Support at ',

  privacyNoticeCopy: {
    title: 'Client Portal Privacy Notice',
    lastUpdated: 'Last Updated:~March 9, 2021',
    yourPrivacy:
      'Your privacy is important to us.~This Client Portal Privacy Notice applies to your use of the website (www.golconda.com) and client portal (collectively, the “',
    portal: 'Portal',
    offeredBy:
      '”) offered by Golconda Partners, LLC and its affiliates.~We developed this privacy notice to explain how we collect, use, share, and protect information accessible through the Portal or generated by your use of the Portal.~Golconda Partners, LLC and its affiliates are collectively referred to in this privacy notice as “',
    golconda: 'Golconda',
    quoteComma: '," "',
    we: 'we',
    us: 'us',
    or: '," or "',
    our: 'our',
    periodEndQuote: '."',
    asYouReview:
      'As you review this Client Portal Privacy Notice, please keep in mind that if you have life insurance, annuity investment accounts, or other financial products or services with us, we will use and share any personal information that we collect from or about you in accordance with our Consumer Privacy Notice and any other applicable privacy notices or authorization forms made available to you at the time your information was collected, which may offer you certain choices with respect to the use and sharing of your personal information.',
    ifYouHaveQuestions:
      'If you have questions about this privacy notice or the ways in which we collect, use, share or protect your information, please contact us as described in the ',
    contactUs: 'Contact Us',
    sectionBelow: ' section below.',
    informationCollection: 'I.~~ Information Collection and Use',
    personalInfo: 'Personal Information',
    personalInfoIs:
      'Personal information is information that identifies someone or relates to an identifiable individual.~The only personal information we collect from or about you in connection with the Portal is your e-mail address, User Name and password, and mobile telephone number (“',
    credentials: 'Credentials',
    credentialsAre:
      '”).~Credentials are used to provide you with access to the Portal, to contact you in the event you need to reset your password, to respond to your requests and inquiries, and to secure the Portal, our networks, and databases (e.g., in some instances, in order to access the Portal, we will send a verification code to your mobile phone in order to verify your identity and secure your account).',
    thePortalProvides:
      'The Portal provides clients, and professional advisors and other individuals specifically permissioned by clients and approved by us, with access to detailed information about their life insurance and annuity portfolios.~In most cases, we collect the financial and other personal information contained in the policies and other documents available through the Portal directly from you or your professional advisors or representatives when developing financial analytics or applying for products or services that we offer.~For life insurance and annuity products, we may collect information pertaining to an individual’s health, occupation, avocations, or other personal characteristics.~We also collect information that you provide to us when you open an account, make an application through Golconda Partners LLC for the purchase of a life insurance policy, securities product, or service, or engage us for one or more of our services such as a financial analysis, review of an existing life insurance or annuity portfolio, or similar offerings.~This information may include your name, address, phone number, business address, business phone number, email address, Social Security number, date of birth, marital status, health history, financial information, and information about your investment objectives and experience.',
    inAdditionTo:
      'In addition to information collected from you or your professional advisors or representatives, we may collect information about you from third parties if we are required to verify information (e.g., for legal or regulatory compliance purposes) or if we need additional information in order to execute transactions or perform services on your behalf.~These third parties may include consumer reporting agencies, employers, plan sponsors, third party administrators, insurance companies, health care providers, identity verification services, insurance support organizations, and our affiliates.',
    theCollectionOf:
      'The collection and use of personal information is necessary for us to properly service our clients and their professional advisors.~We collect and use personal information for such purposes as developing appropriate financial analytics, developing pricing for life insurance and annuity portfolios, submitting application paperwork, administering our clients’ life insurance and annuity portfolios, assisting with claims, processing requested transactions, offering our clients suitable financial products and services, and contacting our clients regarding their applications, policies and accounts.',
    websiteData: 'Website Data',
    whenYouAccess:
      'When you access or use the Portal, we also collect certain website data.~Website data consists of information about how your computer or device links to and interacts with the Portal, preferences expressed, settings chosen, and information about your device, such as your device and browser type, operating system, IP address, mobile network carrier, device and advertising identifiers, date and time of visit, Portal features viewed, and the third-party site your device was accessing before coming to the Portal.~We use cookies and similar technologies on the Portal to automatically receive and track this website data, to enhance the security of the Portal, to perform analytics concerning your use of the Portal, and to comply with and enforce applicable legal requirements, industry standards and our policies.~To learn more about these technologies and our use of them, please see the ',
    websiteDataCookies: 'Use of Website Data | Cookies and Similar Technologies',
    anonymizedAnd: 'Anonymized and Aggregated Data',
    weMayAlso:
      'We may also use any information that we collect on an aggregate or anonymous basis (such that it does not identify any particular individual) for various business purposes, where permissible under applicable laws and regulations.',
    disclosureOf: 'II.~~ Disclosure of Information',
    weSharePersonalInfo:
      'We share personal information, including information about our transactions and experiences with you and information about your creditworthiness, with our affiliates to help meet your product and service needs.~We do not disclose, share, sell, or otherwise transfer personal information to or with any third parties, except as provided below.',
    policyOwnersAnd: 'Policy Owners and Trustees.~',
    weProvide:
      'We provide policyholders and trustees with access to their respective policy and account information.',
    permissionedPro: 'Permissioned Professional Advisors and Other Individuals.~',
    clientsCanGrant:
      'Clients can grant access to the Portal to their respective professional advisors and other specifically designated individuals in order for them to monitor and administer their clients’ policies and accounts via the Portal.~When a client gives their professional advisors and other specifically designated individuals permission to access the client’s policies and accounts via the Portal, such advisors and specifically designated individuals will have access to all personal and financial information available through the Portal.',
    serviceProviders: 'Service Providers | Insurance Companies.~',
    weMayDisclose:
      'We may disclose personal information to third-party service providers and insurance companies, such as life insurance companies, banks, trust companies, law firms, consultants, and other financial institutions and professional firms, that contract with Golconda or you to develop and execute transactions, provide financing, review medical history information, broker or issue policy contracts, perform custodial or administrative functions, detect or prevent criminal activity, fraud, material misrepresentation or material nondisclosure in connection with insurance transactions, and perform other functions in connection with your insurance transactions.~We provide these providers and insurers with only the information they need to perform the specific services we have requested, and we require them to protect and keep confidential your personal information (except as otherwise permitted by law).~Although we use reasonable precautions to ensure that service providers protect your personal information, we cannot guarantee that they will protect your personal information to the same extent as Golconda.',
    theInsuranceCompanies:
      'The insurance companies we work with on your behalf may make a brief report to the Medical Information Bureau (MIB), a non‐profit membership organization of life insurance companies, which operates an information exchange on behalf of its members.~If you apply to another MIB member company for life or health insurance coverage, or a claim for benefits is submitted to such a company, the MIB, upon request, will supply such company with the information in its file.~ Similarly, the insurance company or its reinsurers may release information in its file to other life insurance companies to which you may apply for life or health insurance or to which a claim for benefits may be submitted.~They may also disclose personal information to your doctor if they find a serious health problem you do not know about.~ Further, they may contract with a consumer reporting agency to prepare an investigative consumer report and this agency may keep the information it has gathered and disclose it to others.',
    governmentAnd: 'Government and Regulatory Authorities; Law Enforcement.~',
    weShare1:
      'We share personal information with law enforcement and other governmental and regulatory authorities pursuant to law.',
    inResponse: 'In Response to Valid Administrative or Judicial Orders.~',
    weShare2:
      'We share personal information in response to a facially valid administrative or judicial order, including a search warrant or subpoena.',
    saleTransfer: 'Sale, Transfer, or Merger.~',
    inTheEvent:
      'In the event of a proposed or consummated sale, transfer, merger or consolidation of all or part of the business of Golconda, we may share personal information as reasonably necessary to enable the recipient to make business decisions about the purchase, transfer, merger, or consolidation, provided the recipient agrees not to disclose the information except as permitted by law.',
    asOtherwise: 'As Otherwise Permitted or Required by Law.~',
    withoutLimit:
      'Without limiting the foregoing, we reserve the right to disclose or report personal information in limited circumstances where we believe in good faith that such	disclosure or reporting required by law (e.g., to cooperate with regulators or law enforcement officials), or as needed to perform credit/authentication checks, to protect the integrity of our website, to investigate and resolve consumer disputes, or to fulfill your requests.',
    yourAuthorization: 'Your Authorization.~',
    weMayShare: 'We may share your personal information to the extent expressly authorized by you.',
    inAddition:
      'In addition, we may share anonymous or aggregated information with third parties to help deliver products, services, and content that are tailored to the users of the Portal and for other legitimate purposes.',
    useOfWebsite: 'III.~~ Use of Website Data | Cookies and Similar Technologies',
    whenYouVisit:
      'When you visit or interact with the Portal, we and other companies we work with may deploy and use cookies and similar technologies (such as web beacons, local shared objects and other tracking technologies) for a variety of reasons, including to collect website data in order to enhance the security of the Portal, perform analytics concerning your use of the Portal, and comply with and enforce applicable legal requirements, industry standards, and our policies.',
    weMayAlsoUse:
      'We may also use Google Analytics cookies to collect information about how visitors use the Portal.~These cookies collect information in the aggregate to give us insight into how the Portal is being used.~We anonymize IP addresses in Google Analytics, and the anonymized data is transmitted to and stored by Google on servers in the United States.~Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf.~Google will not associate your IP address with any other data held by Google.~For an overview of these Google Analytics cookies, please visit ',
    googleAnalyticsLink: 'https://support.google.com/analytics/answer/6004245',
    toolsGoogleLink: 'https://tools.google.com/dlpage/gaoptout',
    youMayInstall: '.~You may install a Google Analytics Opt-out Browser Add-on by going to ',
    period: '.',
    beingUsed:
      'being used.~We anonymize IP addresses in Google Analytics, and the anonymized data is transmitted to and stored by Google on servers in the United States.~Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf.~Google will not associate your IP address with any other data held by Google.~For an overview of these Google Analytics cookies, please visit https://support.google.com/analytics/answer/6004245.~You may install a Google Analytics Opt-out Browser Add-on by going to https://tools.google.com/dlpage/gaoptout.',
    youMayAlso:
      'You may have other means of controlling the use of cookies and similar technologies on this and other websites.~You may be able to instruct your browser or mobile device to warn you each time a cookie is being sent, or to reject all or certain kinds of cookies.~Typically, you can do this by changing the privacy settings on your internet browser or device.~However, if you choose to reject cookies, the Portal may not function properly and some Portal services may be diminished.',
    doNotTrack: 'Do Not Track',
    certainWebsites:
      'Certain web browsers may allow you to enable a “do not track” option that sends signals to the websites you visit indicating that you do not want your online activities tracked.~This is different from blocking cookies as browsers with the “do not track” option selected may still accept cookies.~Right now, there is no industry standard for how companies should respond to “do not track” signals, although one may be adopted in the future.~We do not respond to “do not track” signals at this time.~If we do so in the future, we will modify this privacy notice accordingly.~More information about “do not track” is available at ',
    allAboutLink: 'https://www.allaboutdnt.com',
    linksToThirdParty: 'IV.~~ Links to Third-Party Sites',
    golcondaMayProvide:
      'Golconda may provide links to websites that are owned or operated by other companies (“third-party websites”).~When you use a link on the Portal to visit a third-party website, you will be subject to that website’s privacy and security practices, which may differ from ours.~You should familiarize yourself with the privacy policy, terms of use, and security practices of the linked third-party website before providing any information on that website.~We make no warranties or representations about other websites.',
    security: 'V.~~ Security',
    weUseReasonable:
      'We use reasonable physical, electronic, and procedural safeguards that comply with federal and state standards to protect and limit access to personal information.~This includes device safeguards and secured files and buildings.~We also restrict access to personal information to our employees and others who we feel must use it to provide our products and services.~Their use of personal information is limited by law, our employee code of conduct, and written agreements where appropriate.',
    pleaseNoteThat:
      'Please note that information you send to us electronically may not be secure when it is transmitted to us.~We recommend that you do not use unsecure channels to communicate sensitive or confidential information (such as your Social Security number) to us.',
    useOfEmail: 'VI.~~Use of E-mail Addresses',
    whenYouCreate:
      'When you create a Portal account or submit an inquiry to us, we collect your e-mail address so that we can provide you with access to the Portal, contact you in the event you need to reset your password, send you emails concerning your policy or account, transactions with us, and inquiries.~We may also send updates with other information we think you will find useful.~You can stop receiving these updates by using the unsubscribe links provided in our e-mails.',
    reviewingAndUpdating: 'VII.~~Reviewing and Updating Your Information',
    keepingYourPolicy:
      'Keeping your policy and account information up-to-date is very important.~You may review or update your User Name, email address, and password directly via the Portal.~You may review or update other policy and account information by contacting us as described in the ',
    sectionBelowOrBy:
      ' section below, or by calling or writing to us using the contact information listed on your policy documents, account statements, records, or other account materials.',
    whenContacting:
      'When contacting us to review your information, you must describe the kind of information you want to review and state that your request is in response to this Client Portal Privacy Notice.~Include your full name, mailing address, and policy number ',
    ifApplicable: '(if applicable).~',
    within30:
      'Within 30 business days, we will describe what is available and how you may request corrections.',
    whenContactingUs:
      'When contacting us to correct information about you, send a written request as described above, explaining your desired correction.~Within 30 business days, we will either make the requested correction or tell you why we will not.~We cannot correct consumer report information, such as your credit report.~ To do this, you must contact the consumer reporting agency that provided it.',
    ifWeMake:
      'If we make your requested correction, we will notify you in writing.~If required by law, we will also notify others who may have given it to or received it from us.~If we refuse to make the requested correction, you may file with us a concise written statement about why you object, including the information you think is correct.~Your statement will then become part of your file.~It will be sent to the same persons to whom we would send a copy of any correction or change.',
    changesToThis: 'VIII.~ Changes to this Client Portal Privacy Notice',
    weMayChange:
      'We may change this Client Portal Privacy Notice from time to time.~When we do, we will let you know by appropriate means such as by posting the revised notice on this page with a new “Last Updated” date.~Any changes to this Client Portal Privacy Notice will become effective when posted unless indicated otherwise.',
    contactUsHeading: 'IX.~~ Contact Us',
    ifYouHaveAny:
      'If you have any questions or comments about this privacy notice, or to submit a request or otherwise exercise your rights under this privacy notice, please contact us using the contact information below:',
    consumerPrivacyNotice: 'Consumer Privacy Notice',
    facts: 'FACTS',
    whatDoesGolconda: 'What does golconda do with your personal information?',
    why: 'Why?',
    what: 'What?',
    how: 'How?',
    financialCompanies:
      'Financial companies choose how they share your personal information.~Federal law gives consumers the right to limit some but not all sharing.~Federal law also requires us to tell you how we collect, share, and protect your personal information.~Please read this notice carefully to understand what we do.',
    theTypesOf:
      'The types of personal information we collect and share depends on the product or service you have with us.~This information can include:',
    theTypesOfInfoList: [
      'Social Security Number, employment information, income, and demographic data',
      'Account transactions and transaction history',
      'Credit history and payment history',
      'Medical information and insurance claim history',
    ],
    allFinancial:
      'All financial companies need to share customers’ personal information to run their everyday business.~In the section below, we list the reasons financial companies can share their customers’ personal information, the reason Golconda chooses to share, and whether you can limit this sharing.',
    reasonsWeCan: 'REASONS WE CAN SHARE YOUR PERSONAL INFORMATION',
    doesGolcondaShare: 'Does Golconda Share?',
    canYouLimit: 'Can You Limit This Sharing?',
    forOurEveryday: 'For our everyday business purposes – ',
    suchAsTo:
      'Such as to process your transactions, maintain your account(s), respond to court orders and legal \ninvestigations, or report to credit bureaus',
    forOurMarketing: 'For our marketing purposes – ',
    toOfferOur: 'To offer our products and services to you',
    forJointMarketing: 'For joint marketing with other financial companies',
    forOurAffiliates: 'For our affiliates’ everyday business purposes – ',
    informationAboutYour:
      'Information about your transactions and experience, or credit worthiness',
    forNonAffiliates: 'For nonaffiliates to market their products and services to you',
    yes: 'Yes',
    no: 'No',
    notApplicable: 'N/A',
    weWillOnly: 'We will only share with your consent',
    questions: 'Questions?',
    call917Or: 'Call (917) 781-0140 or email ',
    whoWeAre: 'Who We Are',
    whoIsProviding: 'Who is providing this notice?',
    golcondaPartners: 'Golconda Partners, LLC',
    whatWeDo: 'What we do',
    howDoesProtect: 'How does Golconda protect my personal information?',
    toProtect:
      'To protect your personal information from unauthorized access and use, we use security measures that comply with applicable law.~These measures include computer safeguards, secured files, and limiting access to personal information to those with appropriate authority and for intended business purposes only.',
    howDoesCollect: 'How does Golconda collect my personal information?',
    weCollect: 'We collect your personal information, for example, when you',
    weCollectList: [
      'Apply for a life insurance or an annuity account, or pay a premium',
      'Take a withdrawal or loan from a life insurance or annuity account',
      'Terminate a life insurance or annuity account',
      'File a claim for life insurance proceeds',
      'Give us your contact information',
    ],
    weMayAlsoCollectYour:
      'We may also collect your personal information from your employees or professional advisors, from government agencies, such as the Motor Vehicle Department, and from other companies, such as employers, plan sponsors, third party administrators, insurance companies, health care providers, identity verification services, and insurance support organizations.',
    whyCant: 'Why can’t I limit all sharing?',
    federalLaw: 'Federal law gives you the right to limit only',
    federalLawList: [
      'Sharing for affiliates’ everyday purposes – information about your credit worthiness',
      'Affiliates from using your information to market to you',
      ' Sharing for nonaffiliates to market their products and services to you',
    ],
    stateLaws:
      'State laws and individual companies may give you additional rights to limit sharing.',
    definitions: 'Definitions',
    affiliates: 'Affiliates',
    companiesRelated:
      'Companies related by common ownership or control.~They can be financial and nonfinancial companies.~Golconda Partners, LLC has no affiliates as of the last update of this Privacy Notice.',
    nonAffiliates: 'Nonaffiliates',
    companiesNot:
      'Companies not related by common ownership or control.~They can be financial and nonfinancial companies.~Golconda does not share with nonaffiliates so they can market to you.',
    jointMarketing: 'Joint marketing',
    aFormal:
      'A formal agreement between nonaffiliated financial companies that together market financial products or services to you.~Golconda does not share with nonaffiliates for joint marketing purposes.',
    otherImportant: 'other important information',
    pleaseSee:
      'Please see below for more information regarding your rights under state law (as applicable).',
    noticeOf:
      'NOTICE OF INFORMATION PRACTICES FOR ARIZONA, CALIFORNIA, CONNECTICUT, GEORGIA, ILLINOIS, MAINE, MASSACHUSETTS, MINNESOTA, MONTANA, NEVADA, NEW JERSEY, NORTH CAROLINA, OHIO, OREGON, AND VIRGINIA RESIDENTS',
    weMayCollectPersonal:
      'We may collect personal information about you from persons other than you.~In addition to information you provide us on applications or in connection with insurance coverage we issue to you, we may collect personal information about you from insurance support organizations (ISO) and consumer or other reporting agencies.~Information obtained from a report prepared by an ISO may be retained by the ISO and disclosed to other persons.~We may also disclose personal information about you to an insurance regulatory authority, or law enforcement, or other governmental authority to the extent permitted or required by law.',
    youHave:
      'You have the right to request a copy of the personal information that we have about you.~If we receive such a request, we will provide you a copy of your requested personal information within 30 days, as long as the information is reasonably locatable and retrievable.~We may charge a reasonable fee to cover the costs incurred to provide you with copies of requested personal information.',
    youHave2:
      'You have the right to correct, amend or delete personal information we may have recorded about you.~We will respond to your written request to correct, amend or\n delete personal information about you, within our possession, within 30 business days from the date your request is received.',
    ifYouWish:
      'If you wish to exercise your rights as provided in this notice, please write us at:~Golconda Partners, LLC, 550 Fifth Avenue, 11th Floor; New York, NY 10036.~Please provide your full name, address and policy or account number.',
    nevadaLaw:
      'Nevada law requires us to advise you that you have the option to be placed on our internal “do not call” list if you do not want to receive sales calls from Golconda.~You \nmay make this request in the following convenient ways:',
    call: 'Call ',
    writeTo: 'Write us at:~',
    addressString: () =>
      `${Strings.companyInfo.name}, ${Strings.companyInfo.address1}, ${Strings.companyInfo.city}, ${Strings.companyInfo.stateAbbr} ${Strings.companyInfo.zip}`,
    pleaseBeSure:
      'Please be sure to provide us with your name, address and all telephone numbers you wish to include on our list.\nIf you have questions about this notice, you may contact us at the address listed above or you may also contact the Nevada Attorney General’s office at:',
    officeOf: 'Office of the Nevada Attorney General, Bureau of Consumer Protection',
    attorneyAddress: '555 E. Washington Avenue, Suite 3900, Las Vegas, NV 89101',
    attorneyPhone: 'Phone:~(702) 486-3132',
    attorneyEmail: 'Email:~BCPINFO@ag.state.nv.us',
    pleaseNote:
      'Please note that Golconda’s “do not call” list is limited only to telephone solicitation calls.~We may still contact you about your Golconda policy, billing issues, claims and other matters that relate to the administration of your coverage with us.',
    sectionLinks: {
      contactUs: 'ix-contact-us',
      websiteData: 'iii-website-data',
    },
  },
  termsAndConditions: {
    heading: 'Terms And Conditions Of Use',
    message: 'Please scroll down the entire agreement to accept.',
    buttonAccept: 'Accept and Continue',
    lastUpdated: 'Last Updated:~January 1, 2020',
    overviewOfClient: 'OVERVIEW OF CLIENT PORTAL TERMS',
    thisIsAHigh:
      'This is a high-level overview of these Portal Terms for use of the Portal offered by Golconda.~You will be required to scroll down to review the FULL TERMS and ',
    byClicking: 'BY CLICKING “ACCEPT AND CONTINUE,” YOU AGREE TO THESE PORTAL TERMS.~',
    thisOverviewIs:
      'This overview is provided as a convenience only.~In the event of any conflict between the full terms and this overview, the full terms apply.',
    overviewList: [
      'Access to the Portal is limited to authorized clients and individuals permissioned by such clients.~You may use the Portal for your personal, non-commercial use only.~Any information you provide to access the Portal will be accurate and up to date.',
      'Your User Name and password is personal to you and must be treated as confidential.~You must not disclose such information to any other person or provide any other person with access to the Portal using your User Name and password without our approval.',
      'We have the right to disable any User Name and password at any time for any or no reason.',
      'We may text you at the mobile phone number you provide for purposes of sending you verification codes as part of our two-factor authentication process.~Standard message and data rates apply.~You may opt out of receiving future text messages as described in the Portal Terms below.~If you disable text messaging, you may be disabling two-factor authentication and will not be able to access your account.',
      'The information in the Portal is provided as a courtesy and is not intended to be a replacement for policy statements, premium billing, or other records that are maintained by the insurance company.~If there is any discrepancy between the information in the Portal and the insurance company records, the latter will control.',
      'Golconda does not engage in the practice of law or accounting, or give legal, accounting, tax, actuarial, or asset allocation advice.~You are advised to seek counsel in these areas from your appropriate advisors.',
      'Golconda disclaims all warranties, express or implied; disclaims consequential, indirect and other damages; and limits its liability in connection with the Portal and these services as described in greater detail in these Portal Terms.',
      'We reserve the right to revise and update these Portal Terms at any time in our sole discretion by posting the amended terms to the Portal.~All changes are effective immediately when we post them on the Portal.',
    ],
    endOfOverview: '******** End of Overview.~See Below for Full Portal Terms ********',
    endOfOverviewMobile: 'End of Overview.~See Below for Full Portal Terms',
    clientPortalTerms: 'CLIENT PORTAL TERMS OF USE',
    theseClientPortal: 'These Client Portal Terms of Use (',
    portalTerms: '"Portal Terms"',
    governYourAccess: ') govern your access to and use of the website (',
    andCollectively: ') and client portal (collectively, the ',
    portal: '"Portal"',
    offeredByGolconda:
      ') offered by Golconda Partners, LLC and its affiliates, including any features, services, content, and other materials available on or through the Portal.~Golconda Partners, LLC and its affiliates are collectively referred to in these Portal Terms as ',
    golcondaQuotes: '“Golconda"',
    weUs: '"we,""us,"',
    our: '"our."',
    thePortalIsOffered:
      'The Portal is offered and available to authorized Golconda clients for purposes of accessing and monitoring their life insurance and annuity portfolios.~Subject to Golconda’s prior written approval, clients may give permission to their professional financial advisors, trustees, and other specifically designated individuals to access and use the Portal on their behalf.~All access to and use of the Portal by clients and other permissioned individuals is subject to these Portal Terms.',
    pleaseReadThese:
      'PLEASE READ THESE PORTAL TERMS CAREFULLY BEFORE ACCESSING OR USING ANY PART OF THE PORTAL.~',
    byClickingAccept: 'BY CLICKING “I ACCEPT,” YOU AGREE TO THESE PORTAL TERMS.~',
    theOverviewOf:
      'THE OVERVIEW OF PORTAL TERMS IS PROVIDED \nFOR CONVENIENCE ONLY, AND IN THE EVENT OF ANY CONFLICT BETWEEN THE OVERVIEW AND THE BODY OF THESE TERMS, THE BODY OF THESE TERMS APPLY.',
    portalAccessAnd: 'I.~~Portal Access and Account Security',
    toAccessThePortalOrSome:
      'To access the Portal or some of the resources it offers, you will be asked to provide certain registration details or other information, such as your e-mail address and mobile telephone number.~It is a condition of your use of the Portal that all the information you provide is correct, current, and complete.~You agree that all information you provide to register with the Portal or otherwise is governed by our Client Portal ',
    privacyNotice: 'Privacy Notice',
    andYouConsent:
      ', and you consent to all actions we take with respect to your information consistent with such notice.',
    toAccessThePortalYou:
      'To access the Portal, you will choose or be provided with a User Name and password, and in some instances, will receive other pieces of information as part of our security procedures (e.g., a verification code).~You must treat such information as confidential, and you must not disclose it to any other person or entity, unless otherwise approved in writing by us.~You also acknowledge that your Portal account is personal to you and agree not to provide any other person with access to the Portal or portions of it using your User Name, password, or other security information.',
    youAgreeTo:
      'You agree to notify us immediately of any unauthorized access to or use of your User Name or password or any other breach of security.~You also agree to ensure that you exit from your Portal account at the end of each session.~You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.',
    toHelpMaintain:
      'To help maintain the security of our system and protect the data accessible through the Portal, we use a two-factor authentication process that sends a verification code to your mobile phone when you attempt to login to your Portal account.~You must enter both your password and the verification code to gain access to your account.~YOU AGREE THAT WE MAY TEXT YOU AT THE MOBILE PHONE NUMBER YOU PROVIDE FOR PURPOSES OF SENDING YOU VERIFICATION CODES AS PART OF OUR TWO-FACTOR AUTHENTICATION PROCESS.~Standard message and data rates apply.~You may opt out of receiving future text messages from us by sending an e-mail with your request to ',
    howeverIfYou:
      '~However, if you disable text messaging, you may be disabling two-factor authentication and will not be able to access your account.',
    weHaveThe:
      'We have the right to disable any User Name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Portal Terms.',
    portalProvidedAs: 'II.~~Portal Provided as Courtesy Only | No Legal or Accounting Advice',
    theInformationCourtesy:
      'The information in the Portal is provided as a courtesy and is not intended to be a replacement for policy statements, premium billing, or other documents and records that are maintained by the insurance company.~The information in the Portal has been obtained from the applicable insurance companies and Golconda Partners, LLC has taken reasonable steps to accurately reproduce it.~If there is any discrepancy between the information in the Portal or reports generated through the portal and the underlying insurance company or financial institution records, the latter will control.~In any event, insurance coverage is dictated by the relevant policy terms and conditions.',
    theInformationUnderstanding:
      'The information in the portal is provided with the understanding that Golconda Partners, LLC does not engage in the practice of law or accounting, or give legal, accounting, tax, actuarial, or asset allocation advice.~You are advised to seek counsel in these areas from your appropriate advisors.',
    ownershipAndUse: 'III.~~Ownership and Use of Portal Content',
    thePortalAnd:
      'The Portal and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Golconda, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.',
    thesePortalTerms:
      'These Portal Terms permit you to use the Portal for your personal, non-commercial use only.~You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Portal, except as follows:',
    theseportalTermsList: [
      'Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.',
      'You may store files that are automatically cached by your Web browser for display enhancement purposes.',
      'You may print or download a reasonable number of copies of your life insurance and/or annuity portfolio data for your own personal, non-commercial use and not for further reproduction, publication, or distribution; provided, however, that authorized professional advisors and other permissioned individuals may print or download policy, account, and portfolio information, and may distribute such information to their clients, solely as necessary for purposes of providing financial, trustee, or administrative services to such clients.',
    ],
    youAreStrictly:
      'You are strictly prohibited from modifying copies of any materials from the Portal and from deleting or altering any copyright, trademark, or other proprietary rights notices from copies of materials from the Portal.~ Further, you must not modify, create derivative works of, decompile, or otherwise attempt to extract source code from us or the Portal, unless you are expressly permitted to do so under an open source license or we give you express written permission.',
    ifYouPrint:
      'If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Portal in breach of these Portal Terms, your right to use the Portal will stop immediately and you must, at our option, return or destroy any copies of the materials you have made.~No right, title, or interest in or to the Portal or any content on the Portal is transferred to you, and all rights not expressly granted are reserved by Golconda.~Any use of the Portal not expressly permitted by these Portal Terms is a breach of these Portal Terms and may violate copyright, trademark, and other laws.',
    prohibitedUses: 'IV.~~Prohibited Uses',
    youMayUse:
      'You may use the Portal only for lawful purposes and in accordance with these Portal Terms.~You agree ',
    toUseThe: ' to use the Portal:',
    youMayUseList: [
      'In any way that violates any applicable federal, state, local, or international law or regulation.',
      'To solicit, for commercial purposes, any users of the Portal.',
      'To impersonate or attempt to impersonate Golconda, a Golconda employee, another user, or any other person or entity.',
      "To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Portal, or which, as determined by us, may harm Golconda or users of the Portal, or expose them to liability.",
    ],
    youFurther: 'You further agree ',
    not: 'not',
    to: ' to:',
    youFurtherList: [
      'Use or launch any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses the Portal in a manner that sends more request messages to the Portal servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line browser.',
      'Use any manual process to monitor or copy any of the material on the Portal, or for any other purpose not expressly authorized in these Portal Terms, without our prior written consent.',
      'Use any device, software, or routine that interferes with the proper working of the Portal.',
      'Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.',
      'Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Portal, the server on which the Portal is stored, or any server, computer, or database connected to the Portal.',
      'Attack the Portal via a denial-of-service attack or a distributed denial-of-service attack.',
      'Misrepresent an affiliation with any person or organization.',
    ],
    tradeMarks: 'V.~~Trademarks',
    theGolcondaPartners:
      'The Golconda Partners name, the Golconda logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Golconda or its affiliates or licensors.~You must not use such marks without the prior written permission of Golconda.~All other names, logos, product and service names, designs, and slogans on the Portal are the trademarks of their respective owners.',
    termination: 'VI.~~Termination',
    youAgreeThatWe:
      'You agree that we may, at our sole discretion, suspend or terminate your access to all or part of the Portal with or without notice and for any reason, including, without limitation, breach of these Portal Terms.~Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities.~Upon suspension or termination of your account, your right to use the Portal will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.',
    weReserveTheRightToWithdraw:
      'We reserve the right to withdraw or amend the Portal, and any service or material we provide on the Portal, in our sole discretion without notice.~We will not be liable if for any reason all or any part of the Portal is unavailable at any time or for any period.~From time to time, we may restrict access to some parts of the Portal or to users.',
    changesToPortal: 'VII.~~Changes to Portal Terms',
    weReserveTheRightToRevise:
      'We reserve the right to revise and update these Portal Terms at any time in our sole discretion by posting the amended terms to the Portal.~All changes are effective immediately when we post them on the Portal.~Your continued use of the Portal following the posting of amended Portal Terms means that you accept and agree to the changes.~You acknowledge and agree that it is your responsibility to review the most current version of the Portal Terms when accessing the Portal.~If you disagree with these Portal Terms (as amended from time to time) or are dissatisfied with the Portal, your sole and exclusive remedy is to discontinue using the Portal.',
    obligations:
      'Obligations and rights provided for under the terms of insurance policies and other financial agreements accessible through the Portal can only be modified under the terms of such insurance policies or other agreements, as applicable.',
    disclaimerOfWarranties: 'VIII.~~Disclaimer of Warranties and Limitation on Liability',
    weTryToKeep:
      "WE TRY TO KEEP THE PORTAL UP, BUG-FREE, AND SAFE, BUT YOU USE IT AT YOUR OWN RISK.~WE ARE PROVIDING THE PORTAL AS IS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.~WE DO NOT GUARANTEE THAT THE PORTAL WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR THAT GOLCONDA WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS.~GOLCONDA IS NOT RESPONSIBLE FOR THE ACTIONS, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, OWNERS, PARENT COMPANIES, SUBSIDIARIES, AFFILIATES AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES.~WE WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, SPECIAL, INDIRECT, OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.~OUR AGGREGATE LIABILITY ARISING OUT OF THIS AGREEMENT GOLCONDA WILL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US TO ACCESS THE PORTAL IN THE PAST TWELVE MONTHS.~APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.~IN SUCH CASES, GOLCONDA'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.",
    indemnification: 'IX.~~Indemnification',
    youAgreeToIndemnify:
      'You agree to indemnify and hold harmless Golconda and its officers, directors, employees, agents, affiliates, third-party information providers, licensors and others involved in the Portal or the delivery of products, other services or information over the Portal (collectively, the ',
    indeminified: '“Indemnified Parties”',
    fromAndAgainst:
      '), from and against any and all liabilities, expenses, damages and costs, including reasonable attorney’s fees, arising from any violation by you of these Portal Terms or your use of the Portal or any products, other services or information obtained through the Portal.~You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of your use of the information accessed from the Portal.',
    governingLaw: 'X.~~Governing Law and Jurisdiction',
    allMatters:
      'All matters relating to the Portal and these Portal Terms, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of New York without giving effect to any choice or conflict of law provision or rule (whether of the State of New York or any other jurisdiction).',
    anyLegal:
      'Any legal suit, action, or proceeding arising out of, or related to, these Portal Terms or the Portal shall be instituted exclusively in the federal courts of the United States or the courts of the State of New York, in each case located in the City of New York and County of New York.~You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.',
    misc: 'XI.~~Miscellaneous',
    noWaiver:
      'No waiver by Golconda of any term or condition set out in these Portal Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Golconda to assert a right or provision under these Portal Terms shall not constitute a waiver of such right or provision.',
    ifAnyProvision:
      'If any provision of these Portal Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.',
    contactUs: 'XII.~~Contact Us',
    ifYouHave:
      'If you have any questions or comments about these Portal Terms, please contact us using the contact information below:',
  },
  contactUsSection: {
    callUs: 'Call Us:',
    phone: '(917) 781-0140',
    emailus: 'E-mail us:',
    writeToUs: 'Write to us:',
  },
  loremXS:
    'Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec.~Donec viverra eleifend lacus, vitae ullamcorper metus.',
  disclosureTitle: 'Disclosure',
  accountNumber: 'Account Number',
  accountValue: 'Account Value',
  annuitant: 'Annuitant',
  availabilityByIC: 'Availability by Insurance Company',
  asOfMonthYear: (monthAndYear: string) => `As of: \u00a0${monthAndYear}`,
  beneficiary: 'Beneficiary',
  deathBenefitOption: 'Death Benefit Option',
  designee: 'Trustee / Member / Designee',
  clientName: 'Client Name',
  costBasis: 'Cost Basis',
  cumulativeDeposit: 'Cumulative Deposit',
  dateField: 'Date: ',
  dividendOption: 'Dividend Option',
  guaranteedDurationAge: 'Guaranteed Duration Age',
  fromInception: 'from inception',
  selectPolicies: 'Select Policies',
  insuranceCompany: 'Insurance Company',
  insuranceProceeds: 'Insurance Proceeds',
  insured: 'Insured(s)',
  policyDate: 'Policy Date',
  previousPage: 'Previous Page',
  maturityDate: 'Maturity Date',
  riders: 'Riders',
  nextPage: 'Next Page',
  owner: 'Owner',
  monthlyBenefit: 'Monthly Benefit',
  benefitDuration: 'Benefit Duration',
  eliminationPeriod: 'Elimination Period',
  permissionedIndividuals: 'Permissioned Individuals',
  policyAccountBalance: 'Policy Account Balance',
  policyGrossAccountValue: 'Policy Gross Account Value',
  policyLoanBalance: 'Policy Loan Balance',
  policyNetAccountValue: 'Policy Net Account Value',
  policyType: 'Policy Type',
  ppaStatement: 'PPA Statement',
  ppliStatement: 'PPLI Statement',
  ppaInvestmentAccountStatements: 'PPA Investment Account Statements',
  ppliInvestmentAccountStatements: 'PPLI Investment Account Policy Statements',
  premium: 'Premium',
  premiumDueDate: 'Premium Due Date',
  premiumMode: 'Premium Mode',
  premiumPricingGuaranteeDuration: 'Premium Pricing Guarantee Duration',
  premiumPayorEmail: 'Premium Payor Email Address',
  primaryContactPersonEmail: 'Primary Contact Person Email Address',
  premiumBillingAddress: 'Premium Billing Address',
  inforcePolicyCommunicationCCList: 'Inforce Policy Communication~-~CC List',
  resultsSinceInception: 'Results Since Inception',
  scheduledDeathBenefitOptionChangeDate: 'Scheduled Death Benefit Option Change Date',
  scheduledFaceAmountReductionFirstDate: 'Scheduled Face Amount Reduction #1',
  scheduledFaceAmountReductionSecondDate: 'Scheduled Face Amount Reduction #2',
  scheduledFaceAmountReductionThirdDate: 'Scheduled Face Amount Reduction #3',
  scheduledFirstDistributionDate: 'Scheduled First Distribution Date',
  scheduledPremiumDuration: 'Scheduled Premium Duration',
  consolidatedAllAccounts: 'Consolidated (All Accounts)',
  showPriorStatements: 'Show Prior Statements',
  surrenderCharge: 'Surrender Charge',
  termConversionOptionExpirationDate: 'Conversion Option Expiration Date',
  termConversionLanguageChangeDate: 'Term Conversion Language Change Date',
  termLevelPremiumDuration: 'Term Level Premium Duration',
  termLevelPremiumExpirationDate: 'Level Premium Expiration Date',
  toggleFilters: 'Toggle Filters',
  toggleShowStatement: 'Toggle Show Statement',
  inceptionWarning:
    'The inception date is based on availability of historic information for the policy.  Additional historic information has been requested from the life insurance company.',
  permissionedClientList: (fullName: string) => `${fullName} Client List`,
  client: (fullName: string) => `Client: \u00a0${fullName}`,
  pleaseProvideCode: 'Please provide a code',
  noData: (type: string) =>
    `Golconda Partners, LLC is not currently servicing any ${type} for you.`,
  noDataType: {
    annuity: 'Annuity Investment Accounts',
    disability: 'Disability Insurance Policies',
    life: 'Life Insurance Policies',
    ppli: 'PPLI Investment Accounts',
    vul: 'VUL Investment Accounts',
    ppa: 'PPA Investment Accounts',
    iul: 'IUL Investment Accounts',
    policies: 'policies',
  },
  noDataQuestions: {
    line1: (type: string) => `If you have any questions about ${type}, please contact us at `,
    link: {
      text: 'clientservice@golconda.com',
      url: 'clientservice@golconda.com',
    },
    line2: ' or (917) 781-0145.',
  },
  noDownload: (type: string) => `There are currently no ${type} statements to view/download.`,
  noDownloadType: {
    annual: 'annual',
    monthly: 'monthly',
  },
  noDownloadQuestions: {
    line1: 'If you have any questions about IUL Investment Accounts, please contact us at ',
    link: {
      text: 'clientservice@golconda.com',
      url: 'clientservice@golconda.com',
    },
    line2: ' or (917) 781-0145.',
  },
  noDocs: (type: string) => `No documents are currently available for your ${type}.`,
  noDocsType: {
    annuity: 'Annuity Investment Accounts',
    life: 'Life Insurance Policies',
    ppli: 'PPLI Investment Accounts',
    vul: 'VUL Investment Accounts',
    ppa: 'PPA Investment Accounts',
    policies: 'policies',
  },
  noDocsInstructions: {
    line1: 'To add policy documents, please contact us at ',
    link: {
      text: 'clientservice@golconda.com',
      url: 'clientservice@golconda.com',
    },
    line2: ' or (917) 781-0145.',
  },
  chart: {
    inMillions: 'In Millions',
  },
  year: 'year',
  years: 'years',
  comma: ', ',
  loading: 'Loading...',
  genericServerError:
    'Your request cannot be completed due to a www.golconda.com server error.~We apologize for the inconvenience.~We are aware of the problem and are doing our best to solve it as quickly as possible.~Please try again later.',
  mobileMessage: {
    primaryTextPartOne: 'We’re sorry but at this time the ',
    primaryTextPartTwo: ' is not supported on mobile devices (and devices with compact screens).',
    secondaryText:
      'Please use a desktop or a laptop computer and ensure your browser window is maximized to fill the entire screen for an optimal viewing experience.',
  },
  consolidatedFilename: (accountIds: Array<string>, type: string, period: string) => {
    const policy = accountIds.length === 1 ? `#${accountIds[0]}` : 'Consolidated'
    const date = moment(period, 'YYYY-MM-DD').format('MM YYYY')
    return `Golconda ${type} ${policy} Statement  -  ${date}`
  },
  pdfError:
    'Download failed. Please refresh (⌘+R for Mac or F5 for Windows) and try again. If you continue to experience problems, please contact clientservice@golconda.com.',
  inactivityWarning: {
    title: 'Inactivity Warning',
    message1: 'Your session will expire in ',
    message2: ' due to inactivity. Do you want to stay logged in?',
    button: {
      stayLoggedIn: 'Stay Logged In',
      logoutNow: 'Log Out Now',
    },
  },
  annual: 'Annual',
  monthy: 'Monthly',
  periodic: 'Periodic',
  quarterly: 'Quarterly',
  semiAnnual: 'Semi-Annual',
  singlePay: 'Single Pay',
  availableIDFs: 'Available IDFs',
  extremelyWeak: 'Password strength: Extremely Weak',
  veryWeak: 'Password strength: Very Weak',
  weak: 'Password strength: Weak',
  strong: 'Password strength: Strong',
  veryStrong: 'Password strength: Very Strong',
  level: 'Level',
  increasing: 'Increasing',
  corridor: '7702 Corridor',
  varianceNote: {
    Annuity: {
      no_variance: 'The Golconda fee review indicates no variance from expected values.',
      genuine_variance:
        'The Golconda fee review indicates variance from expected values.~The Golconda team will research this variance.',
      no_charge_false_positive:
        'The Golconda fee review indicates that there is an insufficient Money Market balance to cover the M&E Fee.~The M&E Fee will be accrued and deducted once the Money Market balance has been replenished.',
      replenished_false_positive:
        'The Golconda fee review indicates no variance from expected values.~The M&E Fee includes accruals that had not been deducted in the prior month(s).',
    },
    PrivatePlacement: {
      no_variance: 'The Golconda fee review indicates no variance from expected values.',
      genuine_variance:
        'The Golconda fee review indicates variance from expected values.~The Golconda team will research this variance.',
      no_charge_false_positive:
        'The Golconda fee review indicates that there is an insufficient Money Market balance to cover the M&E Fee and Mortality Charge.~The M&E Fee and Mortality Charge will be accrued and deducted once the Money Market balance has been replenished.',
      replenished_false_positive:
        'The Golconda fee review indicates no variance from expected values.~The M&E Fee and Mortality Charge includes accruals that had not been deducted in the prior month(s).',
    },
    VULPolicy: {},
  },
  multiSessions: {
    otherProfiles: 'Other profiles',
    addSession: 'Add',
    sessionExpired: 'Session expired',
    chooseCurrentSession: 'Choose current session',
  },
}

export type StringsObjType = typeof Strings

const cleanedStrings: StringsObjType = replaceInStrings(cloneDeep(Strings), '~', '\u00a0 ')

export default cleanedStrings
