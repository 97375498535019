// @flow
import BaseModel from './base/base-model'
import { defaultFloat } from './defaults'

export type XIRRRateDataType = {|
  inception: ?number,
  month: ?number,
  year: ?number,
|}

export default class XIRRRate extends BaseModel {
  inception: number

  month: number

  year: number

  constructor(
    data: XIRRRateDataType = {
      inception: null,
      month: null,
      year: null,
    },
  ) {
    super()

    this.inception = defaultFloat(data.inception)
    this.month = defaultFloat(data.month)
    this.year = defaultFloat(data.year)
  }
}
