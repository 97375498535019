// @flow
import React from 'react'
import { Strings } from '@/constants'
import { formatTableValue } from '@/helpers'
import type { ModelKeys } from '@/models'

type FooterCellTotalProps = {|
  row: {|
    data: Array<{ [ModelKeys]: string | number, _original: Object }>,
  |},
  value: ModelKeys,
  decimals?: number,
  subtotalColumn: ModelKeys,
  accessor: ModelKeys,
|}

const FooterCellTotal = (props: FooterCellTotalProps) => {
  const { row, value, subtotalColumn, accessor, decimals } = props

  const penultimateRow = row.data[row.data.length - 2]
  const cutoff = !!(penultimateRow && penultimateRow[subtotalColumn] !== Strings.table.subtotal)

  let sum = 0

  row.data.forEach(item => {
    if (item[subtotalColumn] !== Strings.table.subtotal && !item?._original?.hide) {
      sum += item[value]
    }
  })

  let className = 'tableCell dollarSign footer currency'
  className += cutoff ? ' cutoff' : ''
  className += sum ? '' : ' nullValue'

  return (
    <div className={className} data-accessor={`currency-${accessor}`}>
      <span className="value">{formatTableValue(sum, false, decimals)}</span>
      <span className="dollarSignText">$</span>
    </div>
  )
}

export default FooterCellTotal
