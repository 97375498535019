// @flow
import moment from 'moment'
import type Moment from 'moment'
import React from 'react'
import styled from '@emotion/styled'
import type { DateType } from '@/constants'
import { DateTypes } from '@/constants'
import metrics from '@/../src/styles/metrics.scss'
import selectors from '@/selectors'
import { getChartLabels, getPrimaryDataset, getSecondaryDataset } from './constants'
import { SmoothLineChart } from './smooth-line'

type ChartProps = {|
  loading: boolean,
  policyStatements: Array<PPStatementType>,
  title?: ?string,
  hideDateSelector?: boolean,
  storybookDate?: ?Moment,
  noLineTension?: boolean,
|}

type ChartState = {|
  startDate: Moment,
  endDate: Moment,
|}

const ChartContainer = styled.div`
  height: ${(props: { hasTitle: boolean }) =>
    props.hasTitle ? metrics.chartContainerHeight : metrics.noTitleChartHeight};
`

export class Chart extends React.Component<ChartProps, ChartState> {
  minStartDate: Moment = moment()

  maxEndDate: Moment = moment()

  static defaultProps = {
    title: null,
    hideDateSelector: false,
    storybookDate: null,
  }

  constructor(props: ChartProps) {
    super(props)
    const { storybookDate } = props
    this.state = {
      startDate: storybookDate || moment(),
      endDate: moment(),
    }
  }

  componentDidMount() {
    const { policyStatements } = this.props
    if (policyStatements.length) {
      this.setDates()
    }
  }

  componentDidUpdate(prevProps: ChartProps) {
    const { policyStatements } = this.props
    if (prevProps.policyStatements.length !== policyStatements.length) {
      this.setDates()
    }
  }

  handleDateChange = (date: Moment, which: DateType) => {
    if (which === DateTypes.START_DATE) {
      this.setState({ startDate: date })
    } else {
      this.setState({ endDate: date })
    }
  }

  statementsByEndDate = () => {
    const { policyStatements } = this.props
    const { startDate, endDate } = this.state
    return selectors.policy.getStatementsByEndDate(policyStatements, startDate, endDate)
  }

  getPrimaryDataset = () => {
    const statementsByEndDate = this.statementsByEndDate()
    const labels = this.getLabels()
    return getPrimaryDataset(labels, statementsByEndDate)
  }

  getSecondaryDataset = () => {
    const statementsByEndDate = this.statementsByEndDate()
    const labels = this.getLabels()
    return getSecondaryDataset(labels, statementsByEndDate)
  }

  getLabels = (): Array<string> => {
    const statementsByEndDate = this.statementsByEndDate()
    return getChartLabels(statementsByEndDate)
  }

  setDates = () => {
    const { policyStatements } = this.props

    if (policyStatements.length) {
      const sortedStatements = policyStatements.sort((a, b) =>
        moment(a.statementStart).isBefore(b.statementStart) ? -1 : 1,
      )
      const startDate = moment(sortedStatements[0].statementStart)
      const endDate = moment(sortedStatements[sortedStatements.length - 1].statementEnd)

      this.setState({
        startDate,
        endDate,
      })

      this.minStartDate = startDate
      this.maxEndDate = endDate
    }
  }

  render() {
    const { startDate, endDate } = this.state
    const { hideDateSelector, loading, title, noLineTension } = this.props

    return (
      <ChartContainer hasTitle={!!title}>
        <SmoothLineChart
          primaryDataset={this.getPrimaryDataset()}
          secondaryDataset={this.getSecondaryDataset()}
          labels={this.getLabels()}
          handleDateChange={this.handleDateChange}
          startDate={startDate}
          minStartDate={this.minStartDate}
          endDate={endDate}
          maxEndDate={this.maxEndDate}
          loading={loading}
          title={title}
          hideDateSelector={hideDateSelector}
          noLineTension={noLineTension}
        />
      </ChartContainer>
    )
  }
}

export default Chart
