// @flow
import * as React from 'react'
import { isEqual } from 'lodash'

import metrics from '@/../src/styles/metrics.scss'
import { Footnotes } from '@/components'
import GolcondaTable from '@/components/table'
import Format from '@/components/table/format'
import { Strings, MaxTableColumnWidths, TableColumnWidths } from '@/constants'
import { PPAStatement, PPLIStatement, VULStatement, XIRRRate } from '@/models'
import styles from '../styles.scss'
import {
  transformPPAStatementData,
  transformPPLIStatementData,
  transformVULStatementData,
} from './account-detail-data'
import { VariancesNote } from './variances-note'
import { InceptionText } from '../inception-text'

type AccountDetailTableProps = {|
  ownerName: ?string,
  notes: Array<FootnoteType>,
  variances: Array<VarianceType>,
  inception: boolean,
  loading: boolean,
  statements: Array<PPLIStatement> | Array<PPAStatement> | Array<VULStatement>,
  month: ?string,
  transformData: (
    allStatements: Array<PPStatementType>,
    argMonth: ?string,
    xirrRates: XIRRRate | Object,
  ) => Array<TableFormats>,
  pdfKey: PDFKeysType,
  xirrRates: XIRRRate | Object,
  sinceInceptionDate: ?string,
|}

type AccountDetailTableState = {|
  data: Array<TableFormats>,
|}

const shouldBold = (row: *) => row.original.boldRow
const shouldUnderline = (row: *) => row.original.underlineRow
const shouldShowDollar = (row: *) => row.original.showDollarRow

export const AccountDetailColumns = (inception: boolean, sinceInception: string) => [
  {
    name: '',
    accessor: 'account_detail_name',
    cellFormat: (row: *) => {
      const { isBold } = row.original
      return isBold ? <b>{row.value}</b> : <div className="indentLeft">{row.value}</div>
    },
  },
  {
    name: Strings.table.month,
    accessor: 'account_detail_month',
    isCurrency: true,
    headerStyle: {
      padding: '0 30px',
    },
    width: TableColumnWidths.detailMonth,
    maxWidth: MaxTableColumnWidths.maxDetailMonth,
    shouldBold,
    shouldShowDollar,
    shouldUnderline,
  },
  {
    name: Strings.table.yearToDate,
    accessor: 'account_detail_year_to_date',
    isCurrency: true,
    headerStyle: {
      padding: `0 ${metrics.spacerLG}`,
    },
    width: TableColumnWidths.detailYTD,
    maxWidth: MaxTableColumnWidths.maxDetailYTD,
    shouldBold,
    shouldShowDollar,
    shouldUnderline,
  },
  {
    name: inception ? `* ${Strings.table.sinceInception}` : Strings.table.sinceInception,
    subtitle: sinceInception,
    accessor: 'account_detail_since_inception',
    isCurrency: true,
    headerStyle: {
      flexDirection: 'column',
    },
    width: TableColumnWidths.detailSinceInception,
    maxWidth: MaxTableColumnWidths.maxDetailSinceInception,
    shouldBold,
    shouldShowDollar,
    shouldUnderline,
  },
]

export const getVULAccountDetailDownloadData = (
  statements: Array<VULStatement>,
  inception: boolean,
  month: ?string,
  ownerName: ?string,
  xirrRates: XIRRRate | Object,
) => {
  const transformedVULData = transformVULStatementData(statements, month, xirrRates)
  const tableData = transformedVULData.tableRows
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements
  }
  return new Format({
    ownerName,
    statementEnd,
    columns: AccountDetailColumns(inception),
    title: Strings.vul.accountDetail,
    sheetname: Strings.vul.worksheetName.accountDetail,
    vulTable: true,
  }).getTableAsXLSXInput(tableData)
}

export const getPPLIAccountDetailDownloadData = (
  statements: Array<PPLIStatement>,
  inception: boolean,
  month: ?string,
  ownerName: ?string,
  xirrRates: XIRRRate | Object,
  sinceInceptionDate: ?string,
) => {
  const transformedPPLIData = transformPPLIStatementData(statements, month, xirrRates)
  const tableData = transformedPPLIData.tableRows
  const columnsHeader = AccountDetailColumns(inception, sinceInceptionDate)
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements
  }
  return new Format({
    ownerName,
    statementEnd,
    columns: columnsHeader,
    title: Strings.ppli.accountDetail,
    sheetname: Strings.ppli.worksheetName.accountDetail,
  }).getTableAsXLSXInput(tableData)
}
export const getPPAAccountDetailDownloadData = (
  statements: Array<PPAStatement>,
  inception: boolean,
  month: ?string,
  ownerName: ?string,
  xirrRates: XIRRRate | Object,
  sinceInceptionDate: ?string,
) => {
  const transformedPPAData = transformPPAStatementData(statements, month, xirrRates)
  const tableData = transformedPPAData.tableRows
  const columnsHeader = AccountDetailColumns(inception, sinceInceptionDate)
  let statementEnd = ''
  if (statements[0]) {
    ;[{ statementEnd }] = statements
  }
  return new Format({
    ownerName,
    statementEnd,
    columns: columnsHeader,
    title: Strings.ppa.accountDetail,
    sheetname: Strings.ppa.worksheetName.accountDetail,
    ppaTable: true,
  }).getTableAsXLSXInput(tableData)
}

export class AccountDetailTable extends React.Component<
  AccountDetailTableProps,
  AccountDetailTableState,
> {
  constructor(props: AccountDetailTableProps) {
    super(props)

    this.state = {
      data: [],
    }
  }

  componentDidUpdate(prevProps: AccountDetailTableProps) {
    const { loading: prevLoading, xirrRates: prevXirrRates } = prevProps
    const { loading, transformData, statements, month, xirrRates } = this.props
    if ((prevLoading && !loading) || !isEqual(prevXirrRates, xirrRates)) {
      const data = transformData(statements, month, xirrRates)
      this.updateState({ data: data.tableRows })
    }
  }

  updateState(newState: AccountDetailTableState) {
    this.setState(newState)
  }

  renderVariances = () => {
    const { loading, variances } = this.props

    if (!loading && variances.length) {
      return <VariancesNote variances={variances} />
    }
    return null
  }

  renderInceptionWarning = () => {
    const { loading, inception } = this.props
    if (!loading && inception) {
      return (
        <div className={styles.inceptionContainer}>
          <InceptionText withAsterisk />
        </div>
      )
    }
    return null
  }

  renderFootnotes = () => {
    const { notes } = this.props
    if (notes.length) {
      return <Footnotes notes={notes} />
    }
    return null
  }

  render() {
    const { ownerName, loading, pdfKey, inception } = this.props
    const { data } = this.state
    const { sinceInceptionDate } = this.props
    const columnsHeader = AccountDetailColumns(inception, sinceInceptionDate)
    return (
      <div className={styles.removeTableMarginBottom}>
        <GolcondaTable
          ownerName={ownerName}
          loading={loading}
          data={data}
          columns={columnsHeader}
          pdfKey={pdfKey}
        />
        <div className={styles.footerElements}>
          {this.renderVariances()}
          {this.renderInceptionWarning()}
          {this.renderFootnotes()}
        </div>
      </div>
    )
  }
}

type AccountDetailTableWrapperProps = {|
  ownerName: ?string,
  notes: Array<FootnoteType>,
  statements: Array<PPStatementType>,
  loading: boolean,
  month: ?string,
  variances: Array<VarianceType>,
  inception: boolean,
  pdfKey: PDFKeysType,
  xirrRates: XIRRRate | Object,
|}

export const AccountDetailPPATable = (props: AccountDetailTableWrapperProps) => (
  <AccountDetailTable {...props} transformData={transformPPAStatementData} />
)

export const AccountDetailPPLITable = (props: AccountDetailTableWrapperProps) => (
  <AccountDetailTable {...props} transformData={transformPPLIStatementData} />
)

export const AccountDetailVULTable = (props: AccountDetailTableWrapperProps) => (
  <AccountDetailTable {...props} transformData={transformVULStatementData} />
)

export default {}
