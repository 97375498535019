// @flow
// https://github.com/RayDeCampo/nodejs-xirr
import newton from 'newton-raphson-method'
import modifiedNewton from 'modified-newton-raphson'

const MILLIS_PER_DAY = 1000 * 60 * 60 * 24
const DAYS_IN_YEAR = 365

const convert = data => {
  if (!data || !data.length || !data.forEach || data.length < 2) {
    throw new Error('Argument is not an array with length of 2 or more.')
  }

  const investments = []
  let start = Math.floor(data[0].when / MILLIS_PER_DAY)
  let end = start
  let minAmount = Number.POSITIVE_INFINITY
  let maxAmount = Number.NEGATIVE_INFINITY
  let total = 0
  let deposits = 0
  data.forEach(datum => {
    total += datum.amount
    if (datum.amount < 0) {
      deposits += -datum.amount
    }
    const epochDays = Math.floor(datum.when / MILLIS_PER_DAY)
    start = Math.min(start, epochDays)
    end = Math.max(end, epochDays)
    minAmount = Math.min(minAmount, datum.amount)
    maxAmount = Math.max(maxAmount, datum.amount)
    investments.push({
      amount: datum.amount,
      epochDays,
      years: 0,
    })
  })
  if (start === end) {
    throw new Error('Transactions must not all be on the same day.')
  }
  if (minAmount >= 0) {
    throw new Error('Transactions must not all be nonnegative.')
  }
  if (maxAmount < 0) {
    throw new Error('Transactions must not all be negative.')
  }
  investments.forEach(investment => {
    // Number of years (including fraction) this item applies
    investment.years = (end - investment.epochDays) / DAYS_IN_YEAR // eslint-disable-line no-param-reassign
  })
  return {
    total,
    deposits,
    days: end - start,
    investments,
    maxAmount,
  }
}

const xirr = (
  transactions: Array<{ when: Date, amount: number }>,
  options?: {
    tolerance?: number,
    epsilon?: number,
    maxIterations?: number,
    h?: number,
    verbose?: boolean,
  },
  guessArg?: number,
) => {
  const data = convert(transactions)
  if (data.maxAmount === 0) {
    return -1
  }
  const { investments } = data
  const value = rate =>
    investments.reduce((sum, investment) => {
      // Make the consts more Math-y, makes the derivative easier to see
      const A = investment.amount
      const Y = investment.years
      if (rate > -1) {
        return sum + A * (1 + rate) ** Y
      }
      if (rate < -1) {
        // Extend the function into the range where the rate is less
        // than -100%.  Even though this does not make practical sense,
        // it allows the algorithm to converge in the cases where the
        // candidate values enter this range

        // We cannot use the same formula as before, since the base of
        // the exponent (1+rate) is negative, this yields imaginary
        // values for fractional years.
        // E.g. if rate=-1.5 and years=.5, it would be (-.5)^.5,
        // i.e. the square root of negative one half.

        // Ensure the values are always negative so there can never
        // be a zero (as long as some amount is non-zero).
        // This formula also ensures that the derivative is positive
        // (when rate < -1) so that Newton's method is encouraged to
        // move the candidate values towards the proper range

        return sum - Math.abs(A) * (-1 - rate) ** Y
      }
      if (Y === 0) {
        return sum + A // Treat 0^0 as 1
      }
      return sum
    }, 0)
  const derivative = rate =>
    investments.reduce((sum, investment) => {
      // Make the consts more Math-y, makes the derivative easier to see
      const A = investment.amount
      const Y = investment.years
      if (Y === 0) {
        return sum
      }
      if (rate > -1) {
        return sum + A * Y * (1 + rate) ** (Y - 1)
      }
      if (rate < -1) {
        return sum + Math.abs(A) * Y * (-1 - rate) ** (Y - 1)
      }
      return sum
    }, 0)

  const guess =
    typeof guessArg === 'number'
      ? guessArg
      : data.total / data.deposits / (data.days / DAYS_IN_YEAR)
  const rate =
    newton(value, derivative, guess, options) || modifiedNewton(value, derivative, guess, options)

  if (rate === false) {
    // truthiness strikes again, !rate is true when rate is zero
    throw new Error('Newton-Raphson algorithm failed to converge.')
  }
  return rate
}

export default xirr
