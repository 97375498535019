import { staticPath } from '@/helpers'

const Images = {
  factsheet: STORYBOOK
    ? require('../../public/golconda/images/factsheet.svg')
    : staticPath('/public/images/factsheet.svg'),
  sound: STORYBOOK
    ? require('../../public/golconda/images/noun-sound@2x.png')
    : staticPath('/public/images/noun-sound@2x.png'),
  pdf: STORYBOOK
    ? require('../../public/golconda/images/pdf-icon@2x.png')
    : staticPath('/public/images/pdf-icon@2x.png'),
  word: STORYBOOK
    ? require('../../public/golconda/images/word-icon@2x.png')
    : staticPath('/public/images/word-icon@2x.png'),
  excel: STORYBOOK
    ? require('../../public/golconda/images/excel-icon@2x.png')
    : staticPath('/public/images/excel-icon@2x.png'),
  ppt: STORYBOOK
    ? require('../../public/golconda/images/ppt-icon@2x.png')
    : staticPath('/public/images/ppt-icon@2x.png'),
  image: STORYBOOK
    ? require('../../public/golconda/images/image-icon@2x.png')
    : staticPath('/public/images/image-icon@2x.png'),
  other: STORYBOOK
    ? require('../../public/golconda/images/other-icon@2x.png')
    : staticPath('/public/images/other-icon@2x.png'),
  download: STORYBOOK
    ? require('../../public/golconda/images/download-button@2x.png')
    : staticPath('/public/images/download-button@2x.png'),
  print: STORYBOOK
    ? require('../../public/golconda/images/printer@2x.png')
    : staticPath('/public/images/printer@2x.png'),
  arrowRight: STORYBOOK
    ? require('../../public/golconda/images/arrow-right@2x.png')
    : staticPath('/public/images/arrow-right@2x.png'),
  downCaret: STORYBOOK
    ? require('../../public/golconda/images/downCaret@2x.png')
    : staticPath('/public/images/downCaret@2x.png'),
  downCaretBlue: STORYBOOK
    ? require('../../public/golconda/images/downCaretBlue@2x.png')
    : staticPath('/public/images/downCaretBlue@2x.png'),
  searchIcon: STORYBOOK
    ? require('../../public/golconda/images/search.png')
    : staticPath('/public/images/search.png'),
  searchPressed: STORYBOOK
    ? require('../../public/golconda/images/search-pressed.png')
    : staticPath('/public/images/search-pressed.png'),
  success: STORYBOOK
    ? require('../../public/golconda/images/check@2x.png')
    : staticPath('/public/images/check@2x.png'),
  triangle: STORYBOOK
    ? require('../../public/golconda/images/triangle@2x.png')
    : staticPath('/public/images/triangle@2x.png'),
  error: STORYBOOK
    ? require('../../public/golconda/images/red-close@2x.png')
    : staticPath('/public/images/red-close@2x.png'),
  error2: STORYBOOK
    ? require('../../public/golconda/images/redErrorX@2x.png')
    : staticPath('/public/images/redErrorX@2x.png'),
  checkmark: STORYBOOK
    ? require('../../public/golconda/images/greenCheckmark@2x.png')
    : staticPath('/public/images/greenCheckmark@2x.png'),
  about: STORYBOOK
    ? require('../../public/golconda/images/about@2x.png')
    : staticPath('/public/images/about@2x.png'),
  close: STORYBOOK
    ? require('../../public/golconda/images/close@2x.png')
    : staticPath('/public/images/close@2x.png'),
  greyClose: STORYBOOK
    ? require('../../public/golconda/images/grey-close.png')
    : staticPath('/public/images/grey-close.png'),
  navClose: STORYBOOK
    ? require('../../public/golconda/images/nav-close@2x.png')
    : staticPath('/public/images/nav-close@2x.png'),
  dropdownClose: STORYBOOK
    ? require('../../public/golconda/images/dropdownClose@2x.png')
    : staticPath('/public/images/dropdownClose@2x.png'),
  contact: STORYBOOK
    ? require('../../public/golconda/images/contact@2x.png')
    : staticPath('/public/images/contact@2x.png'),
  home2: STORYBOOK
    ? require('../../public/golconda/images/home2@2x.png')
    : staticPath('/public/images/home2@2x.png'),
  phone: STORYBOOK
    ? require('../../public/golconda/images/phone@2x.png')
    : staticPath('/public/images/phone@2x.png'),
  email: STORYBOOK
    ? require('../../public/golconda/images/email@2x.png')
    : staticPath('/public/images/email@2x.png'),
  email2: STORYBOOK
    ? require('../../public/golconda/images/email2@2x.png')
    : staticPath('/public/images/email2@2x.png'),
  home: STORYBOOK
    ? require('../../public/golconda/images/home@2x.png')
    : staticPath('/public/images/home@2x.png'),
  logo: STORYBOOK
    ? require('../../public/golconda/images/logo-only.svg')
    : staticPath('/public/images/logo-only.svg'),
  logoVertical: STORYBOOK
    ? require('../../public/golconda/images/logo-vertical.svg')
    : staticPath('/public/images/logo-vertical.svg'),
  logoHorizontal: STORYBOOK
    ? require('../../public/golconda/images/logo-horizontal.svg')
    : staticPath('/public/images/logo-horizontal.svg'),
  portal: STORYBOOK
    ? require('../../public/golconda/images/portal@2x.png')
    : staticPath('/public/images/portal@2x.png'),
  twitter: STORYBOOK
    ? require('../../public/golconda/images/twitter.png')
    : staticPath('/public/images/twitter.png'),
  facebook: STORYBOOK
    ? require('../../public/golconda/images/facebook.png')
    : staticPath('/public/images/facebook.png'),
  linkedin: STORYBOOK
    ? require('../../public/golconda/images/linkedin@2x.png')
    : staticPath('/public/images/linkedin@2x.png'),
  brokenLinkBG: STORYBOOK
    ? require('../../public/golconda/images/bk-bridge-broken-link2.jpg')
    : staticPath('/public/images/bk-bridge-broken-link2.jpg'),
  exampleIDFImage: STORYBOOK
    ? require('../../public/golconda/images/example-idf-image.png')
    : staticPath('/public/images/example-idf-image.png'),
  spinner: STORYBOOK
    ? require('../../public/golconda/images/spinner.png')
    : staticPath('/public/images/spinner.svg'),
  info: STORYBOOK
    ? require('../../public/golconda/images/info-icon.png')
    : staticPath('/public/images/info-icon.svg'),
  settings: STORYBOOK
    ? require('../../public/golconda/icons/ic-settings.svg')
    : staticPath('/public/icons/ic-settings.svg'),
  settingsGray: STORYBOOK
    ? require('../../public/golconda/icons/ic-settings-gray.svg')
    : staticPath('/public/icons/ic-settings-gray.svg'),
  chevronUp: STORYBOOK
    ? require('../../public/golconda/icons/ic-chevron.svg')
    : staticPath('/public/icons/ic-chevron.svg'),
  logoutGray: STORYBOOK
    ? require('../../public/golconda/icons/ic-logout-gray.svg')
    : staticPath('/public/icons/ic-logout-gray.svg'),
  addGray: STORYBOOK
    ? require('../../public/golconda/icons/ic-add-gray.svg')
    : staticPath('/public/icons/ic-add-gray.svg'),
}

// Preload all images so the page doesn't jump as images load in
const DELAY_PRELOAD_AFTER_LAUNCH = 2000
setTimeout(() => {
  Object.keys(Images).forEach(key => {
    const img = new Image()
    img.src = Images[key]
  })
}, DELAY_PRELOAD_AFTER_LAUNCH)

export default Images
