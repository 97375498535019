// @flow

import React from 'react'

import { classnames } from '@/helpers'
import { Strings, Images } from '@/constants'
import Access from '@/constants/accessibility'
import jsStyles from '../styles'

import styles from './styles.scss'

export type PublicWrapperProps = {|
  closeModal: () => void,
  children: React$Node,
  width?: string,
  fullScreen?: boolean,
  className?: ?string,
|}

export class PublicWrapper extends React.Component<PublicWrapperProps> {
  static defaultProps = {
    width: styles.publicModalWidth,
    fullScreen: false,
    className: null,
  }

  handleBackgroundClick = (e: SyntheticMouseEvent<>) => {
    const { closeModal } = this.props
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  handleButtonPress = () => {
    const { closeModal } = this.props

    closeModal()
  }

  handleKeyPress = (e: SyntheticKeyboardEvent<>) => {
    const { closeModal } = this.props
    if (e.key === 'Escape' || e.key === 'Esc') {
      closeModal()
    }
  }

  render() {
    const { children, width, fullScreen, className } = this.props
    return (
      <div className={styles.modalOverlay}>
        <div
          aria-label={Access.Components.Modal.Wrapper}
          className={styles.modalContainer}
          role="button"
          tabIndex={0}
          onClick={this.handleBackgroundClick}
          onKeyDown={this.handleKeyPress}
        >
          <div
            className={classnames(styles.publicModal, className)}
            css={jsStyles.size({ width, fullScreen })}
          >
            <div className={styles.publicCloseBar}>
              <button
                aria-label={Access.Components.Modal.Close}
                className={styles.closePublicModalButton}
                type="button"
                onClick={this.handleButtonPress}
              >
                <img alt={Strings.close} src={Images.close} />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default PublicWrapper
