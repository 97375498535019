// @flow

import { ModalActions } from '@/constants'
import type { ModalType, ModalAction } from '../../constants'

const eventManager = {
  events: new Map<ModalAction, Function>(),
  rendered: false,
  mounted: false,

  register(action: ModalAction, callback: Function) {
    this.events.set(action, callback)
    return this
  },

  clear(action: ModalAction) {
    this.events.delete(action)
    return this
  },

  emit(action: ModalAction, type?: ModalType, name?: string) {
    if (action === ModalActions.RENDER_MODAL) {
      if (this.events.has(ModalActions.DEACTIVATE_APP_SCROLL)) {
        this.emit(ModalActions.DEACTIVATE_APP_SCROLL)
      }
      this.rendered = true
    } else if (action === ModalActions.REMOVE_MODAL) {
      this.rendered = false
      this.emit(ModalActions.ACTIVATE_APP_SCROLL)
    }
    this.events.get(action).call(null, type, name)
    return this
  },
}

export default eventManager
