// @flow
import { Enums } from '@/constants'
import { Footnote } from '@/models'

import BaseModel from '../base/base-model'
import { defaultArray, defaultEnum, defaultString } from '../defaults'
import type { FootnoteDataType } from './footnote'

export type FootnoteGroupDataType = {|
  id: ?string,
  group_type: ?string,
  footnotes: ?Array<FootnoteDataType>,
|}

export default class FootnoteGroup extends BaseModel {
  id: string

  groupType: string

  footnotes: Array<Footnote>

  constructor(
    data: FootnoteGroupDataType = {
      id: null,
      footnotes: null,
      group_type: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.groupType = defaultEnum(data.group_type, Enums.FootnoteGroupType)
    this.footnotes = defaultArray(data.footnotes).map(f => new Footnote(f))
  }
}
