// @flow
import React from 'react'
import TableActions from './table-actions'

import styles from './styles.scss'

type TableHeaderProps = {|
  title?: string,
  download?: ?(e: SyntheticMouseEvent<>) => void,
  print?: ?(e: SyntheticMouseEvent<>) => void,
  maxWidth?: ?number,
  noData: boolean,
  printPending?: boolean,
  printPendingTable?: boolean,
  hideIcons: boolean,
  loading: boolean,
|}

const TableHeader = (props: TableHeaderProps) => {
  const {
    title,
    download,
    maxWidth,
    noData,
    print,
    printPending,
    printPendingTable,
    hideIcons,
    loading,
  } = props
  const inlineStyles = maxWidth ? { maxWidth: `${maxWidth}px` } : null
  if (title) {
    return (
      <section className={styles.tableHeader} style={inlineStyles}>
        <h2>{title}</h2>
        {hideIcons ? null : (
          <TableActions
            download={download}
            noData={noData}
            print={print}
            printPending={printPending}
            printPendingTable={printPendingTable}
            loading={loading}
          />
        )}
      </section>
    )
  }
  return null
}

TableHeader.defaultProps = {
  download: null,
  print: null,
  title: '',
  maxWidth: null,
  printPending: false,
  printPendingTable: false,
}

export default TableHeader
