// @flow
import { snakeCase, isEmpty } from 'lodash'
import moment from 'moment'

// $FlowFixMe
import config from '@/agencies-config'

export const classnames = (...args: Array<?string>) =>
  args.reduce((acc, curr) => {
    if (curr) return `${curr} ${acc}`
    return acc
  }, '')

export const staticPath = (path: string) => `${config.staticPath}${path}`

export const numberWithCommas = (x: number, decimals: number = 0) =>
  new Intl.NumberFormat('en', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(x)

export const formatPrice = (
  char: number | string,
  zeroChar: string = '-',
  decimals: number = 0,
): string => {
  const parsed = decimals ? parseFloat(char) : Math.round(parseFloat(char))
  if (parsed === 0) {
    return zeroChar
  }
  if (parsed && parsed < 0) {
    return `-$${numberWithCommas(Math.abs(parsed), decimals)}`
  }
  return `$${numberWithCommas(parsed, decimals)}`
}

export const formatTableValue = (
  value: number | string,
  isSubtotal: boolean,
  decimals?: number,
) => {
  let formatted = '0'
  if (typeof value === 'number') {
    if (value === 0) {
      formatted = '–'
    } else {
      const valueWithDecimals = decimals ? value.toFixed(decimals) : Math.ceil(value)
      formatted =
        value < 0
          ? `(${numberWithCommas(Math.abs(valueWithDecimals), decimals)})`
          : numberWithCommas(valueWithDecimals, decimals)
    }
  }
  // If value is not a number and is in a subtotal row, we show nothing.
  // This means we are looking at a Currency Column that is not subtotaled.
  else if (typeof value !== 'number' && isSubtotal) {
    formatted = ''
  }
  return formatted
}

export const formatPercentageValue = (value: number | string) => {
  let formatted = value
  if (typeof value === 'number') {
    formatted = value.toFixed(2)
    if (value === 0) {
      formatted = '0.00'
    } else if (value < 10) {
      formatted = Number(value).toFixed(2).toString()
    }
  }
  return `${formatted}%`
}

export const getSearchObject = (query: string): { [string]: string } => {
  const pathArray = query.replace('?', '').split('&')
  const locationObj = {}

  pathArray.forEach(keyValuePair => {
    const [key, value] = keyValuePair.split('=')
    if (key && value) {
      locationObj[key] = value
    }
  })
  return locationObj
}

export const getSearchString = (obj: Object): string => {
  if (!isEmpty(obj)) {
    let params = Object.keys(obj).map(key => `${key}=${obj[key]}`)
    params = params.join('&')
    return `?${params}`
  }
  return ''
}

export const snakeToCamel = (key: string) =>
  key.replace(/([_][a-z])/gi, $1 => $1.toUpperCase().replace('_', ''))

export const camelObjectToSnakeObject = (obj: { [string]: any }) =>
  Object.keys(obj).reduce(
    (acc, cur) => ({
      ...acc,
      [snakeCase(cur)]: obj[cur],
    }),
    {},
  )

export const inactivityWarning = (callback: () => void) => {
  let timer
  const resetTimer = () => {
    clearTimeout(timer)
    timer = setTimeout(callback, 360000) // time is in milliseconds
  }
  const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'click', 'load']
  events.forEach(name => {
    window.addEventListener(name, resetTimer, true)
  })
}

export const capitalizeFirstLetter = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : str

export const dashIfEmptyArray = (arr: Array<string>) => (arr && arr.length ? arr : '-')

export const getMonthYear = (date: string) => moment(date).format('MMMM, YYYY')

export const parseUrl = (url: string) => {
  const a = document.createElement('a')
  a.href = url

  return a
}

export const _initiateDownload = (filename: string, url: string) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      resolve(xhr)
    }
    xhr.onerror = reject
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }).then(xhr => {
    const a = document.createElement('a')
    const blob = new Blob([xhr.response], { type: 'application/octet-stream' })
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
      return xhr
    }
    a.href = window.URL.createObjectURL(blob) // xhr.response is a blob
    a.download = filename // Set the file name.
    a.style.display = 'none'
    // $FlowFixMe
    document.body.appendChild(a)
    a.click()
    return xhr
  })

export default classnames
