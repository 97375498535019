// @flow
import moment from 'moment'
import { IDFImage } from '@/models'

import BaseModel from '../base/base-model'
import { defaultArray, defaultBoolean, defaultFloat, defaultString, makeDate } from '../defaults'

export type VULStatementDataType = {|
  id?: ?string,
  policy_id?: ?string,
  statement_start?: ?string,
  statement_end?: ?string,
  account_value?: ?number,
  idf_statements: ?Array<number>,
  insurance_proceeds?: ?number,
  policy_gross_account_value?: ?number,
  surrender_charge?: ?number,
  policy_loan_balance?: ?number,
  cost_basis?: ?number,
  policy_account_deposits?: ?number,
  policy_account_withdrawals?: ?number,
  policy_loan?: ?number,
  beginning_balance_net?: ?number,
  beginning_balance_gross?: ?number,
  net_return_month?: ?number,
  net_return_ytd?: ?number,
  net_return_since_inception?: ?number,
  variance?: ?boolean,
  premium_based_charges?: ?number,
  policy_expense_charges?: ?number,
|}

export default class VULStatement extends BaseModel {
  id: string

  idfStatements: Array<string>

  availableIdfImages: Array<IDFImage>

  policyId: string

  statementStart: string

  statementEnd: string

  accountValue: number

  accountValueYTD: number

  accountValueSinceInception: number

  insuranceProceeds: number

  insuranceProceedsYTD: number

  insuranceProceedsSinceInception: number

  policyGrossAccountValue: number

  surrenderCharge: number

  policyLoanBalance: number

  costBasis: number

  policyAccountDeposits: number

  policyAccountDepositsYTD: number

  policyAccountDepositsSinceInception: number

  policyAccountWithdrawals: number

  policyAccountWithdrawalsYTD: number

  policyAccountWithdrawalsSinceInception: number

  policyLoan: number

  policyLoanYTD: number

  policyLoanSinceInception: number

  policyIDFReturn: number

  policyIDFReturnYTD: number

  policyIDFReturnSinceInception: number

  beginningBalanceNet: number

  beginningBalanceGross: number

  netReturnMonth: number

  netReturnYTD: number

  netReturnSinceInception: number

  variance: boolean

  premiumBasedCharges: number

  premiumBasedChargesYTD: number

  premiumBasedChargesSinceInception: number

  policyExpenseCharges: number

  policyExpenseChargesYTD: number

  policyExpenseChargesSinceInception: number

  constructor(
    data: VULStatementDataType = {
      id: null,
      policy_id: null,
      statement_start: null,
      statement_end: null,
      account_value: null,
      idf_statements: null,
      insurance_proceeds: null,
      policy_gross_account_value: null,
      surrender_charge: null,
      policy_loan_balance: null,
      cost_basis: null,
      policy_account_deposits: null,
      policy_account_withdrawals: null,
      policy_loan: null,
      beginning_balance_net: null,
      beginning_balance_gross: null,
      net_return_month: null,
      net_return_ytd: null,
      net_return_since_inception: null,
      variance: null,
      premium_based_charges: null,
      policy_expense_charges: null,
    },
  ) {
    super()
    this.id = defaultString(data.id)
    this.idfStatements = defaultArray(data.idf_statements)
    this.policyId = defaultString(data.policy_id)
    this.statementStart = defaultString(data.statement_start)
    this.statementEnd = defaultString(data.statement_end)
    this.accountValue = defaultFloat(data.account_value)
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.surrenderCharge = defaultFloat(data.surrender_charge)
    this.policyLoanBalance = defaultFloat(data.policy_loan_balance)
    this.costBasis = defaultFloat(data.cost_basis)
    this.policyAccountDeposits = defaultFloat(data.policy_account_deposits)
    this.policyAccountWithdrawals = defaultFloat(data.policy_account_withdrawals)
    this.policyLoan = defaultFloat(data.policy_loan)
    this.beginningBalanceNet = defaultFloat(data.beginning_balance_net)
    this.beginningBalanceGross = defaultFloat(data.beginning_balance_gross)
    this.accountValue = defaultFloat(data.account_value)
    this.netReturnMonth = defaultFloat(data.net_return_month)
    this.netReturnYTD = defaultFloat(data.net_return_ytd)
    this.netReturnSinceInception = defaultFloat(data.net_return_since_inception)
    this.variance = defaultBoolean(data.variance)
    this.premiumBasedCharges = defaultFloat(data.premium_based_charges)
    this.policyExpenseCharges = defaultFloat(data.policy_expense_charges)
  }

  get statementYear(): number {
    return moment(this.statementStart).year()
  }

  get statementStartMonth(): number {
    return moment(this.statementStart).month()
  }

  get statementStartMonthYear(): string {
    return moment(this.statementStart).format('MMMM, YYYY')
  }

  get statementEndMonthYear(): string {
    return moment(this.statementEnd).format('MMMM, YYYY')
  }

  get statementStartFormat(): string {
    return moment(this.statementStart).format('MMMM D, YYYY')
  }

  get statementEndFormat(): string {
    return moment(this.statementEnd).format('MMMM D, YYYY')
  }

  get statementStartDate(): Date {
    return makeDate(this.statementStart)
  }

  get statementEndDate(): Date {
    return makeDate(this.statementEnd)
  }

  startedOnOrBefore(month: string): boolean {
    return moment(this.statementStart).isSameOrBefore(month)
  }
}
