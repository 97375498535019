// @flow
import * as React from 'react'
import { Strings } from '@/constants'
import { classnames } from '@/helpers'
import styles from '../styles.scss'

type VariancesNoteProps = {|
  variances: Array<VarianceType>,
|}

export const VariancesNote = (props: VariancesNoteProps) => {
  const { variances } = props

  const hasMultipleVariances = React.useMemo(
    () => variances.some(({ varianceNote }) => varianceNote !== variances[0].varianceNote),
    variances,
  )

  const renderMessage = ({
    varianceNote,
    accountId,
    insuranceCompanyName,
    modelName,
  }: VarianceType) => {
    const className = `variance-${varianceNote}`
    let message = Strings.varianceNote[modelName][varianceNote]

    if (hasMultipleVariances) {
      message = `${insuranceCompanyName} #${accountId}: ${message}`
    }

    return <div className={styles[className]}>{message}</div>
  }

  return (
    <div className={classnames(styles.varianceContainer, styles.footerElements)}>
      {hasMultipleVariances
        ? variances.map(variance => renderMessage(variance))
        : renderMessage(variances[0])}
    </div>
  )
}

export default VariancesNote
