// @flow

import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { Strings, Images } from '@/constants'
import jsStyles from '../styles'
import styles from './styles.scss'

export const MobileMessage = () => {
  const { mobileMessage: msg } = Strings

  return (
    <div className={styles.container} css={jsStyles.bgImg(Images.brokenLinkBG)}>
      <div className={styles.content}>
        <img alt={Strings.companyInfo.name} src={Images.logo} className={styles.logo} />
        <h1 className={styles.heading}>
          {msg.primaryTextPartOne}
          {Strings.clientFullName}
          {msg.primaryTextPartTwo}
        </h1>
        <p>{msg.secondaryText}</p>
      </div>
    </div>
  )
}

export default withRouter(MobileMessage)
