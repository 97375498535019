// @flow
import { merge } from 'lodash'

import { Enums } from '@/constants'
import { camelObjectToSnakeObject } from '@/helpers'
import BaseModel from './base/base-model'
import InsuranceCompany from './insurance-company'
import type { InsuranceCompanyDataType } from './insurance-company'
import { defaultString, defaultEnum } from './defaults'

export type ProductDataType = {|
  product_name?: ?string,
  product_type?: ?$Values<typeof Enums.PolicyType>,
  description?: ?string,
  insurance_company?: ?InsuranceCompanyDataType,
|}

const defaultDataValues = {
  product_name: null,
  product_type: null,
  description: null,
  insurance_company: null,
}

export default class Product extends BaseModel {
  productName: string

  productType: $Values<typeof Enums.PolicyType>

  description: string

  insuranceCompany: InsuranceCompany = new InsuranceCompany()

  constructor(rawData: ProductDataType = defaultDataValues) {
    super()
    const data: ProductDataType =
      rawData instanceof Product
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData

    this.productName = defaultString(data.product_name)
    this.productType = defaultEnum(data.product_type, Enums.PolicyType)
    this.description = defaultString(data.description)
    this.insuranceCompany = data.insurance_company
      ? new InsuranceCompany(data.insurance_company)
      : new InsuranceCompany()
  }
}
