// @flow
import * as React from 'react'
import styles from './styles.scss'
import classnames from '@/helpers'

type UiTableCellProps = {|
  children: React.Node | string,
  align?: 'left' | 'center' | 'right',
|}

class UiTableCell extends React.Component<UiTableCellProps> {
  render() {
    const { children, align } = this.props
    return (
      <td className={classnames(styles.cell, styles[`cell-${align || 'left'}`])}>{children}</td>
    )
  }
}

export default UiTableCell
