// @flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import Access from '@/constants/accessibility'
import styles from './styles.scss'

type StatementIconPropsType = {|
  statementMonth: string,
  path: string,
  title: string,
|}

const StatementIcon = (props: StatementIconPropsType) => {
  const { path, statementMonth, title } = props
  return (
    <Link
      to={path}
      className={styles.statementIcon}
      aria-label={Access.Components.Icon.StatementIcon}
    >
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{statementMonth}</p>
    </Link>
  )
}

export default StatementIcon
