// @flow
import { merge } from 'lodash'
import moment from 'moment'
import { camelObjectToSnakeObject } from '@/helpers'
import { IDFTransaction, IDF } from '@/models'
import BaseModel from '../base/base-model'
import { defaultFloat, defaultString, defaultArray, makeDate } from '../defaults'
import type { IDFTransactionDataType } from './idf-transaction'

export type IDFStatementDataType = {|
  id: ?string,
  statement_start: ?string,
  statement_end: ?string,
  beginning_balance: ?string,
  account_value: ?string,
  account_id: ?string,
  gross_return_month: ?string,
  gross_return_ytd: ?string,
  return_value: ?number,
  transaction_amount: ?number,
  transactions: ?Array<IDFTransactionDataType>,
  idf_id: ?string,
  policy_id: ?string,
|}

const defaultDataValues = {
  id: null,
  statement_start: null,
  statement_end: null,
  beginning_balance: null,
  account_value: null,
  account_id: null,
  gross_return_month: null,
  gross_return_ytd: null,
  return_value: null,
  transaction_amount: null,
  transactions: null,
  idf_id: null,
  policy_id: null,
}

export default class IDFStatement extends BaseModel {
  id: string

  statementStart: string

  statementEnd: string

  beginningBalance: number

  accountValue: number

  accountId: string

  grossReturnMonth: number

  grossReturnYTD: number

  returnValue: number

  transactionAmount: number

  transactions: Array<IDFTransaction>

  idfId: string

  policyId: string

  _idf: ?IDF

  constructor(rawData: IDFStatement | IDFStatementDataType = defaultDataValues) {
    super()
    const data: IDFStatementDataType =
      rawData instanceof IDFStatement
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    this.id = defaultString(data.id)
    this.statementStart = defaultString(data.statement_start)
    this.statementEnd = defaultString(data.statement_end)
    this.beginningBalance = defaultFloat(data.beginning_balance)
    this.accountValue = defaultFloat(data.account_value)
    this.accountId = defaultString(data.account_id)
    this.grossReturnMonth = defaultFloat(data.gross_return_month)
    this.grossReturnYTD = defaultFloat(data.gross_return_ytd)
    this.returnValue = defaultFloat(data.return_value)
    this.transactionAmount = defaultFloat(data.transaction_amount)
    this.transactions = defaultArray(data.transactions).map(i => new IDFTransaction(i))
    this.idfId = defaultString(data.idf_id)
    this.policyId = defaultString(data.policy_id)
  }

  set idf(idf: IDF) {
    this._idf = idf
  }

  get productName(): string {
    if (this._idf) {
      return this._idf.product.productName
    }
    return ''
  }

  get statementStartFormat(): string {
    return moment(this.statementStart).format('MMMM D, YYYY')
  }

  get statementEndFormat(): string {
    return moment(this.statementEnd).format('MMMM D, YYYY')
  }

  get statementStartDate(): Date {
    return makeDate(this.statementStart)
  }

  get statementEndDate(): Date {
    return makeDate(this.statementEnd)
  }
}
