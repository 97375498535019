// @flow

import { compose, createStore, combineReducers, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import history from '@/helpers/history'
import rootSaga from '@/sagas'
import { reducer as api, reduxSet as apiAC, INITIAL_STATE as APIInitialState } from './api'
import {
  reducer as user,
  reduxSet as userAC,
  INITIAL_STATE as UserInitialState,
  ACTIONS as USER_ACTIONS,
} from './user'
import {
  reducer as policy,
  reduxSet as policyAC,
  INITIAL_STATE as PolicyInitialState,
} from './policy'

import {
  reducer as sessions,
  reduxSet as sessionsAC,
  INITIAL_STATE as SessionsInitialState,
} from './sessions'

// Strange higher-order function to potentially modify the result
const logAction = store => next => action => {
  const before = store.getState()
  const result = next(action)
  if (process.env.NODE_ENV !== 'production') {
    // Group these console logs into one closed group
    /* eslint-disable no-console */
    const after = store.getState()
    console.groupCollapsed(`dispatching action => ${action.type}`)
    console.log('BEFORE', before)
    console.log('ACTION', action.type, action)
    console.log('AFTER', after)
    console.groupEnd()
    /* eslint-enable no-console */
  }

  return result
}

const reducers = combineReducers({
  api,
  router: connectRouter(history),
  user,
  policy,
  sessions,
})

const rootReducer = (state, action) => {
  let newState = state
  if (action.type === USER_ACTIONS.CLEAR_USER_SESSION) {
    const { router } = state
    newState = { router, policy: PolicyInitialState, api: APIInitialState, user: UserInitialState }
  }
  return reducers(newState, action)
}

export const InitialState = {
  api: APIInitialState,
  user: UserInitialState,
  policy: PolicyInitialState,
  sessions: SessionsInitialState,
}

const ActionCreators = {
  api: apiAC,
  user: userAC,
  policy: policyAC,
  sessions: sessionsAC,
  // Stub out router
  router: {},
}

export { ActionCreators }

const configureStore = (initialState: InitialStoreState = InitialState) => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware, logAction, routerMiddleware(history)]
  const composeEnhancers =
    process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose

  const middleware = composeEnhancers(applyMiddleware(...middlewares))

  // $FlowFixMe
  const store = createStore(rootReducer, initialState, middleware)
  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
