// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import moment from 'moment'
import Access from '@/constants/accessibility'
import { Strings, Enums, Routes } from '@/constants'
import { PrimaryButton, SecondaryBlueButton } from '@/components'
import styles from './styles.scss'
import modal from '../modal'

type InactivityWarningModalProps = {|
  onLogoutNow: () => void,
  history: RouterHistory,
|}

type InactivityWarningModalState = {|
  remainingTime: number,
  timeNow: Date,
|}

class InactivityWarning extends React.Component<
  InactivityWarningModalProps,
  InactivityWarningModalState,
> {
  logoutTimer: TimeoutID

  constructor(props: InactivityWarningModalProps) {
    super(props)
    this.state = {
      remainingTime: Enums.Duration.TWO_MINUTES_IN_SEC,
      timeNow: new Date(),
    }
  }

  componentDidMount = () => {
    this.countdownLogoutTimer()
  }

  countdownLogoutTimer = () => {
    const { remainingTime, timeNow } = this.state
    if (remainingTime <= 1) {
      this.onLogoutNow()
    } else {
      this.logoutTimer = setTimeout(this.countdownLogoutTimer, 1000)
      const seconds = Math.round((new Date().getTime() - timeNow.getTime()) / 1000)
      this.setState({ remainingTime: Enums.Duration.TWO_MINUTES_IN_SEC - seconds })
    }
  }

  onLogoutNow = () => {
    const { history, onLogoutNow } = this.props
    modal.remove()
    clearTimeout(this.logoutTimer)
    onLogoutNow()
    history.push(Routes.auth.Login, { logBackIn: true })
  }

  stayLoggedIn = () => {
    clearTimeout(this.logoutTimer)
    modal.remove()
  }

  render() {
    const { remainingTime } = this.state
    const minPart = Math.floor(remainingTime / 60)
    const secPart = remainingTime % 60
    const time = moment().set({ minute: minPart, second: secPart }).format('m:ss')
    return (
      <section className={styles.portalModal}>
        <header className={styles.modalHeader}>
          <span className={styles.modalHeaderText}>{Strings.inactivityWarning.title}</span>
        </header>
        <div className={styles.modalBody}>
          <span className={styles.modalMessage}>
            {Strings.inactivityWarning.message1}
            <b>{time}</b>
            {Strings.inactivityWarning.message2}
          </span>
        </div>
        <div className={styles.modalFooter}>
          <div>
            <SecondaryBlueButton
              type="button"
              onClick={this.onLogoutNow}
              title={Strings.inactivityWarning.button.logoutNow}
              ariaLabel={Access.Components.Modal.LogOut}
            />
          </div>
          <div>
            <PrimaryButton
              type="submit"
              onClick={this.stayLoggedIn}
              title={Strings.inactivityWarning.button.stayLoggedIn}
              ariaLabel={Access.Components.Modal.StayLoggedIn}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(InactivityWarning)
