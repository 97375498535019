// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

// $FlowFixMe
import config from '@/agencies-config'
import { ModalActions } from '@/constants'
import { ActionCreators } from '@/redux'
import { AppWrapper, Modal, Version } from '@/components'
import eventManager from '@/components/modal/event-manager'

type AppProps = {
  children: ?React.Node,
  hydrateUser: () => void,
}

type AppState = {|
  scrollActive: boolean,
|}

export class App extends React.Component<AppProps, AppState> {
  state = {
    scrollActive: true,
  }

  componentDidMount() {
    const { hydrateUser } = this.props

    eventManager.register(ModalActions.ACTIVATE_APP_SCROLL, this.activateAppScroll)
    eventManager.register(ModalActions.DEACTIVATE_APP_SCROLL, this.deactivateAppScroll)

    hydrateUser()

    if (config.logrocket) {
      LogRocket.init(config.logrocket.token, {
        shouldShowReportingButton: config.logrocket.show_reporting_button,
        sha: config.sha,
      })
    }

    if (config.sentry) {
      Sentry.init({
        dsn: config.sentry.token,
        environment: config.sentry.environment,
        release: config.sentry.version,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
        beforeSend(event) {
          const logRocketSession = LogRocket.sessionURL

          if (logRocketSession !== null) {
            if (!event.extra) {
              // eslint-disable-next-line no-param-reassign
              event.extra = {}
            }

            // eslint-disable-next-line no-param-reassign
            event.extra.LogRocket = logRocketSession

            return event
          }

          return event
        },
      })
    }

    // inactivityWarning(inactivityWarningResponse)

    if (eventManager.rendered) {
      eventManager.emit(ModalActions.DEACTIVATE_APP_SCROLL)
    }
  }

  deactivateAppScroll = () => {
    this.setState({ scrollActive: false }, () => {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    })
  }

  activateAppScroll = () => {
    this.setState({ scrollActive: true }, () => {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    })
  }

  render() {
    const { children } = this.props
    const { scrollActive } = this.state

    return (
      <AppWrapper scrollActive={scrollActive}>
        {children}
        <Modal />
        <Version />
      </AppWrapper>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hydrateUser: () => dispatch(ActionCreators.user.hydrateUser.dispatch()),
})

export default withRouter(connect(null, mapDispatchToProps)(App))
