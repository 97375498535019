// @flow
import React from 'react'
import { Strings } from '@/constants'
import styles from './styles.scss'

type NoDownloadProps = {|
  type: string,
|}

const NoDownload = (props: NoDownloadProps) => {
  const { type } = props

  return (
    <div className={styles.noDownloadContainer}>
      <div className={styles.noDownloadContent}>
        <p>{Strings.noDownload(type)}</p>
        <p>
          {Strings.noDownloadQuestions.line1}
          <a href={`mailto:${Strings.noDownloadQuestions.link.url}`}>
            {Strings.noDownloadQuestions.link.text}
          </a>
          {Strings.noDownloadQuestions.line2}
        </p>
      </div>
    </div>
  )
}

export default NoDownload
