// @flow
import { IDF, IDFTransaction } from '@/models'

import { Strings, MaxTableColumnWidths, TableColumnWidths } from '@/constants'

export type IDFTransactionsRow = {
  accountId: string,
  transaction_type: string,
  idfProductName: string,
  deposits: number,
  withdrawals: number,
  dateFormat: string,
}

export const ppliAccountTransactionsColumns = [
  {
    name: Strings.table.accountNumber,
    accessor: 'accountId',
    width: TableColumnWidths.accountId,
  },
  {
    name: Strings.table.description,
    accessor: 'transaction_type',
    width: TableColumnWidths.description,
  },
  {
    name: Strings.table.idf,
    accessor: 'idfProductName',
    width: TableColumnWidths.idfTransactions,
    maxWidth: MaxTableColumnWidths.maxIDFTransactions,
  },
  {
    name: Strings.table.deposits,
    accessor: 'deposits',

    isCurrency: true,
    width: TableColumnWidths.withdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
  },
  {
    name: Strings.table.withdrawals,
    accessor: 'withdrawals',

    isCurrency: true,
    width: TableColumnWidths.withdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
  },
  {
    name: Strings.table.transactionDate,
    accessor: 'dateFormat',
    width: TableColumnWidths.transactionDate,
    sortable: true,
  },
]

export const ppaAccountTransactionsColumns = [
  {
    name: Strings.table.accountNumber,
    accessor: 'accountId',
    width: TableColumnWidths.accountId,
  },
  {
    name: Strings.table.description,
    accessor: 'transaction_type',
    width: TableColumnWidths.description,
  },
  {
    name: Strings.table.idf,
    accessor: 'idfProductName',
    width: TableColumnWidths.idfTransactions,
    maxWidth: MaxTableColumnWidths.maxIDFTransactions,
  },
  {
    name: Strings.table.deposits,
    accessor: 'deposits',

    isCurrency: true,
    width: TableColumnWidths.withdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
  },
  {
    name: Strings.table.withdrawals,
    accessor: 'withdrawals',

    isCurrency: true,
    width: TableColumnWidths.withdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
  },
  {
    name: Strings.table.transactionDate,
    accessor: 'dateFormat',
    width: TableColumnWidths.transactionDate,
    sortable: true,
  },
]

export const vulAccountTransactionsColumns = [
  {
    name: Strings.table.accountNumber,
    accessor: 'accountId',
    width: TableColumnWidths.accountId,
  },
  {
    name: Strings.table.description,
    accessor: 'transaction_type',
    width: TableColumnWidths.description,
  },
  {
    name: Strings.table.vul.subaccount,
    accessor: 'idfProductName',
    width: TableColumnWidths.idfTransactions,
    maxWidth: MaxTableColumnWidths.maxIDFTransactions,
  },
  {
    name: Strings.table.deposits,
    accessor: 'deposits',

    isCurrency: true,
    width: TableColumnWidths.withdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
  },
  {
    name: Strings.table.withdrawals,
    accessor: 'withdrawals',

    isCurrency: true,
    width: TableColumnWidths.withdrawals,
    maxWidth: MaxTableColumnWidths.maxWithdrawals,
  },
  {
    name: Strings.table.transactionDate,
    accessor: 'dateFormat',
    width: TableColumnWidths.transactionDate,
    sortable: true,
  },
]

export const transformIDFTransactionsData = (
  transactions: Array<{ idf: IDF, transactions: Array<IDFTransaction> }>,
  policies: { [string]: PPPolicyType },
): Array<IDFTransactionsRow> => {
  const data = transactions.reduce((acc, curr) => {
    const { idf } = curr
    const transactionsArr = curr.transactions
    const transactionData = transactionsArr.map(t => ({
      accountId: policies[idf?.policyId]?.accountId,
      transaction_type: t.transactionType,
      idfProductName: idf?.productName,
      deposits: t.deposits,
      withdrawals: t.withdrawals,
      dateFormat: t.dateFormat,
      date: t.transactionDate,
    }))
    return acc.concat(transactionData)
  }, [])
  return data
}

export default transformIDFTransactionsData
