import { css } from '@emotion/core'
import styles from './styles.scss'

const Styles = {
  label: props => css`
    :before {
      border: 2px solid ${styles.grey};
    }
    ${props.error
      ? `
    :before {
      border: 2px solid ${styles.red};
    }
  `
      : ''};
    ${props.disabled
      ? `
      color: ${styles.disabledGrey};
      :before {
        cursor: auto;
        border: 2px solid ${styles.disabledGrey}
    }
      `
      : `
      :before {
        cursor: pointer
      }
      `}
  `,
}

export default Styles
