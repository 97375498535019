import { css } from '@emotion/core'
import { spacerLG } from '@/../src/styles/metrics.scss'
import styles from './styles.scss'

const Styles = {
  wrapper: props => css`
    ${props.leftLabel
      ? `
        padding-left: 0;
        padding-right: ${spacerLG};
      `
      : `padding-left: ${spacerLG}`}
  `,
  checkmark: props => {
    const checkStyles = []
    if (props.disabled) {
      checkStyles.push(css`
        border: 2px solid ${styles.disabledGrey};
        cursor: auto;
        &:hover {
          border: 2px solid ${styles.disabledGrey};
        }
      `)
    } else if (props.error)
      checkStyles.push(css`
        cursor: pointer;
        border: 2px solid ${styles.error};
        &:hover {
          border: 2px solid ${styles.error};
        }
      `)
    else
      checkStyles.push(css`
        cursor: pointer;
        border: 2px solid ${styles.defaultGrey};
        &:hover {
          border: 2px solid ${styles.activeGrey};
        }
      `)
    if (props.leftLabel) {
      checkStyles.push(css`
        left: auto;
        right: 0;
      `)
    } else
      checkStyles.push(
        css`
          left: 0;
        `,
      )
    return checkStyles
  },
}

export default Styles
