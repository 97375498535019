// @flow

import '../../agency.macro'

import configs from './blank-config'

export const environment = process.env.NODE_ENV || 'development'

const environmentConfig = configs[environment]

if (environment !== 'test' && !environmentConfig) {
  // eslint-disable-next-line no-console
  console.error('No config file found.  Defaulting to debug')
}

const config: ConfigType = environmentConfig || configs.development

export default config
