import { css } from '@emotion/core'
import styles from './styles.scss'

export default {
  message: isValid => css`
    ${isValid
      ? `
      color: ${styles.success}
    `
      : `
      color: ${styles.error}
    `}
  `,
}
