/* eslint-disable max-len */
// @flow

import React from 'react'
import { withRouter } from 'react-router-dom'

import Access from '@/constants/accessibility'
import { FooterAnchor } from '@/components'
import { Strings, Images, Links } from '@/constants'
import { isPortalRoute } from '@/helpers/routeChecking'
import styles from './styles.scss'

const { statement } = Strings

type Props = {
  location: Object,
  authed?: boolean,
}

const Footer = (props: Props) => {
  const { location, authed } = props
  const inPortal = isPortalRoute(location.pathname)

  const footerLinks = () => (
    <ul className={styles.nav}>
      {Links.footer.reduce((acc, curr) => {
        if (!curr.protected || (inPortal && authed && curr.protected)) {
          acc.push(
            <li key={curr.label} aria-label={Access.Components.NavLink}>
              <FooterAnchor
                key={curr.label}
                path={curr.route}
                ariaLabel={Access.Components.NavLinkItem(curr.label)}
                className={styles.navAnchor}
              >
                {curr.label}
              </FooterAnchor>
            </li>,
          )
        }
        return acc
      }, [])}
    </ul>
  )

  return (
    <footer className={styles.footer}>
      <div className={styles.section}>
        <div className={styles.companyInfo}>
          <img alt={Strings.companyInfo.name} src={Images.logo} />
          <div className={styles.addressInfo}>
            <h3>{Strings.companyInfo.name}</h3>
            <p>{Strings.companyInfo.address1}</p>
            <p>
              {Strings.companyInfo.city}, {Strings.companyInfo.stateAbbr} {Strings.companyInfo.zip}
            </p>
            <p>
              <a href={`mailto:${Strings.contactEmail}`}>{Strings.contactEmail}</a>
            </p>
            <p>
              <a href={`tel:${Strings.contactPhone}`}>{Strings.contactPhone}</a>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionOverflow}>
        <div className={styles.sectionFixed}>
          <div className={styles.copyright}>
            <p>
              {Strings.copyright} <span>{Strings.allRightsReserved}</span>
            </p>
          </div>
          {footerLinks()}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.statement}>
          <p>{statement.golcondaPartners}</p>
          <p>{statement.variableLife}</p>
          <p>
            {statement.securitiesOfferedOne}
            <a href={statement.finraLink.link} target="_blank" rel="noopener noreferrer">
              {statement.finraLink.text}
            </a>
            /
            <a href={statement.sipcLink.link} target="_blank" rel="noopener noreferrer">
              {statement.sipcLink.text}
            </a>
            {statement.securitiesOfferedTwo}
            <a href={statement.brokerCheckLink.link} target="_blank" rel="noopener noreferrer">
              {statement.brokerCheckLink.text}
            </a>
          </p>
          <p>{statement.privatePlacement}</p>
        </div>
      </div>
    </footer>
  )
}

export default withRouter(Footer)
