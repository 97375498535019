/* eslint-disable max-lines */
// @flow
import { merge } from 'lodash'
import moment from 'moment'

import { Entity, IDF, PremiumPayment, Product } from '@/models'
import { formatPrice, capitalizeFirstLetter, camelObjectToSnakeObject } from '@/helpers'
import { Strings, Enums, ModelTypes } from '@/constants'
import BasePolicy from '../base/base-policy'
import type { EntityDataType } from '../base/entity'
import type { ProductDataType } from '../product'
import type { IDFDataType } from '../idf/idf'
import type { PolicyDocumentDataType } from '../policies-documents/policy-document'
import type { PremiumPaymentDataType } from '../premium-payment'

import { defaultArray, defaultEnum, defaultFloat, defaultNumber, defaultString } from '../defaults'

export type VULPolicyDataType = {|
  id: ?string,
  account_id: ?string,
  account_value: ?number,
  beneficiary: ?Array<EntityDataType>,
  cc_list: ?Array<EntityDataType>,
  cost_basis: ?number,
  death_benefit_option: ?string,
  inforce_guaranteed_duration_age: ?number,
  insured: ?Array<EntityDataType>,
  insurance_proceeds: ?number,
  policy_date: ?string,
  base_face_amount: ?number,
  maturity_date: ?string,
  owner: ?EntityDataType,
  permissioned_individuals: ?Array<EntityDataType>,
  policy_documents: ?Array<PolicyDocumentDataType>,
  policyDocuments?: ?Array<PolicyDocumentDataType>,
  preferred_billing_method: ?$Values<typeof Enums.BillingMethod>,
  premium_amount: ?number,
  premium_mode: ?$Values<typeof Enums.PremiumMode>,
  premium_payments: ?Array<PremiumPaymentDataType>,
  paid_to: ?string,
  product: ?ProductDataType,
  scheduled_death_benefit_option_change: ?string,
  scheduled_face_amount_reduction_1: ?string,
  scheduled_face_amount_reduction_2: ?string,
  scheduled_face_amount_reduction_3: ?string,
  scheduled_first_policy_distribution: ?string,
  scheduled_premium_duration: ?string,
  policy_loan_balance: ?number,
  surrender_charge: ?number,
  policy_gross_account_value: ?number,
  termination_date: ?string,
  latest_statement_end_date: ?string,
  idfs: ?Array<IDFDataType>,
  premium_reminder_billing_email: ?string,
  premium_payor_email: ?string,
  primary_contact_person_email: ?string,
  notes_visibility: ?boolean,
|}

const defaultDataValues = {
  id: null,
  beneficiary: null,
  cc_list: null,
  cost_basis: null,
  death_benefit_option: null,
  account_id: null,
  account_value: null,
  inforce_guaranteed_duration_age: null,
  insured: null,
  insurance_proceeds: null,
  policy_date: null,
  base_face_amount: null,
  maturity_date: null,
  owner: null,
  permissioned_individuals: null,
  policy_documents: null,
  preferred_billing_method: null,
  premium_amount: null,
  premium_mode: null,
  premium_payments: null,
  paid_to: null,
  product: null,
  scheduled_death_benefit_option_change: null,
  scheduled_face_amount_reduction_1: null,
  scheduled_face_amount_reduction_2: null,
  scheduled_face_amount_reduction_3: null,
  scheduled_first_policy_distribution: null,
  scheduled_premium_duration: null,
  policy_loan_balance: null,
  surrender_charge: null,
  policy_gross_account_value: null,
  termination_date: null,
  latest_statement_end_date: null,
  idfs: null,
  premium_reminder_billing_email: null,
  premium_payor_email: null,
  primary_contact_person_email: null,
  notes_visibility: false,
}

export default class VULPolicy extends BasePolicy {
  id: string

  accountId: string

  accountValue: number

  ccList: Array<Entity>

  ccType: Array<Entity>

  costBasis: number

  beneficiary: Array<Entity>

  deathBenefitOption: string

  insured: Array<Entity>

  insuranceProceeds: number

  policyDate: string

  baseFaceAmount: number

  inforceGuaranteedDurationAge: number

  maturityDate: string

  owner: Entity

  permissionedIndividuals: Array<{ name: string, excel_disable: boolean, pdf_disable: boolean }>

  policyDocuments: Array<string>

  preferredBillingMethod: $Values<typeof Enums.BillingMethod>

  premiumPayments: Array<PremiumPayment>

  paidTo: string

  product: Product

  scheduledDeathBenefitOptionChange: string

  scheduledFaceAmountReduction1: string

  scheduledFaceAmountReduction2: string

  scheduledFaceAmountReduction3: string

  scheduledFirstPolicyDistribution: string

  scheduledPremiumDuration: number

  policyLoanBalance: number

  surrenderCharge: number

  policyGrossAccountValue: number

  terminationDate: string

  latestStatementEndDate: string

  idfs: ?Array<IDF>

  premiumReminderBillingEmail: string

  premiumPayorEmail: string

  primaryContactPersonEmail: string

  modelName: typeof ModelTypes.VULPolicy

  notesVisibility: boolean

  constructor(rawData: VULPolicy | VULPolicyDataType = defaultDataValues) {
    const data: VULPolicyDataType =
      rawData instanceof VULPolicy
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData

    super(data)
    this.modelName = ModelTypes.VULPolicy
    this.id = defaultString(data.id)
    this.accountId = defaultString(data.account_id)
    this.accountValue = defaultFloat(data.account_value)
    this.ccList = defaultArray(data.cc_list).map(e => new Entity(e))
    this.costBasis = defaultFloat(data.cost_basis)
    this.beneficiary = defaultArray(data.beneficiary).map(e => new Entity(e))
    this.inforceGuaranteedDurationAge = defaultNumber(data.inforce_guaranteed_duration_age)
    this.insured = defaultArray(data.insured).map(i => new Entity(i))
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.policyDate = defaultString(data.policy_date)
    this.preferredBillingMethod = defaultEnum(data.preferred_billing_method, Enums.BillingMethod)
    this.baseFaceAmount = defaultFloat(data.base_face_amount)
    this.maturityDate = defaultString(data.maturity_date)
    this.paidTo = defaultString(data.paid_to)
    this.owner = data.owner ? new Entity(data.owner) : new Entity()
    this.permissionedIndividuals = defaultArray(data.permissioned_individuals)
    this.policyDocuments = defaultArray(data.policy_documents)
    this.premiumAmount = defaultFloat(data.premium_amount)
    this.premiumMode = defaultEnum(data.premium_mode, Enums.PremiumMode)
    this.premiumPayments = defaultArray(data.premium_payments).map(d => new PremiumPayment(d))
    this.product = data.product ? new Product(data.product) : new Product()
    this.scheduledPremiumDuration = defaultNumber(data.scheduled_premium_duration)
    this.deathBenefitOption = defaultString(data.death_benefit_option)
    this.scheduledDeathBenefitOptionChange = defaultString(
      data.scheduled_death_benefit_option_change,
    )
    this.scheduledFaceAmountReduction1 = defaultString(data.scheduled_face_amount_reduction_1)
    this.scheduledFaceAmountReduction2 = defaultString(data.scheduled_face_amount_reduction_2)
    this.scheduledFaceAmountReduction3 = defaultString(data.scheduled_face_amount_reduction_3)
    this.scheduledFirstPolicyDistribution = defaultString(data.scheduled_first_policy_distribution)
    this.policyLoanBalance = defaultFloat(data.policy_loan_balance)
    this.surrenderCharge = defaultFloat(data.surrender_charge)
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.terminationDate = defaultString(data.termination_date)
    this.latestStatementEndDate = defaultString(data.latest_statement_end_date)
    this.idfs = defaultArray(data.idfs).map(i => new IDF(i))
    this.premiumReminderBillingEmail = defaultString(data.premium_reminder_billing_email)
    this.premiumPayorEmail = defaultString(data.premium_payor_email)
    this.primaryContactPersonEmail = defaultString(data.primary_contact_person_email)
    this.notesVisibility = data.notes_visibility || false
  }

  static modelName = ModelTypes.VULPolicy

  getFaceValues(ccTypes: Array<string>) {
    if (!ccTypes || ccTypes.length === 0) {
      return ''
    }
    const ccTypeToValue = {
      statements: 4,
      premium: 5,
      policy_changes: 6,
    }

    const faceValues = ccTypes.map(ccType => ccTypeToValue[ccType] || '')

    return faceValues.sort().join(',')
  }

  get insuranceCompanyName(): string {
    return defaultString(this.product?.insuranceCompany?.name)
  }

  get title(): string {
    const { accountId, insuranceCompanyName } = this
    return `${insuranceCompanyName}\xA0 - \xA0${accountId}`
  }

  get policyAccountValue(): number {
    return this.accountValue
  }

  get policyType(): string {
    return defaultString(this.product.productType)
  }

  get beneficiaryString(): string {
    return this.beneficiary.map(a => a.formattedName).join(', ')
  }

  get ownerString(): string {
    return this.owner.formattedName
  }

  get insuredString(): string {
    return this.insured.map(i => i.formattedName).join(', ')
  }

  get inforcePolicyCommunicationCCList(): Array<string> {
    if (this.notesVisibility) {
      return this.ccList.reduce(
        (acc, cur) =>
          cur && cur.email ? [...acc, `${cur.email}(${this.getFaceValues(cur.cc_type)})`] : acc,
        [],
      )
    }
    return this.ccList.reduce((acc, cur) => (cur && cur.email ? [...acc, cur.email] : acc), [])
  }

  get formattedPremiumMode(): string {
    return capitalizeFirstLetter(this.premiumMode)
  }

  get formattedMaturityDate(): string {
    if (this.maturityDate) {
      return moment(this.maturityDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedPaidTo(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get scheduledPremiumDurationFormat(): string {
    if (this.scheduledPremiumDuration > 0) {
      const years = this.scheduledPremiumDuration > 1 ? Strings.years : Strings.year
      return `${this.scheduledPremiumDuration} ${years} ${Strings.fromInception}`
    }
    return '–'
  }

  get guaranteedDurationAgeFormat(): string {
    if (this.inforceGuaranteedDurationAge > 0) {
      const years = this.inforceGuaranteedDurationAge > 1 ? Strings.years : Strings.year
      return `${this.inforceGuaranteedDurationAge} ${years} ${Strings.fromInception}`
    }
    return '–'
  }

  get formattedDeathBenefitOption(): string {
    if (
      this.deathBenefitOption === Enums.DeathBenefitOptions.one ||
      this.deathBenefitOption === Enums.DeathBenefitOptions.a
    ) {
      return Strings.level
    }
    if (
      this.deathBenefitOption === Enums.DeathBenefitOptions.two ||
      this.deathBenefitOption === Enums.DeathBenefitOptions.b
    ) {
      return Strings.increasing
    }
    if (
      this.deathBenefitOption === Enums.DeathBenefitOptions.CORRIDOR_7772 ||
      this.deathBenefitOption === Enums.DeathBenefitOptions.five
    ) {
      return Strings.corridor
    }
    return Strings.dash
  }

  get formattedScheduledDeathBenefitOptionChange(): string {
    if (this.scheduledDeathBenefitOptionChange) {
      return moment(this.scheduledDeathBenefitOptionChange).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFaceAmountReduction1(): string {
    if (this.scheduledFaceAmountReduction1) {
      return moment(this.scheduledFaceAmountReduction1).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFaceAmountReduction2(): string {
    if (this.scheduledFaceAmountReduction2) {
      return moment(this.scheduledFaceAmountReduction2).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFaceAmountReduction3(): string {
    if (this.scheduledFaceAmountReduction3) {
      return moment(this.scheduledFaceAmountReduction3).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedScheduledFirstPolicyDistribution(): string {
    if (this.scheduledFirstPolicyDistribution) {
      return moment(this.scheduledFirstPolicyDistribution).format('M/D/YYYY')
    }
    return ''
  }

  get costBasisFormat(): string {
    return formatPrice(this.costBasis)
  }

  get insuranceProceedsFormat(): string {
    return formatPrice(this.insuranceProceeds)
  }

  get accountValueFormat(): string {
    return formatPrice(this.accountValue)
  }

  get policyAccountValueFormat(): string {
    return formatPrice(this.accountValue)
  }

  get policyGrossAccountValueFormat(): string {
    return formatPrice(this.policyGrossAccountValue)
  }

  get formattedTerminationDate(): string {
    if (this.terminationDate) {
      return moment(this.terminationDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get surrenderChargeFormat(): string {
    return formatPrice(this.surrenderCharge)
  }

  get policyLoanBalanceFormat(): string {
    return formatPrice(this.policyLoanBalance)
  }

  get formattedAsOfDate(): ?string {
    return this.latestStatementEndDate
      ? moment(this.latestStatementEndDate).format('M/D/YYYY')
      : null
  }

  startedOnOrBefore(month: string): boolean {
    return moment(this.policyDate).isSameOrBefore(month)
  }

  get detailFilename(): string {
    return Strings.policyDetailFilename(this.insuranceCompanyName, this.accountId)
  }

  get formattedPremiumDueDate(): string {
    if (this.paidTo) {
      return moment(this.paidTo).format('MM DD')
    }
    return Strings.dash
  }
}
