// @flow
import React from 'react'
import classnames from '@/helpers'
import { DownloadIcon, PrintIcon } from '@/components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import selectors from '@/selectors'
import styles from './styles.scss'

type TableActionsProps = {|
  download?: ?(e: SyntheticMouseEvent<>) => void,
  noData: boolean,
  print?: ?(e: SyntheticMouseEvent<>) => void,
  printPending?: boolean,
  printPendingTable?: boolean,
  loading: boolean,
  user: any,
  activeClient: any,
|}

const TableActions = (props: TableActionsProps) => {
  const { download, noData, print, printPending, printPendingTable, loading, user, activeClient } =
    props
  let disableExcelForUser = false
  let disbalePdfForUser = false
  if (
    user?.excel_disabled?.length > 0 &&
    activeClient &&
    user.excel_disabled.map(String).includes(String(activeClient.id))
  ) {
    disableExcelForUser = true
  }
  if (
    user?.pdf_disabled?.length > 0 &&
    activeClient &&
    user.pdf_disabled.map(String).includes(String(activeClient.id))
  ) {
    disbalePdfForUser = true
  }
  if (!download && !print) {
    return null
  }
  const disabled = noData || (printPending && !printPendingTable) || loading
  return (
    <ul className={classnames(styles.tableActionsList)}>
      {download && !disableExcelForUser ? (
        <li>
          <DownloadIcon onClick={download} disabled={disabled} />
        </li>
      ) : null}
      {print && !disbalePdfForUser ? (
        <li>
          <PrintIcon onClick={print} disabled={disabled} pending={printPendingTable} />
        </li>
      ) : null}
    </ul>
  )
}

TableActions.defaultProps = {
  download: null,
  print: null,
  printPending: false,
  printPendingTable: false,
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  activeClient: selectors.user.getActiveClient(state),
})

export default withRouter(connect(mapStateToProps)(TableActions))
