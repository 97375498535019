/* eslint-disable consistent-return */
// @flow
import XLSX from 'xlsx/dist/xlsx.full.min'
import { Strings } from '@/constants'

const MIN_COL_WIDTH = 40
const PX_PER_CHAR = 7

const defaultSheetname = (index: number = 1) => `${Strings.defaultSheetname} ${index}`

const calculateColsWidth = data =>
  data
    .reduce((acc, row) => {
      row.forEach((cell, index) => {
        const { v, t, z } = cell

        let cellLength = 0

        if (t === 'n' && typeof v === 'number') {
          cellLength = v.toFixed(2).length + (z === '0.00%' ? 1 : 0)
        } else if (t === 's' && typeof v === 'string') {
          cellLength = v.length
        }

        acc[index] = Math.max(acc[index] || MIN_COL_WIDTH, cellLength * PX_PER_CHAR)
      })

      return acc
    }, [])
    .map(chars => ({ wpx: chars }))

export const singleSheetDownload = (sheet: ?PayloadSheet) => {
  if (!sheet) {
    return {}
  }

  const { filename, sheetname, data } = sheet

  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet(data)

  worksheet['!cols'] = calculateColsWidth(data)

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetname || defaultSheetname())
  XLSX.writeFile(workbook, `${filename}.xlsx`, {})
}

export const multiSheetsDownload = (
  sheets: ?Array<PayloadSheet>,
  accountIds: Array<string>,
  type: string,
  period: string,
) => {
  if (!sheets) {
    return {}
  }

  const workbook = XLSX.utils.book_new()

  const filtered = sheets.filter((sheet: PayloadSheet) => sheet.data)
  filtered.forEach((sheet: PayloadSheet, index: number) => {
    const { sheetname, data } = sheet
    const worksheet = XLSX.utils.aoa_to_sheet(data)

    worksheet['!cols'] = calculateColsWidth(data)

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetname || defaultSheetname(index))
  })

  const filename = Strings.consolidatedFilename(accountIds, type, period)
  XLSX.writeFile(workbook, `${filename}.xlsx`, {})
}

export default {
  singleSheetDownload,
  multiSheetsDownload,
}
