// @flow
import { css } from '@emotion/core'

export default {
  modalCheck: (scrollActive?: boolean) => css`
    ${scrollActive
      ? `
      max-height: none;
    `
      : `
      overflow: hidden;
  `}
  `,
}
