// @flow
import { merge } from 'lodash'
import moment from 'moment'

import { Strings, Enums, ModelTypes } from '@/constants'
import { formatPrice, capitalizeFirstLetter, camelObjectToSnakeObject } from '@/helpers'
import { IDF, Entity, Product } from '@/models'
import BasePolicy from '../base/base-policy'
import { defaultArray, defaultFloat, defaultString, defaultEnum } from '../defaults'
import type { EntityDataType } from '../base/entity'
import type { PolicyDocumentDataType } from '../policies-documents/policy-document'
import type { ProductDataType } from '../product'
import type { IDFDataType } from '../idf/idf'

export type AnnuityShortDataType = {|
  account_id?: ?string,
  insured: ?Array<EntityDataType>,
  beneficiary: ?Array<EntityDataType>,
  cost_basis: ?number,
  id: ?string,
  insurance_proceeds?: ?number,
  owner: ?EntityDataType,
  policy_documents: ?Array<PolicyDocumentDataType>,
  premium_amount: ?number,
  premium_mode: ?$Values<typeof Enums.PremiumMode>,
  termination_date: ?string,
  policy_date: ?string,
  maturity_date: ?string,
  product: ?ProductDataType,
  latest_statement_end_date: ?string,
  idfs: ?Array<IDFDataType>,
  policy_gross_account_value: ?number,
  disable_idf: boolean,
  notes_visibility: ?boolean,
|}

const defaultDataValues = {
  account_id: null,
  insured: null,
  beneficiary: null,
  cost_basis: null,
  id: null,
  insurance_proceeds: null,
  policy_date: null,
  maturity_date: null,
  owner: null,
  policy_documents: null,
  premium_amount: null,
  premium_mode: null,
  termination_date: null,
  product: null,
  latest_statement_end_date: null,
  idfs: null,
  policy_gross_account_value: null,
  disable_idf: false,
  notes_visibility: false,
}

export default class AnnuityShort extends BasePolicy {
  accountId: string

  insured: Array<Entity>

  beneficiary: Array<Entity>

  ccType: Array<Entity>

  costBasis: number

  id: string

  insuranceProceeds: number

  policyDate: string

  maturityDate: string

  owner: Entity

  policyDocuments: Array<string>

  terminationDate: string

  product: Product

  latestStatementEndDate: string

  idfs: Array<IDF>

  policyGrossAccountValue: number

  disableIdf: boolean

  modelName: typeof ModelTypes.Annuity

  notesVisibility: boolean

  constructor(rawData: AnnuityShort | AnnuityShortDataType = defaultDataValues) {
    const data: AnnuityShortDataType =
      rawData instanceof AnnuityShort
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData
    super(data)
    this.modelName = ModelTypes.Annuity
    this.accountId = defaultString(data.account_id)
    this.insured = defaultArray(data.insured).map(e => new Entity(e))
    this.beneficiary = defaultArray(data.beneficiary).map(e => new Entity(e))
    this.costBasis = defaultFloat(data.cost_basis)
    this.id = defaultString(data.id)
    this.insuranceProceeds = defaultFloat(data.insurance_proceeds)
    this.policyDate = defaultString(data.policy_date)
    this.maturityDate = defaultString(data.maturity_date)
    this.premiumAmount = defaultFloat(data.premium_amount)
    this.premiumMode = defaultEnum(data.premium_mode, Enums.PremiumMode)
    this.policyGrossAccountValue = defaultFloat(data.policy_gross_account_value)
    this.owner = data.owner ? new Entity(data.owner) : new Entity()
    this.policyDocuments = defaultArray(data.policy_documents)
    this.terminationDate = defaultString(data.termination_date)
    this.product = new Product(data.product || {})
    this.latestStatementEndDate = defaultString(data.latest_statement_end_date)
    this.idfs = defaultArray(data.idfs).map(i => new IDF(i))
    this.disableIdf = data.disable_idf
    this.notesVisibility = data.notes_visibility || false
  }

  static modelName = ModelTypes.Annuity

  getFaceValues(ccTypes: Array<string>) {
    if (!ccTypes || ccTypes.length === 0) {
      return ''
    }
    const ccTypeToValue = {
      statements: 4,
      premium: 5,
      policy_changes: 6,
    }

    const faceValues = ccTypes.map(ccType => ccTypeToValue[ccType] || '')

    return faceValues.sort().join(',')
  }

  get insuranceCompanyName(): string {
    return this.product?.insuranceCompany?.name || ''
  }

  get policyType(): string {
    return defaultString(this.product?.productType)
  }

  get title(): string {
    const { accountId, insuranceCompanyName } = this
    return `${insuranceCompanyName}\xA0 - \xA0${accountId}`
  }

  get ownerString(): string {
    return this.owner.formattedName
  }

  get insuredString(): string {
    return this.insured.map(i => i.formattedName).join(', ')
  }

  get annuitant(): Array<Entity> {
    return this.insured
  }

  get formattedMaturityDate(): string {
    if (this.maturityDate) {
      return moment(this.maturityDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get beneficiaryString(): string {
    return this.beneficiary.map(a => a.formattedName).join(', ')
  }

  get costBasisFormat(): string {
    return formatPrice(this.costBasis)
  }

  get formattedTerminationDate(): string {
    if (this.terminationDate) {
      return moment(this.terminationDate).format('M/D/YYYY')
    }
    return Strings.dash
  }

  get formattedPremiumMode(): string {
    return capitalizeFirstLetter(this.premiumMode)
  }

  get formattedAsOfDate(): ?string {
    return this.latestStatementEndDate
      ? moment(this.latestStatementEndDate).format('M/D/YYYY')
      : null
  }

  get detailFilename(): string {
    return Strings.annuityDetailFilename(this.insuranceCompanyName, this.accountId)
  }

  startedOnOrBefore(month: string): boolean {
    return moment(this.policyDate).isSameOrBefore(month)
  }
}
