// @flow

import * as React from 'react'
import dropdownStyles, { customComponents } from './dropdown-styles'
import StyledReact from './styled-react'
import styles from './styles.scss'
import inputStyles from '../input/styles.scss'

type DropdownProps = {|
  value: *,
  onChange: (value: *) => void,
  options: OptionsType<*>,
  ariaLabel: string,
  placeholder: string,
  name: string,
  label?: string,
  error?: ?string,
  disabled?: boolean,
|}

export class Dropdown extends React.Component<DropdownProps> {
  static defaultProps = {
    label: '',
    error: null,
    disabled: false,
  }

  renderError = () => {
    const { error } = this.props
    if (error) {
      return error
    }
    return null
  }

  render() {
    const { ariaLabel, name, label, placeholder, value, onChange, options, error, disabled } =
      this.props
    return (
      <div className={styles.flexColumn}>
        <label id={label} htmlFor={name} className={`${inputStyles.inputLabel} ${styles.label}`}>
          {label}
        </label>
        <StyledReact
          id={name}
          className={styles.dropdown}
          styles={dropdownStyles}
          aria-label={ariaLabel}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          options={options}
          components={customComponents}
          error={error}
          isDisabled={disabled}
        />
        <span className={styles.error}>{this.renderError()}</span>
      </div>
    )
  }
}

export default Dropdown
