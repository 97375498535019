// @flow
import { createBrowserHistory } from 'history'
import GA from './ga'

const history = createBrowserHistory()

history.listen(location => {
  GA.set({ page: location.pathname })
  GA.send({ hitType: 'pageview', page: location.pathname })
})

export default history
