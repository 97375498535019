// @flow
import { merge } from 'lodash'

import { camelObjectToSnakeObject } from '@/helpers'
import { IDFProduct } from '@/models'
import BaseModel from '../base/base-model'
import { defaultString } from '../defaults'
import type { IDFProductDataType } from './idf-product'

export type IDFDataType = {|
  id: ?string,
  product: ?IDFProductDataType,
  policy_id: ?string,
|}

const defaultDataValues = {
  id: null,
  product: null,
  policy_id: null,
}

export default class IDF extends BaseModel {
  id: string

  product: IDFProduct

  policyId: string

  constructor(rawData: IDF | IDFDataType = defaultDataValues) {
    super()
    const data: IDFDataType =
      rawData instanceof IDF
        ? merge({}, defaultDataValues, camelObjectToSnakeObject(rawData))
        : rawData

    this.id = defaultString(data.id)
    this.product = data.product ? new IDFProduct(data.product) : new IDFProduct()
  }

  get productName(): string {
    return defaultString(this.product?.productName)
  }
}
