// @flow

import '../../agency.macro'

import Images from './blank-images'
import Strings from './blank-strings'

// Routes
export const Routes = {
  // Public
  public: {
    Home: '/',
    Invite: '/invite',
    ExpiredReset: '/expired-reset',
    ExpiredRegistration: '/expired-registration',
    LockAccount: '/lock-account',
  },

  // Auth
  auth: {
    Login: '/auth/login',
    VerifyDevice: '/auth/verify-device',
    ForgotPassword: '/auth/forgot-password',
    ResetPassword: '/auth/reset-password',
    AcceptInvite: '/auth/accept-invite',
  },

  // Client Portal
  portal: {
    ClientHome: '/portfolio-summary',
    InsurancePortfolio: '/insurance-portfolio',
    AnnuityPortfolio: '/annuity-portfolio',
    DisabilityPortfolio: '/disability-portfolio',
    DisabilityDetail: '/disability-detail',
    AccountDetail: '/account-detail',
    PolicyDetail: '/policy-detail',
    PolicyDocuments: '/policy-docs',
    IUL: '/iul-statements',
    PPLI: '/ppli-statements',
    VUL: '/vul-statements',
    PPA: '/ppa-statements',
    PPLIDetail: '/ppli-statements/:month',
    VULDetail: '/vul-statements/:month',
    PPADetail: '/ppa-statements/:month',
    ClientList: '/client-list',
    PrivacyNotice: '/privacy-notice',
    Terms: '/terms-and-conditions',
    CustomizedPolicyStatements: '/customize-policy-statements',
    FundPerformance: '/fund-performance',
  },
}

export const PolicyCategories = {
  life: 'life', // includes PrivatePlacement, InsurancePolicy, VULPolicy
  annuity: 'annuity',
}

export const ModalTypes = {
  settings: 'SETTINGS',
  setPassword: 'SET_PASSWORD',
  policyDetailAnnuity: 'POLICY_DETAIL_ANNUITY',
  policyDetailLife: 'POLICY_DETAIL_LIFE',
  policyDetailDisability: 'POLICY_DETAIL_DISABILITY',
  policyDetailVul: 'POLICY_DETAIL_VUL',
  termsOfService: 'TERMS_OF_SERVICE',
  inactivityWarning: 'INACTIVITY_WARNING',
}

export const ModelTypes = {
  PrivatePlacement: 'PrivatePlacement',
  InsurancePolicy: 'InsurancePolicy',
  Annuity: 'Annuity',
  VULPolicy: 'VULPolicy',
  Disability: 'Disability',
}

export const ModalQueryParams = {
  [ModalTypes.settings]: 'settings',
  [ModalTypes.setPassword]: 'setPassword',
}

export const ModalActions = {
  RENDER_MODAL: 'RENDER_MODAL',
  DID_MOUNT: 'DID_MOUNT',
  WILL_UNMOUNT: 'WILL_UNMOUNT',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  SET_DETAIL_CONTENT: 'SET_DETAIL_CONTENT',
  CHANGE_SETTINGS_TAB: 'CHANGE_SETTINGS_TAB',
  REMOVE_MODAL: 'REMOVE_MODAL',
  ACTIVATE_APP_SCROLL: 'ACTIVATE_APP_SCROLL',
  DEACTIVATE_APP_SCROLL: 'DEACTIVATE_APP_SCROLL',
}

export const SettingsViewTypes = {
  email: 'email',
  username: 'username',
  password: 'password',
  mobile: 'mobile',
  activeSessions: 'activeSessions',
}

export const ModalSuccessFlags = {
  email: 'portalSetEmailSuccess',
  // deepcode ignore NoHardcodedPasswords: Not a password but path
  password: 'portalSetPasswordSuccess',
  username: 'portalSetUsernameSuccess',
  mobile: 'portalSetMobileSuccess',
}

export const StatementScreenTypes = {
  ppa: 'ppa',
  ppli: 'ppli',
  vul: 'vul',
}

export type ModalAction = $Values<typeof ModalActions>
export type ModalType = $Values<typeof ModalTypes>
export type ModalQueryParam = $Values<typeof ModalQueryParams>
export type SettingsViewType = $Values<typeof SettingsViewTypes>
export type StatementScreenType = $Values<typeof StatementScreenTypes>

export const Links = {
  portalHome: {
    label: Strings.clientPortal,
    route: Routes.portal.ClientHome,
  },
  public: [],
  portal: [
    {
      label: Strings.clientHome.title,
      route: Routes.portal.ClientHome,
    },
    {
      label: Strings.insurancePortfolio.headerLinkTitle,
      route: Routes.portal.InsurancePortfolio,
    },
    {
      label: Strings.annuityPortfolio.headerLinkTitle,
      route: Routes.portal.AnnuityPortfolio,
    },
    {
      label: Strings.disabilityPortfolio.headerLinkTitle,
      route: Routes.portal.DisabilityPortfolio,
    },
    {
      label: Strings.policyDocuments.title,
      route: Routes.portal.PolicyDocuments,
    },
    {
      label: Strings.customizedPolicyStatements.title,
      route: Routes.portal.CustomizedPolicyStatements,
    },
    // {
    //   label: Strings.fundPerformance.title,
    //   route: Routes.portal.FundPerformance,
    // },
  ],
  footer: [
    {
      label: Strings.terms.title,
      route: Routes.portal.Terms,
      protected: true,
    },
    {
      label: Strings.privacyNotice.title,
      route: Routes.portal.PrivacyNotice,
      protected: false,
    },
  ],
}

export const PortalScreenSections = {
  [Strings.insurancePortfolio.headerLinkTitle]: {
    links: [
      {
        link: Routes.portal.InsurancePortfolio,
        text: Strings.insurancePortfolio.portfolioLinkTitle,
      },
      {
        link: Routes.portal.PolicyDetail,
        text: Strings.insurancePortfolio.policyDetailLinkTitle,
      },
    ],
  },
  [Strings.annuityPortfolio.headerLinkTitle]: {
    links: [
      {
        link: Routes.portal.AnnuityPortfolio,
        text: Strings.annuityPortfolio.portfolioLinkTitle,
      },
      {
        link: Routes.portal.AccountDetail,
        text: Strings.annuityPortfolio.accountDetailLinkTitle,
      },
    ],
  },
  [Strings.disabilityPortfolio.headerLinkTitle]: {
    links: [
      {
        link: Routes.portal.DisabilityPortfolio,
        text: Strings.disabilityPortfolio.portfolioLinkTitle,
      },
      {
        link: Routes.portal.DisabilityDetail,
        text: Strings.disabilityPortfolio.policyDetailLinkTitle,
      },
    ],
  },
  [Strings.customizedPolicyStatements.title]: {
    links: [
      {
        link: Routes.portal.IUL,
        text: Strings.iul.title,
        type: 'iulStatements',
      },
      {
        link: Routes.portal.VUL,
        text: Strings.vul.title,
        type: 'vulStatementsShort',
      },
      {
        link: Routes.portal.PPLI,
        text: Strings.ppli.title,
        type: 'ppliStatementsShort',
      },
      {
        link: Routes.portal.PPA,
        text: Strings.ppa.title,
        type: 'ppaStatementsShort',
      },
    ],
  },
}

export const TableColumnWidths: { [ModelKeys]: number } = {
  accountId: 166,
  account_detail_name: 369,
  account_detail_month: 109,
  account_detail_year_to_date: 109,
  account_detail_since_inception: 109,
  annualPremium: 169,
  accountValue: 169,
  insuredString: 185,
  annuityAccountValue: 169,
  beneficiaryString: 185,
  costBasis: 169,
  deposits: 109,
  depositsWithdrawals: 128,
  description: 298,
  detailMonth: 200,
  detailSinceInception: 200,
  detailYTD: 200,
  idf: 185,
  idfTransactions: 185,
  idfReturn: 139,
  idfReturnsBeforeSubField: 165,
  idfValue: 139,
  insuranceCompany: 185,
  insuranceCompanyName: 185,
  policyDate: 110,
  insuranceProceeds: 169,
  owner: 185,
  ownerString: 185,
  paidTo: 120,
  policyAccountValue: 169,
  policyType: 83,
  premium: 169,
  premiumAmount: 169,
  subaccountValue: 160,
  subaccountReturnsBeforeSubField: 190,
  transactionDate: 138,
  withdrawals: 136.5,
  monthlyBenefit: 169,
  benefitDuration: 169,
  eliminationPeriod: 120,
}

export const MaxTableColumnWidths: { [string]: number } = {
  maxAccountValue: 150,
  maxAnnuitant: 440,
  maxCostBasis: 150,
  maxPolicyType: 250,
  maxBeneficiary: 440,
  maxDetailMonth: 125,
  maxDetailSinceInception: 125,
  maxDetailYTD: 125,
  maxIDF: 440,
  maxIDFTransactions: 440,
  maxInsuranceCompany: 440,
  maxInsured: 440,
  maxPaidTo: 170,
  maxOwner: 440,
  maxWithdrawals: 162,
  maxMonthlyBenefit: 170,
  maxBenefitDuration: 170,
}

export const TablePropAccessors = {
  statement_gross_return_month: 'statement_gross_return_month',
  statement_gross_return_ytd: 'statement_gross_return_ytd',
  statement_gross_return_month_total: 'statement_gross_return_month_total',
  statement_gross_return_ytd_total: 'statement_gross_return_ytd_total',
  product_name: 'product_name',
  beginning_balance: 'beginning_balance',
  deposits_withdrawals: 'deposits_withdrawals',
  statement_return_value: 'statement_return_value',
  statement_ending_balance: 'statement_ending_balance',
  idf_factsheet: 'idf_factsheet',
}

export const SocialMedia = [
  {
    name: 'facebook',
    icon: Images.facebook,
    link: '',
  },
  {
    name: 'twitter',
    icon: Images.twitter,
    link: '',
  },
  {
    name: 'linkedin',
    icon: Images.linkedin,
    link: '',
  },
]

export const Regex = {
  oneLower: /(?=.*[a-z])/,
  oneUpper: /(?=.*[A-Z])/,
  oneNumber: /.*[0-9].*/,
  oneSpecialChar: /(?=.*[~`!@#$%^&*()+=_\-{}[\]|:;"'?/<>,.])/,
}

export const PasswordErrors = [
  Strings.auth.passwordValidErr.min,
  Strings.auth.passwordValidErr.oneLower,
  Strings.auth.passwordValidErr.oneUpper,
  Strings.auth.passwordValidErr.oneNumber,
  Strings.auth.passwordValidErr.oneSpecialChar,
]

export const Enums = {
  VarianceNote: {
    NO_VARIANCE: 'no_variance',
    GENUINE_VARIANCE: 'genuine_variance',
    NO_CHARGE_FALSE_POSITIVE: 'no_charge_false_positive',
    REPLENISHED_FALSE_POSITIVE: 'replenished_false_positive',
  },
  PremiumMode: {
    ANNUAL: 'annual',
    SEMI_ANNUAL: 'semi-annual',
    QUARTERLY: 'quarterly',
    MONTHLY: 'monthly',
    SINGLE_PAY: 'single-pay',
    PERIODIC: 'periodic',
  },
  SchedulePremiumDuration: {
    TEN_YEARS: '10',
    TWENTY_YEARS: '20',
    THIRTY_YEARS: '30',
    INFINITY: '∞',
  },
  DividendOption: {
    TAKEN_AS_CASH: 'Option A:  Cash Payment',
    PREMIUM_REDUCTION: 'Option B:  Reduce Premium',
    DIVIDEND_ACCUMULATION: 'Option C:  Dividend Accumulation',
    PAID_UP_ADDITIONS: 'Option D:  Paid Up Additions',
    OYT_LT_CASH_VALUE_DIVIDEND:
      'Option F:  One-Year Term Addition Not in Excess of Cash Value-Balance to Purchase Dividend Additions',
    OYT_LT_CASH_VALUE_PREMIUM:
      'Option G:  One-Year Term Addition Not in Excess of Policy Cash Value-Balance to Reduce Premium',
    ADDITIONAL_COVERAGE_DIVIDEND: 'Option K:  Additional Coverage Dividend Option',
    OYT_2_POLI_FACE_VALUE_ADDITIONS:
      "Option L:  One-Year Term Addition Up to Twice Policy's Face Amount-Balance to Purchase Additions",
    OYT_2_POLI_FACE_VALUE_PREMIUM:
      "Option P:  One-Year Term Addition Up to Twice Policy's Face Amount-Balance to Reduce Premium",
    OYT_ADDITION_W_FACE_AMOUNT: 'Option Q:  One-Year Term Addition with Target Face Amount',
    INCREASING_Q_TERM: 'Option R:  Increasing Q-Term Option',
    PREMIUM_OFFSET: 'Option S:  Premium Offset',
    LOAN_REPAYMENT: 'Option U:  Loan Repayment Option',
    NOT_APPLICABLE: 'Not Applicable',
    OTHER: 'Other',
  },
  PeriodUnit: {
    MONTHLY: '12',
    QUARTERLY: '3',
    ANNUAL: '1',
  },
  BillingMethod: {
    EMAIL: 'E-mail',
    MAIL: 'Mail',
    BOTH: 'Both',
    OTHER: 'Other',
  },
  PolicyType: {
    TERM: 'Term Life',
    UNIVERSAL_LIFE: 'Universal Life',
    VARIABLE_UNIVERSAL_LIFE: 'Variable Universal Life',
    GUARANTEED_UNIVERSAL_LIFE: 'Guaranteed Universal Life',
    GUARANTEED_VARIABLE_UNIVERSAL_LIFE: 'Guaranteed Variable Universal Life',
    INDEX_UNIVERSAL_LIFE: 'Index Universal Life',
    PRIVATE_PLACEMENT_LIFE: 'Private Placement Life Insurance',
    VARIABLE_ANNUITY: 'Variable Annuity',
    PRIVATE_PLACEMENT_ANNUITY: 'Private Placement Annuity',
    WHOLE: 'Whole Life',
    FIVE_YEAR_TERM: '5-Year Term Life',
    TEN_YEAR_TERM: '10-Year Term Life',
    FIFTEEN_YEAR_TERM: '15-Year Term Life',
    TWENTY_YEAR_TERM: '20-Year Term Life',
    TWENTY_FIVE_YEAR_TERM: '25-Year Term Life',
    THIRTY_YEAR_TERM: '30-Year Term Life',
    THIRTY_FIVE_YEAR_TERM: '35-Year Term Life',
    LONG_TERM_DISABILITY: 'Long-Term Disability',
  },
  PolicyCategoryType: {
    LIFE: 'life', // includes PrivatePlacement, InsurancePolicy, VULPolicy
    ANNUITY: 'annuity',
    DISABILITY: 'disability',
  },
  UploadFileType: {
    POLICY_CONTRACT: 'Policy Contract',
    PLAN_DESIGN: 'Plan Design',
    AS_ACQUIRED_ECONOMIC_ANALYSIS: 'As Acquired    Economic Analysis',
    AS_ACQUIRED_ILLUSTRATION: 'As Acquired Illustration',
    BENCHMARK_ILLUSTRATION: 'Benchmark Illustration',
    MOST_RECENT_ILLUSTRATION: 'Most Recent Illustration',
    ANNUITY_INVESTMENT_CONTRACT: 'Annuity Investment Contract',
    PRIOR_ILLUSTRATIONS: 'Prior Illustrations',
    PROSPECTUS: 'PPM  /  Prospectus',
    INDEX_ACCOUNT_RATES: 'Index Account Rates',
  },
  IULUploadedFileType: {
    COLLATERAL_REPORT: 1,
    LOW_POINT_LETTER: 2,
    PERFORMANCE_REPORT: 3,
    RECONCILIATION_STATEMENT: 4,
  },
  TransactionType: {
    PREMIUM_DEPOSIT: 'Premium Deposit',
    POLICY_ACCOUNT_WITHDRAWL: 'Policy Account Withdrawal',
    STRUCTURING_FEE: 'Structuring Fee',
    PREMIUM_TAX: 'Premium Tax',
    M_AND_E_FEE: 'M&E Fee',
    MORTALITY_CHARGE: 'Mortality Charge',
    TRANSFER: 'Transfer',
    LOAN: 'Loan',
    LOAN_REPAYMENT: 'Loan Repayment',
    LOAN_INTEREST: 'Loan Interest',
    ADJUSTMENT: 'Adjustment',
    OTHER: 'Other',
    PREMIUM_BASED_CHARGES: 'Premium Based Charges',
    POLICY_EXPENSE_CHARGES: 'Policy Expense Charges',
  },
  AuthyVerificationType: {
    VOICE: 'voice',
    SMS: 'sms',
  },
  FootnoteGroupType: {
    CLIENT_LIST: 'Client List',
    POLICY_DOCUMENTS: 'Policy Documents',
    ACCOUNT_DETAIL_PPA: 'Account Detail PPA',
    ACCOUNT_DETAIL_PPLI: 'Account Detail PPLI',
    ACCOUNT_DETAIL_VUL: 'Account Detail VUL',
    ACCOUNT_SUMMARY_PPA: 'Account Summary PPA',
    ACCOUNT_SUMMARY_PPLI: 'Account Summary PPLI',
    ACCOUNT_SUMMARY_VUL: 'Account Summary VUL',
    ACCOUNT_TRANSACTIONS_PPA: 'Account Transactions PPA',
    ACCOUNT_TRANSACTIONS_PPLI: 'Account Transactions PPLI',
    ACCOUNT_TRANSACTIONS_VUL: 'Account Transactions VUL',
    IDF_RETURNS_PPA: 'IDF Returns PPA',
    IDF_RETURNS_PPLI: 'IDF Returns PPLI',
    IDF_RETURNS_VUL: 'IDF Returns VUL',
    PORTFOLIO_ANNUITY: 'Portfolio Annuity',
    PORTFOLIO_LIFE_INSURANCE: 'Portfolio Life Insurance',
    PORTFOLIO_DISABILITY: 'Portfolio Disability',
    INFORCE_ANNUITY: 'Inforce Annuity',
    INFORCE_LIFE_INSURANCE: 'Inforce Life Insurance',
    INFORCE_DISABILITY: 'Inforce Disability',
    PREMIUM_CALENDAR: 'Premium Calendar',
    STATEMENT_LIST_PPA: 'Statement List PPA',
    STATEMENT_LIST_PPLI: 'Statement List PPLI',
    STATEMENT_LIST_VUL: 'Statement List VUL',
  },
  Duration: {
    TWO_MINUTES_IN_SEC: 120,
  },
  DeathBenefitOptions: {
    one: '1',
    two: '2',
    a: 'A',
    b: 'B',
    CORRIDOR_7772: '7702 Corridor',
    five: '5',
  },
  IULTabs: {
    monthly: 'monthly',
    annual: 'annual',
    indexAccountRates: 'indexAccountRates',
  },
}

export const PDFKeys = {
  annuityPortfolio: 'annuity-portfolio',
  annuitySummary: 'annuity-summary',
  disabilitySummary: 'disability-summary',
  insuranceCalendar: 'insurance-calendar',
  insuranceInforce: 'insurance-inforce',
  disabilityInforce: 'disability-inforce',
  insurancePolicyDetail: 'insurance-policy-detail',
  insuranceSummary: 'insurance-summary',
  ppaPolicyDetail: 'ppa-policy-detail',
  ppaStatementDetail: 'ppa-statement-detail',
  ppliPolicyDetail: 'ppli-policy-detail',
  ppliStatementDetail: 'ppli-statement-detail',
  vulPolicyDetail: 'vul-policy-detail',
  vulStatementDetail: 'vul-statement-detail',
  disabilityPolicyDetail: 'disability-policy-detail',
}

export const FileKeys = {
  file: 'file',
  idfAvailabilityPdf: 'idf-availability-pdf',
  iulAnnualStatements: 'iul-annual-statements',
  iulMonthlyStatements: 'iul-monthly-statements',
}

export const DateTypes = {
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
}

export type DateType = $Values<typeof DateTypes>

export { Images, Strings }

// Based on https://gist.github.com/kcak11/4a2f22fb8422342b3b3daa7a1965f4e4#file-countries-json

export const countries = [
  {
    name: 'Afghanistan',
    dialCode: '+93',
    isoCode: 'AF',
  },
  {
    name: 'Aland Islands',
    dialCode: '+358',
    isoCode: 'AX',
  },
  {
    name: 'Albania',
    dialCode: '+355',
    isoCode: 'AL',
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    isoCode: 'DZ',
  },
  {
    name: 'American Samoa',
    dialCode: '+1684',
    isoCode: 'AS',
  },
  {
    name: 'Andorra',
    dialCode: '+376',
    isoCode: 'AD',
  },
  {
    name: 'Angola',
    dialCode: '+244',
    isoCode: 'AO',
  },
  {
    name: 'Anguilla',
    dialCode: '+1264',
    isoCode: 'AI',
  },
  {
    name: 'Antarctica',
    dialCode: '+672',
    isoCode: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    isoCode: 'AG',
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    isoCode: 'AR',
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    isoCode: 'AM',
  },
  {
    name: 'Aruba',
    dialCode: '+297',
    isoCode: 'AW',
  },
  {
    name: 'Ascension Island',
    dialCode: '+247',
    isoCode: 'AC',
  },
  {
    name: 'Australia',
    dialCode: '+61',
    isoCode: 'AU',
  },
  {
    name: 'Austria',
    dialCode: '+43',
    isoCode: 'AT',
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    isoCode: 'AZ',
  },
  {
    name: 'Bahamas',
    dialCode: '+1242',
    isoCode: 'BS',
  },
  {
    name: 'Bahrain',
    dialCode: '+973',
    isoCode: 'BH',
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    isoCode: 'BD',
  },
  {
    name: 'Barbados',
    dialCode: '+1246',
    isoCode: 'BB',
  },
  {
    name: 'Belarus',
    dialCode: '+375',
    isoCode: 'BY',
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    isoCode: 'BE',
  },
  {
    name: 'Belize',
    dialCode: '+501',
    isoCode: 'BZ',
  },
  {
    name: 'Benin',
    dialCode: '+229',
    isoCode: 'BJ',
  },
  {
    name: 'Bermuda',
    dialCode: '+1441',
    isoCode: 'BM',
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    isoCode: 'BT',
  },
  {
    name: 'Bolivia',
    dialCode: '+591',
    isoCode: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    isoCode: 'BA',
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    isoCode: 'BW',
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    isoCode: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    isoCode: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    dialCode: '+673',
    isoCode: 'BN',
  },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    isoCode: 'BG',
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    isoCode: 'BF',
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    isoCode: 'BI',
  },
  {
    name: 'Cambodia',
    dialCode: '+855',
    isoCode: 'KH',
  },
  {
    name: 'Cameroon',
    dialCode: '+237',
    isoCode: 'CM',
  },
  {
    name: 'Canada',
    dialCode: '+1',
    isoCode: 'CA',
  },
  {
    name: 'Cape Verde',
    dialCode: '+238',
    isoCode: 'CV',
  },
  {
    name: 'Cayman Islands',
    dialCode: '+1345',
    isoCode: 'KY',
  },
  {
    name: 'Central African Republic',
    dialCode: '+236',
    isoCode: 'CF',
  },
  {
    name: 'Chad',
    dialCode: '+235',
    isoCode: 'TD',
  },
  {
    name: 'Chile',
    dialCode: '+56',
    isoCode: 'CL',
  },
  {
    name: 'China',
    dialCode: '+86',
    isoCode: 'CN',
  },
  {
    name: 'Christmas Island',
    dialCode: '+61',
    isoCode: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    isoCode: 'CC',
  },
  {
    name: 'Colombia',
    dialCode: '+57',
    isoCode: 'CO',
  },
  {
    name: 'Comoros',
    dialCode: '+269',
    isoCode: 'KM',
  },
  {
    name: 'Congo',
    dialCode: '+242',
    isoCode: 'CG',
  },
  {
    name: 'Cook Islands',
    dialCode: '+682',
    isoCode: 'CK',
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    isoCode: 'CR',
  },
  {
    name: 'Croatia',
    dialCode: '+385',
    isoCode: 'HR',
  },
  {
    name: 'Cuba',
    dialCode: '+53',
    isoCode: 'CU',
  },
  {
    name: 'Cyprus',
    dialCode: '+357',
    isoCode: 'CY',
  },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    isoCode: 'CZ',
  },
  {
    name: 'Democratic Republic of the Congo',
    dialCode: '+243',
    isoCode: 'CD',
  },
  {
    name: 'Denmark',
    dialCode: '+45',
    isoCode: 'DK',
  },
  {
    name: 'Djibouti',
    dialCode: '+253',
    isoCode: 'DJ',
  },
  {
    name: 'Dominica',
    dialCode: '+1767',
    isoCode: 'DM',
  },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    isoCode: 'DO',
  },
  {
    name: 'Ecuador',
    dialCode: '+593',
    isoCode: 'EC',
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    isoCode: 'EG',
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    isoCode: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    isoCode: 'GQ',
  },
  {
    name: 'Eritrea',
    dialCode: '+291',
    isoCode: 'ER',
  },
  {
    name: 'Estonia',
    dialCode: '+372',
    isoCode: 'EE',
  },
  {
    name: 'Eswatini',
    dialCode: '+268',
    isoCode: 'SZ',
  },
  {
    name: 'Ethiopia',
    dialCode: '+251',
    isoCode: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    isoCode: 'FK',
  },
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    isoCode: 'FO',
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    isoCode: 'FJ',
  },
  {
    name: 'Finland',
    dialCode: '+358',
    isoCode: 'FI',
  },
  {
    name: 'France',
    dialCode: '+33',
    isoCode: 'FR',
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    isoCode: 'GF',
  },
  {
    name: 'French Polynesia',
    dialCode: '+689',
    isoCode: 'PF',
  },
  {
    name: 'Gabon',
    dialCode: '+241',
    isoCode: 'GA',
  },
  {
    name: 'Gambia',
    dialCode: '+220',
    isoCode: 'GM',
  },
  {
    name: 'Georgia',
    dialCode: '+995',
    isoCode: 'GE',
  },
  {
    name: 'Germany',
    dialCode: '+49',
    isoCode: 'DE',
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    isoCode: 'GH',
  },
  {
    name: 'Gibraltar',
    dialCode: '+350',
    isoCode: 'GI',
  },
  {
    name: 'Greece',
    dialCode: '+30',
    isoCode: 'GR',
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    isoCode: 'GL',
  },
  {
    name: 'Grenada',
    dialCode: '+1473',
    isoCode: 'GD',
  },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    isoCode: 'GP',
  },
  {
    name: 'Guam',
    dialCode: '+1671',
    isoCode: 'GU',
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    isoCode: 'GT',
  },
  {
    name: 'Guernsey',
    dialCode: '+44',
    isoCode: 'GG',
  },
  {
    name: 'Guinea',
    dialCode: '+224',
    isoCode: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    dialCode: '+245',
    isoCode: 'GW',
  },
  {
    name: 'Guyana',
    dialCode: '+592',
    isoCode: 'GY',
  },
  {
    name: 'Haiti',
    dialCode: '+509',
    isoCode: 'HT',
  },
  {
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    isoCode: 'VA',
  },
  {
    name: 'Honduras',
    dialCode: '+504',
    isoCode: 'HN',
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    isoCode: 'HK',
  },
  {
    name: 'Hungary',
    dialCode: '+36',
    isoCode: 'HU',
  },
  {
    name: 'Iceland',
    dialCode: '+354',
    isoCode: 'IS',
  },
  {
    name: 'India',
    dialCode: '+91',
    isoCode: 'IN',
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    isoCode: 'ID',
  },
  {
    name: 'Iran',
    dialCode: '+98',
    isoCode: 'IR',
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    isoCode: 'IQ',
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    isoCode: 'IE',
  },
  {
    name: 'Isle of Man',
    dialCode: '+44',
    isoCode: 'IM',
  },
  {
    name: 'Israel',
    dialCode: '+972',
    isoCode: 'IL',
  },
  {
    name: 'Italy',
    dialCode: '+39',
    isoCode: 'IT',
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: '+225',
    isoCode: 'CI',
  },
  {
    name: 'Jamaica',
    dialCode: '+1876',
    isoCode: 'JM',
  },
  {
    name: 'Japan',
    dialCode: '+81',
    isoCode: 'JP',
  },
  {
    name: 'Jersey',
    dialCode: '+44',
    isoCode: 'JE',
  },
  {
    name: 'Jordan',
    dialCode: '+962',
    isoCode: 'JO',
  },
  {
    name: 'Kazakhstan',
    dialCode: '+77',
    isoCode: 'KZ',
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    isoCode: 'KE',
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    isoCode: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: '+850',
    isoCode: 'KP',
  },
  {
    name: 'Korea, Republic of South Korea',
    dialCode: '+82',
    isoCode: 'KR',
  },
  {
    name: 'Kosovo',
    dialCode: '+383',
    isoCode: 'XK',
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    isoCode: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    dialCode: '+996',
    isoCode: 'KG',
  },
  {
    name: 'Laos',
    dialCode: '+856',
    isoCode: 'LA',
  },
  {
    name: 'Latvia',
    dialCode: '+371',
    isoCode: 'LV',
  },
  {
    name: 'Lebanon',
    dialCode: '+961',
    isoCode: 'LB',
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    isoCode: 'LS',
  },
  {
    name: 'Liberia',
    dialCode: '+231',
    isoCode: 'LR',
  },
  {
    name: 'Libya',
    dialCode: '+218',
    isoCode: 'LY',
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    isoCode: 'LI',
  },
  {
    name: 'Lithuania',
    dialCode: '+370',
    isoCode: 'LT',
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    isoCode: 'LU',
  },
  {
    name: 'Macau',
    dialCode: '+853',
    isoCode: 'MO',
  },
  {
    name: 'Madagascar',
    dialCode: '+261',
    isoCode: 'MG',
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    isoCode: 'MW',
  },
  {
    name: 'Malaysia',
    dialCode: '+60',
    isoCode: 'MY',
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    isoCode: 'MV',
  },
  {
    name: 'Mali',
    dialCode: '+223',
    isoCode: 'ML',
  },
  {
    name: 'Malta',
    dialCode: '+356',
    isoCode: 'MT',
  },
  {
    name: 'Marshall Islands',
    dialCode: '+692',
    isoCode: 'MH',
  },
  {
    name: 'Martinique',
    dialCode: '+596',
    isoCode: 'MQ',
  },
  {
    name: 'Mauritania',
    dialCode: '+222',
    isoCode: 'MR',
  },
  {
    name: 'Mauritius',
    dialCode: '+230',
    isoCode: 'MU',
  },
  {
    name: 'Mayotte',
    dialCode: '+262',
    isoCode: 'YT',
  },
  {
    name: 'Mexico',
    dialCode: '+52',
    isoCode: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dialCode: '+691',
    isoCode: 'FM',
  },
  {
    name: 'Moldova',
    dialCode: '+373',
    isoCode: 'MD',
  },
  {
    name: 'Monaco',
    dialCode: '+377',
    isoCode: 'MC',
  },
  {
    name: 'Mongolia',
    dialCode: '+976',
    isoCode: 'MN',
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    isoCode: 'ME',
  },
  {
    name: 'Montserrat',
    dialCode: '+1664',
    isoCode: 'MS',
  },
  {
    name: 'Morocco',
    dialCode: '+212',
    isoCode: 'MA',
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    isoCode: 'MZ',
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    isoCode: 'MM',
  },
  {
    name: 'Namibia',
    dialCode: '+264',
    isoCode: 'NA',
  },
  {
    name: 'Nauru',
    dialCode: '+674',
    isoCode: 'NR',
  },
  {
    name: 'Nepal',
    dialCode: '+977',
    isoCode: 'NP',
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    isoCode: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    dialCode: '+599',
    isoCode: 'AN',
  },
  {
    name: 'New Caledonia',
    dialCode: '+687',
    isoCode: 'NC',
  },
  {
    name: 'New Zealand',
    dialCode: '+64',
    isoCode: 'NZ',
  },
  {
    name: 'Nicaragua',
    dialCode: '+505',
    isoCode: 'NI',
  },
  {
    name: 'Niger',
    dialCode: '+227',
    isoCode: 'NE',
  },
  {
    name: 'Nigeria',
    dialCode: '+234',
    isoCode: 'NG',
  },
  {
    name: 'Niue',
    dialCode: '+683',
    isoCode: 'NU',
  },
  {
    name: 'Norfolk Island',
    dialCode: '+672',
    isoCode: 'NF',
  },
  {
    name: 'North Macedonia',
    dialCode: '+389',
    isoCode: 'MK',
  },
  {
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
    isoCode: 'MP',
  },
  {
    name: 'Norway',
    dialCode: '+47',
    isoCode: 'NO',
  },
  {
    name: 'Oman',
    dialCode: '+968',
    isoCode: 'OM',
  },
  {
    name: 'Pakistan',
    dialCode: '+92',
    isoCode: 'PK',
  },
  {
    name: 'Palau',
    dialCode: '+680',
    isoCode: 'PW',
  },
  {
    name: 'Palestine',
    dialCode: '+970',
    isoCode: 'PS',
  },
  {
    name: 'Panama',
    dialCode: '+507',
    isoCode: 'PA',
  },
  {
    name: 'Papua New Guinea',
    dialCode: '+675',
    isoCode: 'PG',
  },
  {
    name: 'Paraguay',
    dialCode: '+595',
    isoCode: 'PY',
  },
  {
    name: 'Peru',
    dialCode: '+51',
    isoCode: 'PE',
  },
  {
    name: 'Philippines',
    dialCode: '+63',
    isoCode: 'PH',
  },
  {
    name: 'Pitcairn',
    dialCode: '+872',
    isoCode: 'PN',
  },
  {
    name: 'Poland',
    dialCode: '+48',
    isoCode: 'PL',
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    isoCode: 'PT',
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1939',
    isoCode: 'PR',
  },
  {
    name: 'Qatar',
    dialCode: '+974',
    isoCode: 'QA',
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    isoCode: 'RE',
  },
  {
    name: 'Romania',
    dialCode: '+40',
    isoCode: 'RO',
  },
  {
    name: 'Russia',
    dialCode: '+7',
    isoCode: 'RU',
  },
  {
    name: 'Rwanda',
    dialCode: '+250',
    isoCode: 'RW',
  },
  {
    name: 'Saint Barthelemy',
    dialCode: '+590',
    isoCode: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    isoCode: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    isoCode: 'KN',
  },
  {
    name: 'Saint Lucia',
    dialCode: '+1758',
    isoCode: 'LC',
  },
  {
    name: 'Saint Martin',
    dialCode: '+590',
    isoCode: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    isoCode: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    isoCode: 'VC',
  },
  {
    name: 'Samoa',
    dialCode: '+685',
    isoCode: 'WS',
  },
  {
    name: 'San Marino',
    dialCode: '+378',
    isoCode: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    isoCode: 'ST',
  },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    isoCode: 'SA',
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    isoCode: 'SN',
  },
  {
    name: 'Serbia',
    dialCode: '+381',
    isoCode: 'RS',
  },
  {
    name: 'Seychelles',
    dialCode: '+248',
    isoCode: 'SC',
  },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    isoCode: 'SL',
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    isoCode: 'SG',
  },
  {
    name: 'Sint Maarten',
    dialCode: '+1721',
    isoCode: 'SX',
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    isoCode: 'SK',
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    isoCode: 'SI',
  },
  {
    name: 'Solomon Islands',
    dialCode: '+677',
    isoCode: 'SB',
  },
  {
    name: 'Somalia',
    dialCode: '+252',
    isoCode: 'SO',
  },
  {
    name: 'South Africa',
    dialCode: '+27',
    isoCode: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    isoCode: 'GS',
  },
  {
    name: 'South Sudan',
    dialCode: '+211',
    isoCode: 'SS',
  },
  {
    name: 'Spain',
    dialCode: '+34',
    isoCode: 'ES',
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    isoCode: 'LK',
  },
  {
    name: 'Sudan',
    dialCode: '+249',
    isoCode: 'SD',
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    isoCode: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    isoCode: 'SJ',
  },
  {
    name: 'Sweden',
    dialCode: '+46',
    isoCode: 'SE',
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    isoCode: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    isoCode: 'SY',
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    isoCode: 'TW',
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    isoCode: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dialCode: '+255',
    isoCode: 'TZ',
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    isoCode: 'TH',
  },
  {
    name: 'Timor-Leste',
    dialCode: '+670',
    isoCode: 'TL',
  },
  {
    name: 'Togo',
    dialCode: '+228',
    isoCode: 'TG',
  },
  {
    name: 'Tokelau',
    dialCode: '+690',
    isoCode: 'TK',
  },
  {
    name: 'Tonga',
    dialCode: '+676',
    isoCode: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    isoCode: 'TT',
  },
  {
    name: 'Tunisia',
    dialCode: '+216',
    isoCode: 'TN',
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    isoCode: 'TR',
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    isoCode: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    isoCode: 'TC',
  },
  {
    name: 'Tuvalu',
    dialCode: '+688',
    isoCode: 'TV',
  },
  {
    name: 'Uganda',
    dialCode: '+256',
    isoCode: 'UG',
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    isoCode: 'UA',
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    isoCode: 'AE',
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    isoCode: 'GB',
  },
  {
    name: 'United States',
    dialCode: '+1',
    isoCode: 'US',
  },
  {
    name: 'United States Minor Outlying Islands',
    dialCode: '+246',
    isoCode: 'UMI',
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    isoCode: 'UY',
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    isoCode: 'UZ',
  },
  {
    name: 'Vanuatu',
    dialCode: '+678',
    isoCode: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dialCode: '+58',
    isoCode: 'VE',
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    isoCode: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    isoCode: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    isoCode: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    dialCode: '+681',
    isoCode: 'WF',
  },
  {
    name: 'Yemen',
    dialCode: '+967',
    isoCode: 'YE',
  },
  {
    name: 'Zambia',
    dialCode: '+260',
    isoCode: 'ZM',
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    isoCode: 'ZW',
  },
]

export const LOGIN_STATUSES = {
  SUCCESS: 'SUCCESS',
  DISABLED: 'DISABLED',
  TWO_FA_SENT: '2FA_SENT',
}
