// @flow
import React from 'react'

type HeaderCellProps = {|
  children: ?any,
  style: Object,
  accessor?: string,
|}

const HeaderCell = (props: HeaderCellProps) => {
  const { children, style, accessor } = props

  return (
    <div className="tableHeader" style={style} data-accessor={`cell-${accessor}`}>
      {children}
    </div>
  )
}

export default HeaderCell
