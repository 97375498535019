// @flow

import moment from 'moment'
import BaseModel from './base/base-model'
import { defaultString } from './defaults'

export default class SessionStatus extends BaseModel {
  id: number | null

  created_at: string

  expires_at: string

  persistent: boolean

  ip_address: string

  location: string

  device: string

  is_current: boolean

  get createdDateFormatted(): string {
    return defaultString(moment(this.created_at).format('MM/DD/YYYY'))
  }

  get expiresDateFormatted(): string {
    return defaultString(moment(this.expires_at).format('MM/DD/YYYY'))
  }

  constructor(data: {
    id: number,
    created_at: string,
    expires_at: string,
    persistent: boolean,
    ip_address: string,
    location: string,
    device: string,
    is_current: boolean,
  }) {
    super()
    this.id = data.id
    this.created_at = data.created_at
    this.expires_at = data.expires_at
    this.persistent = data.persistent
    this.ip_address = data.ip_address
    this.location = data.location
    this.device = data.device
    this.is_current = data.is_current
  }
}
