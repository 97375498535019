// @flow
import * as React from 'react'
import { HashLink } from 'react-router-hash-link'
import moment from 'moment'

import { Strings, Routes, MaxTableColumnWidths, TableColumnWidths } from '@/constants'

export const ppaAccountSummaryColumns = (cellFormat?: (row: *) => React.Node) => [
  {
    name: Strings.table.insuranceCompany,
    accessor: 'insuranceCompanyName',
    group: true,
    sortable: true,
    footerText: Strings.table.total,
    maxWidth: MaxTableColumnWidths.maxInsuranceCompany,
  },
  {
    name: Strings.table.accountNumber,
    accessor: 'accountId',
    cellFormat,
    width: TableColumnWidths.accountId,
  },
  {
    name: Strings.table.annuitant,
    accessor: 'insuredString',
    group: true,
    sortable: true,
    width: TableColumnWidths.insuredString,
    maxWidth: MaxTableColumnWidths.maxAnnuitant,
  },
  {
    name: Strings.table.owner,
    accessor: 'ownerString',
    group: true,
    sortable: true,
    width: TableColumnWidths.owner,
    maxWidth: MaxTableColumnWidths.maxOwner,
  },
  {
    name: Strings.table.beneficiary,
    accessor: 'beneficiaryString',
    group: true,
    sortable: true,
    width: TableColumnWidths.beneficiary,
    maxWidth: MaxTableColumnWidths.maxBeneficiary,
  },
  {
    name: Strings.table.policyDate,
    accessor: 'formattedPolicyDate',
    sortable: true,
    width: TableColumnWidths.policyDate,
    cellFormat: (row: *) => {
      if (!row.value) {
        return null
      }
      return (
        <HashLink
          className="tableLink"
          key={row.original.accountId}
          to={`${Routes.portal.PolicyDocuments}/?accountId=${row.original.accountId}`}
          scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
        >
          {row.value && moment(row.value).format('M/D/YYYY')}
        </HashLink>
      )
    },
  },
  {
    name: Strings.table.costBasis,
    accessor: 'costBasis',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.costBasis,
  },
  {
    name: Strings.table.accountValue,
    accessor: 'policyGrossAccountValue',
    superscript: '1',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.accountValue,
    maxWidth: MaxTableColumnWidths.maxAccountValue,
  },
]

export const ppliAccountSummaryColumns = (cellFormat?: (row: *) => React.Node) => [
  {
    name: Strings.table.insuranceCompany,
    accessor: 'insuranceCompanyName',
    group: true,
    sortable: true,
    footerText: Strings.table.total,
    maxWidth: MaxTableColumnWidths.maxInsuranceCompany,
  },
  {
    name: Strings.table.accountNumber,
    accessor: 'accountId',
    cellFormat,
    width: TableColumnWidths.accountId,
  },
  {
    name: Strings.table.insured,
    accessor: 'insuredString',
    group: true,
    sortable: true,
    maxWidth: MaxTableColumnWidths.maxInsured,
    width: TableColumnWidths.insured,
  },
  {
    name: Strings.table.owner,
    accessor: 'ownerString',
    group: true,
    sortable: true,
    width: TableColumnWidths.owner,
    maxWidth: MaxTableColumnWidths.maxOwner,
  },
  {
    name: Strings.table.beneficiary,
    accessor: 'beneficiaryString',
    group: true,
    sortable: true,
    width: TableColumnWidths.beneficiary,
    maxWidth: MaxTableColumnWidths.maxBeneficiary,
  },
  {
    name: Strings.table.policyDate,
    accessor: 'formattedPolicyDate',
    sortable: true,
    width: TableColumnWidths.policyDate,
    cellFormat: (row: *) => {
      if (!row.value) {
        return null
      }
      return (
        <HashLink
          className="tableLink"
          key={row.original.accountId}
          to={`${Routes.portal.PolicyDocuments}/?accountId=${row.original.accountId}`}
          scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
        >
          {row.value && moment(row.value).format('M/D/YYYY')}
        </HashLink>
      )
    },
  },
  {
    name: Strings.table.costBasis,
    accessor: 'costBasis',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.costBasis,
  },
  {
    name: Strings.table.accountValue,
    accessor: 'policyGrossAccountValue',
    superscript: '1',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.accountValue,
    maxWidth: MaxTableColumnWidths.maxAccountValue,
  },
  {
    name: Strings.table.insuranceProceeds,
    accessor: 'insuranceProceeds',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.insuranceProceeds,
  },
]

export const vulAccountSummaryColumns = (cellFormat?: (row: *) => React.Node) => [
  {
    name: Strings.table.insuranceCompany,
    accessor: 'insuranceCompanyName',
    group: true,
    sortable: true,
    footerText: Strings.table.total,
    maxWidth: MaxTableColumnWidths.maxInsuranceCompany,
  },
  {
    name: Strings.table.accountNumber,
    accessor: 'accountId',
    cellFormat,
    width: TableColumnWidths.accountId,
  },
  {
    name: Strings.table.insured,
    accessor: 'insuredString',
    group: true,
    sortable: true,
    maxWidth: MaxTableColumnWidths.maxInsured,
    width: TableColumnWidths.insured,
  },
  {
    name: Strings.table.owner,
    accessor: 'ownerString',
    group: true,
    sortable: true,
    width: TableColumnWidths.owner,
    maxWidth: MaxTableColumnWidths.maxOwner,
  },
  {
    name: Strings.table.beneficiary,
    accessor: 'beneficiaryString',
    group: true,
    sortable: true,
    width: TableColumnWidths.beneficiary,
    maxWidth: MaxTableColumnWidths.maxBeneficiary,
  },
  {
    name: Strings.table.policyDate,
    accessor: 'formattedPolicyDate',
    sortable: true,
    width: TableColumnWidths.policyDate,
    cellFormat: (row: *) => {
      if (!row.value) {
        return null
      }
      return (
        <HashLink
          className="tableLink"
          key={row.original.accountId}
          to={`${Routes.portal.PolicyDocuments}/?accountId=${row.original.accountId}`}
          scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
        >
          {row.value && moment(row.value).format('M/D/YYYY')}
        </HashLink>
      )
    },
  },
  {
    name: Strings.table.costBasis,
    accessor: 'costBasis',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.costBasis,
  },
  {
    name: Strings.table.accountValue,
    accessor: 'policyGrossAccountValue',
    superscript: '1',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.accountValue,
    maxWidth: MaxTableColumnWidths.maxAccountValue,
  },
  {
    name: Strings.table.insuranceProceeds,
    accessor: 'insuranceProceeds',
    showTotal: true,
    isCurrency: true,
    width: TableColumnWidths.insuranceProceeds,
  },
]

export default {
  ppaAccountSummaryColumns,
  ppliAccountSummaryColumns,
  vulAccountSummaryColumns,
}
