// @flow
import React from 'react'

import Throbber from './throbber'

import styles from './styles.scss'

type LoadingProps = {|
  isTable?: boolean,
  tableWidth?: ?number,
|}

const Loading = (props: LoadingProps) => {
  const { isTable, tableWidth } = props

  let widthStyle = null
  if (isTable && tableWidth && tableWidth > 0) {
    widthStyle = { width: tableWidth }
  }

  return (
    <div className={styles.loadingContainer} style={widthStyle}>
      <div className={styles.loadingContent}>
        <Throbber />
      </div>
    </div>
  )
}

Loading.defaultProps = {
  isTable: false,
  tableWidth: null,
}

export default Loading
